import $ from "jquery";

function validate(element_ids, validation_error_response) {
  for (const [key, value] of Object.entries(validation_error_response)) {
    //remove any valid feedback tags entered before.
    let element = $("#" + `${key}`)
    element.removeClass("is-valid").after().removeAttr("div")
    //remove any invalid elements that already exist
    $("#" + key + "." + "invalid-feedback").remove()
    element.addClass("is-invalid")
      .after("<div class='invalid-feedback'" + "id='" + key + "'" + ">" + `${value}` + "</div>")

    //get element position in array
    let element_index = element_ids.indexOf(`${key}`)
    // remove the element from valid elements
    element_ids.splice(element_index, 1)
  }
  //add a valid tag to the valid elements
  element_ids.forEach(valid_element => {
    // remove any invalid tags
    let element = $("#" + valid_element)
    element.removeClass("is-invalid").after().removeAttr("div")
    element.addClass("is-valid")
  });
}

function toggleActive(element) {
  $('li.active').removeClass("active")
  $(element.target).closest("li").addClass("active")
  //
}



function toTitleCase(str) {
  if (str) {
    return capitalize(str.replace(/[_-]/g, " "));
  } else {
    return str
  }
}

function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return x
  }
}

function calculateAge(birthday) { // birthday is a date
  let parsed_birthday = new Date(birthday)
  var ageDifMs = Date.now() - parsed_birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function capitalize(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
function displayServerErrMessage(err){
  let full_error_message = ''
  Object.keys(err).map(msg_instance => {
    full_error_message += ` <span class="capitalize"> ${msg_instance.toString()} + ':' + ${err[msg_instance]} + "\n" </span>`;
  })
  this.$notification.error(full_error_message, {title:'An Error Occurred',});
}

function decodeToken(token=''){
  let tk=''
  token=== '' ? tk = localStorage.getItem('token') : tk=token
  try {
    return JSON.parse(atob(tk.split('.')[1]));
    // eslint-disable-next-line no-empty
  } catch (e) {
  }
}

export {
  decodeToken,
  displayServerErrMessage,
  validate,
  toggleActive,
  toTitleCase,
  numberWithCommas,
  calculateAge
}