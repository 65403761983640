<template>
  <div :input_name="input_name" class="invalid-feedback">
              <span v-for="(error, index) in getError[input_name]" v-bind:key="index">
                {{ error }}
              </span>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'InputErrorComponent',
  props: ['input_name'],
  methods: {
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getError'
    ]),
  },
}
</script>
