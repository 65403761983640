import api from "@/api";

const state ={
  all_levels: [
    {
      id: 1,
      name: "national",
      verbose_name: "National",
    },
    {
      id: 2,
      name: "regional",
      verbose_name: "Regional",
    },
    {
      id: 3,
      name: "county",
      verbose_name: "County",
    },
    {
      id: 4,
      name: "subcounty",
      verbose_name: "Sub-County"
    },
    {
      id: 5,
      name: "facility",
      verbose_name: "Facility",
    },
    {
      id: 6,
      name: "vendor",
      verbose_name: "Vendor",
    },
    {
      id: 7,
      name: "distributor",
      verbose_name: "Distributor",
    }
  ],
  all_centers: [
  ],
  national_centers :[
  ],
  regional_centers: [
  ],
  ward_centers: [
  ]
}
const getters ={
  allNationalCenters(state){
    return state.national_centers
  },
  allRegionalCenters(state){
    return state.regional_centers
  },
  allWardCenters(state){
    return state.ward_centers
  },
  allCenters(state){
    return state.all_centers
  },
  getCenterById: (state) => (level_id) => {
    state.all_levels.filter((level) =>{
        return level.id = level_id
    })
  },
  allLevels(state){
    return state.all_levels
  }
}

const actions ={
  fetchCenters: ({commit}, payload) => {

    // If payload is undefined
    if(!payload){
        payload = ''
    }

    return new Promise((resolve, reject)=>{
      api.get('/distribution-center/' + payload ).then(resp => {
        commit('setAllCenters', resp.data)
        resolve(resp.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchSingleCenter({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/distribution-center/'+payload).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
}
const mutations ={
  setNationalCenters:(state, national_centers) => (state.national_centers = national_centers),
  setRegionalCenters:(state, regional_centers) => (state.regional_centers = regional_centers),
  setWardCenters:(state, ward_centers) => (state.ward_centers = ward_centers),
  setAllCenters:(state, all_centers) => (state.all_centers = all_centers),
  setAllLevels:(state, all_levels) => (state.all_levels = all_levels)
}

export default {
  state,
  getters,
  actions,
  mutations,
}