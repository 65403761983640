<template>
  <div>
    <!-- Start Leftbar -->
    <div class="leftbar">
      <!-- Start Sidebar -->
      <div class="sidebar">
        <!-- Start Logobar -->
        <div class="logobar">
          <a class="logo logo-large" href="#">
            <img
                alt="logo" class="img-fluid" src="@/assets/images/kenya_emblem.png">
            <span class="font-15 pt-3 text-white" style="font-weight: 600">
              Chanjo KE
            </span>
          </a>
          <a class="logo logo-small" href="#"><img
              alt="logo" class="img-fluid" src="@/assets/images/logo.png"></a>
        </div>
        <!-- End Logobar -->
        <!-- Start Navigationbar -->
        <div class="navigationbar active">
          <ul v-for="(fetched_route, index) in fetched_routes" :key="index" class="vertical-menu in">
            <li v-if="fetched_route.children.length > 0 || (fetched_route.isSingleRoute && allowed_routes.indexOf(fetched_route.name) !== -1)">
              <a href="#" :class="[currently_active_route===fetched_route.name ? ' active': ' ']" @click="toggleActiveRoute(fetched_route.name)">
                <i v-if="!fetched_route.icon" class="dripicons-graph-bar"></i>
                <span v-else>
                                      <span v-html="fetched_route.icon"></span>
                                    </span>
                <span :class="['text-capitalize pl-3']">{{
                    fetched_route.parentName ? fetched_route.parentName : (fetched_route.name).replace(/([A-Z])/g, " $1")
                  }}
                                    </span>
                <i v-if="fetched_route.children" class="feather icon-chevron-right pull-right"></i>
              </a>

              <ul :class="['vertical-submenu ', (currently_active_route === (fetched_route.name).toString() || currently_active_parent === fetched_route.name)  ? 'menu-open d-block' : ' d-none']">
                <li v-if="allowed_routes.indexOf(fetched_route.name) !== -1">
                  <router-link v-slot="{href, route, navigate, isActive }"
                               :to={name:fetched_route.name}>
                    <a :href="href" :class="[isActive ? 'active': '']" @click="navigate">
                      <span :class="['text-capitalize ']">{{
                          fetched_route.verboseName ? fetched_route.verboseName : (route.name).replace(/([A-Z])/g, " $1")
                        }}
                    </span>
                    </a>
                  </router-link>
                </li>
                <li v-for="(child_route, index) in fetched_route.children" :key="index">
                  <router-link v-if="child_route.showInLeftBar !== false" v-slot="{href, route, navigate, isActive }"
                               :to={name:child_route.name}>
                    <a :class="['text-capitalize ', isActive ? ' active ' : ' ']" :href="href"
                       @click="navigate">
                      <!--                      {{ (child_route.name).replace(/([A-Z])/g, " $1") }}-->
                      {{ child_route.verboseName? child_route.verboseName: makeRouteReadable(child_route.name)}}</a>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- End Navigationbar -->
      </div>
      <!-- End Sidebar -->
    </div>
    <!-- End Leftbar -->
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import route_permissions from '@/router/route_permissions.json'
export default {
  name: "LeftBar",
  data: function () {
    return {
      active_menu_item: undefined,
      auth_user: null,
      fetched_routes: [],
      parent_routes:[],
      allowed_routes: [],
      currently_active_route: '',
      currently_active_parent: '',
    };
  },
  props: [
    'centerInfo',
  ],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters(['getUser']),
    currentRoutePath() {
      return this.$route.path;
    }
  }, methods: {

    getUserRoles() {
      let tk = this.$store.getters.getToken;
      try {
        let tkres = JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
        // eslint-disable-next-line no-empty
      } catch (e) {
        return []
      }
    },
    makeRouteReadable(originalRoute = ''){
      let resultingString = originalRoute.replace(/([A-Z])/g, " $1")
      resultingString = resultingString.split('_').join(' ')
      return resultingString;
    },
    setActiveRoute(routeName) {
      this.currently_active_route = routeName
    },
    toggleActiveRoute(routeName) {
      if(this.currently_active_route === routeName){
        this.currently_active_route = ''
      }else{
        this.currently_active_route = routeName
      }
      if(this.currently_active_parent === routeName){
        this.currently_active_parent = ''
        this.currently_active_route = ''
      }else{
        this.currently_active_parent = routeName
      }
    },
    returnIncluded(route, userAllowedRoutes){
      let includedItems = []
      if(route.children){
        route.children.map(item =>{
          if(userAllowedRoutes.indexOf(item.name) !== -1){
            includedItems.push(item)
          }
        });
        return includedItems
      }else{
        return []
      }

    },
    userHasRole(role) {
      return this.getUserRoles().includes(role)
    }
  },
  mounted() {
    let userAllowedRoutes = []
    let userAllowedComponents = {}
    let user_specific_route_permissions = []
    let user_roles = this.getUserRoles()
    Object.keys(route_permissions).map(role=>{
      if(user_roles.indexOf(role) !== -1){
        user_specific_route_permissions.push(route_permissions[role])
      }
    })
    // console.log("roles", user_roles)
    Object.keys(user_specific_route_permissions).map(role_name=>{
      Object.keys(user_specific_route_permissions[role_name]).map(permission_key=>{
        if(Object.keys(userAllowedComponents).indexOf(permission_key) !== -1){
          //this key already exists
          userAllowedComponents[permission_key] = userAllowedComponents[permission_key].concat(user_specific_route_permissions[role_name][permission_key])
        }else{
          userAllowedComponents[permission_key] = user_specific_route_permissions[role_name][permission_key]
        }
        if(userAllowedRoutes.indexOf(permission_key) === -1)
          userAllowedRoutes.push(permission_key)
      })
    })
    // console.log("allowed", userAllowedRoutes)
    this.allowed_routes = userAllowedRoutes
    this.$store.dispatch('createAllowedComponents', userAllowedComponents)
    this.getUserRoles()
    this.$router.options.routes.forEach(route => {
      // if (route.showInLeftBar !== false && userAllowedRoutes.indexOf(route.name) !== -1) {
      if (route.showInLeftBar !== false ) {
        this.parent_routes.push(route.path)
        this.fetched_routes.push({
          name: route.name,
          verboseName: route.verboseName,
          parentName: route.parentName,
          path: route.path,
          icon: route.icon,
          isSingleRoute: route.isSingleRoute,
          children: this.returnIncluded(route, userAllowedRoutes)
        })
      }

    })
    // console.log("routes", this.fetched_routes)
    // this.currentlyActiveRoute= this.$route.name
  },
  watch: {
    $route(to) {
      this.currently_active_route = to.name
    }
  },

}
</script>
<style>

</style>