<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Vaccination Stats"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="$func.userHasRoles(['Vaccinator','Trainer of Trainers(TOT)','Manager'])">
        <BriefStatsReport/>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions} from "vuex";
import BriefStatsReport from "../../components/reports/BriefStatsReport";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "CummulativeStats",
  components: {AccessDeniedCard, BriefStatsReport, Breadcrumbs},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
  },
  mounted() {
    this.clearErrors()
  }
}
</script>

<style scoped>

</style>