import api from "@/api";

const state = {
  batches: [],
  batches_to_approve: [],
  error: {}
}
const getters = {
  allBatches(state) {
    return state.batches
  },
  allBatchesToApprove(state) {
    return state.batches_to_approve
  }
}
const actions = {
  fetchBatches({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/vaccine-batch/").then(resp => {
        commit("setBatch", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  createBatch({commit}, payload) {
    const createBatchResp = new Promise((resolve, reject) => {
      api({
        url: '/vaccine-batch/', method: 'POST', data: {
          batch_number: payload.batch_number,
          date_of_manufacture: payload.date_of_manufacture,
          date_of_expiry: payload.date_of_expiry,
          starting_quantity: payload.quantity,
          vaccine_id: payload.vaccine_id, approved: payload.approved,
          vaccine_brand_id: payload.vaccine_brand_id
        }
      }).then(resp => {
        commit('addBatch', resp.data)
        api({
          url: '/distribution-transactions/', method: "POST", data: {
            batch_id: resp.data.id,
            quantity: resp.data.starting_quantity,
            action: payload.action,
            condition: payload.condition,
            remark: payload.remark,
            is_returned: payload.is_returned,
            center_id: payload.to_center_id,
            received_from: payload.received_from,
            to_center_id: payload.to_center_id,
            s_13: payload.s13_delivery_note,
            s_11: payload.s11_delivery_reports,
            vvm_status: payload.vvm_status
          }
          // eslint-disable-next-line no-unused-vars
        }).then(resp => {

          }
        ).catch(err => {
          commit('set_error', err.response.data)
        })
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      });
    })
    return createBatchResp

  },
  dispatchBatch({commit}, {batchNo, dispatchTo}) {
    commit('dispatchBatch', {batchNo, dispatchTo})
  },
  fetchBatchesToApprove({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/vaccine-batch/?vendor_type=private").then(resp => {
        commit("setBatchesToApprove", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  updateBatchInventory({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/vaccine-batch/' + payload.id + '/', method: "PATCH", data: {
          batch_number: payload.batch_number,
          approved: payload.approved,
          approval_remarks: payload.remark
        }
      }).then(resp => {
        // commit('setAdverseEffectsForPerson', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  updateBatchDailyChange({commit}, payload) {
    return new Promise((resolve, reject)=>{
      api.post("/batch-inventory-daily-balance/", payload).then(resp=>{
        resolve (resp.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  cancelBatchDailyReport({commit}, payload) {
    return new Promise((resolve, reject)=>{
      var url = ''
      if(payload.type === 'report'){
        url = '/batch-inventory-daily-balance/' + payload.id + '/cancel-report/'
      }else{
        url = '/batch-inventory-daily-balance/' + payload.id + '/cancel-transaction/'
      }

      api({
        url: url, method: "PATCH", data: {
          remarks: payload.remarks
        }
      }).then(resp => {
        resolve(resp.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}
const mutations = {
  setBatch: (state, batches) => (state.batches = batches),
  setBatchesToApprove: (state, batches_to_approve) => (state.batches_to_approve = batches_to_approve),
  addBatch: (state, batch) => (state.batches.push(batch)),
  dispatchBatch(state, {batchNo, dispatchTo}) {
    state.batches.find((batch) => {
      if (batch.batchNo === batchNo) {
        batch['dispatchedTo'] = dispatchTo;
        batch['status'] = 'dispatched';
        return true; // stop searching
      }
    });
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}