<template>
  <a class="btn btn-white pr-2">
    Loading
    <div class="spinner-grow  text-success ml-1" role="status" style="height: 20px; width: 20px">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger ml-1" role="status" style="height: 20px; width: 20px">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-warning ml-1" role="status" style="height: 20px; width: 20px">
      <span class="sr-only">Loading...</span>
    </div>
  </a>
</template>
<script>
export default {
  name: 'SubmittingLoader'
}
</script>