<template>
  <div>
    <!-- Start Topbar Mobile -->
    <div class="topbar-mobile">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="mobile-logobar mt-2">
            <a class="mobile-logo pt-1" href="#">

              <img
                  alt="logo" class="img-fluid mt-2" src="../../../assets/images/kenya_emblem.png" style="display: none;">


            </a><span class="mt-3">Chanjo KE</span>
            <!--            https://www.flaticon.com/free-icon/vaccine_3027579?term=vaccine&page=1&position=11&page=1&position=11&related_id=3027579&origin=style-->

          </div>
          <div class="mobile-togglebar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <div class="topbar-toggle-icon">
                  <a class="topbar-toggle-hamburger" @click="closeOpenTopBar()" href="#">
                    <img alt="horizontal"
                         class="img-fluid menu-hamburger-horizontal"
                         src="../../../../src/assets/images/icons/ellipsis_horizontal.png">
                    <img alt="verticle"
                         class="img-fluid menu-hamburger-vertical"
                         src="../../../../src/assets/images/icons/ellipsis.png">
                  </a>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="menubar">
                  <a class="menu-hamburger" @click="toggleHumburger">
                    <img alt="collapse"
                         class="img-fluid menu-hamburger-collapse"
                         src="../../../../src/assets/images/icons/collapse.png">
                    <img alt="close"
                         class="img-fluid menu-hamburger-close" src="../../../../src/assets/images/icons/close.png">
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Topbar Mobile -->
    <!-- Start Topbar -->
    <div class="topbar">
      <!-- Start row -->
      <div class="row ">
        <!-- Start col -->
        <div class="col-md-12 align-self-center">
          <div class="togglebar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <div class="menubar">
                </div>
              </li>
              <li class="list-inline-item align-items-center" style="padding-top: 11.8px">
                <h6 class="text-center align-baseline ml-1 text-capitalize" style="color:aliceblue">
                  {{ centerInfo.name }} - {{ (centerInfo.level !== null) ? centerInfo.level : '' }}
                </h6>
              </li>
            </ul>
          </div>
          <div class="infobar">
            <ul class="list-inline mb-0">
              <!-- User authenticated -->
              <li class="list-inline-item">
                <div class="profilebar mt-2">
                  <div class="dropdown">
                    <a id="profilelink2" aria-expanded="false" aria-haspopup="true" class="dropdown-toggle "
                       data-toggle="dropdown" href="#"
                       role="button">
                      <!--Dynamic User Avatar -->
                      <!--                      <img-->
                      <!--                          :src="getUser.user_avatar"-->
                      <!--                          class="img-fluid" alt="profile">-->
                      <svg fill="none" stroke="#fff" style="margin-top: -14px;" viewBox="0 0 24 24"
                           width="25" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                      </svg>
                      <span
                          class="feather icon-chevron-down live-icon"></span></a>
                    <div aria-labelledby="profilelink"
                         class="dropdown-menu dropdown-menu-right">
                      <div class="dropdown-item">
                        <div class="profilename">
                          <h5>{{ loggedInUserName() }}</h5>
                        </div>
                      </div>
                      <div class="userbox">
                        <ul class="list-unstyled mb-0">
                          <li class="media dropdown-item">
                            <a class="mb-3 profile-icon" @click="getSelectedUser()">
                              <img alt="user" class="img-fluid" src="../../../assets/images/icons/user.png">
                              My Profile
                            </a>
                          </li>
                          <li class="media dropdown-item" style="mouse:pointer;" @click="logout">
                            <a
                                class="profile-icon"><img
                                alt="logout"
                                class="img-fluid " src="../../../../src/assets/images/icons/power.png"
                                style="mouse:pointer">Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- End user authenticated -->
            </ul>
          </div>
        </div>
        <!-- End col -->
      </div>

      <!-- End row -->
    </div>
    <!-- End Topbar -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import router from "@/router";
import $ from "jquery";

export default {
  name: "TopBar",
  data() {
    return {
      loggedInUser: {},
      loggedInUserBusiness: {},
      user_id: '',
    }
  },
  props: [
    'centerInfo',
  ],

  methods: {
    toggleHumburger(){
      $("body").toggleClass("toggle-menu");
      $(".menu-hamburger img").toggle();
    },
    closeOpenTopBar(){
      $("body").toggleClass("topbar-toggle-menu");
      $(".topbar-toggle-hamburger img").toggle();
    },
    ...mapActions(['fetchCurrentUser']),
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/')
          })
    },
    getUserName() {
      return 'Test'
    },
    userHasRole(role) {
      return this.getUserRoles().includes(role)
    },
    decodeToken(){

    },
    loggedInUserName() {
      try {
        var tkres = JSON.parse(atob(this.getToken.split('.')[1]));
        this.user_id = tkres.user_id
        return tkres.username
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    getSelectedUser(){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchSingleSystemUser', this.user_id).then(resp=>{
        router.push({name: 'update_user'});
      })
    },
    checkPasswordLastChanged(){
      var password_last_changed_at = JSON.parse(atob(this.getToken.split('.')[1])).password_last_changed_at

      if(this.$route.name !== 'login' && password_last_changed_at === null){
        router.push('/forgot_password')
      }
    }
  },
  mounted() {
    this.checkPasswordLastChanged()
    this.loggedInUserName()
    this.$store.commit('SET_LAYOUT', 'inside-dashboard')
  },

  computed: {
    ...mapGetters(['getUser', 'getToken']),
  },
}

</script>
