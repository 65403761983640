<template>
  <div class="row">
    <div class="col-md-6">
      <vaccination_schedule :expired_batch="expired_batch"></vaccination_schedule>
    </div>
  </div>
</template>
<script>
import vaccination_schedule from "@/components/persons/vaccination_schedule";

export default {
  name: "dosage_timeline",
  components: {vaccination_schedule},
  data() {
    return {
      riskFactors: [
        {'value': 'low', 'recommendation': 'Vaccination is not urgent', 'color': 'text-success'},
        {'value': 'medium', 'recommendation': 'Vaccination is urgent', 'color': 'text-warning'},
        {'value': 'high', 'recommendation': 'Vaccination is very urgent', 'color': 'text-danger'}
      ],
      date_administered: '',
      submitting: false,
      valid_date: '',
      show_submit: false,
      scheduled_doses: [],
      schedule_to_update: {},
      vaccine: {},
      username: '',
      schedule_to_delete: {}
    }
  },
  props: ['expired_batch'],
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>
<style lang="">

</style>