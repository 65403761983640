<template>
  <div>
    <h4 v-if="isCummulativeStats()">
      Cummulative Stats
    </h4>
    <h4 v-else>
      Daily Stats
    </h4>
    <Filter_facility_by_location @setLocationFilter="setLocationFilter" size="col-12 col-md-3"/>
    <div class="form-row">
      <Vaccine_filter
          @setVaccineFilter="setVaccineFilter"
          class="col-12 col-md-3"
      />
      <BatchFilter @setBatchFilter="setBatchFilter"
                   :vaccine-filter="vaccine_filter"
                   class="col-12 col-md-3"/>
      <Dose_filter
          @setDoseFilter="setDoseFilter"
          class="col-12 col-md-3"
          :vaccine_doses="vaccine_doses"
      />
      <div v-if="isCummulativeStats()" class="col-12 col-md-3">
        <div class="row">
          <DateFromFilter
              @setDateFromFilter="setDateFromFilter"
              class="col-12 col-md-6"
          />
          <DateToFilter
              @setDateToFilter="setDateToFilter"
              class="col-12 col-md-6"
          />
        </div>
      </div>
      <div v-else class="col-12 col-md-4">
        <DateFilter
            @setDateFilter="setDateFilter"
        />
      </div>
    </div>
    <div v-if="isCummulativeStats()" class="form-row">
      <AgeRangeFilter @setAgeRangeFilter="setAgeRangeFilter"/>
    </div>
    <div class="form-row mb-3">
      <div class="col-12 col-md-2 d-flex pb-3">
        <button type="button" @click="filterStats" class="btn btn-primary align-self-end">
          Filter Stats
          <i class="feather icon-arrow-right"></i>
        </button>
      </div>
    </div>
    <Vaccinations_stats_csv
        v-if="isCummulativeStats()"
        :payload="payload"
        :centerInfo="centerInfo"
    />
    <div v-if="filter_selected">
      <StatCards
          class="mt-2"
          :isLoading="is_loading"
          :allVaccinations="all_vaccinations"
          :jabsPerGender="gender_stats"
      />
      <div class="row">
        <div class="col-12 col-md-6">
          <OccupationVaccinations
              :payload="payload"
              :triggerUpdate="triggerUpdate"
          />
        </div>
        <div class="col-12 col-md-6">
          <AgePieChart
              v-if="isCummulativeStats()"
              :payload="payload"
              :triggerUpdate="triggerUpdate"
          />
        </div>
      </div>
      <PeriodicJabs v-if="centerInfo.level === 'national' && isCummulativeStats()" class="mt-3"/>
    </div>
    <div v-else>
      <div class="card mt-2">
        <div class="card-body">
          <h4 class="text-center text-primary">
            Select filters then click filter stats to generate report
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Filter_facility_by_location from "@/components/filters/LocationFilter";
import Vaccine_filter from "@/components/filters/Vaccine_filter";
import Dose_filter from "@/components/filters/Dose_filter";
import DateFromFilter from "@/components/filters/DateFromFilter";
import DateToFilter from "@/components/filters/DateToFilter";
import Vaccinations_stats_csv from "@/components/downloads/Vaccinations_stats_csv";
import StatCards from "./StatCards";
import OccupationVaccinations from "./OccupationVaccinations";
import PeriodicJabs from "../charts/PeriodicJabs";
import DateFilter from "../filters/DateFilter";
import AgePieChart from "@/components/charts/AgePieChart";
import AgeRangeFilter from "../filters/AgeRangeFilter";
import BatchFilter from "@/components/filters/BatchFilter";
// import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";

export default {
  name: "BriefStatsReport",
  data() {
    return {
      search_value: '',
      stats: null,
      is_loading: true,
      payload: '',
      registrations: 0,
      all_occupations: [],
      all_vaccinations: 0,
      above_58: 0,
      other_occupations: 0,
      pending_vaccinations: 0,
      vaccine: null,
      filter_selected: false,
      occupation_stats_loading: false,
      vaccination_stats_csv: [],
      monthly_stats_csv: [],
      age_group_stats_csv: [],
      location_filter: '',
      vaccine_doses: '',
      vaccine_filter: '',
      dose_filter: '',
      batch_filter: '',
      from_date: '',
      to_date: '',
      ageRange: '',
      age_stats_loading:true,
      date: '',
      gender_stats: [],
      triggerUpdate: false,
    }
  },
  components: {
    BatchFilter,
    AgeRangeFilter,
    AgePieChart,
    DateFilter,
    PeriodicJabs,
    OccupationVaccinations,
    StatCards,
    Vaccinations_stats_csv,
    DateToFilter, DateFromFilter, Dose_filter, Vaccine_filter, Filter_facility_by_location},
  methods: {
    ...mapActions([
      'fetchCounties',
      'fetchCenterInfo',
      'fetchSubCounties',
      'fetchCenters',
      'fetchVaccines'
    ]),
    isCummulativeStats(){
      return this.currentRouteName === 'cummulative_stats'
    },
    fetchStats(payload) {
      this.$store.dispatch('clearStats')
      this.all_occupations = []
      this.above_58 = 0
      this.other_occupations = 0

      this.is_loading = true
      this.occupation_stats_loading = true
      this.age_stats_loading = true

      // Emit
      this.triggerUpdate = !this.triggerUpdate

      this.$store.dispatch('fetchGenderGroupedJabs', payload).then(stats => {
        this.gender_stats = stats

        this.all_vaccinations = this.gender_stats.map(gender => {
          return gender.people
        }).reduce((a, b) => a + b, 0)
        this.is_loading = false

        this.brief_stats = stats

        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false
      })
    },
    updateFullPayload(){
      let payload = this.location_filter +
          this.dose_filter;

      // Only fetch by vaccine if batch is not selected
      if(this.batch_filter === ''){
        payload = payload + this.vaccine_filter
      } else {
        payload = payload + this.batch_filter
      }

      if(this.isCummulativeStats()){
        payload = payload +
            this.from_date +
            this.to_date +
            this.ageRange
      }else{
        payload = payload + this.date
      }

      // Replace first string with ?
      this.payload = payload.replace('&','?');

      console.log('new payload', this.payload)
    },
    filterStats(){
      this.filter_selected = true
      this.updateFullPayload()
      this.fetchStats(this.payload)
    },
    setLocationFilter(payload){
      this.location_filter = payload
      this.updateFullPayload()
    },
    setVaccineFilter(vaccine_filter, doses){
      this.vaccine_filter = vaccine_filter
      this.vaccine_doses = doses
      this.updateFullPayload()
    },
    setBatchFilter(batch_filter){
      this.batch_filter = batch_filter
      this.updateFullPayload()
    },
    setDoseFilter(payload){
      this.dose_filter = payload
      this.updateFullPayload()
    },
    setDateFromFilter(payload){
      this.from_date = payload
      this.updateFullPayload()
    },
    setDateToFilter(payload){
      this.to_date = payload
      this.updateFullPayload()
    },
    setDateFilter(payload){
      this.date = payload
      this.updateFullPayload()
    },
    setAgeRangeFilter(payload){
      this.ageRange = payload
      this.updateFullPayload()
    }
  },
  computed: {
    ...mapGetters([
      'allCenters',
      'centerInfo',
      'allCounties',
      'allSubCounties',
      'getCountyStatsCSV',
      'allVaccines'
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    countyFacilities() {
      if(this.my_subcounties === ''){
        return []
      }else{
        return this.allCenters.filter(center => {
          return this.my_subcounties.map(subcounty => {
                return subcounty.id
              }).includes(center.sub_county) &&
              center.level === 'facility'
        })
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
~