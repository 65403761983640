<template>
  <div>
    <PersonTable
        :data="getAllJabs"
        action="fetchAllJabs"
        :isLoading="isLoading"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PersonTable from "../tables/PersonTable";

export default {
  name: "vaccinations_today_report",
  components: {PersonTable},
  data() {
    return {
      isLoading: true,
    }
  },
  methods: {
    retrieveAllRegistrations() {
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchAllJabs', '?limit=10&offset=0').then(resp => {
        this.isLoading = false;
      })
    },
  },
  computed: {
    ...mapGetters(['getAllJabs']),
  },
  mounted() {
    this.retrieveAllRegistrations()
  }
}
</script>

<style scoped>

</style>