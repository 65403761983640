<template>
  <div class="card mb-2">
    <div class="card-body">
      <div v-if="is_loading" class="offset-5">
        <p class="loader"></p>
        <p>Loading</p>
      </div>
      <form @submit.prevent="createGadget">
        <div class="form-row">
          <div class="form-group col-md-3 mb-2">
            <label for="imeiNumber">IMEI number</label>
            <input id="imeiNumber" v-model="imeiNumber" :class="'form-control ' + checkIfErrorExists('imei_number')"
                   type="number" required>
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError.imei_number" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="form-group col-md-3 mb-2">
            <label>Phone Number</label>
            <input id="phoneNumber" v-model="phoneNumber" :class="'form-control ' + checkIfErrorExists('phone_number')" type="tel" required>
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError.phone_number" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="form-group col-md-2">
            <label for="recording_county">Select County</label>
            <select v-model="center_county" id="recording_county" class="form-control" required>
              <option v-for="(county, index) in allCounties" :key="index" :value="county.id">
                {{county.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label for="recording_subcounty">Select Subcounty</label>
            <select v-model="center_subcounty" id="recording_subcounty" class="form-control" required>
              <option v-for="(subcounty, index) in filtered_subcounties" :key="index" :value="subcounty.id">
                {{subcounty.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2 mb-2">
            <label for="centerId">Select Center</label>
            <select id="centerId" v-model="centerId" :class="'form-control ' + checkIfErrorExists('center_id')" required>
              <option v-for="(center, index) in filtered_centers" v-bind:key="index" :value="center.id">
                {{ center.name }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError.center_id" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
<!--        <div class="form-row">-->
<!--          <div class="form-group col-md-4">-->
<!--            <label for="receiverID">ID of Person Assigned</label>-->
<!--            <input v-model="receiver_id_number" type="text" class="form-control" id="receiverID">-->
<!--          </div>-->
<!--          <div class="form-group col-md-4">-->
<!--            <label for="receiverName">Name of Person Assigned</label>-->
<!--            <input v-model="receiver_name" type="text" class="form-control" id="receiverName">-->
<!--          </div>-->
<!--          <div class="form-group col-md-4">-->
<!--            <label for="receiverPhone">Phone Number of Person Assigned</label>-->
<!--            <input v-model="receiver_phone" type="text" class="form-control" id="receiverPhone">-->
<!--          </div>-->
<!--        </div>-->
          <button class="btn btn-primary" type="submit">
            Add Gadget
            <i class="feather icon-arrow-right"></i>
          </button>
      </form>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "register_gadgets",
  data() {
    return {
      imeiNumber: '',
      phoneNumber: '',
      centerId: '',
      is_loading:false,
      filtered_subcounties:[],
      filtered_centers: [],
      center_county: null,
      center_subcounty: null,
      receiver_id_number: '',
      receiver_name: '',
      receiver_phone: ''
    }
  },
  computed: {
    ...mapGetters([
      'allCenters', 'centerInfo', 'getError', "allCenters", 'allCounties', 'allSubCounties'
    ]),
  },
  methods: {
    ...mapActions(['setError','fetchCenters','fetchCounties', 'fetchSubCounties']),
    getCenterInfo() {
      this.centerId = this.centerInfo().centerId
    },
    createGadget() {
      this.is_loading = true;
      // remove errors
      this.setError({})
      let imeiNumber = this.imeiNumber
      let phoneNumber = this.phoneNumber
      let centerId = this.centerId
      let assignedTo = {
        id_number: this.receiver_id_number.toString(),
        phone_number : this.receiver_phone.toString(),
        name: this.receiver_name.toString()
      }
      this.$store.dispatch('createGadget', {
        imeiNumber, phoneNumber, centerId , assignedTo
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        if(resp){
          this.is_loading = false;
          this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Created Gadget"}])
          this.imeiNumber = ''
          this.phoneNumber = ''
          this.centerId = ''
          this.filtered_subcounties = []
          this.filtered_centers = []
          this.center_county = null
          this.center_subcounty = null

          this.receiver_id_number = assignedTo.id_number
          this.receiver_name = assignedTo.name
          this.receiver_phone = assignedTo.phone_number
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err=>{
        this.is_loading = false;
      })
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    hide_err(){
      this.setError({})
    },
    retrieveAllCenters() {
      return this.fetchCenters();
    },
    retrieveCounties() {
      return this.fetchCounties()
    },
    retrieveSubCounties() {
      return this.fetchSubCounties()
    },
  },
  mounted() {
    this.retrieveSubCounties()
    this.retrieveCounties()
    this.retrieveAllCenters()
    this.getCenterInfo()
  },
  watch: {
    center_county: function () {
      let filteredSubCounties = []
      // use getter to return all centers
      let allSubcountiesList = this.allSubCounties;

      // loop through all centers
      allSubcountiesList.map((subcounty) => {
        // get the centers that match that level
        if (subcounty.county === this.center_county) {
          filteredSubCounties.push(subcounty)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_subcounties = filteredSubCounties
    },

    center_subcounty: function () {
      let filteredCenters = []
      // use getter to return all centers
      let allCentersList = this.allCenters;

      // loop through all centers
      allCentersList.map((center) => {
        // get the centers that match that level
        if (center.sub_county === this.center_subcounty) {
          filteredCenters.push(center)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_centers = filteredCenters
    }
  },
}
</script>