<template>
  <div>
    <div v-if="currentRouteName === 'persons'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Registered Vaccinees Management"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <success_messages></success_messages>
        <search_person class="mb-3"></search_person>
        <view_person v-if="Object.keys(personDetails).length !==0"></view_person>
        <search_results v-if="Object.keys(getSearchResults).length !== 0 && getSearchResults.length !==0"></search_results>
      </div>
    </div>
    <router-view>
    </router-view>
  </div>
</template>
<script>
  import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
  import search_person from "@/components/persons/search_person";
  import view_person from "@/components/persons/view_person";
  import success_messages from '@/components/layouts/includes/success_messages.vue';
  import {mapActions, mapGetters} from "vuex";
  import search_results from "@/components/support/search_results";

  export default {
    name: "persons",
    components: {search_results, view_person, Breadcrumbs, search_person, success_messages},
    methods: {
      ...mapActions(['setError']),
      clearErrors(){
        this.setError({})
      }
    },
    computed: {
      ...mapGetters(['personDetails','getSearchResults']),
      currentRoutePath() {
        return this.$route.path;
      },
      currentRouteName() {
        return this.$route.name;
      }
    },
    mounted() {
      this.clearErrors()
    }
  }
</script>