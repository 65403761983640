<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Receive batches"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="userHasRole('Stock Manager')">
        <success_messages></success_messages>
        <receiving_form v-if="centerInfo.level === 'national' || centerInfo.level === 'vendor' && userHasRole('Central Store Manager')"></receiving_form>
        <receive_batches_list></receive_batches_list>
      </div>

      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import receive_batches_list from "@/components/inventory/receive_batches_list";
import receiving_form from "@/components/inventory/receiving_form";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "receive",
  methods: {
    ...mapActions(['fetchCenterInfo', 'setError']),
    retrieveCurrentCenter() {
      return this.fetchCenterInfo();
    },
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  components: {AccessDeniedCard, receiving_form, Breadcrumbs, receive_batches_list, success_messages},
  computed: {
    ...mapGetters(['centerInfo']),
  },
  // created() {
  //   this.$store.dispatch('fetchInventoryToReceive')
  // },
  mounted() {
    this.getUserRoles()
    this.retrieveCurrentCenter();
    this.clearErrors()
  }
}
</script>