<template>
  <div>
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-8">
            <div class="breadcrumbbar my-3">
              <div class="breadcrumb-list">
                <ol class="breadcrumb">
                  <li @click="setBaseLevel"
                      aria-current="page" class="breadcrumb-item active pointer text-capitalize">{{ firstLevel.name }}
                  </li>

                  <li @click="updateCurrentLevel({name:level.name, id:level.id, level:level.level})"
                      v-for="(level,index) in subsequent_levels" v-bind:key="index" aria-current="page"
                      class="breadcrumb-item pointer">
                    {{ level.name }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <vaccine_filter :default="vaccine_filter" v-if="vaccines_loaded" :vaccines="vaccines" @updatedVaccineFilter="updatedVaccineFilter"></vaccine_filter>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <h4 class="text-capitalize">{{ currentLevel }} Level Inventory Report</h4>
        <table v-if="currentLevel === 'facility'" class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Batch Number</th>
            <th scope="col">Vaccine</th>
            <th scope="col">Doses in stock</th>
            <th scope="col">Wasted Doses</th>
            <th scope="col">Expired Doses</th>
            <th scope="col">Last Stock Update</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(inventory, index) in getInventoryStats.inventories" v-bind:key="index">
            <td>{{ inventory.batch.batch_number }}</td>
            <td>
              {{ inventory.batch.vaccine.name }}
            </td>
            <td>
              {{ inventory.good_quantity | withCommas }}
            </td>
            <td>
              {{ inventory.damaged_quantity | withCommas }}
            </td>
            <td>
              {{ inventory.expired_quantity | withCommas }}
            </td>
            <td>
              {{new Date(inventory.last_stock_update).toDateString()}}
            </td>
          </tr>
          </tbody>
        </table>
        <table v-else class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Level</th>
            <th scope="col">Total Doses</th>
            <th scope="col">Doses in Stock</th>
            <th scope="col">Doses Used</th>
            <th scope="col">Wasted Doses</th>
            <th scope="col">Expired Doses</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(level_item, index) in inventories" v-bind:key="index">
            <td>{{ level_item.name }}</td>
            <td>
              {{ level_item.good_quantities + level_item.used_quantities | withCommas }}
            </td>
            <td>
              {{ level_item.good_quantities | withCommas }}
            </td>
            <td>
              {{ level_item.used_quantities | withCommas }}
            </td>
            <td>
              {{ level_item.damaged_quantities | withCommas }}
            </td>
            <td>
              {{ level_item.expired_quantities | withCommas }}
            </td>
            <td>
              <button v-if="currentLevel !== 'facility'" class="btn btn-primary"
                      @click="changeCurrentLevel(level_item)">
                View Level Stats
                <i class="feather icon-arrow-right"></i>
              </button>
            </td>
          </tr>
          <tr v-if="inventories.length > 1" class="font-weight-bold">
            <td>Total</td>
            <td>
              {{ getInventoryStats.good_quantities + getInventoryStats.used_quantities | withCommas }}
            </td>
            <td>
              {{ getInventoryStats.good_quantities | withCommas }}
            </td>
            <td>
              {{ getInventoryStats.used_quantities | withCommas }}
            </td>
            <td>
              {{ getInventoryStats.damaged_quantities | withCommas }}
            </td>
            <td>
              {{ getInventoryStats.expired_quantities | withCommas }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import vaccine_filter from "../filters/Vaccine_filter";

export default {
  name: "vaccine_inventory_report",
  components: {vaccine_filter},
  data() {
    return {
      dataToDisplay: 'testData',
      currentLevel: null,
      nextLevelToShow: '',
      subsequent_levels: [],
      inventoryStats: {},
      inventories: [],
      vaccines: [],
      vaccines_loaded: false,
      vaccine_filter: 'all_vaccines',
      payload: '',
      vaccine_name: '',
      firstLevel: {},
      currentLevelId: '',
      county: ''
    }
  },
  methods: {
    ...mapActions([
      'fetchCenterInventory',
      'fetchInventoryStats',
      'fetchCounties',
      'fetchSubCounties',
      'fetchWards',
      'fetchCenterInfo',
      'fetchRegions'
    ]),
    retrieveCenterInventory() {
      return this.fetchCenterInventory();
    },
    retrieveInventoryStats() {
      if(this.centerInfo.level === 'national' || this.centerInfo.level === 'regional'){
        this.firstLevel = {name:this.centerInfo.name, id:this.centerInfo.id, level:this.centerInfo.level}

        return this.fetchInventoryStats().then(resp => {
          this.inventories = resp.children.filter(item => {
            return item.good_quantities
          })
        })
      }else{
        var subcounty = ''
        this.$store.dispatch('fetchSubCounty', this.centerInfo.sub_county).then(resp => {
          subcounty = resp

          this.$store.dispatch('fetchCounty', subcounty.county).then(resp => {
            this.county = resp

            this.firstLevel = {name:this.county.name, id:this.county.id, level:'county'}

            this.$store.dispatch('fetchDetailedInventoryStats', '/?county=' + this.county.id).then(resp => {
              this.inventories = resp.children.filter( item => {
                return item.good_quantities
              })
            })
          })
        })
      }
    },
    retrieveRegions() {
      return this.fetchRegions()
    },
    setCurrentLevel() {
      if (this.centerInfo.level === 'regional') {
        this.currentLevel = 'region'
      } else if (this.centerInfo.level !== 'national') {
        this.currentLevel = 'county'
      } else {
        this.currentLevel = this.centerInfo.level
      }
    },
    changeCurrentLevel(level_item) {
      this.subsequent_levels.push({name: level_item.name, id: level_item.pk, level: this.getNextLevel()})
      this.$store.dispatch('fetchDetailedInventoryStats', '?' + this.getNextLevel() + '=' + level_item.pk +
        (this.vaccine_filter !== 'all_vaccines' ? '&vaccine=' + this.vaccine_filter : '')
      ).then(resp => {
        this.inventories = resp.children.filter(item => {
          return item.good_quantities
        })
      })
      this.currentLevel = this.getNextLevel()
    },
    // Select level using breadcrumb
    updateCurrentLevel(level_item) {
      let obj = Object.keys(this.subsequent_levels).find(key => this.subsequent_levels[key].level === level_item.level);
      let levels = this.subsequent_levels

      levels = this.subsequent_levels.filter((level, index) => {
        return index <= obj
      })
      this.subsequent_levels = levels
      this.$store.dispatch('fetchDetailedInventoryStats', '?' + this.subsequent_levels.slice(-1)[0].level + '=' + level_item.pk
       + (this.vaccine_filter !== 'all_vaccines' ? '&vaccine=' + this.vaccine_filter : '')
      ).then(resp => {
        this.inventories = resp.children.filter(item => {
          return item.good_quantities
        })
      })

      this.currentLevel = this.subsequent_levels.slice(-1)[0].level
    },
    // Click first bread crumb element
    setBaseLevel(){
      this.subsequent_levels = []
      this.$store.dispatch('fetchDetailedInventoryStats', '/?' + this.firstLevel.level + '=' + this.firstLevel.id
          + (this.vaccine_filter !== 'all_vaccines' ? '&vaccine=' + this.vaccine_filter : '')
      ).then(resp => {
        this.inventories = resp.children.filter(item => {
          return item.good_quantities
        })
      })

      if(this.centerInfo.level === 'national' || this.centerInfo.level === 'regional'){
        this.currentLevel = this.centerInfo.level
      }else{
        this.currentLevel = 'county'
      }
    },
    getNextLevel() {
      switch (this.currentLevel) {
        case 'national':
          return 'region'
        case 'regional':
          return 'county'
        case 'region':
          return 'county'
        case 'county':
          return 'subcounty'
        case 'subcounty':
          return 'facility'
      }
    },
    updatedVaccineFilter(vaccine_filter){
      this.vaccine_filter = vaccine_filter
    },
    getAllVaccines() {
      this.$store.dispatch('fetchVaccines').then(resp => {
        this.vaccines = resp
        this.vaccines_loaded = true
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {"detail": "error fetching vaccines"})
      });
    },
  },
  computed: {
    ...mapGetters([
      'allCenterInventory',
      'centerInfo',
      'getInventoryStats',
      'allCounties',
      'allSubCounties',
      'allWards',
      'allRegions',
      'allVaccines'
    ]),
    filteredInventory() {
      if (this.search_value === '') {
        return this.allCenterInventory
      } else {
        return this.allCenterInventory.filter(inventory => {
          return inventory.batch.batch_number.startsWith(this.search_value)
        })
      }
    },
    inventoryCount: function () {
      // return this.allCenterInventory[0].good_quantity
      return this.allCenterInventory.map(inventory => {
        return inventory.good_quantity - inventory.used_quantity
      }).reduce((a, b) => a + b, 0)
    }
  },
  watch: {
    vaccine_filter: function () {
      if(this.vaccine_filter !== 'all_vaccines'){
        this.vaccine_name = this.allVaccines.filter(vacc => {
          return vacc.id === this.vaccine_filter
        })[0].name

        // this.$store.dispatch('fetchDetailedInventoryStats', '?' + this.getNextLevel() + '=' + level_item.pk +
        //     (this.vaccine_filter !== 'all_vaccines' ? '&vaccine=' + this.vaccine_filter : ''))
      }
    }
  },
  mounted() {
    this.retrieveCenterInventory()
    this.retrieveInventoryStats()
    this.retrieveRegions()
    this.setCurrentLevel()
    this.getAllVaccines()
  }
}
</script>

<style scoped>
.breadcrumbbar {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}

.bold{

}
</style>