<template>
  <div class="card mb-2">
    <div class="card-body">
      <div v-if="$func.userHasRoles(['Stock Manager'])">
        <h4 class="text-danger">
          Update your daily stock report under:
        </h4>
        <div class="d-flex flex-row">
          <div class="badge badge-secondary mr-2">Vaccine Inventory <i class="feather icon-arrow-right"></i></div>
          <div class="badge badge-secondary mr-2">My Inventory <i class="feather icon-arrow-right"></i></div>
          <div class="badge badge-secondary">
            <router-link to="master_inventory">Daily Inventory Report</router-link>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 class="text-danger">
          Please request your facility stock manager to update your daily stock report.
        </h4>
      </div>
<!--      <button class="btn btn-primary text-white mt-2" @click="refreshBatchReportInfo()">Refresh-->
<!--        <i class="feather icon-refresh-cw ml-2 "></i></button>-->
      <span class="text-danger d-block mt-3">NB: Vaccination will be locked out soon if daily stock reports have not been uploaded</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "update_stock_warning",
  props: [
      'is_upto_date'
  ],

}
</script>

<style scoped>

</style>