<template>
  <default_modal aria_label="Update Stock" modal_header="Update Stock" modal_id="update_stock_modal">
    <div class="form form-row">
      <div class="col-12">
        <h6 v-if="Object.keys(batch).length !== 0">Batch Number : {{ batch.batch.batch_number }}, Starting Qty:
          {{ $func.numberWithCommas(batch.good_quantity) }}</h6>
        <label class="text-capitalize mt-2"> Date </label>
        <input type="date" name="amount_left" id="date" v-model="date"
               :class="'form-control ' + $func.checkIfErrorExists('date')" required>
        <div class="invalid-feedback">
              <span v-for="(error, index) in getError.date" v-bind:key="index">
                {{ error }}
              </span>
        </div>
      </div>
      <div class="col-12 mt-3">
        <label class="text-capitalize mt-1"> Adjustment Type <span class="text-danger">*</span></label>
        <select v-model="adjustment_type" :class="'form-control ' + $func.checkIfErrorExists('adjustment_type')" name="adjustment_type" id="adjustment_type" required>
          <option value="" disabled>Select Adjustment Type</option>
          <option value="positive">Positive</option>
          <option value="negative">Negative</option>
        </select>
        <div class="invalid-feedback">
              <span v-for="(error, index) in getError.adjustment_type" v-bind:key="index">
                {{ error }}
              </span>
        </div>
      </div>
      <div class="row" v-if="adjustment_type!==''">
        <div class="col-12 mt-4">
          <h6 class="text-capitalize "> Current Doses In System : {{ $func.numberWithCommas(initial_doses_balance) }}</h6>
          <label class="text-capitalize mt-1">
            <span v-if="adjustment_type===''">Total Doses Being Adjusted {{ $func.numberWithCommas(doses_balance) }} <span class="text-danger">*</span></span>
            <span v-if="adjustment_type==='positive'" class="text-success"> {{ $func.numberWithCommas(doses_balance) }} Doses Will Be Added to stock +</span>
            <span v-if="adjustment_type==='negative'" class="text-danger">{{ $func.numberWithCommas(doses_balance) }} Doses Will Be removed from stock -</span>
          </label>
          <input type="number" v-model="doses_balance" id="doses_balance"
                 :class="'form-control ' + $func.checkIfErrorExists('doses_balance')" required>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.doses_balance" v-bind:key="index">
                {{ error }}
              </span>
          </div>
          <h6 class="text-capitalize mt-3"> New Doses Balance Will be : {{ $func.numberWithCommas(calculated_doses_balance) }}</h6>
        </div>
        <div class="col-12 mt-2 d-none">
          <label class="text-capitalize mt-1">Total Doses Wasted</label>
          <input type="number" v-model="doses_wasted" id="doses_wasted"
                 :class="'form-control ' + $func.checkIfErrorExists('doses_wasted')" required>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.doses_wasted" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12 mt-2">
          <label class="text-capitalize mt-1">Reason For Adjustment <span class="text-danger">*</span></label>
          <select name="adjustment_reason" id="adjustment_reason" v-model="adjustment_reason" :class="'form-control ' + $func.checkIfErrorExists('adjustment_reason')" required>
            <option value=""> Select Reason For Adjustment</option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="expired">Expired </option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="vvm stage 3 or 4">VVM stage 3 or 4 </option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="frozen">Frozen </option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="broken">Broken </option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="heat exposure">Heat exposure </option>
            <option v-if="adjustment_type==='negative'" class="text-capitalize" value="vaccine damage">Vaccine damage </option>
            <option v-if="adjustment_type!=='' " class="text-capitalize" value="physical stock take">Physical stock take </option>
            <option value="other">Other </option>
          </select>
          <input type="text" class="form-control mt-3" v-if="adjustment_reason === 'other'" placeholder="Specify Reason" required>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.doses_wasted" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12">
          <label class="text-capitalize mt-2"> Remarks <span class="text-danger">*</span></label>
          <textarea name="remark" id="remark" v-model="remarks"
                    :class="'form-control ' + $func.checkIfErrorExists('remarks')">
        </textarea>
          <div class="invalid-feedback">
            <span v-for="(error, index) in getError.remarks" v-bind:key="index">
              {{ error }}
            </span>
          </div>
        </div>
      </div>

    </div>
    <template v-slot:modal_footer>
      <button v-if="submitting === false && adjustment_type!==''" class="btn btn-primary" @click="submitBatchAdjustment">
        Stock Adjustment
      </button>
      <div v-if="submitting">
        <SubmittingLoader/>
      </div>
    </template>
  </default_modal>
</template>

<script>
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import {mapGetters} from "vuex";
import $ from "jquery";

export default {
  name: "AdjustStockModal",
  components: {SubmittingLoader, default_modal},
  props: ['batch'],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      doses_balance: 0,
      doses_wasted: 0,
      adjustment_type: '',
      other_adjustment_reason:'',
      calculated_doses_balance: 0,
      adjustment_reason: '',
      initial_doses_balance: 0,
      remarks: '',
      submitting: false,
      loading: false,
      show_submit: true,
    }
  },
  computed: {
    ...mapGetters([
      'getError'
    ]),
  },
  watch: {
    batch: function () {
      this.doses_balance = 0;
      this.doses_wasted = this.batch.damaged_quantity;
      this.initial_doses_balance = this.batch.good_quantity - this.batch.used_quantity;
      this.calculated_doses_balance = this.batch.good_quantity - this.batch.used_quantity;
    },
    doses_balance: function () {
      this.calculateNewSystemDoseBalance()
    },
    adjustment_type: function () {
      this.calculateNewSystemDoseBalance()
    },
  },
  methods: {
    retrieveCenterInventory() {
      this.loading = true
      this.$store.dispatch(this.action, `?good_quantity_gt=0&limit=${this.limit}&offset=0`).then(resp => {
        this.loading = false
        return resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.loading = false
        this.$store.dispatch('setError', {'detail': 'Could Not Fetch Inventory'})
      });
    },
    submitBatchAdjustment() {
      this.submitting = true
      let adj_reason = ''
      if (this.adjustment_reason==='other'){
        adj_reason = this.other_adjustment_reason
      }else{
        adj_reason = this.adjustment_reason
      }
      this.$store.dispatch('updateBatchDailyChange', {
        batch_inventory_id: this.batch.id,
        date: this.date,
        doses_balance: this.calculated_doses_balance,
        adjustment_reason: adj_reason,
        remarks: this.remarks,
        batch_transaction_type: 'transaction'
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        $('#update_stock_modal').modal('hide')
        this.loading = true
        this.retrieveCenterInventory();
        this.doses_balance = 0
        this.submitting = false;
        this.$store.dispatch('setSuccessMessages', [{"message": "Stock Updated"}])
        this.loading = false
        this.$store.dispatch('setError', {})
      }).catch(err => {
        this.submitting = false;
        this.$store.dispatch('setError', err.response.data)
        console.log("err", err.response.data)
      })
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    calculateNewSystemDoseBalance(){
      if(this.adjustment_type === 'negative'){
        this.calculated_doses_balance = parseInt(this.initial_doses_balance) - parseInt(this.doses_balance);
      }
      if(this.adjustment_type === 'positive'){
        this.calculated_doses_balance = parseInt(this.initial_doses_balance) + parseInt(this.doses_balance);
      }
    },
  }
}
</script>

<style scoped>

</style>