import store from './store'

export const func = {
  diffDays: (date1, date2) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    let  diffTime = date2 - date1;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  },
  checkIfErrorExists(input_id) {
    if (Object.keys(store.getters.getError).length === 0) {
      return '';
    } else if (typeof store.getters.getError[input_id] !== "undefined") {
      return 'is-invalid'
    }
  },
  userHasRole(role) {
    var tk = store.getters.getToken;
    // var user = this.$store.getters.getUserInStorage;

    var tkres =  JSON.parse(atob(tk.split('.')[1]));
    return tkres.roles.includes(role)
  },
  userHasRoles(roles){
    let  token = store.getters.getToken;
    // var user = this.$store.getters.getUserInStorage;

    let user_roles =  JSON.parse(atob(token.split('.')[1])).roles;
    return roles.some(r=> user_roles.includes(r))
  },
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  },
  downloadCSV(csv_data, file_name) {
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_data);
    anchor.target = '_blank';
    anchor.download = file_name + '.csv';

    anchor.click()
  },
  // Get months betwenn 2 dates
  datesInRange(startDate, endDate) {
    var start      = startDate.split('-');
    var end        = endDate.split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];

    for(var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? '0'+month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  },
  calculateAge(age){
    let dob = new Date(age)
    var ageDifMs = Date.now() - dob.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },
  convertCSVtoObject(csv){
    var arr = csv.toString().split('\n');

    var jsonObj = [];
    var headers = arr[0].split(',');
    for(var i = 1; i < arr.length; i++) {
      var data = arr[i].split(',');
      var obj = {};
      for(var j = 0; j < data.length; j++) {
        obj[headers[j].trim()] = data[j].trim();
      }
      jsonObj.push(obj);
    }
    return JSON.parse(JSON.stringify(jsonObj));
  },
  titleCase(snake_case) {
    if(snake_case)
      return this.capitalize(snake_case.replace(/_/g, " "));
    else
      return snake_case
  },
  capitalize(str) {
    if(str){
      return str.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    } else{
      return str;
    }

  },
  numberWithCommas(int) {
    return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  },
  maskString(str) {
    // Check for null or empty string
    if (!str) return '';

    // If the string length is less than or equal to 3, return the string as is
    if (str.length <= 3) return str;

    // Replace all but the last 3 characters with *
    return '*'.repeat(str.length - 3) + str.slice(-3);
}
}