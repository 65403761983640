import { render, staticRenderFns } from "./support_search.vue?vue&type=template&id=2dcd322b&scoped=true&"
import script from "./support_search.vue?vue&type=script&lang=js&"
export * from "./support_search.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcd322b",
  null
  
)

export default component.exports