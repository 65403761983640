<template>
    <div>
			<!-- Start Breadcrumbbar -->
			<breadcrumbs pageName="Create System User"></breadcrumbs>
			<!-- End Breadcrumbbar -->
			<!-- Start main page content -->
      <success_messages></success_messages>
			<div class="contentbar">
				<user_form></user_form>
			</div>
    </div>
</template>
<script>
    import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
    import user_form from "@/components/users/user_form";
    import success_messages from "@/components/layouts/includes/success_messages";
    export default {
        name:"CreateUser",
        components: {success_messages, user_form, Breadcrumbs}
    }
</script>
<style lang="">
  
</style>