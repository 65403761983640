<template>
  <div :input_name="input_name" class="valid-feedback d-block">
              <span v-for="(successMessage, index) in getSuccessMessages" v-bind:key="index">
                {{ successMessage[input_name] }}
              </span>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'InputSuccessComponent',
  props: ['input_name'],
  methods: {
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSuccessMessages'
    ]),
  },
}
</script>
