<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Deactivated Vaccinees"></breadcrumbs>
    <div class="contentbar">
      <success_messages></success_messages>
      <deactivated_vaccinees_list></deactivated_vaccinees_list>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import deactivated_vaccinees_list from "@/components/support/deactivated_vaccinees_list";

export default {
  name: "InvalidVaccinations",
  components: {Breadcrumbs, deactivated_vaccinees_list, success_messages}
}
</script>

<style scoped>

</style>