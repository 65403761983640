import DailyStats from "../../views/daily_vaccination_stats";
import CummulativeStats from "../../views/cummulative_vaccination_stats";
import VaccineInventoryReport from "../../views/vaccine_inventory_report";
import VaccinationDefaultersReport from "../../views/vaccination_defaulters";
import DefaultersByAgeReport from "../../views/defaulters_by_age";
import VaccineUtilizationReportPage from "../../views/vaccine_utilization_report";
import DistributionLedgerTabular from "@/views/distribution_ledger/tabular";

const reports_routes=[
    {
        path: '/reports',
        name: 'vaccination_stats',
        component: DailyStats,
        parentName: 'Reports',
        verboseName: 'Daily Stats',
        icon:'<i class="feather icon-file"></i>',
        children: [
            {
                path: 'cummulative-stats',
                name: 'cummulative_stats',
                verboseName: 'Cummulative Stats',
                component: CummulativeStats
            },
            {
                path: 'vaccine-inventory',
                name: 'vaccine_inventory_report',
                verboseName: 'Vaccine Inventory',
                component: VaccineInventoryReport,
            },
            {
                path: 'vaccination-defaulters',
                name: 'vaccination_defaulters_report',
                verboseName: 'Defaulters',
                component: VaccinationDefaultersReport,
            },
            {
                path: 'defaulters-by-age',
                name: 'defaulters_by_age',
                verboseName: 'Defaulters By Age',
                component: DefaultersByAgeReport,
            },
            {
                path: 'vaccine-utilization',
                name: 'vaccine_utilization',
                verboseName: 'Vaccine Utilization',
                component: VaccineUtilizationReportPage
            },
            {
                path: 'distribution-ledger-tabular',
                name: 'distribution_ledger_report_tabular_reports',
                verboseName: 'Distribution Ledger Table',
                component: DistributionLedgerTabular,
            },
        ]
    },
]
export default reports_routes