<template >
  <!-- Start col -->
  <div class="col-lg-12 col-xl-6">
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-6 col-lg-9">
            <h5 class="card-title mb-0">All Centers</h5>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-borderless mb-0">
            <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Level</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(center, index) in allCenters" v-bind:key="index">
              <td>{{index + 1}}</td>
              <td>{{center.name}}</td>
              <td>{{ center.level }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- End col -->

</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "centers_list",
  data: function(){
    return {

    }
  },
  methods: {
    ...mapActions(['fetchCenters']),
    logInAsCenter : function (center_id){
      this.$store.dispatch('logInAsCenter', center_id)
    },

    getAllCenters() {
      return this.fetchCenters()
    }
  },
  computed:{
    ...mapGetters([
      'allRegionalCenters', 'loggedInCenter', 'allCenters', 'allLevels', 'getCenterById'
    ]),
  },
  mounted() {
    this.getAllCenters()
  }
}
</script>
<style lang="">

</style>