<template>
  <div>
    <div class="modal fade" id="personModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{person.first_name}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <h5 class="card-title">Vaccinees Registered Today</h5>
          </div>
          <div class="col-md-4">
            <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                   class="form-control" placeholder="Search by Name or ID" type="search">
          </div>
        </div>
      </div>
      <div class="card-body">
        <h6 class="card-subtitle">
          NB: Only vaccinees who have not received a dose can be edited
        </h6>
        <div class="table-responsive m-b-30">
          <table class="table">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Identification</th>
              <th scope="col">Date of Birth</th>
              <th scope="col">Gender</th>
              <th scope="col">Contact</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(person, index) in filteredPersons" v-bind:key="index">
              <td>{{titleCase(person.first_name)}} {{titleCase(person.middle_name)}} {{titleCase(person.last_name)}}</td>
              <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ titleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
              </td>
              <td>
                {{ new Date(person.date_of_birth).toDateString() }} ({{ calculateAge(person.date_of_birth) }} Years)
              </td>
              <td>
              <span v-if="person.gender === 'M'" class="mb-0">
                Male
              </span>
                <span v-else>
                Female
              </span>
              </td>
              <td>
                {{$func.maskString(person.phone)}}
              </td>
              <td>
                <button v-if="person.vaccinations.length === 0" class="btn btn-primary mr-2" @click="getSelectedPerson(person.id)">
                  Edit Vaccinee
                  <i class="feather icon-edit"></i>
                </button>
                <!--              <button class="btn btn-primary" @click="openDetailsModal(person)">-->
                <!--                View More Details-->
                <!--                <i class="feather icon-arrow-right"></i>-->
                <!--              </button>-->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import $ from "jquery";

export default {
  name: "edit_vaccinees_table",
  data() {
    return {
      person: '',
      search_value: ''
    }
  },
  methods: {
    ...mapActions(['fetchBriefStats', 'fetchCenterInfo']),
    retrieveCurrentCenter(){
      return this.fetchCenterInfo()
    },
    retrieveBriefStats(){
      return this.fetchBriefStats()
    },
    retrievePersonsInLocation(){
      return this.$store.dispatch('fetchPersonsInWaitlist', '?today_only=1')
    },
    titleCase(snake_case) {
      return this.capitalize(snake_case.replace(/_/g, " "));
    },
    capitalize(str) {
      return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
    },
    calculateAge(birthday) { // birthday is a date
      let parsed_birthday = new Date(birthday)
      var ageDifMs = Date.now() - parsed_birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    getSelectedPerson(person_id){
      console.log("red")
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp=>{
        router.push("update-vaccinee", resp.id);
      })
    },
    openDetailsModal(person){
      $('#personModal').modal('show')
      this.person = person
    }
  },
  computed: {
    ...mapGetters(['getBriefStats', 'centerInfo', 'personsInWaitlistToday']),
    filteredPersons() {
      if (this.search_value === '') {
        return this.personsInWaitlistToday.filter(person=>{
          return person.vaccinations.length === 0
        })
      } else {
        return this.personsInWaitlistToday.filter(person => {
          return person.first_name.startsWith(this.search_value.toLowerCase() || this.search_value.toUpperCase() ) ||
              person.middle_name.startsWith(this.search_value.toLowerCase() || this.search_value.toUpperCase() ) ||
              person.last_name.startsWith(this.search_value.toLowerCase() || this.search_value.toUpperCase() ) ||
              person.identification[0].id_number.startsWith(this.search_value) &&
              person.vaccinations.length === 0
        })
      }
    }
  },
  mounted() {
    this.retrieveBriefStats()
    this.retrieveCurrentCenter()
    this.retrievePersonsInLocation()
  }
}
</script>

<style scoped>

</style>