<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h4>Personal Information</h4>
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Gender</th>
            <th>Religion</th>
            <th>DOB</th>
            <th>Nationality</th>
            <th>Identification</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ personDetails.first_name }} {{ personDetails.middle_name }} {{ personDetails.last_name }}</td>
            <td v-if="personDetails.gender ='M'">Male</td>
            <td v-else>Female</td>
            <td>{{ personDetails.religion }}</td>
            <td>{{ personDetails.date_of_birth }}</td>
            <td>{{ personDetails.nationality }}</td>
            <td>
                    <span v-for="(identification, index) in personDetails.identification" v-bind:key="index">
                      <strong>{{ toTitleCase(identification.id_type) }}</strong>: {{ identification.id_number }}
                    </span>
            </td>
          </tr>
          </tbody>
        </table>
        <h4>Contact Information</h4>
        <table class="table">
          <thead>
          <tr>
            <th>Email</th>
            <th>Phone</th>
            <th>Next of Kin email</th>
            <th>Next of Kin phone</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ personDetails.email }}</td>
            <td>{{ $func.maskString(personDetails.phone) }}</td>
            <td v-if="personDetails.next_of_kin.email !== ''">
              {{ personDetails.next_of_kin.email }}
            </td>
            <td>
              Not Provided
            </td>
            <td v-if="personDetails.next_of_kin.phone !== ''">
              {{ $func.maskString(personDetails.next_of_kin.phone) }}
            </td>
            <td>
              Not Provided
            </td>
          </tr>
          </tbody>
        </table>
        <h4>Location</h4>:
        <table class="table">
          <thead>
          <tr>
            <th>County</th>
            <th>Subcounty</th>
            <th>Ward</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ personDetails.location.name }}</td>
            <td>{{ personDetails.location.name }}</td>
            <td>{{ personDetails.location.name }}</td>
          </tr>
          </tbody>
        </table>
        <h4>
          Occupation
        </h4>
        <h5 class="my-4">{{ personDetails.occupation.name }}</h5>
        <h4>Conditions</h4>
        <table class="table">
          <thead>
          <tr>
            <th>Allergies</th>
            <th>Pre-existing Conditions</th>
            <th>Other Conditions</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <span v-if="personDetails.allergies.length === 0">
                None Provided
              </span>
              <span v-else>
                    <span v-for="(allergy, index) in personDetails.allergies" v-bind:key="index">
                      {{ allergy.name }}
                    </span>
                  </span>
            </td>
            <td>
                  <span v-if="personDetails.preexisting_conditions.length === 0">
                    None Provided
                  </span>
              <span v-else>
                    <span v-for="(condition, index) in personDetails.preexisting_conditions" v-bind:key="index">
                      {{ condition.name }}
                    </span>
                  </span>
            </td>
            <td>
                  <span v-if="Object.keys(personDetails.other_conditions).length === 0">
                    None Provided
                  </span>
              <span v-else>
                    <span v-for="(other_condition, index) in personDetails.other_conditions" v-bind:key="index">
                      {{ other_condition.name }}
                    </span>
                  </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {toTitleCase} from "@/utils/methods";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "person_details",
  methods: {
    ...mapActions(['fetchAllAdverseEffects']),
    retrieveAdverseEffects() {
      // return this.$store.dispatch('fetchPersonDetails', "/person/" + this.$route.params.id)
      return this.fetchAllAdverseEffects()
    },
    toTitleCase(str) {
      return toTitleCase(str)
    }
  },
  computed: {
    ...mapGetters([
      'personDetails'
    ])
  },
  mounted() {
    // Get persons when the component has been mounted
    this.retrieveAdverseEffects();
  }
}
</script>
<style lang="">

</style>