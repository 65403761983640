<template>
  <div>
    <div class="valid-feedback d-block mb-4 mt-0" v-if="success_reset">
              <span>
                Password reset successfully. Please Login
              </span>
    </div>
    <form @submit.prevent="resetPassword">
      <div class="form-head">
        <div class="">
          <img
              src="@/assets/images/mohlogo.png" style="width: 90px; margin-top: -14px" class="img-fluid" alt="logo">
          <!--          <img-->
          <!--              src="../../assets/images/logo.png" style="width: 52px; margin-top: -14px" class="img-fluid" alt="logo">-->
          <span class="font-19 pt-3 text-defaultColor h4 d-block" style="font-weight: 600">Chanjo KE</span>
        </div>
        <div>

        </div>
      </div>
      <h5 class="text-info my-4">Enter username and OTP received to reset your password</h5>

      <div class="form-group">
        <input id="username" v-model="username" autocapitalize="none" autocomplete="username" autofocus="" class="form-control required"
               maxlength="150" name="username" placeholder="Enter Username" required="required"
               type="text">
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <input id="otp" v-model="otp" autocapitalize="none" autocomplete="otp" autofocus="" class="form-control required"
                 maxlength="150" name="otp" placeholder="Enter OTP" type="text"
          >
        </div>
        <div class="form-group col-md-4">
          <button class="btn btn-primary" type="button" @click="getOTP">
            Get OTP
          </button>
        </div>
        <div v-if="is_loading" class="offset-5">
          <p class="loader"></p>
          <p>Loading...</p>
        </div>
        <div class="invalid-feedback d-block">
              <span v-for="(error, index) in getError" v-bind:key="index">
                {{ error.message }}
              </span>
        </div>
        <div class="valid-feedback d-block mb-4 mt-0" v-if="countDown !==0">
              <span v-for="(successMessage, index) in getSuccessMessages" v-bind:key="index">
                {{ successMessage.message }}.
                Expires in {{countDown}}
              </span>
        </div>
        <div class="invalid-feedback d-block mb-4 mt-0" v-if="countDown === 0">
                <span>
                  OTP Expired, Request another token
                </span>
        </div>


      </div>
      <div class="form-group" v-if="(otp).toString().length === 6">
        <label for="password" class="pull-left">New Password</label>
        <input v-model="password" type="password" name="password" id="password" autocomplete="current-password"
               class="form-control"
               placeholder="Enter Password" >
      </div>

      <button v-if="(otp).toString().length === 6" class="btn btn-defaultColor btn-lg btn-block font-18"  type="submit">Reset Password</button>
      <button v-else class="btn btn-defaultColor btn-lg btn-block font-18" disabled type="submit">Reset Password</button>
    </form>
    <div class="login-or">
      <h6 style="color: transparent">OR</h6>
    </div>
    <div class="form-row mb-3">

      <div class="ml-2">
        <p class="mb-0 mt-3">Proceed to
          <a href="#" @click="logout">Log In</a>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "forgot_password",
  data() {
    return {
      error: {},
      username: "",
      otp: "",
      password:"",
      is_loading: false,
      countDown: 500,
      success_reset:false,
      prompt_to_reset: false
    }
  },
  methods: {
    ...mapActions(['setError', 'setSuccessMessages']),
    logout(){
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/')
          })
    },
    getOTP() {
      this.is_loading = true;
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('requestOTPUnauthenticated', {username: this.username}).then((resp) => {
        this.is_loading = false;
        this.countDown = 500;
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Sent OTP"}]);
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.is_loading = false;
      })
    },
    resetPassword(){
      this.is_loading = true;
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('resetUserPassword', {username: this.username, password: this.password, otp: this.otp}).then(resp=>{
        this.is_loading=false;
        this.success_reset = true;
        this.$store.dispatch('setSuccessMessages', []);
        this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/')
            })
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.is_loading = false;
      })
    },
    setLayout() {
      this.$store.commit('SET_LAYOUT', 'outside-dashboard')
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    hide_err() {
      this.setError({})
      this.setSuccessMessages({})
    },
    setAlert(){
      let token = JSON.parse(atob(this.getToken.split('.')[1]));

      if(!token.password_last_changed_at){
        this.setError({'detail': 'Reset your password to activate your account'})
      }
    }
  },
  computed: {
    ...mapGetters(['getError', 'getSuccessMessages', 'getToken'])
  },
  created() {
    this.setLayout()
    this.countDownTimer()
    this.hide_err()
    this.setAlert()
  }
}
</script>

<style scoped>

</style>
