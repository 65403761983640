<template>
  <div>
    <LocationFilter @setLocationFilter="setLocationFilter" size="col-md-3"/>
    <div class="form-row">
      <Vaccine_filter
          @setVaccineFilter="setVaccineFilter"
          class="col-md-4"
      />
      <div class="col-md-4">
        <div class="row">
          <DateFromFilter
              @setDateFromFilter="setDateFromFilter"
              class="col-md-6"
          />
          <DateToFilter
              @setDateToFilter="setDateToFilter"
              class="col-md-6"
          />
        </div>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col-md-2 d-flex pb-3">
        <button type="button" @click="filterStats" class="btn btn-primary align-self-end">
          Filter Stats
          <i class="feather icon-arrow-right"></i>
        </button>
      </div>
    </div>
    <div v-if="filter_selected">
      <div class="row">
        <div class="col-md-3">
          <div class="card m-b-30">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-5">
                  <span class="action-icon badge badge-primary-inverse mr-0"><i class="fa fa-stethoscope"></i></span>
                </div>
                <div class="col-7 text-right">
                  <h2 class="card-title font-14">Defaulters</h2>
                  <h2 v-if="!is_loading" class="mb-0">{{ totalDropouts | withCommas }} </h2>
                  <div v-else class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-for="(group, index) in getDropoutStats.gender_grouped" v-bind:key="index">
          <div class="card m-b-30">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
              <span class="action-icon badge badge-primary-inverse mr-0">
                <i v-if="group.gender === 'M'" class="fa fa-male"></i>
                <i v-else-if="group.gender === 'F'" class="fa fa-female"></i>
                <i v-else-if="group.gender === 'O'" class="fa fa-intersex"></i>
                <i v-else-if="group.gender === 'T'" class="fa fa-intersex"></i>
              </span>
                </div>
                <div class="col-9 text-right">
                  <h2 class="card-title font-14">Defaulters by Gender:
                    <strong v-if="group.gender === 'M'" class="text-uppercase">MALE</strong>
                    <strong v-else-if="group.gender === 'F'" class="text-uppercase">FEMALE</strong>
                    <strong v-else-if="group.gender === 'O'" class="text-uppercase">INTERSEX</strong>
                    <strong v-else-if="group.gender === 'T'" class="text-uppercase">TRANS</strong>
                  </h2>
                  <h2 class="mb-0">{{ group.people | withCommas }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>
                Total Defaulters by Occupation
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive m-b-30">
                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th scope="col">Occupation</th>
                    <th scope="col">Defaulters</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(stat, index) in getDropoutStats.occupation_grouped" v-bind:key="index">
                    <td>{{ stat.occupation_name }}</td>
                    <td>{{ stat.people | withCommas}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-primary">
            Select filters then click filter stats to generate defaulters report
          </h4>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vaccine_filter from "../filters/Vaccine_filter";
import DateFromFilter from "../filters/DateFromFilter";
import DateToFilter from "../filters/DateToFilter";
import LocationFilter from "@/components/filters/LocationFilter";

export default {
  name: "defaulters_count",
  components: {LocationFilter, DateToFilter, DateFromFilter, Vaccine_filter},
  data(){
    return {
      is_loading: true,
      defaulters_count: '',
      filter_selected: false,
      location_filter: '',
      vaccine_filter: '',
      from_date: '',
      to_date: '',
      payload : ''
    }
  },
  computed: {
    ...mapGetters([
      'getDropoutStats'
    ]),
    totalDropouts: function () {
      return this.getDropoutStats.gender_grouped.map(gender => {
        return gender.people
      }).reduce((a, b) => a + b, 0)
    }
  },
  methods: {
    ...mapActions([
        'fetchCenters'
    ]),
    filterStats(){
      this.filter_selected = true
      this.updateFullPayload()
      this.fetchStats(this.payload)
    },
    fetchStats(payload) {
      this.$store.dispatch('clearDropoutStats')

      this.is_loading = true
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchDropoutStats', payload).then(stats => {
        this.is_loading = false

       // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false
      })
    },
    updateFullPayload(){
      let payload = this.location_filter +
          this.vaccine_filter

      payload = payload +
          this.from_date +
          this.to_date

      // Replace first string with ?
      this.payload = payload.replace('&','?');
    },
    setLocationFilter(payload){
      this.location_filter = payload
      this.updateFullPayload()
    },
    setVaccineFilter(vaccine_filter, doses){
      this.vaccine_filter = vaccine_filter
      this.vaccine_doses = doses
      this.updateFullPayload()
    },
    setDateFromFilter(payload){
      this.from_date = payload
      this.updateFullPayload()
    },
    setDateToFilter(payload){
      this.to_date = payload
      this.updateFullPayload()
    },
  },
  watch: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>