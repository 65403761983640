<template>
  <div id="app">
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
// import axios from 'axios'
import inside_dashboard from "@/components/layouts/templates/inside_dashboard";
import outside_dashboard from "@/components/layouts/templates/outside_dashboard";
import { mapActions } from 'vuex'
import Error_messages from "@/components/layouts/includes/error_messages";
import api from "@/api";
import router from "@/router";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import store from './store'
// import {api_url, baseUrl} from "@/utils/constants";
// import axios from "@vue/cli-service/lib/options";
// eslint-disable-next-line no-unused-vars
import { actions as authActions } from "@/store/modules/Auth"
export default {
  computed: {
    ...mapActions(['logout']),
    layout() {
      return this.$store.getters.layout
    }
  },
  methods: {

  },
  name: 'App',
  components: {
    Error_messages,
    'inside-dashboard': inside_dashboard,
    'outside-dashboard': outside_dashboard
  },
  created: function () {
    //check if user is logged in
    api.interceptors.request.use((config) => {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
      config.headers['X-Username'] = localStorage.getItem('userName')
      return config;
    });

    api.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.response.status === 401) {
          // if you ever get an unauthorized, logout the user
          localStorage.removeItem('token')
          delete axios.defaults.headers.common['Authorization']
          console.log("happened", router.currentRoute.path)
          if (router.currentRoute.path !== '/') {
            router.push('/')
          }
        }
        // if (err.response.status === 500) {
        //   console.log("err 500")
        //   store.dispatch('setError', {'data':'An Unknown Error Occurred'})
        // }
        throw err;
      });
    });
  },
}
</script>

