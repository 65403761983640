<template>
  <div class="form-group">
    <label for="dose-filter" class="control-label">
      Filter By Dose
    </label>
    <select v-model="dose_filter" class="form-control" id="dose-filter" required>
      <option value="all_doses">All Doses</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">Booster</option>
<!--      <option v-for="(dose, index) in vaccine_doses" v-bind:key="index" :value="dose.dose_number">{{ dose.dose_number }}-->
<!--      </option>-->
    </select>
  </div>
</template>

<script>
export default {
  name: "Dose_filter",
  data(){
    return {
      dose_filter: 'all_doses',
    }
  },
  props: ['vaccine_doses'],
  methods:{
    setDoses(){
      this.vaccine_doses = this.vaccine.doses
    }
  },
  watch: {
    dose_filter: function () {
      let params = ''
      if(this.dose_filter === '3')
        params = '&booster=1'
      else
        params = '&dose=' + this.dose_filter
      this.$emit('setDoseFilter',
          this.dose_filter === 'all_doses' ?
              '' : params)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>