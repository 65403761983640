import api from "@/api";
// import $ from "jquery";

const state ={
  gadgets :[],
  showGadgetReassignForm: false,
  successMessages:[

  ],
  gadgetLedger:[

  ]
}
const getters ={
  allGadgets(state){
      return state.gadgets
  },
  gadgetReassignFormStatus(state){
    return state.showGadgetReassignForm
  },
  getSuccessMessages(state){
    return state.successMessages
  },
  allGadgetLedgers(state){
    return state.gadgetLedger
  }
}
const actions = {
  EditGadget({commit},payload){
    return new Promise((resolve , reject) => {
      api({
        url: '/gadget/' + payload.gadget_id + '/', method: 'PATCH', data: payload
      }).then((resp) => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  createGadget({commit},payload){
    return new Promise((resolve , reject) => {
      api({
        url: '/gadget/', method: 'POST', data: {
          imei_number: payload.imeiNumber,
          phone_number: payload.phoneNumber,
          center_id: payload.centerId,
          assigned_to: payload.assignedTo
        }
      }).then((resp) => {
        commit('createGadget', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  reAssignGadget({commit},payload){
    return new Promise((resolve , reject) => {
      api({
        url: '/gadget-ledger/', method: 'POST', data: {
          assigned_to_id: payload.centerId,
          gadget_id: payload.gadget_id,
        }
      }).then(resp => {
        commit('reAssignGadget', {"gadget": resp.data.gadget, "position":payload.gadget_position})
        commit('modifyGadgetReassignFormStatus', false)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  fetchGadgets({commit}){
    return new Promise((resolve, reject) => {
      api.get('/gadget/').then(resp =>{
          commit('addAllGadgets', resp.data)
          resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err)
        reject(err)
      })
    });
  },
  fetchGadgetLedger({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/gadget-ledger/?gadget=' + payload.gadget_id).then(resp =>{
        commit('modifyGadgetLedger', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err)
        reject(err)
      })
    });
  },
  changeGadgetReassignFormStatus({commit}, payload){
    commit('modifyGadgetReassignFormStatus', !(payload.status))
  },
  setSuccessMessages({commit}, payload){
      commit('modifySuccessMessages', payload);
      setTimeout(
        function() {
          // $("#success_alert").remove();
          commit('modifySuccessMessages', []);
          }, 5000);
  },
  setNonFadingSuccessMessages({commit}, payload){
    commit('modifySuccessMessages', payload);
  }
}

const mutations ={
  addAllGadgets:(state, gadgets) => (state.gadgets = gadgets),
  reAssignGadget:(state, gadget) => (state.gadgets.splice(
      gadget.position, 1, gadget.gadget
  )),
  createGadget:(state, gadgets) => (state.gadgets.push(gadgets)),
  modifyGadgetReassignFormStatus:(state, status) => (state.showGadgetReassignForm = status),
  modifySuccessMessages: (state, messages) =>(
    state.successMessages = messages),
  modifyGadgetLedger:(state, ledger) =>(
      state.gadgetLedger = ledger
  )
}

export default {
  state,
  getters,
  actions,
  mutations,
}