<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="User Actions"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <success_messages></success_messages>
    <div class="contentbar">
      <user_actions_list></user_actions_list>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from "@/components/layouts/includes/success_messages";
import user_actions_list from "@/components/users/user_actions_list";

export default {
  name: "actions",
  components: {user_actions_list, success_messages, Breadcrumbs},
}
</script>
<style lang="">

</style>