<template>
  <div class="card m-b-30">
    <div class="card-header">
      <div class="row justify-content-between">
        <div class="col-md-3">
          <h4 class="card-title">{{getInvalidAges.count}} Vaccinees with Invalid Ages</h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <default_table :isLoading="is_loading" @previousPage="previousPage()" @nextPage="nextPage()">
        <template #thead>
          <th>#</th>
          <th v-for="(header, index) in headers" v-bind:key="index">
            {{header}}
          </th>
        </template>
        <template #tbody>
          <tr v-for="(person, index) in getInvalidAges.results" v-bind:key="index">
            <td>{{index+offset+1}}</td>
            <td>{{ titleCase(person.first_name) }} {{ titleCase(person.middle_name) }}
              {{ titleCase(person.last_name) }}
            </td>
            <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ titleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
            </td>
            <td>
              {{ new Date(person.date_of_birth).toDateString() }} ({{ calculateAge(person.date_of_birth) }} Years)
            </td>
            <td>
              <span v-if="person.gender === 'M'" class="mb-0">
                Male
              </span>
              <span v-else>
                Female
              </span>
            </td>
            <td>
              {{person.occupation.name}}
            </td>
            <td>
              <button class="btn btn-primary mr-2"
                      @click="getSelectedPerson(person.id)">
                Edit Vaccinee
                <i class="feather icon-edit"></i>
              </button>
            </td>
          </tr>
        </template>
      </default_table>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import router from "@/router";
import default_table from "@/components/templates/DefaultTable";


export default {
  name: "invalid_ages_report",
  components: {default_table},
  data(){
    return {
      headers: [
        'Name',
        'Identification',
        'Date of Birth',
        'Gender',
        'Contact',
        'Occupation',
        'Actions'
      ],
      is_loading: true,
      offset: 0
    }
  },
  methods: {
    titleCase(snake_case) {
      return this.capitalize(snake_case.replace(/_/g, " "));
    },
    calculateAge(birthday) { // birthday is a date
      let parsed_birthday = new Date(birthday)
      var ageDifMs = Date.now() - parsed_birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    capitalize(str) {
      return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
    },
    getSelectedPerson(person_id) {
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        router.push("update-vaccinee", resp.id);
      })
    },
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      this.is_loading = true
      this.offset = this.offset + 10
      return this.$store.dispatch('fetchInvalidAges', '?'+ this.getParams(this.getInvalidAges.next)).then(
          this.is_loading = false
      )
    },
    previousPage(){
      this.is_loading = true
      this.offset = this.offset - 10
      return this.$store.dispatch('fetchInvalidAges', '?'+ this.getParams(this.getInvalidAges.previous)).then(
          this.is_loading = false
      )
    },
    retrieveInvalidAges(){
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchInvalidAges', '?limit=10&offset=0').then(resp => {
        console.log("resp", resp)
        this.is_loading = false
      })
    }
  },
  computed: {
    ...mapGetters(['getInvalidAges']),
  },
  mounted() {
    this.retrieveInvalidAges()
  }
}
</script>

<style scoped>

</style>