<template>
  <div class="row my-3">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-7">
            <h4 class="px-4">County
              Vaccinations Heat Map <span v-if="selectedCounty" class="font-bold text-sm float-right">{{ selectedCounty }} county: {{ donations | withCommas }} vaccinations</span> </h4>

            <div v-if="statsLoading" class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
              <span class="sr-only">Loading...</span>
            </div>
            <svg ref="svg"></svg>
          </div>
          <div class="col-12 col-md-5">
            <h4>County Vaccination Totals</h4>
            <div class="row">
              <div v-for="(statGroup, index) in countyStats" :key="index" class="col-md-6">
                <span class="font-weight-bold">{{statGroup['County']}}:</span> {{ Number(statGroup['Total Jabs']) | withCommas}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {select, geoPath, geoMercator, zoom} from "d3";
import * as topojson from "topojson-client";
import kenyaGeoData from "./kenyaGeoData.json";


export default {
  data: () => ({
    selectedCounty: null,
    donations: null,
    statsLoading: true,
    countyStats: []
  }),
  mounted() {
    this.fetchCountyStats();
  },
  methods: {
    chunkStats(stats){
      if(!this.statsLoading){
        var arrays = [], size = 2;

        while (stats.length > 0)
          arrays.push(stats.splice(0, size));

        console.log('rest', arrays)
        return this.countyStats = arrays
      }
    },
    fetchCountyStats(){
      this.$store.dispatch('fetchCountyStatsCSV', '?from=2021-03-01&upto=2022-03-07').then(statsCSV => {
        const stats = this.$func.convertCSVtoObject(statsCSV).filter(item => {
          return item.County
        });
        this.countyStats = stats;
        this.createMap(stats)
      })
    },
    createMap(vaccinations = []) {
      const width = 1080,
          height = 1050;
      const projection = geoMercator()
          .center([36.8, -1.3])
          .scale(1000 * 4.5)
          .translate([width / 2, height / 2]);
      const svg = select(this.$refs.svg);

      svg.attr("viewBox", `0 0 ${width} ${height}`).attr("preserveAspectRatio", "none");
      const g = svg.append("g");
      const pathGenerator = geoPath().projection(projection);

      const stats = vaccinations.map(itm => {
        return {
          "county": String(itm.County).substring(0, String(itm.County).length - 7),
          "totalJabs": itm['Total Jabs']
        }
      })

      this.chunkStats(stats)

      const countyData = topojson.feature(kenyaGeoData, kenyaGeoData.objects.kenya).features;
      countyData.forEach((d) => {
        if (d.properties["COUNTY_NAM"]) {
          d.properties["donation"] = stats.find((stat) => stat?.county?.toLowerCase() === d.properties["COUNTY_NAM"]?.toLowerCase())?.totalJabs || 0;
        }
      });
      g.selectAll("path")
          .data(countyData)
          .enter()
          .append("path")
          .style("fill", (d) => this.styleOnHover(d))
          .style("stroke", '#fff')
          .attr("d", pathGenerator)
          .on("mouseover", (e, d) => this.mouseOver(e, d))
          .on("mouseout", (e) => this.mouseOut(e));
      this.zoomMap(g);

      this.statsLoading = false;
    },
    zoomMap(g) {
      select(this.$refs.svg).call(
          zoom().on("zoom", (e) => {
            g.attr("transform", e.transform);
          })
      );
    },
    styleOnHover(d) {
      const vaccinations = d.properties.donation
      if (vaccinations < 100000) {
        return `#e06666`;
      } else if (vaccinations < 200000){
        return '#d63232';
      } else if (vaccinations < 600000){
        return '#cc0000';
      } else if (vaccinations < 3000000){
        return '#b70000';
      } else{
        return '#660000';
      }
    },
    mouseOver(e, d) {
      if (d.properties.COUNTY_NAM) {
        const countyName = d.properties.COUNTY_NAM;
        this.selectedCounty = `${countyName.substring(0, 1)}${countyName.substring(1).toLowerCase()}`;
        this.donations = `${d.properties.donation}`;
      }
      select(e)
          .transition()
          .duration(200)
          .style("opacity", .5)

      select(e.target)
          .transition()
          .duration(50)
          .style("opacity", 1)
          .style("cursor", 'pointer')
          .style("left", e.pageX + 10 + "px")
          .style("top", e.pageY - 15 + "px");
    },
    mouseOut(e) {
      this.selectedCounty = null;
      this.donations = null;

      select(e.target)
          .transition()
          .duration(50)
          .attr("opacity", "1");
      select(this.$refs.tooltip)
          .transition()
          .duration("50")
          .style("opacity", 0);
    },
  },
};
</script>
