import Login from "../../views/authentication/login";
import register from "../../views/authentication/register";
import forgot_password from "../../views/authentication/forgot_password";
import MyProfile from "../../views/my_profile/update";

const account_management=[
    {
        path: '/',
        name: 'login',
        component: Login,
        showInLeftBar: false

    },
    {
        path: '/register',
        name: 'register',
        component: register,
        showInLeftBar: false
    },
    {
        path: '/forgot_password',
        name: 'forgot_password',
        component: forgot_password,
        showInLeftBar: false
    },
    {
        path: '/my-profile',
        name: 'my_profile',
        component: MyProfile,
        showInLeftBar: false
    },
]

export default account_management