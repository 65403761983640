import Persons from "../../views/persons";
import RegisterPerson from "../../views/persons/create";
import Vaccination from "../../views/vaccination_page";
import AdministerVaccine from "../../views/vaccine_administration/create";
import UpdatePerson from "../../views/persons/update";
import PersonDetails from "../../views/person_details";
import AdverseEffectsPage from "../../views/adverse_effects_page";
import RecordAdverseEffect from "../../views/adverse_effects/create";
import AdverseEffects from "../../views/adverse_effects";
import VaccinationCertificate from "../../views/vaccination_certificate";
import EditVaccinees from "../../views/edit_vaccinees";
import AllRegistrations from "../../views/all_registrations";
import AllVaccinations from "../../views/all_vaccinations";
import PendingVaccinations from "../../views/pending_vaccinations";
import Search from "../../views/search";
import ExternalVaccination from "../../views/external_vaccination/create";
import ExpiredBatchVaccination from "../../views/expired_batch_vaccination/create";
import VerifyCertificate from "../../views/verify_certificate/show";

const person_routes = [
    {
        path: '/persons',
        name: 'persons',
        component: Persons,
        isSingleRoute:true,
        parentName: 'Vaccination',
        verboseName: 'Search Vaccinee',
        icon:'<i class="feather icon-activity"></i>',
        children: [
            {
                path: 'register-vaccinee',
                name: 'register_person',
                verboseName: 'Register Vaccinee',
                component: RegisterPerson,
            },
            {
                path: 'vaccination',
                name: 'vaccination',
                verboseName: 'Administer Vaccine',
                component: Vaccination,
            },
            {
                path: 'administer-vaccine',
                name: 'administer_vaccine',
                component: AdministerVaccine,
                showInLeftBar: false
            },
            {
                path: 'update-vaccinee',
                name: 'update_person',
                component: UpdatePerson,
                showInLeftBar: false
            },
            {
                path: 'person-details/:id',
                name: 'person_details',
                component: PersonDetails,
                showInLeftBar: false
            }, {
                path: 'adverse_events',
                name: 'adverse_events_page',
                verboseName: 'Adverse Events',
                component: AdverseEffectsPage
            },
            {
                path: 'adverse-effects/record/person/:id',
                name: 'record_adverse_effects',
                component: RecordAdverseEffect,
                showInLeftBar: false
            },
            {
                path: 'adverse-effects/person/:id',
                name: 'adverse_effects',
                component: AdverseEffects,
                showInLeftBar: false
            },
            {
                path: 'vaccination-certificate/vaccinee/:person',
                name: 'vaccination_certificate',
                component: VaccinationCertificate,
                showInLeftBar: false
            },
            {
                path: 'edit-vaccinees',
                name: 'edit_vaccinees',
                component: EditVaccinees,
                showInLeftBar: false
            },
            {
                path: 'all-registrations',
                name: 'all_registrations',
                component: AllRegistrations
            },
            {
                path: 'all-vaccinations',
                name: 'all_vaccinations',
                component: AllVaccinations
            },
            {
                path: 'pending-vaccinations',
                name: 'pending_vaccinations',
                component: PendingVaccinations
            },
            {
                path: 'search',
                name: 'search',
                component: Search
            },
            {
                path: 'external_vaccination',
                name: 'create_external_vaccination',
                verboseName: 'External Vaccinations',
                component: ExternalVaccination,
                showInLeftBar: true
            },
            {
                path: 'expired_batch_vaccination',
                name: 'expired_batch_vaccination',
                verboseName: 'Expired Batches',
                component: ExpiredBatchVaccination,
                showInLeftBar: true
            },
        ]
    },
    {
        path: '/certificate/:hash',
        name: 'certificate',
        component: VerifyCertificate,
        showInLeftBar: false
    },
]

export default person_routes