<template>
    <div>
        <div id="infobar-settings-sidebar" class="infobar-settings-sidebar">
            <div class="infobar-settings-sidebar-head d-flex w-100 justify-content-between">
                <h4>Settings</h4><a href="javascript:void(0)" id="infobar-settings-close"
                                    class="infobar-settings-close"><img src="../../../assets/images/icons/close.png"
                                                                        class="img-fluid menu-hamburger-close"
                                                                        alt="close"></a>
            </div>
          <div class="infobar-settings-sidebar-body">
          </div>
        </div>
        <div class="infobar-settings-sidebar-overlay"></div>
    </div>
</template>

<script>
    export default {
        name: "InfoBar"
    }
</script>
