<template>
  <div class="card">
    <div class="card-body">
      <div v-if="Object.keys(getError).length !==0 " id="err_alert" class="alert alert-danger alert-dismissible fade show"
           role="alert">
        <p v-for="(item, index) in getError" :key="index">
          {{ (index[0]).toUpperCase() }}{{ index.substr(1, index.length) }} : <span v-for="(sub_item, index) in item"
                                                                                    :key="index">{{ sub_item }}</span>
        </p>

        <button class="close" type="button" @click="hide_err">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h4 class="mb-3">Search for a vaccinee</h4>
        </div>
      </div>
      <form @submit.prevent="searchPerson()">
        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="search_by">
              Search By
              <span class="text-danger">*</span>
            </label>
            <select id="search_by" v-model="search_by" class="form-control mr-2" required>
              <option value="name">First and Last Name</option>
              <option value="phone_number">Phone Number</option>
            </select>
          </div>
          <div class="form-group col-md-2 mr-2">
            <label for="search_value">
              Value
              <span class="text-danger">*</span>
            </label>
            <input id="search_value" v-model="search_value" class="form-control" type="text" required>
          </div>
          <div class="col-md-2">
            <label class=""></label> <br>
            <div>
              <button class="btn btn-primary mt-2" type="submit">
                Search Vaccinee
                <i class="feather icon-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div v-if="is_loading" class="offset-5">
        <p class="loader"></p>
        <p>Loading</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "support_search",
  data: function () {
    return {
      search_by: 'name',
      is_loading: false,
      search_value: ''
    }
  },
  methods: {
    ...mapActions(['setError']),
    searchPerson() {
      this.$store.dispatch('setPersonDetails', {})
      this.is_loading = true;
      var payload = '';

      if(this.search_by === 'name'){
        let first_name = this.search_value.substr(0,this.search_value.indexOf(' '));
        let last_name = this.search_value.substr(this.search_value.indexOf(' ')+1);

        payload = '?first_name=' + first_name + '&last_name=' + last_name
      }else{
        payload = '?phone=%2B' + (this.search_value).toString().substring(1)
      }

      this.$store.dispatch('advancedSearch', payload
      ).then(resp => {
        if (resp.length > 0) {
          this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinees found successfully"}])
          this.setError({})
          this.is_loading = false;
        }else{
          this.is_loading = false;
          this.setError({
            message: "No vaccinee found"
          })
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;
      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    },
    hide_err(){
      this.setError({})
    },
    clearSearchResults() {
      this.$store.dispatch('clearSearchResults', {})
    }
  },
  computed: {
    ...mapGetters(['getError'])
  },
  mounted() {
    this.getUserRoles()
    this.clearSearchResults()
  }
}
</script>

<style scoped>

</style>