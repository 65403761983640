<template>
  <div class="form-group">
    <label for="upto-date">Upto</label>
    <div class="input-group">
      <input v-model='to_date' id="upto-date" class="datepicker-here form-control"
             aria-describedby="basic-addon2"
             placeholder="dd/mm/yyyy" type="date">
    </div>
  </div>
</template>

<script>
export default {
  name: "DateToFilter",
  data(){
    return{
      to_date: this.$func.formatDate(new Date()),
    }
  },
  watch: {
    to_date: function (){
      this.$emit('setDateToFilter', '&upto=' + this.to_date)
    }
  },
  mounted(){
    this.$emit('setDateToFilter', '&upto=' + this.to_date)
  }
}
</script>

<style scoped>

</style>