import Notifications from "../../views/notifications";
import create_notification_schema from "../../schemas/notifications/createNotification.json";
import schema_create_modal from "../../components/templates/data/schema_create_modal";

const notification_routes=[
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        children:[
            {
                path: 'create_notification',
                name: 'create_notification',
                component: schema_create_modal,
                props: {
                    jsonSchema: create_notification_schema,
                    vuex_action: 'createNotification',
                    object_title: 'Notification',
                    // size: 'w-3/4',
                }
            }
        ]
    },
]
export default notification_routes