<template>
  <div>
    <div v-if="currentRouteName === 'notifications'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Notifications"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <div class="card">
          <div class="card-body">

            <data_table_template v-bind="$attrs" :table_headings="['#',
                'Sent', 'Type', 'Recipient', 'notification_message', 'priority', 'attempted At',
                'start sending at', 'retries', 'retries', 'failed at', 'server response', ]"
                                 vuex_data_action="fetchNotifications">
              <template v-slot="{item}">
                <td>{{item.id}}</td>
                <td>
                  <i v-if="item.sent" class="dripicons-checkmark text-success"></i>
                  <i v-if="!item.sent && item.failed_at" class="dripicons-warning text-danger"></i>
                  <i v-if="!item.sent && !item.failed_at" class="dripicons-clock text-warning"></i>
                </td>
                <td>{{ item.notification_type }}</td>
                <td>{{ item.recipient }}</td>
                <td>
                  <button :data-target="`notification_message` +item.id" aria-controls="notification_message"
                          aria-expanded="true" class="btn btn-link" data-toggle="collapse"
                          @click="toggleCollapse('notification_message'+item.id)">
                    View Message
                  </button>
                  <div :id="`notification_message` +item.id" aria-labelledby="notification_message" class="collapse"
                       data-parent="#accordion">
                    <div class="card-body">
                      {{ item.notification_message }}
                    </div>
                  </div>
                </td>
                <td>{{ item.priority }}</td>
                <td>{{
                    item.attempted_at !== null ? `${new Date(item.attempted_at).toLocaleDateString()} ${new Date(item.attempted_at).toLocaleTimeString()}` : 'N/A'
                  }}
                </td>
                <td>{{
                    item.start_sending_at !== null ? `${new Date(item.start_sending_at).toLocaleDateString()} ${new Date(item.start_sending_at).toLocaleTimeString()}` : 'N/A'
                  }}
                </td>
                <td>{{ item.retries }}</td>
                <td>{{
                    item.failed_at !== null ? `${new Date(item.failed_at).toLocaleDateString()} ${new Date(item.failed_at).toLocaleTimeString()}` : 'N/A'
                  }}
                </td>
                <td>
                  <button :data-target="`server_response` +item.id" aria-controls="server_response"
                          aria-expanded="true" class="btn btn-link" data-toggle="collapse"
                          @click="toggleCollapse('server_response'+item.id)">
                    View Response
                  </button>
                  <div :id="`server_response` +item.id" aria-labelledby="server_response" class="collapse"
                       data-parent="#accordion">
                    <div class="card-body">
                      {{ item.server_response }}
                    </div>
                  </div>
                </td>
              </template>
            </data_table_template>
          </div>
        </div>
      </div>
    </div>
    <router-view>

    </router-view>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions} from "vuex";
import data_table_template from "@/components/templates/data/DataTableTemplate";
import $ from 'jquery'


export default {
  name: "notifications",
  components: {data_table_template, Breadcrumbs, },
  methods: {
    ...mapActions(['setError']),
    clearErrors() {
      this.setError({})
    },
    toggleCollapse(id) {
      let id_selector = $("#" + id)
      console.log("sel", id_selector[0].classList.contains("show"))
      if (id_selector[0].classList.contains("show")) {
        id_selector.removeClass("show")
      } else {
        id_selector.addClass("show")
      }
    }
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
}
</script>