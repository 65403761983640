import api from "@/api";

const state = {
  effect_types: [
    {"id":1,"created_at":"2021-03-04T20:31:15.926591+03:00","updated_at":"2021-03-04T20:31:15.926641+03:00","name":"BCG Lymphadenitis"},{"id":2,"created_at":"2021-03-04T20:32:06.417098+03:00","updated_at":"2021-03-04T20:32:06.417144+03:00","name":"Convulsion"},{"id":3,"created_at":"2021-03-04T20:32:22.478448+03:00","updated_at":"2021-03-04T20:32:22.478495+03:00","name":"Generalized urtacaria (hives)"},{"id":4,"created_at":"2021-03-04T20:32:56.084924+03:00","updated_at":"2021-03-04T20:32:56.084969+03:00","name":"High Fever"},{"id":5,"created_at":"2021-03-04T20:33:05.787765+03:00","updated_at":"2021-03-04T20:33:05.787811+03:00","name":"Injection site abscess"},{"id":6,"created_at":"2021-03-04T20:33:12.628548+03:00","updated_at":"2021-03-04T20:33:12.628593+03:00","name":"Sever Local Reaction"},{"id":7,"created_at":"2021-03-04T20:33:31.896267+03:00","updated_at":"2021-03-04T20:33:31.896312+03:00","name":"Anaphylaxis"},{"id":8,"created_at":"2021-03-04T20:33:41.669325+03:00","updated_at":"2021-03-04T20:33:41.669370+03:00","name":"Enchephalitis/Menegitis"},{"id":9,"created_at":"2021-03-04T20:33:52.917116+03:00","updated_at":"2021-03-04T20:33:52.917162+03:00","name":"Encephalopathy"},{"id":10,"created_at":"2021-03-04T20:33:58.892106+03:00","updated_at":"2021-03-04T20:33:58.892151+03:00","name":"Paralysis"},{"id":11,"created_at":"2021-03-04T20:34:11.057197+03:00","updated_at":"2021-03-04T20:34:11.057242+03:00","name":"Toxic Shock"},{"id":12,"created_at":"2021-05-24T11:45:04.294931+03:00","updated_at":"2021-05-24T11:45:04.295005+03:00","name":"Other"}
  ]
}
const getters = {
  allEffectTypes(state) {
    return state.effect_types
  },
}
const actions = {
  reportAdverseEffects({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/report-adverse-effect/', method: "POST", data: payload
      }).then(resp => {
        commit('setAdverseEffectsForPerson', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  reportOutcome({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/report-adverse-effect/' + payload.id + '/', method: "PATCH", data: {
          outcome: payload.outcome
        }
      }).then(resp => {
        commit('setAdverseEffectsForPerson', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  fetchEffectTypes({commit}){
    return new Promise((resolve , reject) =>{
      api.get("/adverse-effect-category/").then(resp=>{
        commit("setAllEffectTypes", resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
}

const mutations = {
  setAdverseEffectsForPerson: (state, adverse_effects_for_person) => (state.adverse_effects_for_person = adverse_effects_for_person),
  setAllEffectTypes: (state, effect_types) => (state.effect_types = effect_types),
}

export default {
  state,
  getters,
  actions,
  mutations,
}