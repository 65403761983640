<template>
  <div>
    <div :id="modal_id" aria-hidden="true" :aria-labelledby="aria_label" :modal_size="modal_size"
         class="modal fade"
         role="dialog" tabindex="-1">
      <div :class="['modal-dialog ' + modal_size]" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="dispatchModalLabel" class="modal-title">{{modal_header}}</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot>

            </slot>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <slot name="modal_footer">

            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "default_modal",
props: [
  'modal_id',
  'aria_label',
  'modal_header',
  'click_action',
  'click_text',
  'modal_size'
]
}
</script>

<style scoped>

</style>