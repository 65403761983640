<template>
  <default_modal aria_label="Daily Stock Report" modal_header="Stock Report" modal_id="report_stock_level_modal">
    <div class="form form-row">
      <div v-if="unreportedDays.length > 1" class="col-12">
        <p class="text-danger font-14">It looks like you last created your stock report on {{unreportedDays[0]}}. You will be required to
          create a report for each day between {{unreportedDays[1]}} and today {{new Date().toLocaleDateString()}}. </p>
      </div>
      <p v-if="unreportedDays.length ===1" class="text-success">All Done! Looks like you last reported today!</p>
      <div class="mb-4 col-12 row justify-end">
        <div class="col-md" v-if="unreportedDays.length > 1">
          <button  :class="['btn btn-default']" @click="setReportDate(unreportedDays[1])">
            Report For {{unreportedDays[1]}}
          </button>
        </div>
        <div class="col-md" v-if="unreportedDays.length > 0 && unreportedDays.length<1">
          <button  :class="['btn btn-default']" @click="setReportDate(unreportedDays[0])">
            Report For {{unreportedDays[0]}}
          </button>
        </div>
        <div v-if="unreportedDays.length >1" class="col-md">
          <a class="btn btn-outline-warning" data-toggle="collapse" href="#collapseUnreportedDates" role="button" aria-expanded="false" aria-controls="collapseExample">
            View Unreported Days
          </a>
        </div>
      </div>
      <div class="collapse col-12" id="collapseUnreportedDates">
        <div class="card card-body ">
          <div class="row" >
            <div v-for="(un_reported_day, index) in unreportedDays" class="col-4" :key="index">
              {{un_reported_day}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h6 v-if="Object.keys(batch).length !== 0">Batch Number : {{ batch.batch.batch_number }}, Starting Qty:
          {{ $func.numberWithCommas(batch.good_quantity) }}</h6>
        <label class="text-capitalize mt-2">Date </label>
        <input type="date" name="amount_left" id="report_date" v-model="report_date"
               :class="'form-control ' + $func.checkIfErrorExists('date') + valid_date" required>
        <div class="invalid-feedback">
              <span v-for="(error, index) in getError.date" v-bind:key="index">
                {{ error }}
              </span>
          <span v-if="valid_date === 'is-invalid'">
                Please select a valid date
              </span>
        </div>
      </div>
      <div class="row" v-if="report_date !== '' && valid_date !== 'is-invalid'">
        <div class="col-12 mt-3">
          <label class="text-capitalize mt-1"> Doses used on <span
              class="text-danger text-capitalize">{{ report_date }}</span>
            (number of vials opened <span class="text-lowercase">x</span> number of doses per vial)
          </label>
          <input type="number" v-model="report_doses_used" id="report_doses_used"
                 :class="'form-control ' + $func.checkIfErrorExists('report_doses_used')" required>
          <span class="text-danger" v-if="report_doses_used < 0">
              Cannot submit negative value
            </span>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.report_doses_used" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12 mt-3 d-none">
          <label class="text-capitalize mt-1"> Number Vaccinated In System<span
              class="text-danger text-capitalize">{{ report_date }}</span></label>
          <input type="number" v-model="system_number_vaccinated" id="system_number_vaccinated"
                 :class="'form-control ' + $func.checkIfErrorExists('system_number_vaccinated')">
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.system_number_vaccinated" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <label class="text-capitalize mt-1"> Doses administered on <span
              class="text-danger text-capitalize">{{ report_date }}</span></label>
          <input type="number" v-model="report_number_vaccinated" id="report_number_vaccinated"
                 :class="'form-control ' + $func.checkIfErrorExists('report_number_vaccinated')" required>
          <span class="text-danger" v-if="report_number_vaccinated < 0">
              Cannot submit negative value
            </span>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.report_number_vaccinated" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12 mt-4">
          <label class="text-capitalize mt-1"> Doses Balance for <span
              class="text-danger text-capitalize">{{ report_date }} </span>(Day's Starting Balance - Today's Used Doses)</label>

          <input type="number" v-model="report_doses_balance" id="report_doses_balance"
                 :class="'form-control ' + $func.checkIfErrorExists('doses_balance')" required="required" readonly>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.doses_balance" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12 mt-2">
          <label class="text-capitalize mt-1">Total Doses Wasted on <span
              class="text-danger text-capitalize">{{ report_date }} </span></label>
          <input type="number" v-model="report_doses_wasted" id="report_doses_wasted"
                 :class="'form-control ' + $func.checkIfErrorExists('doses_wasted')" required="required" readonly>
          <div class="invalid-feedback">
              <span v-for="(error, index) in getError.doses_wasted" v-bind:key="index">
                {{ error }}
              </span>
          </div>
        </div>
        <div class="col-12">
          <label class="text-capitalize mt-2"> Remarks</label>
          <textarea name="remark" id="report_remark" v-model="report_remarks"
                    :class="'form-control ' + $func.checkIfErrorExists('remarks')">
        </textarea>
          <div class="invalid-feedback">
            <span v-for="(error, index) in getError.report_remarks" v-bind:key="index">
              {{ error }}
            </span>
          </div>
        </div>
      </div>

    </div>
    <template v-slot:modal_footer>
      <button v-if="submitting === false && report_date!=='' && report_date !== '' && valid_date !== 'is-invalid' && validData()"
              class="btn btn-primary" @click="submitBatchReport">
        Create Report
      </button>
      <div v-if="submitting === true">
        <SubmittingLoader/>
      </div>
    </template>
  </default_modal>
</template>

<script>

import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import $ from "jquery";
import {mapGetters} from "vuex";

export default {
  name: "CreateDailyStockReportModal",
  components: {SubmittingLoader, default_modal},
  props: ['batch', 'centerInfo', 'unreportedDays'],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      report_date: '',
      doses_wasted: 0,
      report_doses_used: 0,
      report_doses_balance: 0,
      system_number_vaccinated: 0,
      report_number_vaccinated: 0,
      report_viles_balance: 0,
      report_doses_wasted: 0,
      report_initial_doses_balance: 0,
      report_remarks: '',
      submitting: false,
      loading: false,
      valid_date: '',
      show_submit: true,
    }
  },
  computed: {
    ...mapGetters([
      'getError'
    ]),
  },
  watch: {
    batch: function() {
      this.report_doses_balance =  this.batch.good_quantity - this.batch.used_quantity
      this.doses_wasted = this.batch.damaged_quantity;
    },
    report_doses_used: function () {
      this.report_doses_wasted = this.report_doses_used - this.report_number_vaccinated
      this.report_doses_balance = this.batch.good_quantity - this.batch.used_quantity - this.report_doses_used

    },
    report_number_vaccinated: function () {
      this.report_doses_wasted = this.report_doses_used - this.report_number_vaccinated
      this.report_doses_balance = this.batch.good_quantity - this.batch.used_quantity - this.report_doses_used
      console.log('goodQ', this.batch.good_quantity);
    },
    report_date: function () {
      let report_date = new Date(this.report_date)
      if (report_date < new Date('2021-2-1') || report_date > new Date()) {
        this.valid_date = 'is-invalid'
        this.show_submit = false
      } else {
        this.valid_date = 'is-valid'
        this.show_submit = true
        // this.$store.dispatch('fetchBriefStatsJabs', '?date=' + String(this.report_date)).then(resp => {
        //   this.system_number_vaccinated = resp.jabs_today;
        // })
      }
    },
  },
  methods: {
    validData(){
      return this.report_doses_used >= 0 && this.report_number_vaccinated >= 0;
    },
    setReportDate(date){
      this.report_date = date
    },
    retrieveCenterInventory() {
      this.loading = true
      this.$store.dispatch(this.action, `?good_quantity_gt=0&limit=${this.limit}&offset=0`).then(resp => {
        this.loading = false
        return resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.loading = false
        this.$store.dispatch('setError', {'detail': 'Could Not Fetch Inventory'})
      });
    },
    submitBatchReport() {
      this.submitting = true
      this.$store.dispatch('updateBatchDailyChange', {
        batch_inventory_id: this.batch.id,
        date: this.report_date,
        number_vaccinated: this.report_number_vaccinated,
        system_number_vaccinated: this.system_number_vaccinated,
        report_doses_used: parseInt(this.report_doses_used),
        doses_balance: this.report_doses_balance,
        doses_wasted: this.report_doses_wasted,
        batch_transaction_type: 'report',
        remarks: this.report_remarks,
        adjustment_reason: 'daily stock report'
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        $('#report_stock_level_modal').modal('hide')
        this.loading = true
        this.retrieveCenterInventory();
        this.submitting = false;
        this.$store.dispatch('setSuccessMessages', [{"message": "Stock Updated"}])
        this.loading = false
        this.report_date = ''
        this.report_doses_balance = 0
        this.report_number_vaccinated = 0
        this.system_number_vaccinated = 0
        this.report_doses_wasted = 0
        this.report_doses_used = 0
        this.batch_transaction_type = 0
        this.report_remarks = ''
        this.$store.dispatch('setError', {})
        this.$store.dispatch('fetchCenterInfoWithBatchReport')
      }).catch(err => {
        this.submitting = false;
        this.$store.dispatch('setError', err.response.data)
        console.log("err", err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
