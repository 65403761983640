<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Invalid Vaccinee Ages"></breadcrumbs>
    <div class="contentbar">
      <invalid_ages_report></invalid_ages_report>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import invalid_ages_report from "@/components/invalid_data/invalid_ages_report";

export default {
  name: "InvalidAges",
  components: {invalid_ages_report, Breadcrumbs}
}
</script>

<style scoped>

</style>