<template>
  <div>
    <div v-if="currentRouteName === 'manage_vaccinees'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Vaccinees Management"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <success_messages></success_messages>
        <!-- <advanced_search class="mb-3"></advanced_search> -->
        <view_person v-if="Object.keys(personDetails).length !==0"></view_person>
        <search_results v-if="Object.keys(getSearchResults).length !== 0 && getSearchResults.length !==0"></search_results>
      </div>
    </div>
    <router-view>
    </router-view>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
// import advanced_search from "@/components/support/advanced_search";
import view_person from "@/components/persons/view_person";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import search_results from "@/components/support/search_results";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "manage_vaccinees",
  components: {search_results, view_person, Breadcrumbs, 
    // advanced_search,
     success_messages},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    }
  },
  computed: {
    ...mapGetters(['personDetails','getSearchResults']),
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.clearErrors()
  }
}
</script>