<template>
  <div>
    <div v-if="currentRouteName === 'users'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Users"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <div v-if="currentRouteName === 'users'">
          <router-link :to="{name: 'new_user'}" class="btn btn-primary mb-3 mr-2">
          Add New System User
          <i class="feather icon-user-plus"></i>
        </router-link>
        <span v-if="$func.userHasRoles(['Trainer of Trainers(TOT)', 'Support'])">
          <SubmittingLoader status="Downloading CSV" v-if="downloading_csv" class="mb-3"></SubmittingLoader>
          <button v-else class="btn btn-primary mb-3" @click="downloadUsersCSV">
            Download {{ county.name }} Users CSV
            <i class="feather icon-file-text"></i>
          </button>
        </span>
        </div>
        <success_messages></success_messages>
        <search_system_user class="mb-3"></search_system_user>
        <search_user_results
            v-if="Object.keys(allSystemUsers).length !== 0 && allSystemUsers.length !==0"></search_user_results>

      </div>
    </div>
    <router-view>
    </router-view>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import search_system_user from "@/components/support/search_system_user";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import search_user_results from "@/components/support/search_user_results";
import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Users",
  components: {search_user_results, Breadcrumbs, search_system_user, success_messages, SubmittingLoader},
  data(){
    return {
     is_downloading: false,
     county: '',
     downloading_csv: false
    }
  },
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getLoggedInUserCounty(){
      var subcounty = ''

      this.$store.dispatch('fetchSubCounty', this.centerInfo.sub_county).then(resp => {
        subcounty = resp

        this.$store.dispatch('fetchCounty', subcounty.county).then(resp => {
          this.county = resp
        })
      })
    },
    downloadUsersCSV(){
      this.downloading_csv = true

      let payload = '?county=' + this.county.id
      this.$store.dispatch('fetchUsersCSV', payload).then(data => {
        this.downloading_csv = false
        var file_name = 'all_users_csv_'

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        anchor.target = '_blank';
        anchor.download = file_name + '.csv';

        anchor.click()
      })
    },
  },
  computed: {
    ...mapGetters(['personDetails', 'allSystemUsers', 'centerInfo']),
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.clearErrors()
    this.getLoggedInUserCounty()
  }
}
</script>
