<template>
  <div >
    <div style="height: 500px">
      <table :title="title" class="table">
        <thead class="thead-dark">
        <tr>
          <th v-for="(heading, index) in table_headings" :key="index">
            {{heading}}
          </th>
        </tr>
        </thead>
      </table>
      <vuescroll>
        <table :title="title" class="table">

          <tbody v-if="!loading && pageResult.length < 1">
          <tr >
            <td v-for="(heading, index) in Math.ceil(table_headings.length/2) -1 " :key="index"></td>
            <td>
              No Data Was Found
            </td>
          </tr>
          </tbody>
          <tr v-if="loading">
            <td v-for="(heading, index) in Math.ceil(table_headings.length/2) -1 " :key="index"></td>
            <submitting-loader ></submitting-loader>
          </tr>
          <!-- for non-paginated data-->
          <!--      <vuescroll>-->

          <!--    <tbody v-if="pageResult.length > 0 && !loading">-->
          <!--        <tr v-for="(item,index) in pageResult"-->
          <!--            :key="index">-->
          <!--          <slot :item="item" name="tbody">-->
          <!--          </slot>-->
          <!--        </tr>-->

          <!--      </tbody>-->
          <!--      &lt;!&ndash;end non.paginated data&ndash;&gt;-->
          <!--      </vuescroll>-->

          <!-- for paginated data-->
          <tbody v-if="Object.keys(pageResult).indexOf('results') !== -1 && !loading">

          <tr v-for="(item,index) in pageResult.results"
              :key="index">
            <slot v-bind:item="item" :index="index">
            </slot>
          </tr>

          </tbody>
          <!--end paginated data-->
        </table>
      </vuescroll>
    </div>

    <div class="sticky-bottom bg-blue">
    <div class="">
      <ul class="pagination" style="    margin-top: 42px; background: aliceblue;">
        <li v-if="currentPage!==1" @click="previousPage()" class="page-item"><a class="page-link" href="#">Previous</a></li>
          <li v-for="number in pageNumbers" class="page-item" :key="number">
            <a class="page-link" @click="navigatePage(number+1, '?limit=100&offset='+(number+1)*pageSize)">{{number}}</a>
          </li>
        <li v-if="(currentPage!==recordsCount/pageSize) && recordsCount - pageSize > 0" @click="lastPage()" class="page-item"><a class="page-link" href="#">Last Page</a></li>

        <!--      <li v-if="previousLink !==null" @click="previousPage()" class="page-item"><a class="page-link" href="#">Previous</a>-->
        <!--      </li>-->
        <!--      <li v-if="nextLink !==null" @click="nextPage()" class="page-item"><a class="page-link" href="#">Next</a>-->
        <!--      </li>-->
      </ul>
    </div>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';

import SubmittingLoader from "@/components/inventory/SubittingLoader";
export default {
  name: "data_table_template",
  // eslint-disable-next-line vue/no-unused-components
  components: {SubmittingLoader, vuescroll},
  data(){
    return {
      pageResult:[],
      loading : false,
      pageNumbers:[1],
      recordsCount:0,
      pageSize: 10,
      currentPage:1
    }
  },
  props: {
    title:{
      type:String,
      default:''
    },
    table_headings: {
      type: Array,
      default: ()=>{return []}
    },
    showBack:{
      type: Boolean,
      default: false
    },
    vuex_data_action: {
      type: String,
      default : ''
    },
    action_payload: {}
  },
  methods:{
    previousPage(){
      let first_number = this.pageNumbers[0] - this.pageSize
      this.pageNumbers = []
      for(var i=first_number > 0 ? first_number : this.pageSize; i<=(first_number+this.pageSize); i++){
        this.pageNumbers.push(i)
      }
    },
    lastPage(){
      let first_number = this.recordsCount - this.pageSize
      this.pageNumbers = []
      for(var i=first_number; i<=(first_number+this.pageSize); i++){
        this.pageNumbers.push(i)
      }
    },
    navigatePage(pageNumber, path){
      this.pageNumbers =[]
      let pageLimit = this.recordsCount/this.pageSize;
      this.currentPage = (pageNumber -1 ) === 0 ? pageNumber : pageNumber -1
      for(var i=this.currentPage; i<=(this.currentPage+10 > pageLimit ? pageLimit : this.currentPage+10 ); i++){
        this.pageNumbers.push(i)
      }
      this.fetchData(path)
    },
    goBack(){
      this.$router.back()
    },
    fetchData(){
      this.loading = true
      // eslint-disable-next-line no-unused-vars
       this.$store.dispatch(this.vuex_data_action, this.action_payload).then(resp => {
         this.pageResult = resp
         if(resp.count){
           this.recordsCount = resp.count
           let numbers_array = []
           for(var i=this.currentPage; i<=this.currentPage+10; i++){
              numbers_array.push(i)
           }
           this.pageNumbers = numbers_array
         }
         // eslint-disable-next-line no-unused-vars
       }).catch(err=>{
         this.$store.dispatch('setError', {'detail':'Error Fetching Data'})
       }).then(()=>{
         this.loading = false
       })
    },
  },
  mounted() {
    this.fetchData('?limit='+ this.pageSize+'&offset=0')
  },
  watch: {
    $route: {
      async handler() {
        if (
            this.$route.matched.length === 1 || (this.$route.matched.length > 1 && this.pageResult).length === 0 )
        {
          this.fetchData('?limit='+ this.pageSize+'&offset=0');
        }
      },
    },
  },
}
</script>

<style scoped>

</style>