<template>
  <div>
    <default_modal aria_label="Deactivate User" modal_header="Deactivate Vaccinee"
                   modal_id="deactivate_vaccinee_modal">
      <h4 class="text-center text-danger">Confirm Deactivation of {{ personDetails.first_name }}
        {{ personDetails.middle_name }}
        {{ personDetails.last_name }}</h4>
      <template v-slot:modal_footer>
        <button v-if="submitting === false" class="btn btn-danger" @click="deactivateVaccinee">
          Deactivate
          <i class="feather icon-trash-2"></i>
        </button>
        <div v-else>
          <SubmittingLoader/>
        </div>
      </template>
    </default_modal>
    <default_modal v-if="personDetails.vaccinations" aria_label="Mark External Vaccination" modal_header="Mark External Vaccination"
                   modal_id="mark_external_vaccination_modal">
      <h4 class="text-center text-danger">Mark External Vaccination for {{ personDetails.first_name }}
        {{ personDetails.middle_name }}
        {{ personDetails.last_name }}</h4>
      <p>This will remove the vaccinee from the defaulters list if they completed their
        vaccination outside the country.</p>
      <div v-if="personDetails.vaccinations.length > 0" class="mt-2">
        <!-- todo: allow selection of disease to filter vaccinations by disease -->
        <ul v-for="(vaccinationSchedule, index) in personDetails.vaccinations[0].scheduled_doses"
            :key="index" class="list-group mt-2">
          <div class="">
            <li class="list-group-item d-flex flex-row justify-content-between">
              Dose {{ vaccinationSchedule.dose_number }}
              <span
                  v-if="vaccinationSchedule.date_administered !== null">Administered on {{ new Date(vaccinationSchedule.date_administered).toLocaleDateString() }}</span>
              <span
                  v-if="vaccinationSchedule.date_administered === null">Scheduled Date {{ new Date(vaccinationSchedule.date_scheduled).toLocaleDateString() }}</span>
              <p v-else>Administered </p>
              <div v-if="vaccinationSchedule.date_administered === null">

                <button v-if="submitting === false" class="btn btn-success" @click="markAsExternalVaccination(vaccinationSchedule.id)">
                  Mark External
                  <i class="feather icon-check"></i>
                </button>
                <div v-else>
                  <SubmittingLoader/>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <div v-else>
        <h4 class="text-danger">No Vaccinations Yet</h4>
      </div>
      <template v-slot:modal_footer>

      </template>
    </default_modal>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">
              {{ personDetails.first_name }} {{ personDetails.middle_name }}
              {{ personDetails.last_name }}
              -
              <span class="text-muted h4">{{ personDetails.occupation.name }}
                          <span
                              v-if="personDetails.occupation.name === 'Other'">({{ personDetails.occupation_detail }})</span>
                        </span>
            </h3>
          </div>
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="row mb-3">
          <div class="col-md-3">
            <h5 v-for="(identification, index) in personDetails.identification" v-bind:key="index"
                class="mb-0">
              <i class="feather icon-hash"></i>
              <strong>
                {{ $func.titleCase(identification.id_type) }}:
              </strong> {{ identification.id_number }}
            </h5>
          </div>
          <div class="col-md-2">
            <h6>
              <i class="feather icon-clock"></i>
              {{ $func.calculateAge(personDetails.date_of_birth) }} Years
            </h6>
          </div>
          <div class="col-md-2">
            <h5 v-if="personDetails.gender === 'M'" class="mb-0">
              <i class="fa fa-male"></i>
              Male
            </h5>
            <h5 v-else-if="personDetails.gender === 'F'">
              <i class="fa fa-female"></i>
              Female
            </h5>
            <h5 v-else>
              <i class="fa fa-intersex"></i>
              Intersex
            </h5>
          </div>
          <div class="col-md-2">
            <h6>
              <i class="fa fa-globe"></i>
              {{ getNationalityCountry(personDetails.nationality) }}
            </h6>
          </div>
          <div class="col-md-2">
            <h5 v-if="personDetails.religion !== null">
              <i class="feather icon-book-open"></i>
              {{ $func.capitalize(personDetails.religion) }}
            </h5>
          </div>
          <div class="col-md-3">
            <h5>
              <i class="feather icon-map-pin"></i>
              {{ personDetails.location.name }}
            </h5>
          </div>
          <div v-if=" personDetails.phone !== ''" class="col-md-3">
            <h5>
              <i class="feather icon-phone"></i>
              {{ $func.maskString(personDetails.phone) }}
            </h5>
          </div>
          <div v-if=" personDetails.email !== ''" class="col-md-5">
            <h5>
              <i class="feather icon-inbox"></i>
              {{ personDetails.email }}
            </h5>
          </div>
        </div>
        <div v-if="personDetails.next_of_kin.length !== 0" class="row mb-4">
          <div v-for="(kin, index) in personDetails.next_of_kin" v-bind:key="index" class="col-md-10">
            <h4>Next of Kin Details</h4>
            <div class="row">
              <div class="col-md-3">
                <h5>
                  <i class="feather icon-user"></i>
                  {{ kin.name }}
                </h5>
              </div>
              <div v-if="kin.phone" class="col-md-3">
                <h5>
                  <i class="feather icon-phone"></i>
                  {{ $func.maskString(kin.phone) }}
                </h5>
              </div>
              <div v-if="kin.email" class="col-md-3">
                <h5>
                  <i class="feather icon-inbox"></i>
                  {{ kin.email }}
                </h5>
              </div>
              <div v-if="kin.relationship" class="col-md-3">
                <h5>
                  <i class="feather icon-inbox"></i>
                  {{ kin.relationship }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div v-if="personDetails.occupation.name === 'Health Worker'" class="row">
          <div class="col-md-10">
            <h4>Health Worker Details</h4>
            <div class="row">
              <div class="col-md-4">
                <h5 v-if="personDetails.occupation_detail !== null">
                  <i class="feather icon-info mr-2"></i>
                  <strong>Health Worker Category:</strong>
                  {{
                    (personDetails.occupation_detail !== null) ? $func.titleCase(personDetails.occupation_detail) : ''
                  }}
                </h5>
              </div>
              <div class="col-md-3">
                <h5>
                  <i class="feather icon-phone mr-2"></i>
                  <strong>Personal Number:</strong>
                  {{ (personDetails.extra_fields !== null) ? personDetails.extra_fields.hw_phone : '' }}
                </h5>
              </div>
              <div class="col-md-3">
                <h5>
                  <i class="feather icon-hash"></i>
                  <strong>Practicing Number:</strong>
                  {{
                    (personDetails.extra_fields !== null) ? personDetails.extra_fields.hw_practicing_no : ''
                  }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div v-if="personDetails.occupation.name === 'Teacher'" class="row">
          <div class="col-md-10">
            <h4>Teacher Details</h4>
            <div class="row">
              <div class="col-md-4">
                <h5>
                  <i class="feather icon-phone mr-2"></i>
                  <strong>Teacher Category:</strong>
                  {{
                    (personDetails.occupation_detail !== null) ? $func.titleCase(personDetails.occupation_detail) : ''
                  }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <button v-if="canEdit()" class="btn btn-primary mr-2" @click="getSelectedPerson(personDetails.id)">
          Edit Vaccinee
          <i class="feather icon-edit"></i>
        </button>
      </div>
      <div class="card-footer row d-flex flex-row justify-content-between">
        <div v-if="$func.userHasRole('Support')" class="col-sm d-flex flex-row justify-content-between ">
          <div>Portal support: Use vaccinee code <strong>{{ personDetails.id }}</strong> to link account</div>
          <button class="btn btn-danger pull-right" @click="deactivateVaccineeModal">
            Deactivate Vaccinee
            <i class="feather icon-trash-2"></i>
          </button>
        </div>
        <div v-if="$func.userHasRoles(['Support', 'Vaccinator'])">
          <button class="col-sm btn btn-primary pull-right" @click="markExternalVaccinationModal">
            Mark External Vaccination
            <i class="feather icon-check"></i>
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {mapGetters} from "vuex";
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";

export default {
  name: "PersonDetails",
  data() {
    return {
      submitting: false
    }
  },
  components: {SubmittingLoader, default_modal},
  computed: {
    ...mapGetters(['personDetails', 'allNationalities'])
  },
  methods:{
    deactivateVaccineeModal() {
      $('#deactivate_vaccinee_modal').modal('show')
    },
    markExternalVaccinationModal() {
      $('#mark_external_vaccination_modal').modal('show')
    },
    markAsExternalVaccination(vaccinationScheduleId) {
      this.submitting = true
      this.$store.dispatch('markExternalVaccination', vaccinationScheduleId).then(()=>{
        this.$store.dispatch('setSuccessMessages',[{'details': 'Marked Successfully'}])
        this.$store.dispatch('setPersonDetails', {})
        this.submitting = false
        $('#mark_external_vaccination_modal').modal('hide')
      }).catch(err=>{
        this.$store.dispatch('setError', err)
        this.submitting = false
      })
    },
    deactivateVaccinee() {
      this.submitting = true
      let invalid_identifications = this.personDetails.identification.map(item => {
        item.id_number = item.id_number + '-INVALID'

        return item
      })

      let valuesToUpdate = {
        identification: invalid_identifications,
        person_id: this.personDetails.id,
        active: false
      }

      this.$store.dispatch('updatePerson', valuesToUpdate).then(resp => {
        this.$store.dispatch('setPersonDetails', {})
        if (resp) {
          this.is_loading = false;
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setPersonDetails', {})
        this.is_loading = false;
        this.$store.dispatch('set_error', err.response.data)
      })

      this.$store.dispatch('setPersonDetails', {})
      $('#deactivate_vaccinee_modal').modal('hide')
      this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinee Deactivated successfully"}])
      this.submitting = false

    },
    getSelectedPerson(person_id) {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        this.$router.push({name: "update_person"});
      })
    },
    canEdit() {
      // If person not vaccinated
      if (this.personDetails.vaccinations && this.personDetails.vaccinations.length === 0) {
        return true
      } else {
        return this.$func.userHasRoles(['Trainer of Trainers(TOT)','Support', 'HRIO'])
      }
    },
    viewMoreDetails(){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchPersonToUpdate', this.personDetails.id).then(resp => {
        this.$emit('toggleMoreDetails', true)
      })
    },
    getNationalityCountry(countryCode) {
      return this.allNationalities.filter(nationality => {
        return nationality.value === countryCode
      })[0].name
    },
  }
}
</script>

<style scoped>

</style>