<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card m-b-30">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-5">
              <span class="action-icon badge badge-primary-inverse mr-0"><i class="fa fa-stethoscope"></i></span>
            </div>
            <div class="col-7 text-right">
              <h2 class="card-title font-14">Total Vaccinations</h2>
              <div v-if="isLoading" class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
              <h2 v-else class="mb-0">{{ allVaccinations | withCommas }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3" v-for="(group, index) in jabsPerGender" v-bind:key="index">
      <div v-if="!isLoading" class="card m-b-30">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-3">
              <span class="action-icon badge badge-primary-inverse mr-0">
                <i v-if="group.gender === 'M'" class="fa fa-male"></i>
                <i v-else-if="group.gender === 'F'" class="fa fa-female"></i>
                <i v-else-if="group.gender === 'O'" class="fa fa-intersex"></i>
                <i v-else-if="group.gender === 'T'" class="fa fa-intersex"></i>
              </span>
            </div>
            <div class="col-9 text-right">
              <h2 class="card-title font-14">Vaccinations by Gender:
                <strong v-if="group.gender === 'M'" class="text-uppercase">MALE</strong>
                <strong v-else-if="group.gender === 'F'" class="text-uppercase">FEMALE</strong>
                <strong v-else-if="group.gender === 'O'" class="text-uppercase">INTERSEX</strong>
                <strong v-else-if="group.gender === 'T'" class="text-uppercase">TRANS</strong>
              </h2>
              <h2 class="mb-0">{{ group.people | withCommas }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatCards",
  props: {
    allVaccinations: Number,
    jabsPerGender: Array,
    isLoading: Boolean
  }
}
</script>

<style scoped>

</style>