<template>
  <div>
    <div >
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Administer Vaccine"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <success_messages></success_messages>
        <search_person class="mb-3"></search_person>
        <search_results
            v-if="Object.keys(getSearchResults).length !== 0 && getSearchResults.length !==0"></search_results>
        <div v-if="Object.keys(personDetails).length !== 0" class="card">
          <div class="card card-body">
            <dosage_timeline :expired_batch="false"></dosage_timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import dosage_timeline from "@/components/persons/dosage_timeline";
import search_person from "@/components/persons/search_person";
import {mapActions, mapGetters} from "vuex";
import search_results from "@/components/support/search_results";

export default {
  name: "index",
  components: {search_results, Breadcrumbs, success_messages, search_person, dosage_timeline},
  computed: {
    ...mapGetters(['personDetails', 'getSearchResults', 'getAllowedComponents']),
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions(['setPersonDetails'])
  },
  mounted() {
    this.setPersonDetails({})
  }
}
</script>

<style scoped>

</style>