import SystemUsers from "../../views/users";
import DeactivatedUsers from "../../views/users/DeactivatedUsers";
import CreateUser from "../../views/users/create";
import UpdateUser from "../../views/users/update";
import ManageSystemUsers from "../../views/support/manage_system_users";
import UserActions from "../../views/users/actions";

const user_management_routes=[
    {
        path: '/users',
        name: 'users',
        verboseName: 'Manage Users',
        parentName: 'System Users',
        isSingleRoute:true,
        component: SystemUsers,
        icon:'<i class="feather icon-users"></i>',
        children: [
            {
                path: 'deactivated',
                name: 'deactivated_users',
                verboseName: 'Deactivated Users',
                component: DeactivatedUsers
            },
            {
                path: 'new_user',
                name: 'new_user',
                component: CreateUser,
            },
            {
                path: 'update_user/',
                name: 'update_user',
                component: UpdateUser,
            },
            {
                path: 'manage_users',
                name: 'manage_users',
                component: ManageSystemUsers
            },
            {
                path: 'actions',
                name: 'user_actions',
                component: UserActions
            }
        ]
    },
]
export default user_management_routes