import api from "@/api";

const state ={
  search_results: [],
  notifications: [],
  vaccination_message: '',
  facility_search_result: {}
}
const getters ={
  getSearchResults(state){
    return state.search_results
  },
  getNotifications(state){
    return state.notifications
  },
  getVaccinationMessage(state){
    return state.vaccination_message
  },
}

const actions ={
  advancedSearch({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/person/" + payload).then(resp => {
        commit("setSearchResults", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  sendNotification({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/notifications/', method: "POST", data: {
          notification_type: payload.notification_type,
          notification_message: payload.notification_message,
          priority: payload.priority,
          start_sending_at: payload.start_sending_at,
          recipient: payload.recipient,
        }
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  searchFacilityByCode({commit}, payload){
    return new Promise((resolve, reject)=>{
      api.get(`/find-and-create-facility/?kmfl_code=${payload}`).then(resp=>{
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err.response.data)
        reject(err.response)
      })
    })
  },
  insertFacilityByCode({commit}, payload){
    return new Promise((resolve, reject)=>{

      api.post('/find-and-create-facility/', payload).then(resp=>{
        resolve(resp.data)
      }).catch(err=>{
        console.log("resp", err.response)
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  resendNotification({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/notifications/' + payload.id + '/', method: "PATCH", data: {
          notification_message: payload.notification_message,
          start_sending_at: payload.start_sending_at,
          sent: payload.sent,
        }
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  customVaccinationMessage({commit}, message){
    commit('setVaccinationMessage', message)
  },
  clearSearchResults({commit}) {
    commit('setSearchResults', [])
  },
  updateVaccinationDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      let id = payload.id
      delete payload.id

      api({
        url: '/person-vaccination-schedule/' + id + '/', method: "PATCH", data: payload
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
}
const mutations ={
  setSearchResults:(state, search_results) => (state.search_results = search_results),
  setNotifications:(state, notifications) => (state.notifications = notifications),
  setVaccinationMessage:(state, vaccination_message) => (state.vaccination_message = vaccination_message),
}

export default {
  state,
  getters,
  actions,
  mutations,
}