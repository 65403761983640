<template>
  <div class="card mw-100">
    <div class="card-header">
      <h4>
        Vaccinations By Gender
      </h4>
    </div>
    <div class="card-body">
      <div v-if="is_loading" class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
        <span class="sr-only">Loading...</span>
      </div>
      <div>
        <canvas id="jabs-by-gender"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'GenderPieChart',
  props: {
    jabs: [Object, Array],
    is_loading: Boolean
  },
  data(){
    return {
      loaded_periodic_chart: true
    }
  },
  methods: {
    plotChart(){
      const labels = this.jabs.map(item => {
        if (item.gender === 'M'){
          return 'Male';
        } else if (item.gender === 'F') {
          return 'Female';
        } else if(item.gender === 'O') {
          return 'Intersex'
        } else {
          return 'Transgender'
        }
      })

      const data = this.jabs.map(item => {
          return item.people;
      })

      const ctx = document.getElementById('jabs-by-gender');
      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [{
            label: 'Jabs By Gender',
            data: data,
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 453, 86)',
              'rgb(255, 120, 86)'
            ],
            hoverOffset: 4
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    }
  },
  mounted() {
    this.plotChart()
  }
}
</script>
