<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="All pending vaccinations"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="userHasRole('Trainer of Trainers(TOT)') || userHasRole('Vaccinator') || userHasRole('Clerk')">
        <pending_vaccinations_report></pending_vaccinations_report>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import pending_vaccinations_report from "@/components/reports/pending_vaccinations_report";
import {mapActions} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "PendingVaccinations",
  components: {AccessDeniedCard, Breadcrumbs, pending_vaccinations_report},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>