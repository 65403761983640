<template lang="">
  <div>
    
  </div>
</template>
<script>
export default {
  name:"AssignGadgets"
}
</script>
<style lang="">
  
</style>