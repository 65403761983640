<template >
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Gadget Management"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="userHasRole('Stock Manager')">
        <h4 class="my-4">
          Register Gadgets
        </h4>

        <register_gadgets></register_gadgets>
        <success_messages></success_messages>
        <h4 class="my-4">
          Manage Gadgets
        </h4>
        <gadgets_list></gadgets_list>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import gadgets_list from "@/components/gadgets/gadgets_list";
import register_gadgets from "@/components/gadgets/register_gadgets";
import success_messages from "@/components/layouts/includes/success_messages";
import {mapActions} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "Gadgets",
  components: {AccessDeniedCard, success_messages, gadgets_list, Breadcrumbs, register_gadgets},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        console.log("info", tkres)
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>