<template>
  <div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-3">
            <h4 class="card-title">{{getPendingVaccinations.count}} Pending Vaccinations</h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive m-b-30">
          <table class="table">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Identification</th>
              <th scope="col">Date of Birth</th>
              <th scope="col">Gender</th>
              <th scope="col">Contact</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(person, index) in getPendingVaccinations.results" v-bind:key="index">
              <td>{{ titleCase(person.first_name) }} {{ titleCase(person.middle_name) }}
                {{ titleCase(person.last_name) }}
              </td>
              <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ titleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
              </td>
              <td>
                {{ new Date(person.date_of_birth).toDateString() }} ({{ calculateAge(person.date_of_birth) }} Years)
              </td>
              <td>
              <span v-if="person.gender === 'M'" class="mb-0">
                Male
              </span>
                <span v-else>
                Female
              </span>
              </td>
              <td>
                {{ $func.maskString(person.phone) }}
              </td>
              <td>
                <button class="btn btn-primary" @click="openVaccinationPage(person.id)">
                  Vaccinate
                  <i class="feather icon-arrow-right"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <ul class="pagination">
            <li v-if="getPendingVaccinations.previous !== null" @click="previousPage()" class="page-item"><a class="page-link" href="#">Previous</a></li>
            <li v-if="getPendingVaccinations.next !== null" @click="nextPage()" class="page-item"><a class="page-link" href="#">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import router from "@/router";
import $ from "jquery";

export default {
  name: "vaccinations_today_report",
  data() {
    return {
      person: '',
      search_value: '',
      day: this.formatDate(new Date())
    }
  },
  methods: {
    openVaccinationPage(id){
      this.$store.dispatch('setPersonDetails', {})
      return this.$store.dispatch('fetchPersonDetails', "/person/" + id).then(
          router.push("vaccination")
      )
    },
    retrieveAllRegistrations() {
      return this.$store.dispatch('fetchPendingVaccinations', '?limit=10&offset=0')
    },
    titleCase(snake_case) {
      if(snake_case){
        return this.capitalize(snake_case.replace(/_/g, " "));
      }else{
        return snake_case
      }

    },
    capitalize(str) {
      return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
    },
    calculateAge(birthday) { // birthday is a date
      let parsed_birthday = new Date(birthday)
      var ageDifMs = Date.now() - parsed_birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    getSelectedPerson(person_id) {
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        router.push("update-vaccinee", resp.id);
      })
    },
    openDetailsModal(person) {
      $('#personModal').modal('show')
      this.person = person
    },
    getRegistrationsForDay(){
      return this.$store.dispatch('fetchPendingVaccinations', '?limit=10&offset=0&date='+ String(this.day) )
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    // function you can use:
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      return this.$store.dispatch('fetchPendingVaccinations', '?'+ this.getParams(this.getPendingVaccinations.next) + '&date=' + this.day)
    },
    previousPage(){
      return this.$store.dispatch('fetchPendingVaccinations', '?'+ this.getParams(this.getPendingVaccinations.previous) + '&date=' + this.day)
    }
  },
  computed: {
    ...mapGetters(['getPendingVaccinations']),
  },
  mounted() {
    this.retrieveAllRegistrations()
  }
}
</script>

<style scoped>

</style>