<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs :pageName="'Record adverse events for  ' + personDetails.first_name +' '+ personDetails.middle_name  +' '+ personDetails.last_name"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages> </success_messages>
      <adverse_effects_form></adverse_effects_form>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import adverse_effects_form from "@/components/adverse_effects/adverse_effects_form";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import {mapGetters} from "vuex";
import {mapActions} from "vuex/dist/vuex.mjs";
export default {
  name: "RecordAdverseEffect",
  components: {adverse_effects_form, Breadcrumbs, success_messages},
  methods: {
    ...mapActions(['fetchPersonDetails']),
    retrievePersonDetails() {
      return this.$store.dispatch('fetchPersonDetails', "/person/"+this.$route.params.id)
    },
  },
  computed: {
    ...mapGetters([
      'personDetails'
    ])
  },
  mounted() {
    // Get persons when the component has been mounted
    this.retrievePersonDetails();
  }
}
</script>

<style scoped>

</style>