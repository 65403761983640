var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"leftbar"},[_c('div',{staticClass:"sidebar"},[_vm._m(0),_c('div',{staticClass:"navigationbar active"},_vm._l((_vm.fetched_routes),function(fetched_route,index){return _c('ul',{key:index,staticClass:"vertical-menu in"},[(fetched_route.children.length > 0 || (fetched_route.isSingleRoute && _vm.allowed_routes.indexOf(fetched_route.name) !== -1))?_c('li',[_c('a',{class:[_vm.currently_active_route===fetched_route.name ? ' active': ' '],attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleActiveRoute(fetched_route.name)}}},[(!fetched_route.icon)?_c('i',{staticClass:"dripicons-graph-bar"}):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(fetched_route.icon)}})]),_c('span',{class:['text-capitalize pl-3']},[_vm._v(_vm._s(fetched_route.parentName ? fetched_route.parentName : (fetched_route.name).replace(/([A-Z])/g, " $1"))+" ")]),(fetched_route.children)?_c('i',{staticClass:"feather icon-chevron-right pull-right"}):_vm._e()]),_c('ul',{class:['vertical-submenu ', (_vm.currently_active_route === (fetched_route.name).toString() || _vm.currently_active_parent === fetched_route.name)  ? 'menu-open d-block' : ' d-none']},[(_vm.allowed_routes.indexOf(fetched_route.name) !== -1)?_c('li',[_c('router-link',{attrs:{"to":{name:fetched_route.name}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive ? 'active': ''],attrs:{"href":href},on:{"click":navigate}},[_c('span',{class:['text-capitalize ']},[_vm._v(_vm._s(fetched_route.verboseName ? fetched_route.verboseName : (route.name).replace(/([A-Z])/g, " $1"))+" ")])])]}}],null,true)})],1):_vm._e(),_vm._l((fetched_route.children),function(child_route,index){return _c('li',{key:index},[(child_route.showInLeftBar !== false)?_c('router-link',{attrs:{"to":{name:child_route.name}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:['text-capitalize ', isActive ? ' active ' : ' '],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(child_route.verboseName? child_route.verboseName: _vm.makeRouteReadable(child_route.name)))])]}}],null,true)}):_vm._e()],1)})],2)]):_vm._e()])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logobar"},[_c('a',{staticClass:"logo logo-large",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"logo","src":require("@/assets/images/kenya_emblem.png")}}),_c('span',{staticClass:"font-15 pt-3 text-white",staticStyle:{"font-weight":"600"}},[_vm._v(" Chanjo KE ")])]),_c('a',{staticClass:"logo logo-small",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"logo","src":require("@/assets/images/logo.png")}})])])}]

export { render, staticRenderFns }