<template>
  <div>
    <div v-if="inventory !== null" :id="'receiveModal'" aria-hidden="true" aria-labelledby="receiveModalLabel" class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">

        <div  class="modal-content">
          <div class="modal-header">
            <h5 id="receiveModalLabel" class="modal-title">Receive
              <span v-if="is_additional_stock"> Additional Stock For </span>
              Batch No
              <span v-if="ObjectHasKeys(inventory)">{{ inventory.batch.batch_number }}</span>
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="is_additional_stock && ObjectHasKeys(inventory)">
              <h4 class="text-danger"> Please Confirm that you are adding more stock to batch {{inventory.batch.batch_number}}</h4>
            </div>
            <submitting-loader v-if="is_loading"></submitting-loader>
            <div  v-else-if="!is_loading && is_additional_stock">
              <!-- done loading and inventory is not set-->
              <div class="form-group">
                <label class="control-label">
                  Select batch
                  <span class="text-danger">*</span>
                </label>
                <select v-model="selected_inventory_batch_number" class="form-control" id="vvm_status" required>
                  <option v-for="(batch, index) in batches_list" :value="batch.batch.batch_number" :key="index">
                    {{batch.batch.batch_number}}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="!is_loading && ObjectHasKeys(inventory)">
              <div class="form-group">
                <label for="batch_number_verifier" class="control-label">
                  Please Verify Batch Number
                </label>
                <input v-model="batch_number_verifier" id="batch_number_verifier" class="form-control" type="text" required>
              </div>
              <div v-if="batch_number_verifier === inventory.batch.batch_number">
                <div class="form-group">
                  <label for="s13_delivery_note" class="control-label">
                    S13
                  </label>
                  <input v-model="s13_delivery_note" id="s13_delivery_note" class="form-control" type="text" required>
                </div>
                <div class="form-group">
                  <label for="receiverID">
                    ID of Person Receiving
                    <span class="text-danger">*</span>
                  </label>
                  <input id="receiverID" v-model="receiver_id" class="form-control" required type="text"
                         :placeholder="!(inventory.collector === undefined) ? inventory.collector.id : ''">
                </div>
                <div class="form-group">
                  <label for="receiverName">
                    Name of Person Receiving
                    <span class="text-danger">*</span>
                  </label>
                  <input id="receiverName" v-model="receiver_name" class="form-control" required type="text"
                         :placeholder="!(inventory.collector === undefined) ? inventory.collector.name : ''" >
                </div>
                <div class="form-group">
                  <label for="receiverPhone">
                    Phone Number of Person Receiving
                    <span class="text-danger">*</span>
                  </label>
                  <input id="receiverPhone" v-model="receiver_phone" class="form-control" required type="text"
                         :placeholder="!(inventory.collector === undefined) ? inventory.collector.phone : ''">
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="batchAmount">
                      Doses to Receive {{quantity}}  <span class="text-danger" v-if="quantity<1"> Receiving {{quantity}} Doses ? Please Confirm </span>
                      <span class="text-danger">*</span>
                    </label>
                    <input id="batchAmount" v-model="quantity"
                           :class="'form-control ' + checkIfErrorExists('quantity')" required type="number">
                    <div class="invalid-feedback">
                            <span v-for="(error, index) in getError.quantity" v-bind:key="index">
                              {{ error }}
                            </span>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="condition">
                      Condition On Arrival
                      <span class="text-danger">*</span>
                    </label>
                    <select id="condition" v-model="condition" class="form-control" required>
                      <option value="good" selected>Accepted</option>
                      <option value="damaged">Rejected(Damaged)</option>
                      <option value="expired">Rejected(Expired)</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label for="vvm_status" class="control-label">
                    VVM Status
                    <span class="text-danger">*</span>
                  </label>
                  <select v-model="vvm_status" class="form-control" id="vvm_status" required>
                    <option value="N/A">N/A</option>
                    <option value="1">Stage 1</option>
                    <option value="2">Stage 2</option>
                    <option value="3">Stage 3</option>
                    <option value="4">Stage 4</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="remark">
                    Remark
                    <span class="text-danger">*</span>
                  </label>
                  <textarea id="remark" v-model="remark" :class="'form-control ' + checkIfErrorExists('remark')" rows="3"></textarea>
                  <div class="invalid-feedback">
                          <span v-for="(error, index) in getError.remark" v-bind:key="index">
                            {{ error }}
                          </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <a v-if="submitting === true" class="btn btn-white pr-2">
              Loading
              <div class="spinner-grow  text-success" role="status" style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status"  style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status"  style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
            </a>
            <div v-else>
              <button v-if="is_additional_stock && confirmation_stage==='confirmation-stage'" class="btn btn-primary pr-2" @click="receiveAdditionalStock(inventory)">
                Receive
                <i class="feather icon-arrow-right"></i>
              </button>
              <button v-if="is_additional_stock && confirmation_stage==='input-stage'" class="btn btn-primary pr-2" @click="enterConfirmationStage">
                Confirm
                <i class="feather icon-check"></i>
              </button>
              <button v-if="!is_additional_stock" class="btn btn-primary pr-2" @click="receiveInventory(inventory)">
                Receive
                <i class="feather icon-arrow-right"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <h5 class="card-title">Receive Batches in {{ centerInfo.name }} <span class="text-defaultColor"></span></h5>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                     class="form-control" placeholder="Search by batch number" type="search">
              <div class="input-group-append">
                <button id="button-addon2" class="btn" type="submit">
                  <i class="feather icon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(centerInfo.level ==='regional' || centerInfo.level === 'national') && $func.userHasRoles(['Stock Manager'])" class="row">
          <button class="btn btn-primary ml-3"  @click="openReceiveAdditionalStockModal()">
            Receive Additional Stock <i class="feather icon-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Batch Number</th>
              <th scope="col">Vaccine</th>
              <th scope="col">Dispatched By</th>
              <th scope="col">Number of Doses</th>
              <th scope="col">Status</th>
              <th scope="col">Condition</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>

            <tbody v-for="(inventory, index) in filteredInventoryToReceive" v-bind:key="index" class="border-top-0">
            <tr v-if="inventory.quantity_received === inventory.quantity">

            </tr>
            <tr v-else>
              <td>{{ inventory.batch.batch_number }}</td>
              <td>{{inventory.batch.vaccine.name}}</td>
              <td>{{ inventory.center.name }}</td>
              <td>{{ inventory.quantity - inventory.quantity_received }}</td>
              <td>{{ inventory.action }}</td>
              <td>{{ inventory.condition }}</td>
              <td v-if="inventory.action !== 'received'">
                <button @click="setInventoryOnModal(inventory)" class="btn btn-primary"  type="button">
                  Receive
                  <i class="feather icon-arrow-right"></i>
                </button>

              </td>
              <td v-else>No Actions</td>
            </tr>
            </tbody>
            <tbody v-if="allInventoryToReceive.length === 0">
            <tr>
              <td colspan="7" class="text-center h5 text-danger">
                No Inventory available to receive
              </td>
            </tr>
            </tbody>
          </table>
          <ul class="pagination">
            <a href="#" v-if="allInventoryToReceive.previous !== null" class="page-item" @click="gotoPage(allInventoryToReceive.previous)"><a
                class="page-link">Previous</a></a>
            <a href="#" v-if="allInventoryToReceive.next !== null" class="page-item" @click="gotoPage(allInventoryToReceive.next)"><a class="page-link">Next</a>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import $ from 'jquery'
import SubmittingLoader from "../layouts/includes/SubmittingLoader";

export default {
  name: "receive_batches_list",
  components: {SubmittingLoader},
  data: function () {
    return {
      action: "Received",
      receivedBy: 'none',
      batch_number: '324',
      quantity: '',
      date_of_manufacture: '',
      date_of_expiry: '',
      sourceCenter: null,
      vaccine_id: null,
      remark: '',
      condition: '',
      received_from: null,
      to_center_id: 0,
      is_returned: false,
      is_loading: false,
      receiver_id: '',
      receiver_name: '',
      receiver_phone: '',
      search_value: '',
      inventory: null,
      s13_delivery_note: '',
      s11_delivery_reports: '',
      vvm_status: '',
      submitting: false,
      is_additional_stock: false,
      batches_list:[],
      selected_inventory_batch_number: null,
      confirmation_stage: 'input-stage',
      batch_number_verifier: ''
    }
  },
  methods: {
    ...mapActions(['fetchInventoryToReceive']),
    validateDateOfManufacture() {
      new Date(this.date_of_manufacture) > new Date()
    },
    ObjectHasKeys(object){
      return Object.keys(object).length > 0
    },
    openReceiveAdditionalStockModal(){
      this.inventory = {}
      this.is_additional_stock = true
      $('#receiveModal').modal('show')

      this.center_id = this.centerInfo.id
      this.$store.dispatch('fetchVaccinationBatches', {in_stock_batches_only: false}).then(resp=>{
        this.is_loading = false;
        this.batches_list = resp
      }).catch(()=>{
        this.is_loading = false;
        this.$store.dispatch('setError', {'detail':'Could not retrieve batches'})
      })
    },
    receiveAdditionalStock(){
      let reqValue = {
        s_13: this.s13_delivery_note,
        s_11: this.s11_delivery_reports,
        vvm_status: this.vvm_status,
        batch_id: this.inventory.batch.id,
        quantity: this.quantity,
        is_mint:true,
        action: 'received',
        condition: this.condition,
        remark: this.remark,
        is_returned: false,
        center_id: this.centerInfo.id,
        received_from: null,
        to_center_id: this.centerInfo.id,
        collector: {
          id : this.receiver_id,
          name: this.receiver_name,
          phone: this.receiver_phone
        },
      }
      this.$store.dispatch('createInventory', reqValue).then(resp => {
        this.submitting = false;
        this.confirmation_stage = 'input-stage'
        this.retrieveInventoryToReceive()
        if (resp) {
          $('.modal').modal('hide')
          this.is_loading = false;
          this.receiver_id = ''
          this.receiver_name = ''
          this.receiver_phone = ''
          this.quantity = ''
          this.remark = ''
        }
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Received Batch"}])
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.submitting = false;
        this.is_loading = false;
        this.confirmation_stage = 'input-stage'
      })
    },
    retrieveInventoryToReceive() {
      this.$store.dispatch('fetchCenterInfo').then(resp=>{
        this.$store.dispatch('fetchInventoryToReceive', '?action=dispatched&to_center=' + resp.id)
      })
    },
    getParams(str) {
      return str.split('?')[1];
    },
    gotoPage(url){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchInventoryToReceive', '?' + this.getParams(url)).then(resp=>{
        // eslint-disable-next-line no-unused-vars
      }).catch(err=>{
        this.$store.dispatch('setError', {'detail':'Error Fetching Inventory'})
      })
    },
    setInventoryOnModal(inventory){
      $('#receiveModal').modal('show')
      this.inventory = inventory;
      this.receiver_name = (inventory.collector !== null ) ? inventory.collector.name : ''
      this.receiver_id = (inventory.collector !== null ) ? inventory.collector.id : ''
      this.receiver_phone = (inventory.collector !== null ) ? inventory.collector.phone : ''
      this.quantity = inventory.quantity - inventory.quantity_received
    },
    receiveInventory(inventory) {
      this.submitting = true;
      let reqValue = {
        s_13: this.s13_delivery_note,
        s_11: this.s11_delivery_reports,
        vvm_status: this.vvm_status,
        batch_id: inventory.batch.id,
        quantity: this.quantity,
        action: 'received',
        condition: this.condition,
        remark: this.remark,
        is_returned: false,
        center_id: inventory.to_center.id,
        received_from: inventory.id,
        to_center_id: inventory.to_center.id,
        collector: {
          id : this.receiver_id,
          name: this.receiver_name,
          phone: this.receiver_phone
        },
      }
      this.$store.dispatch('createInventory', reqValue).then(resp => {
        this.submitting = false;
        this.confirmation_stage = 'input-stage'
        this.retrieveInventoryToReceive()
        if (resp) {
          $('.modal').modal('hide')
          this.is_loading = false;
          this.receiver_id = ''
          this.receiver_name = ''
          this.receiver_phone = ''
          this.quantity = ''
          this.remark = ''
        }
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Received Batch"}])
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.submitting = false;
        this.is_loading = false;
        this.confirmation_stage = 'input-stage'
      })
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    enterConfirmationStage(){
      this.confirmation_stage = 'confirmation-stage'
    }
  },
  watch:{
    selected_inventory_batch_number(){
      this.inventory = this.batches_list.filter(batch_inventory=>{
        return batch_inventory.batch.batch_number === this.selected_inventory_batch_number
      })[0]
      this.receiver_id = ''
      this.receiver_name = ''
      this.receiver_phone = ''
      this.quantity = ''
      this.remark = ''
    }
  },
  computed: {
    ...mapGetters([
      'getError', 'allInventoryToReceive', 'centerInfo', 'allCenterInventory',
    ]),
    filteredInventoryToReceive() {
      if (this.search_value === '') {
        return this.allInventoryToReceive.results
      } else {
        return this.allInventoryToReceive.results.filter(inventory => {
          return inventory.batch.batch_number.startsWith(this.search_value)
        })
      }
    }
  },
  mounted() {
    this.retrieveInventoryToReceive()
  }
}
</script>