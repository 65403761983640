import api from "@/api";

const state ={
  gender_stats :[],
  occupation_stats :[],
  brief_stats_jabs :{},
  daily_brief_stats: [],
  inventory_stats : [],
  county_stats_csv: [],
  all_registrations: {},
  all_jabs: {},
  pending_vaccinations: [],
  adverse_effects_csv: [],
  jabs_over_58: [],
  invalid_ages: [],
  invalid_jabs: [],
  inactive_persons: [],
  monthly_stats: [],
  age_group_stats: [],
  vaccine_utilization_stats:[],
 dropout_stats: [],
  dropouts_csv: [],
  dropouts_by_age: {}
}
const getters ={
  getBriefStats(state){
    return state.brief_stats
  },
  getDailyBriefStats(state){
    return state.brief_stats
  },
  getInventoryStats(state){
    return state.inventory_stats
  },
  getCountyStatsCSV(state){
    return state.county_stats_csv
  },
  getAllRegistrations(state){
    return state.all_registrations
  },
  getAllJabs(state){
    return state.all_jabs
  },
  getPendingVaccinations(state){
    return state.pending_vaccinations
  },
  getAdverseEffectsCSV(state){
    return state.adverse_effects_csv
  },
  getJabsOver58(state){
    return state.jabs_over_58
  },
  getInvalidAges(state){
    return state.invalid_ages
  },
  getInvalidJabs(state){
    return state.invalid_jabs
  },
  getInactivePersons(state){
    return state.inactive_persons
  },
  getMonthlyStats(state){
    return state.monthly_stats
  } ,
  getVaccineUtilizationStats(state){
    return state.vaccine_utilization_stats
  },
  getDropoutStats(state){
    return state.dropout_stats
  },
  getDropoutsByAge(state){
    return state.dropouts_by_age
  }
}
const actions = {
  fetchOccupationGroupedJabs({commit},payload){
    return new Promise((resolve, reject) => {
      api.get('/occupation-grouped-jabs/'+ payload).then(resp =>{
        // commit('setBriefStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchGenderGroupedJabs({commit},payload){
    return new Promise((resolve, reject) => {
      api.get('/gender-grouped-jabs/'+ payload).then(resp =>{
        // commit('setBriefStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchBriefStatsForCounty({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/brief-stats' + payload).then(resp =>{
        // commit('setBriefStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchInventoryStats({commit}){
    return new Promise((resolve, reject) => {
      api.get('/inventories-detailed/').then(resp =>{
        commit('addInventoryStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  checkIfErrorExists(input_id) {
    if (Object.keys(this.getError).length === 0) {
      return '';
    } else if (typeof this.getError[input_id] !== "undefined") {
      return 'is-invalid'
    }
  },
  fetchDetailedInventoryStats({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/inventories-detailed' + payload).then(resp =>{
        commit('addInventoryStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchCountyStatsCSV({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/jabs-per-county' + payload).then(resp =>{
        commit('addCountyStatsCSV', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
    fetchWardStatsCSV({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/jabs-per-ward' + payload).then(resp =>{
        // commit('addCountyStatsCSV', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchFacilityStatsCSV({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/jabs-per-facility' + payload).then(resp =>{
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchWeeklyStatsCSV({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/jabs-per-week' + payload).then(resp =>{
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchBoosterStatsCSV({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/annotated-booster-jabs-csv/' + payload).then(resp =>{
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchDefaultersReport(){

  },
  fetchAllRegistrations({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/my-registrations' + payload).then(resp =>{
        commit('setAllRegistrations', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchAllJabs({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/my-jabs' + payload).then(resp =>{
        commit('setAllJabs', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchPendingVaccinations({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/pending-vaccinations' + payload).then(resp =>{
        commit('setPendingVaccinations', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  // eslint-disable-next-line no-unused-vars
  clearStats({commit}){
    // commit('setBriefStats', [])
  },
  fetchAdverseCSV({commit}){
    return new Promise((resolve, reject) => {
      api.get('/adverse-effects-csv/').then(resp =>{
        commit('setAdverseEffectsCSV', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchJabsOver58({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/jabs-over-58/' + payload).then(resp => {
        commit('setJabsOver58', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchInvalidAges({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/invalid-aged-persons/' + payload).then(resp => {
        commit('setInvalidAges', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchInvalidJabs({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/invalid-jabs/' + payload).then(resp => {
        commit('setInvalidJabs', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchInactivePersons({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get('/inactive-person/' + payload).then(resp => {
        commit('setInactivePersons', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchMonthlyStats({commit}, payload){
   return new Promise((resolve,reject) => {
     api.get('/jabs-per-month/' + payload).then(resp => {
       commit('setMonthlyStats', resp.data)
       resolve(resp.data)
     }).catch(err => {
       commit('set_error', {})
       reject(err)
     })
   })
  },
  fetchAgeGroupStats({commit}, payload){
    return new Promise((resolve,reject) => {
      api.get('/age-grouped-csv/' + payload).then(resp => {
        commit('setAgeGroupStats', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchVaccineUtilizationStats({commit},payload){
    return new Promise((resolve, reject)=>{
      console.log("here")
      api.get('/vaccine-utilization-rate/'+ payload).then(resp=>{
        commit('setVaccineUtilizationStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchDropoutStats({commit},payload){
    return new Promise((resolve, reject)=>{
      api.get('/dropouts-stats/' + payload ).then(resp=>{
        commit('setDropoutStats', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    })
  },
  fetchDropoutsCSV({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/dropouts-csv/' + payload).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchDropoutsByAge({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/age-grouped-dropouts-stats/' + payload).then(resp => {
        commit('setDropoutsByAge', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchPeriodicJabs({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/periodic-jabs/' + payload).then(resp => {
        commit('setDropoutStats', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    });
  },
  clearDropoutStats({commit}){
    commit('setDropoutStats', [])
  },
}

const mutations ={
  setGenderStats:(state, data) => (state.gender_stats = data),
  setOccupationStats:(state, data) => (state.occupation_stats = data),
  setBriefStatsJabs:(state, brief_stats_jabs) => (state.brief_stats_jabs = brief_stats_jabs),
  addCountyStatsCSV:(state, county_stats_csv) => (state.county_stats_csv = county_stats_csv),
  addInventoryStats:(state, inventory_stats) => (state.inventory_stats = inventory_stats),
  setAllRegistrations:(state, all_registrations) => (state.all_registrations = all_registrations),
  setAllJabs:(state, all_jabs) => (state.all_jabs = all_jabs),
  setPendingVaccinations:(state, pending_vaccinations) => (state.pending_vaccinations = pending_vaccinations),
  setAdverseEffectsCSV:(state, adverse_effects_csv) => (state.adverse_effects_csv = adverse_effects_csv),
  setJabsOver58:(state, jabs_over_58) => (state.jabs_over_58 = jabs_over_58),
  setInvalidAges:(state, invalid_ages) => (state.invalid_ages = invalid_ages),
  setInvalidJabs:(state, invalid_jabs) => (state.invalid_jabs = invalid_jabs),
  setInactivePersons:(state, inactive_persons) => (state.inactive_persons = inactive_persons),
  setMonthlyStats:(state, monthly_stats) => (state.monthly_stats = monthly_stats),
  setAgeGroupStats:(state, age_group_stats) => (state.age_group_stats = age_group_stats),
  setDropoutStats:(state, stats) => (state.dropout_stats = stats),
  setDropoutsByAge:(state, stats) => (state.dropouts_by_age = stats),
  setVaccineUtilizationStats:(state, vaccine_utilization_stats) => (state.vaccine_utilization_stats = vaccine_utilization_stats)
}

export default {
  state,
  getters,
  actions,
  mutations,
}