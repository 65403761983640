<template>
  <div class="row">
    <default_modal aria_label="Deactivate User" modal_id="deactivate_user_modal">
      <h4 v-if="$route.name==='users'" class="text-center text-danger">Confirm Deactivation of {{ user.username }}</h4>
      <h4 v-if="$route.name==='deactivated_users'" class="text-center text-success">Confirm Activation of {{ user.username }}</h4>
      <template v-slot:modal_footer>
        <button v-if="!submitting && $route.name==='deactivated_users'" class="btn btn-success" @click="activateUser">
          <span >Activate</span>
          <i class="feather icon-check"></i>
        </button>
        <button v-if="!submitting && $route.name==='users'" class="btn btn-danger" @click="deactivateUser">
          <span v-if="$route.name==='users'">Dectivate</span>
          <span v-else>Deactivate</span>
          <i class="feather icon-trash-2"></i>
        </button>
        <div v-if="submitting">
          <SubmittingLoader/>
        </div>
      </template>
    </default_modal>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title"> System Users</h5>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive-sm">
            <table class="table" style="width:100%">
              <thead class="thead-dark">
              <tr>
                <th>Name</th>
                <th>User Name</th>
                <th>Roles</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody v-if="allSystemUsers.length !== 0">
              <tr v-for="(user, index) in allSystemUsers" v-bind:key="index">
                <td v-if="user.first_name === '' && user.last_name === ''">Not Provided</td>
                <td v-else>{{ user.first_name }} {{ user.last_name }}</td>
                <td class="w-25">{{ user.username }}</td>
                <td class="w-25">
                  <span v-if="user.groups.length === 0">
                    Not Assigned
                  </span>
                  <span v-else>
                    <span v-for="(group, index) in user.groups" v-bind:key="index">
                      <span class="badge badge-secondary mr-1">{{ group.name }}  </span>
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="user.phone_number === null">
                    Not Provided
                  </span>
                  {{ $func.maskString(user.phone_number) }}
                </td>
                <td v-if="$route.name === 'users'">
                  <button class="btn btn-primary mb-1" @click="getSelectedUser(user.id, 'update_user')">
                    Update User
                    <i class="feather icon-edit"></i>
                  </button>
                  <button class="btn btn-primary mx-2 mb-1" @click="getSelectedUser(user.id, 'user_actions')">
                    View Actions
                    <i class="feather icon-activity"></i>
                  </button>
                  <button @click="deactivateUserModal(user)" class="btn btn-danger mb-1">
                    Deactivate User
                    <i class="feather icon-trash-2"></i>
                  </button>
                </td>
                <td v-if="$route.name === 'deactivated_users'">
                  <button class="btn btn-success mx-2 mb-1" @click="deactivateUserModal(user)">
                    Activate User
                    <i class="feather icon-check"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import $ from "jquery";

export default {
  name: "search_user_results",
  components: {default_modal, SubmittingLoader},
  data() {
    return {
      user: {},
      submitting: false
    }
  },
  methods: {
    ...mapActions(['fetchSystemUsers', 'fetchCenterInfo', 'fetchCounties']),
    getSelectedUser(user_id, path) {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchSingleSystemUser', user_id).then(resp => {
        router.push({name: path});
      })
    },
    deactivateUserModal(user) {
      this.user = user
      $('#deactivate_user_modal').modal('show')
    },
    deactivateUser() {
      this.submitting = true

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('DeactivateSystemUser', this.user.id).then(resp => {
        // eslint-disable-next-line no-unused-vars
        this.$store.dispatch('setSuccessMessages', [{"message": "User Deactivated successfully"}])
      }).catch(err => {
        this.is_loading = false;
        this.$store.dispatch('set_error', err.response.data)
      })

      this.$store.dispatch('clearSystemUsers', {})
      $('#deactivate_user_modal').modal('hide')
      this.submitting = false
    },
    activateUser() {
      this.submitting = true

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('ActivateSystemUser', this.user.id).then(resp => {
        // eslint-disable-next-line no-unused-vars
        this.$store.dispatch('setSuccessMessages', [{"message": "User Activated successfully"}])
      }).catch(err => {
        this.is_loading = false;
        this.$store.dispatch('set_error', err.response.status === 500 ? 'Unknown Error Occured' : err.response.data)
      })

      this.$store.dispatch('clearSystemUsers', {})
      $('#deactivate_user_modal').modal('hide')
      this.submitting = false
    }
  },
  computed: {
    ...mapGetters([
      'allSystemUsers',
    ]),
  },
  mounted() {
  }
}
</script>
