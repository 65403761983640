<template>
  <div>
    <div >
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Vaccinees Management"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <success_messages></success_messages>
        <add-facility></add-facility>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import {mapActions, mapGetters} from "vuex";
import AddFacility from "../../components/support/add_facility";

export default {
  name: "ManageFacilities",
  components: {AddFacility, Breadcrumbs, success_messages},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    }
  },
  computed: {
    ...mapGetters(['personDetails', 'getSearchResults']),
  },
  mounted() {
    this.clearErrors()
  }
}
</script>