<template>
  <div>
    <default_modal v-if="Object.keys(current_batch_being_reversed).length !== 0" modal_id="reverse_distribution_modal"
                   aria_label="Reverse Distribution"
                   click_text="Confirm" modal_header="Reverse Distribution Transaction" :click_action="confirmReversal">
      <div v-if="(!user_confirmed_reversal)">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="remark">Batch Number {{ current_batch_being_reversed.id }}</label>
            <input class="form-control" type="text" id="batch_number" :value="current_batch_being_reversed.batch_number"
                   disabled>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="text-capitalize">date of manufacture </label>
            <input class="form-control" type="text" :value="current_batch_being_reversed.batch.date_of_manufacture"
                   disabled>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="text-capitalize">date of expiry</label>
            <input class="form-control" type="text" :value="current_batch_being_reversed.batch.date_of_expiry" disabled>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="text-capitalize">starting quantity</label>
            <input class="form-control" type="text" :value="current_batch_being_reversed.batch.starting_quantity"
                   disabled>
          </div>
        </div>
        <p class="text-capitalize">condition: good </p>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="remark">Remark</label>
            <textarea class="form-control" name="remark" id="remark" cols="30" v-model="remark" rows="10"></textarea>
          </div>
        </div>
      </div>
      <div v-else class="row content-center">
        <div class="col-md-12 justify-content-center text-center mb-3">
          <i class="feather icon-alert-circle text-warning" style="font-size: 64px"></i>
        </div>
        <div class="col-md-12 text-center">
          <h5 class="text-warning"> Are you sure you want to reverse this transaction ?</h5>
        </div>
      </div>
      <template v-slot:modal_footer>
        <a v-if="user_confirmed_reversal" class="btn btn-default pr-2"
           @click="confirmReversal(false)">
          <i class="feather icon-arrow-left"></i>
          <span>
            No Take me back
          </span>
        </a>
        <a v-if="(!user_confirmed_reversal)" class="btn btn-success pr-2"
           @click="confirmReversal()">
          <span>
            confirm
          </span>
          <i class="feather icon-arrow-right"></i>
        </a>
        <a v-else class="btn btn-success pr-2"
           @click="reverseTransaction()">
          <span>
            I am Sure
          </span>
          <i class="feather icon-arrow-right"></i>
        </a>

      </template>
    </default_modal>
    <div class="px-2">
      <div class="form-row mb-3 bg-white px-3 py-3 rounded">
        <div class="form-group col-md-2">
          <label for="vaccine_filter" class="control-label">
            Filter By Vaccine
          </label>
          <select v-model="selected_vaccine_filter" class="form-control" id="vaccine_filter" required>
            <option v-for="(vaccine, index) in all_vaccines" v-bind:key="index" :value="vaccine.id">{{ vaccine.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="stock_condition" class="control-label">
            Filter By Condition
          </label>
          <select v-model="selected_condition_filter" class="form-control" id="stock_condition" required>
            <option v-for="(condition, index) in all_conditions" v-bind:key="index" :value="condition">
              {{ condition }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="start_date">Date</label>
            <div class="input-group">
              <input v-model='start_date_filter' id="start_date" :class="'datepicker-here form-control ' + checkIfErrorExists('start_date_filter')"
                     aria-describedby="basic-addon2"
                     placeholder="dd/mm/yyyy" type="date">
              <InputErrorComponent input_name="start_date_filter"/>
            </div>
          </div>
        </div>
<!--        <div class="col-md-2">-->
<!--          <div class="form-group">-->
<!--            <label for="end_date">End Date</label>-->
<!--            <div class="input-group">-->
<!--              <input v-model='end_date_filter' id="end_date" :class="'datepicker-here form-control ' + checkIfErrorExists('end_date_filter')"-->
<!--                     aria-describedby="basic-addon2"-->
<!--                     placeholder="dd/mm/yyyy" type="date">-->
<!--            </div>-->
<!--            <InputErrorComponent input_name="end_date_filter"/>-->

<!--          </div>-->
<!--        </div>-->
        <div class="col-md-3 d-flex pb-3">
          <button type="button" @click="retrieveGroupedInventory" class="btn btn-primary align-self-end">
            Filter
            <i class="feather icon-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div v-if="is_loading" class="col-md-4">
            <p class="loader"></p>
            Loading...
          </div>
          <div class="col-md-4">
            <h5 class="card-title">Ledger for {{ centerInfo.name }} <span class="text-defaultColor"></span>
            </h5>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                     class="form-control" placeholder="Search by Center" type="search">
              <div class="input-group-append">
                <button id="button-addon2" class="btn" type="submit">
                  <i class="feather icon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!(is_loading)" class="card-body">
        <div v-if="allGroupedInventory.results.size ===0">
          <h4 class="text-center text-danger">
            You have not received or dispatched any stock
          </h4>
        </div>
        <div v-for="(value, index) in allGroupedInventory.results" v-bind:key="index">
          <div class="row pb-2 pt-2 border" style="background: #C4C4C4; border-radius: 3px">
            <div class="col-md-3">
              <h5>Batch No. {{ value[1][0].batch.batch_number }} -
                {{ value[1][0].batch.vaccine.name }} </h5>
            </div>
            <div v-if="arrayHasValues(value[1])" class="col-md-3">
              <h5 v-if="batch_list_inventory[index].length > 0">
                {{
                  numberWithCommas(batch_list_inventory[index][0].good_quantity - batch_list_inventory[index][0].used_quantity)
                }}
                in stock
                <span
                    v-if="(batch_list_inventory[index][0].good_quantity - batch_list_inventory[index][0].used_quantity) < 0"
                    class="text-danger">Overdrawn</span>
              </h5>

            </div>
          </div>
          <div class="row mt-3">
            <div v-for="(batch_value, index) in (value[1])  " :key="index" class="col-md-9 offset-3 mb-3 ">
              <div
                  :class="['p-2 b-r-4 ', ((batch_value.action === 'dispatched' && batch_value.center.id === centerInfo.id) ? 'bg-light-danger' : 'bg-light-success'),
                   (batch_value.condition !== 'good'? 'bg-danger text-white': '')]">
                <div class="row">
                  <div class="col-md-2">
                      <span v-if="batch_value.action === 'dispatched' && batch_value.center.id === centerInfo.id">
                        <i class="feather icon-arrow-up-right text-dark mr-2 ml-2 pt-1"></i>
                        <i class="feather icon-minus text-dark mr-2 ml-2 pt-1"></i>
                      </span>
                    <i v-else class="feather icon-arrow-down-left text-dark mr-2 ml-2 pt-1"></i>
                    <i v-if="batch_value.action === 'received' && batch_value.condition === 'good'"
                       class="feather icon-plus text-dark mr-2 ml-2 pt-1"></i>
                    <i v-if="batch_value.condition !== 'good'"
                       class="feather icon-alert-triangle text-dark mr-2 ml-2 pt-1"></i>
                  </div>
                  <div class="col-md-6">
                    <h6 v-if="batch_value.action === 'received'"
                        :class="(batch_value.condition !== 'good'? 'text-white': '')">

                      {{ numberWithCommas(batch_value.quantity) }} doses in batch {{ batch_value.batch.batch_number }}
                      ({{batch_value.batch.vaccine.name}})
                      <span
                          class="font-weight-bold text-capitalize">{{ batch_value.action }} </span><span
                        v-if="batch_value.center.id">at {{ batch_value.center.name }} in {{ batch_value.condition }} condition</span>
                    </h6>
                    <h6 v-else>{{ numberWithCommas(batch_value.quantity) }} doses in batch
                      {{ batch_value.batch.batch_number }} ({{batch_value.batch.vaccine.name}}) <span
                          class="font-weight-bold text-capitalize">{{ batch_value.action }} </span><span
                          v-if="batch_value.center.id">from {{ batch_value.center.name }}</span>
                      <span v-if="batch_value.to_center.id"> to {{
                          batch_value.to_center.name
                        }} {{ batch_value.condition }} condition</span></h6>
                    <span class="font-weight-bold"
                          v-if="batch_value.created_at">Performed at {{ new Date(batch_value.created_at).toLocaleDateString() }}</span>

                  </div>
                  <div class="col-md-3 text-right">
                    <h6 v-if="batch_value.is_reversed" class="text-warning font-weight-bold">Transaction Reversed</h6>
                    <button  v-if="!batch_value.quantity_received > 0 && !batch_value.is_reversed"
                        @click="openReverseModal(batch_value)"
                        class="btn btn-outline-dark pull-right">
                      Reverse
                    </button>
                    <span v-if="batch_value.quantity_received > 0">
                     {{ numberWithCommas(batch_value.quantity_received) }} Doses Already Received <span v-if="batch_value.to_center">
                        at {{ batch_value.to_center.name }}
                      </span>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ul class="pagination">
          <a href="#" v-if="allGroupedInventory.previous !== null" class="page-item" @click="gotoPage(allGroupedInventory.previous)"><a
              class="page-link">Previous</a></a>
          <a href="#" v-if="allGroupedInventory.next !== null" class="page-item" @click="gotoPage(allGroupedInventory.next)"><a class="page-link">Next</a>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import default_modal from "@/components/modal/default_modal";
import $ from "jquery";
import InputErrorComponent from "@/components/users/InputErrorComponent";

export default {
  name: "distribution_ledger_report",
  components: {InputErrorComponent, default_modal},
  data: function () {
    return {
      action: "Received",
      receivedBy: 'none',
      batch_number: '324',
      quantity: '',
      date_of_manufacture: '',
      date_of_expiry: '',
      sourceCenter: null,
      vaccine_id: null,
      remark: '',
      condition: '',
      received_from: null,
      to_center_id: 0,
      is_returned: false,
      is_loading: true,
      receiver_id: '',
      receiver_name: '',
      receiver_phone: '',
      search_value: '',
      inventory: null,
      s13_delivery_note: '',
      s11_delivery_reports: '',
      vvm_status: '',
      batch_list_inventory: [],
      user_confirmed_reversal: false,
      distribution_transaction_id: 0,
      current_batch_being_reversed: {},
      no_transactions: false,
      all_vaccines: [],
      selected_vaccine_filter: '',
      all_conditions: ["good", "expired", "damaged"],
      selected_condition_filter: '',
      start_date_filter:'',
      end_date_filter:'',
    }
  },
  methods: {
    ...mapActions(['fetchVaccines']),
    getAllVaccines() {
      this.$store.dispatch('fetchVaccines').then(resp => {
        this.all_vaccines = resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {"detail": "error fetching vaccine types"})
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    confirmReversal(condition) {
      if (condition === undefined) {
        this.user_confirmed_reversal = true
      } else {
        this.user_confirmed_reversal = false
      }
    },
    reverseTransaction() {
      this.$store.dispatch('editDistributionTransactions', {
        id: this.current_batch_being_reversed.id,
        is_reversed: true,
        remark: this.current_batch_being_reversed.remark + this.remark
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        this.user_confirmed_reversal = false
        this.$store.dispatch('setSuccessMessages', [{"message": "Transaction Reversed Successfully"}])
        this.retrieveGroupedInventory()
      }).catch(err => {
        this.$store.dispatch('setError', err)
      })
    },
    openReverseModal(distribution_transaction) {
      this.current_batch_being_reversed = distribution_transaction
      // this.amountToDispatch = amount
      $('#reverse_distribution_modal').modal('show')
    },
    retrieveGroupedInventory() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchLedgerDistributionTransactions', '?'+
      (this.selected_vaccine_filter !== '' ? '&vaccine='+this.selected_vaccine_filter : '')
          + (this.start_date_filter!=='' ? '&date='+this.start_date_filter : '')
          + (this.selected_condition_filter!=='' ? '&condition='+ this.selected_condition_filter :'')
      ).then(grouped_inventory => {
        this.is_loading = true;
        if (grouped_inventory.results.length === 0) {
          this.is_loading = false;
        } else {
          this.$store.dispatch('fetchCenterInventory', '')
              .then((my_center_inventory) => {
            // loop through all dispatched and received
            // eslint-disable-next-line no-unused-vars
            grouped_inventory.results.forEach(distribution_transaction => {
              this.batch_list_inventory.push(
                  my_center_inventory.results.filter(inventory_value => {
                    return inventory_value.batch.batch_number === distribution_transaction[0].batch_number
                  }));
            });
            this.is_loading = false;
          });
        }
      });
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    arrayHasValues(arr) {
      return arr.length > 0;
    },
    getParams(str) {
      return str.split('?')[1];
    },
    gotoPage(url) {
      this.is_loading = true;
      this.$store.dispatch('fetchLedgerDistributionTransactions', '?' + this.getParams(url)).then(grouped_inventory => {
        // this.setBatchesArray(grouped_inventory)

        if (grouped_inventory.results.length === 0) {
          this.is_loading = false;
        } else {

            // loop through all dispatched and received
            // eslint-disable-next-line no-unused-vars
            this.batch_list_inventory.length=0
            grouped_inventory.results.forEach(distribution_transaction => {
              this.batch_list_inventory.push(
                  this.allCenterInventory.results.filter(inventory_value => {
                    return inventory_value.batch.batch_number === distribution_transaction[0].batch_number
                  }));
              this.is_loading = false;
            });

        }
      });
    },
    getCenterById(centerId) {
      this.is_loading = true
      this.$store.dispatch('fetchSingleCenter', centerId).then(resp => {
        this.loaded_center_info = resp
        this.is_loading = false
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false
        this.$store.dispatch('setError', {"detail": "could not fetch center"})
      })
    },
    setBatchesArray(grouped_inventory){
      this.is_loading = true;
      if (grouped_inventory.size === 0) {
        this.is_loading = false;
        this.no_transactions = true
      } else {
        // loop through all dispatched and received
        // eslint-disable-next-line no-unused-vars
        grouped_inventory.results.forEach(distribution_transaction => {
          this.batch_list_inventory.push(
              this.allCenterInventory.filter(inventory_value => {
                return inventory_value.results.batch.batch_number === distribution_transaction[0].batch_number
              }));

        });
      }
      this.is_loading = false;
    }
  },
  computed: {
    ...mapGetters([
      'getError', 'allDispatchedInventory', 'centerInfo', 'allCenterInventory', 'allGroupedInventory',
    ]),

  },
  watch:{
    start_date_filter : function (){
      let new_start_date = new Date(this.start_date)
      let new_end_date = new Date(this.end_date)
      if (new_end_date<new_start_date){
        this.$store.dispatch('setError', {"start_date":"Start date cannot be after end date"})
      }
    },
    end_date_filter : function (){
      let new_start_date = new Date(this.start_date)
      let new_end_date = new Date(this.end_date)
      if (new_end_date<new_start_date){
        this.$store.dispatch('setError', {"end_date":"end date cannot be before start date"})
      }
    }
  },
  mounted() {
    this.retrieveGroupedInventory();
    this.getAllVaccines()
  }
}
</script>
<style>
.bg-light-success {
  background: #AEFEC4;
}

.white-text {
  color: white !important;
}

.bg-light-danger {
  background: #FED4AE;
}

</style>