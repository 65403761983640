import api from "@/api";

const state = {
  persons: [],
  person_vaccinations: [],
  personsInWaitlistToday: [],
  personsPendingVaccination: [],
  person_details: {},
  smsMessage: {},
  person_being_updated: {},
  registrations_csv: [],
  vaccinations_csv: [],
  defaulters: []
}
const getters = {
  allPersons(state) {
    return state.persons
  },
  personVaccinations(state){
    return state.person_vaccinations
  },
  personsInWaitlistToday(state) {
    return state.personsInWaitlistToday
  },
  personsPendingVaccination(state) {
    return state.personsPendingVaccination
  },
  personDetails(state) {
    return state.person_details
  },
  getPersonBeingUpdated(state) {
    return state.person_being_updated;
  },
  getRegistrationsCSV(state) {
    return state.registrations_csv
  },
  getVaccinationsCSV(state) {
    return state.vaccinations_csv
  },
  allDefaulters(state) {
    return state.defaulters
  }
}
const actions = {
  fetchPersons({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/person/").then(resp => {
        commit("setPerson", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchPersonsInWaitlist({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/my-waitlist" + payload).then(resp => {
        commit("setPersonsInWaitlistToday", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchPersonToUpdate({commit}, person_id) {
    return new Promise((resolve, reject) => {
      api.get("/person/" + person_id + '/').then(resp => {
        commit("setPersonBeingUpdated", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchPersonDetails({commit}, url) {
    return new Promise((resolve, reject,) => {
      api.get(url).then(resp => {
        commit("setPersonDetails", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchPersonVaccinations({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/person-vaccination/?person=" + payload).then(resp => {
        commit("setPersonVaccinations", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  searchPerson({commit}, payload) {
    return new Promise((resolve, reject,) => {
      api.get('/get-person/id/' + payload).then(resp => {
        if(Array.isArray(resp.data)){
          commit("setSearchResults", resp.data)
        }else{
          commit("setPersonDetails", resp.data)
        }
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    });

  },
  createPerson({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.post(
        '/person/', payload
      ).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        console.log("issue")
        commit('set_error', err.response.data)
        console.log(err)
        reject(err)
      })
    })
    // console.log(payload)
  },
  updatePerson({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/person/' + payload.person_id + '/', method: "PATCH", data: payload,
      }).catch(err => {
        if (err.response.status === 500) {
          commit('set_error', {"identification": [{"id_number": ["A vaccinee with the given identification already exists"]}]})
          reject(err)
        } else {
          commit('set_error', err.response.data)
          console.log("find_error", err)
          reject(err)
        }
      })

      api({
        url: '/person/' + payload.person_id + '/', method: "PATCH", data: {
          allergies_id: payload.allergies,
        }
      }).then(resp => {
        commit('setPersonDetails', resp.data)
        resolve(resp.data)
      })

    })
  },
  sendMessage({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.post("/notifications/", payload).then(resp => {
        commit('setSmsMessage', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  // eslint-disable-next-line no-unused-vars
  markExternalVaccination({commit}, payload) {
    return new Promise((resolve, reject)=>{
      api.patch(`/person-vaccination-schedule/${payload}/mark-external/`, {}).then(resp=>{
        resolve(resp.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
  administerVaccine({commit}, payload) {
    let params = payload.params;
    delete payload.params;
    

    return new Promise((resolve, reject) => {
      api({
        url: `/person-vaccination-schedule/${params}`, method: "POST", data: payload
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  updateVaccinationSchedule({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/person-vaccination-schedule/' + payload.schedule_id + '/', method: "PATCH", data: {
          person_id: payload.person_id,
          batch: payload.batch,
          dose: payload.dose,
          facility: payload.facility,
          date_administered: payload.date_administered,
          vaccinator: payload.vaccinator,
          temperature: payload.temperature
        }
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  deleteVaccinationSchedule({commit}, schedule_id){
    return new Promise((resolve, reject) => {
      api({
        url: '/person-vaccination-schedule/' + schedule_id + '/', method: "DELETE"
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  changeVaccinatingBatch({commit}, payload){
    return new Promise((resolve, reject) => {
      api({
        url: '/person-vaccination-schedule/' + payload.schedule_id + '/change-batch/', method: "PATCH", data: {
          batch: payload.batch,
          date_administered: payload.date_administered
        }
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  setPersonDetails({commit}, person) {
    commit('setPersonDetails', person)
  },
  clearPersonVaccination({commit}) {
    commit('setPersonVaccinations', [])
  },
  fetchRegistrationsCSV({commit}) {
    new Promise((resolve, reject) => {
      api.get('/registrations-csv/').then(resp => {
        commit('setRegistrationsCSV', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchVaccinationsCSV({commit}) {
    new Promise((resolve, reject) => {
      api.get('/jabs-csv/').then(resp => {
        commit('setVaccinationsCSV', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchPersonsPendingVaccination({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/my-waitlist/").then(resp => {
        commit("setPersonsPendingVaccination", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  activatePerson({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/inactive-person/' + payload.person_id + '/', method: "PATCH", data: payload,
      }).catch(err => {
        commit('set_error', err.response.data)
        console.log("find_error", err)
        reject(err)
      }).then(resp => {
        resolve(resp.data)
      })

    })
  },
  fetchDefaulters({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get("/dropouts/" + payload).then(resp => {
        commit("setDefaulters", resp.data)
        resolve (resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  deleteVaccinationObject({commit}, person_vaccination_id){
    return new Promise((resolve, reject) => {
      api({
        url: '/delete-person-vaccination/' + person_vaccination_id, method: "DELETE"
      }).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
}

const mutations = {
  setPerson: (state, persons) => (state.persons = persons),
  setPersonVaccinations: (state, data) => (state.person_vaccinations = data),
  setPersonsInWaitlistToday: (state, personsInWaitlistToday) => (state.personsInWaitlistToday = personsInWaitlistToday),
  setPersonsPendingVaccination: (state, personsPendingVaccination) => (state.personsPendingVaccination = personsPendingVaccination),
  setSmsMessage: (state, sms) => (state.smsMessage = sms),
  setPersonDetails: (state, person_details) => (state.person_details = person_details),
  setPersonBeingUpdated: (state, person) => (state.person_being_updated = person),
  setRegistrationsCSV: (state, registrations_csv) => (state.registrations_csv = registrations_csv),
  setVaccinationsCSV: (state, vaccinations_csv) => (state.vaccinations_csv = vaccinations_csv),
  setDefaulters: (state, defaulters) => (state.defaulters = defaulters),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
