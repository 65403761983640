<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title pl-3">
        {{getUserActions.count}} actions by user: {{getUserBeingUpdated.username}}
      </h5>
      <div class="row mt-2">
        <div class="form-group col-md-4 ">
          <label >Select Results Limit
          </label>
          <select class="form-control" name="response_limit" id="response_limit" v-model="limit">
            <option value="10" selected>10</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
        <div class="mt-4">
          <button type="text" @click="submitResultsLimit()" class="btn btn-primary mt-6">Submit</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <default_table v-if="getUserActions.count > 0" :nextLink="getUserActions.next" :previousLink="getUserActions.previous" @nextPage="nextPage()" @previousPage="previousPage()">
        <template #thead>
          <th v-for="(header, index) in headers" v-bind:key="index">
            {{ header }}
          </th>
        </template>
        <template #tbody>
          <tr v-for="(action, index) in getUserActions.results" v-bind:key="index">
            <td>{{index+offset+1}}</td>
            <td class="text-capitalize">{{ action.verb }}</td>
            <td>{{ new Date(action.timestamp).toLocaleDateString() }} at {{ new Date(action.timestamp).toLocaleTimeString() }},  Timezone : {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</td>
          </tr>
        </template>
      </default_table>
      <h4 v-else class="text-center text-danger">
        No actions perfomed yet
      </h4>
    </div>
  </div>
</template>

<script>
import default_table from "@/components/templates/DefaultTable";
import {mapGetters} from "vuex";

export default {
  name: "user_actions_list",
  components: {default_table},
  data() {
    return {
      headers: [
        '#',
        'Activity',
        'Time Performed',
      ],
      offset: 0,
      limit:10
    }
  },
  computed: {
    ...mapGetters(['getUserActions', 'getUserBeingUpdated'])
  },
  methods: {
    retrieveUserActions(payload){
      return this.$store.dispatch('fetchUserActions', payload)
    },
    // function you can use:
    getParams(str) {
      return '?' + str.split('?')[1];
    },
    nextPage(){
      let payload = {
        user_id: this.getUserBeingUpdated.id,
        params: this.getParams(this.getUserActions.next)
      }

      this.offset = parseInt(this.offset) + parseInt(this.limit)
      this.retrieveUserActions(payload)
    },
    previousPage(){
      let payload = {
        user_id: this.getUserBeingUpdated.id,
        params: this.getParams(this.getUserActions.previous)
      }

      this.offset = parseInt(this.offset) - parseInt(this.limit)

      this.retrieveUserActions(payload)
    },
    submitResultsLimit(){
      this.fetchUserActions()
    },
    fetchUserActions(){
      this.retrieveUserActions({
        user_id: this.getUserBeingUpdated.id,
        params: `?limit=${this.limit}&offset=0`
      })
    }
  },
  mounted() {
    this.fetchUserActions()
  }
}
</script>

<style scoped>

</style>
