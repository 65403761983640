<template>
  <div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">
              {{ personDetails.first_name }} 
              <!-- {{ personDetails.middle_name }}
              {{ personDetails.last_name }} -->
            </h3>
          </div>
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="row mb-3">
          <!-- <div class="col-md-3">
            <h5 v-for="(identification, index) in personDetails.identification" v-bind:key="index"
                class="mb-0">
              <i class="feather icon-hash"></i>
              <strong>
                {{ $func.titleCase(identification.id_type) }}:
              </strong> {{ identification.id_number }}
            </h5>
          </div> -->
          <div class="col-md-2">
            <h6>
              <i class="feather icon-clock"></i>
              {{ $func.calculateAge(personDetails.date_of_birth) }} Years
            </h6>
          </div>
          <div class="col-md-2">
            <h5 v-if="personDetails.gender === 'M'" class="mb-0">
              <i class="fa fa-male"></i>
              Male
            </h5>
            <h5 v-else-if="personDetails.gender === 'F'">
              <i class="fa fa-female"></i>
              Female
            </h5>
            <h5 v-else>
              <i class="fa fa-intersex"></i>
              Intersex
            </h5>
          </div>
          <!-- <div v-if=" personDetails.phone !== ''" class="col-md-5">
            <h5>
              <i class="feather icon-phone"></i>
              {{ personDetails.phone }}
            </h5>
          </div> -->
        </div>
        <button v-if="canEdit()" class="btn btn-primary mr-2" @click="viewMoreDetails(personDetails.id)">
          View more Details
          <i class="feather icon-eye"></i>
        </button>
        <button v-if="canEdit()" class="btn btn-primary mr-2" @click="getSelectedPerson(personDetails.id)">
          Edit Vaccinee
          <i class="feather icon-edit"></i>
        </button>
      </div>
      <!--    <div class="card-footer row d-flex flex-row justify-content-between">-->
      <!--      <div v-if="userHasRole('Support')" class="col-sm d-flex flex-row justify-content-between ">-->
      <!--        <div>Portal support: Use vaccinee code <strong>{{ personDetails.id }}</strong> to link account</div>-->
      <!--        <button class="btn btn-danger pull-right" @click="deactivateVaccineeModal">-->
      <!--          Deactivate Vaccinee-->
      <!--          <i class="feather icon-trash-2"></i>-->
      <!--        </button>-->
      <!--      </div>-->
      <!--      <div v-if="userHasRole('Support')">-->
      <!--        <button class="col-sm btn btn-primary pull-right" @click="markExternalVaccinationModal">-->
      <!--          Mark External Vaccination-->
      <!--          <i class="feather icon-check"></i>-->
      <!--        </button>-->
      <!--      </div>-->

      <!--    </div>-->

    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {mapGetters} from "vuex";

export default {
  name: "PersonDetailsMinimal",
  data() {
    return {
      submitting: false
    }
  },
  computed: {
    ...mapGetters(['personDetails'])
  },
  methods:{
    deactivateVaccineeModal() {
      $('#deactivate_vaccinee_modal').modal('show')
    },
    markExternalVaccinationModal() {
      $('#mark_external_vaccination_modal').modal('show')
    },
    markAsExternalVaccination(vaccinationScheduleId) {
      this.submitting = true
      this.$store.dispatch('markExternalVaccination', vaccinationScheduleId).then(()=>{
        this.$store.dispatch('setSuccessMessages',[{'details': 'Marked Successfully'}])
        this.$store.dispatch('setPersonDetails', {})
        this.submitting = false
        $('#mark_external_vaccination_modal').modal('hide')
      }).catch(err=>{
        this.$store.dispatch('setError', err)
        this.submitting = false
      })
    },
    deactivateVaccinee() {
      this.submitting = true
      let invalid_identifications = this.personDetails.identification.map(item => {
        item.id_number = item.id_number + '-INVALID'

        return item
      })

      let valuesToUpdate = {
        identification: invalid_identifications,
        person_id: this.personDetails.id,
        active: false
      }

      this.$store.dispatch('updatePerson', valuesToUpdate).then(resp => {
        this.$store.dispatch('setPersonDetails', {})
        if (resp) {
          this.is_loading = false;
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setPersonDetails', {})
        this.is_loading = false;
        this.$store.dispatch('set_error', err.response.data)
      })

      this.$store.dispatch('setPersonDetails', {})
      $('#deactivate_vaccinee_modal').modal('hide')
      this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinee Deactivated successfully"}])
      this.submitting = false

    },
    getSelectedPerson(person_id) {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        this.$router.push({name: "update_person"});
      })
    },
    canEdit() {
      // If person not vaccinated
      if (this.personDetails.vaccinations && this.personDetails.vaccinations.length === 0) {
        return true
      } else {
        return this.$func.userHasRoles(['Trainer of Trainers(TOT)','Support', 'HRIO'])
      }
    },
    viewMoreDetails(){
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchPersonDetails', "/person/" + this.personDetails.id).then(resp => {
        this.$emit('toggleMoreDetails', true)
      })

    }
  }
}
</script>

<style scoped>

</style>