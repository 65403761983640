<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs
        pageName="Administer External Vaccine"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <search_person class="mb-3"></search_person>
      <external_vaccination_form></external_vaccination_form>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import {mapGetters} from "vuex";
import {mapActions} from "vuex/dist/vuex.mjs";
import search_person from "@/components/persons/search_person";
import external_vaccination_form from "@/components/vaccine_administration/external_vaccination_form";

export default {
  name: "create_external_vaccination",
  components: {external_vaccination_form, search_person, Breadcrumbs, success_messages},
  methods: {
    ...mapActions(['fetchPersonDetails']),
    clearPerson(){
      this.personDetails = {}
    }
  },
  computed: {
    ...mapGetters([
      'personDetails'
    ])
  },
  mounted() {
    this.clearPerson()
  }
}
</script>

<style scoped>

</style>