<template>
  <div class="card mw-100">
    <div class="card-header">
      <h4>
        Vaccinations By Age
      </h4>
    </div>
    <div class="card-body">
      <div v-if="loading" class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
        <span class="sr-only">Loading...</span>
      </div>
      <div>
        <canvas id="jabs-by-age"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'AgePieChart',
  props: {
    payload: String,
    triggerUpdate: Boolean
  },
  data() {
    return {
      loaded_periodic_chart: true,
      loading: true
    }
  },
  watch: {
    triggerUpdate: function () {
      return this.fetchOccupationJabs()
    }
  },
  methods: {
    plotChart(jabs) {
      const labels = jabs.map(item => {
        if(item.Age === '-15')
          return 'Under 15'
        else
          return item.Age
      })

      const data = jabs.map(item => {
        return item.Vaccinations;
      })

      const ctx = document.getElementById('jabs-by-age');
      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [{
            label: 'Jabs By Age',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132)',
              'rgba(255, 159, 64)',
              'rgba(255, 205, 86)',
              'rgba(75, 192, 192)',
              'rgba(54, 162, 235)',
              'rgba(153, 102, 255)',
              'rgba(201, 203, 207)'
            ],
            hoverOffset: 4
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        }
      });

    },
    fetchAgeStats(){
      this.$store.dispatch('fetchAgeGroupStats', this.payload).then(resp => {
        const ageStats = this.$func.convertCSVtoObject(resp).filter( stat => {
          return stat.Age !== 'Total' &&
              stat.Age !== ''
        })

        console.log('test', ageStats);
        this.loading = false
        this.plotChart(ageStats)

      })
    }
  },
  mounted() {
    this.fetchAgeStats()
  }
}
</script>
