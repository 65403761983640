<template>
  <div class="card">
    <div class="card-header">
      <h4>
        Search Results
      </h4>
    </div>
    <div class="card-body">
      <table class="table">
        <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Identification</th>
          <th scope="col">Date of Birth</th>
          <th scope="col">Gender</th>
          <th scope="col">Contact</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(person, index) in getSearchResults" v-bind:key="index">
          <td>{{ titleCase(person.first_name) }} {{ person.middle_name }}
            {{ titleCase(person.last_name) }}
          </td>
          <td>
        <span v-for="(identification, index) in person.identification" v-bind:key="index"
              class="mb-0">
          <strong>
            {{ titleCase(identification.id_type) }}:
          </strong> {{ identification.id_number }}
        </span>
          </td>
          <td>
            {{ new Date(person.date_of_birth).toDateString() }} ({{ calculateAge(person.date_of_birth) }} Years)
          </td>
          <td>
        <span v-if="person.gender === 'M'" class="mb-0">
          Male
        </span>
            <span v-else>
          Female
        </span>
          </td>
          <td>
            {{ $func.maskString(person.phone) }}
          </td>
          <td>
            <button class="btn btn-primary mr-2"
                    @click="setPerson(person.id)">
              Manage Vaccinee
              <i class="feather icon-arrow-right"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
// import router from "@/router";

export default {
  name: "search_results",
  computed:{
    ...mapGetters(['getSearchResults']),
  },
  methods:{
    setPerson(person_id) {
      this.$store.dispatch('clearSearchResults', {})
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchPersonDetails', "/person/" + person_id + "?minimal=true").then(resp => {
      })
    },
    titleCase(snake_case) {
      return this.capitalize(snake_case.replace(/_/g, " "));
    },
    capitalize(str) {
      return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
    },
    calculateAge(birthday) { // birthday is a date
      let parsed_birthday = new Date(birthday)
      var ageDifMs = Date.now() - parsed_birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

  }
}
</script>

<style scoped>

</style>