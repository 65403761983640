<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Update My Profile"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <success_messages></success_messages>
    <div class="contentbar">
      <user_form :selected_user_id="getUserBeingUpdated.id"></user_form>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import user_form from "@/components/users/user_form";
import success_messages from "@/components/layouts/includes/success_messages";
import {mapGetters} from "vuex";

export default {
  name: "updateUser",
  components: {success_messages, user_form, Breadcrumbs},
  computed: {
    ...mapGetters(['getUserBeingUpdated'])
  },
  methods: {
    setProfileDetails() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres = JSON.parse(atob(tk.split('.')[1]));
        this.$store.dispatch('fetchSingleSystemUser', tkres.user_id)

        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
  },
  mounted() {
    this.setProfileDetails()
  }
}
</script>
<style lang="">

</style>