import Gadgets from "../../views/gadgets";
import AssignGadgets from "../../views/assign_gadgets";

const gadget_routes=[{
    path: '/gadgets',
    name: 'gadgets',
    component: Gadgets,
    verboseName: 'Gadgets',
    icon:'<i class="feather icon-tablet"></i>',
    children: [
        {
            path: '/assign_gadgets',
            name: 'assign_gadgets',
            component: AssignGadgets,
            showInLeftBar: false
        },
    ]
},]

export default gadget_routes