<template >
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs :pageName="personDetails.first_name"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
    <div class="row mb-3">
      <person_details></person_details>
    </div>
      <div class="row">
        <adverse_effects_list></adverse_effects_list>
      </div>
      <dosage_timeline :expired_batch="false"></dosage_timeline>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import dosage_timeline from "@/components/persons/dosage_timeline";
import adverse_effects_list from "@/components/persons/adverse_effects_list";
import person_details from "@/components/persons/person_details";

export default {
  name: "PersonDetails",
  components: {Breadcrumbs, dosage_timeline, adverse_effects_list, person_details},
  methods: {

	},
	computed: {

    ...mapGetters([
      'personDetails'
    ])
	},
  mounted() {
		// Get persons when the component has been mounted
  }
}
</script>
