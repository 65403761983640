<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ getInactivePersons.count }} Deactivated Vaccinees
      </h5>
    </div>
    <div class="card-body">
      <default_table @previousPage="previousPage()" @nextPage="nextPage()">
        <template #thead>
          <th v-for="(header, index) in headers" v-bind:key="index">
            {{header}}
          </th>
        </template>
        <template #tbody>
          <tr v-for="(person, index) in getInactivePersons.results" v-bind:key="index">
            <td>{{ toTitleCase(person.first_name) }} {{ toTitleCase(person.middle_name) }}
              {{ toTitleCase(person.last_name) }}
            </td>
            <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ toTitleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
            </td>
            <td>
              {{ person.phone }}
            </td>
            <td>
              {{person.occupation.name}}
            </td>
            <td>
              <button @click="activatePerson(person.id)" class="btn btn-primary">
                Activate
                <i class="feather icon-arrow-right"></i>
              </button>
            </td>
          </tr>
        </template>
      </default_table>
    </div>
  </div>
</template>

<script>
import default_table from "@/components/templates/DefaultTable";
import {mapGetters} from "vuex";
import {toTitleCase, calculateAge} from "@/utils/methods";

export default {
  name: "deactivated_vaccinees_list",
  components: {default_table},
  data() {
    return {
      headers: [
        'Name ',
        'Identification',
        'Contact',
        'Occupation',
        'Actions'
      ],
    }
  },
  computed : {
    ...mapGetters(['getInactivePersons']),
  },
  methods: {
    toTitleCase(str) {
      return toTitleCase(str)
    },
    calculateAge(birthday) {
      return calculateAge(birthday)
    },
    retrieveInactivePersons(){
      return this.$store.dispatch('fetchInactivePersons', '?limit=10&offset=0')
    },
    // function you can use:
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      return this.$store.dispatch('fetchInactivePersons', '?'+ this.getParams(this.getInactivePersons.next))
    },
    previousPage(){
      return this.$store.dispatch('fetchInactivePersons', '?'+ this.getParams(this.getInactivePersons.previous))
    },
    activatePerson(person_id){
      let valuesToUpdate = {
        person_id: person_id,
        active: true
      }

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('activatePerson', valuesToUpdate).then(resp => {
        this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinee Activated successfully"}])
        this.retrieveInactivePersons()
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('set_error', err.response.data)
      })
    }
  },
  mounted(){
    this.retrieveInactivePersons()
  }
}
</script>

<style scoped>

</style>