<template>
  <div>
    <DispatchModal :inventory="batch"/>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <h5 class="card-title"> {{ allAvailableInventory.count }} Batches in <span class="text-defaultColor">{{
                center_info_with_batch_report.name
              }}</span></h5>
          </div>
        </div>
      </div>
      <div class="card-body">
        <DefaultTable
            :previous-link="allAvailableInventory.previous"
            :next-link="allAvailableInventory.next"
            @nextPage="nextPage()"
            @previousPage="previousPage()"
            :isLoading="loading">
          <template #thead>
            <th v-for="(header, index) in headers" v-bind:key="index">
              {{ header }}
            </th>
          </template>
          <template #tbody v-if="allAvailableInventory.results && allAvailableInventory.results.length">
            <tr v-for="(batch, index) in allAvailableInventory.results" v-bind:key="index">
              <td>{{index+offset+1}}</td>
              <td>{{ batch.batch.batch_number }}</td>
              <td>{{ batch.batch.vaccine.name }} - {{ batch.batch.vaccine.disease.name }}</td>
              <td>
                <span :class="getExpiredColorCode(batch.batch.date_of_expiry)">
                  {{ new Date(batch.batch.date_of_expiry).toDateString() }}
                </span>
              </td>
              <td class="flex justify-content-between">
                <span> {{ $func.numberWithCommas(batch.good_quantity - batch.used_quantity) }}</span>
                <span v-if="(batch.good_quantity - batch.used_quantity)<=0" class="text-danger"> Out Of Stock</span>
              </td>
              <td>
                <button class="btn btn-primary ml-1 text-sm" @click="openDispatchModal(batch)">
                  Dispatch
                  <i class="feather icon-arrow-right"></i>
                </button>
              </td>
            </tr>
          </template>
        </DefaultTable>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
import DefaultTable from "@/components/templates/DefaultTable";
import DispatchModal from "@/components/inventory/DispatchModal";

export default {
  name: "batches_master_inventory",
  components: {DispatchModal, DefaultTable},
  data() {
    return {
      search_value: '',
      batch: {},
      date: new Date().toISOString().substr(0, 10),
      batch_inventory_id: 0,
      loading: false,
      show_submit: true,
      center_info_with_batch_report: {},
      un_reported_days_array: [],
      headers: [
        '#',
        'Batch Number',
        'Vaccine',
        'Date Of Expiry',
        'Available Doses',
        'Dispatch',
      ],
      action: 'fetchAvailableInventory',
      offset: 0,
      limit: 20
    }
  },
  methods: {
    ...mapActions(['fetchCenterInventory']),
    openDispatchModal(batchInventory) {
      this.batch = batchInventory;
      $('#dispatchModal').modal('show')
    },
    getDaysArray(batch_number) {
      let arr = [];
      let dt;
      let last_report_date = this.center_info_with_batch_report.last_stock_update[batch_number.toString()];
      if (last_report_date === '') {
        arr.push(this.$func.formatDate(new Date()))
      } else {
        for (arr, dt = new Date(this.center_info_with_batch_report.last_stock_update[batch_number.toString()]); dt <= new Date(); dt.setDate(dt.getDate() + 1)) {
          arr.push(this.$func.formatDate(new Date(dt)));
        }
      }

      this.un_reported_days_array = arr;
    },
    openStockReportModal(batchInventory) {
      this.batch = batchInventory;
      this.getDaysArray(batchInventory.batch.batch_number);
      $('#report_stock_level_modal').modal('show')
    },
    retrieveCenterInventory() {
      this.loading = true
      this.$store.dispatch(this.action, `?paginate=1&limit=${this.limit}&offset=0`).then(resp => {
        this.loading = false
        return resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.loading = false
        this.$store.dispatch('setError', {'detail': 'Could Not Fetch Inventory'})
      });
    },
    retrieveDistributionTransactions() {
      return this.$store.dispatch('fetchDistributionTransactions', '?action=received')
    },
    distributionTransactionForBatch(batch_id) {
      return this.allDistributionTransactions.filter(transaction => {
        return transaction.batch.id === batch_id
      })[0]
    },
    getExpiredColorCode(date) {
      var difference = this.$func.diffDays(new Date(), date)
      if (difference < 30 && difference > 0) {
        return "text-warning"
      } else if (difference < 0) {
        return "text-danger"
      }
    },
    retrieveCenterInfoWithBatchReport() {
      this.$store.dispatch('fetchCenterInfoWithBatchReport').then(resp => {
        this.center_info_with_batch_report = resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {'detail': 'could not fetch facility info'})
      })
    },
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      this.is_loading = true
      this.offset = this.offset + 20
      return this.$store.dispatch(this.action, '?'+ this.getParams(this.allAvailableInventory.next))
    },
    previousPage(){
      this.is_loading = true
      this.offset = this.offset - 20
      return this.$store.dispatch(this.action, '?'+ this.getParams(this.allAvailableInventory.previous))
    }
  },
  computed: {
    ...mapGetters([
      'allAvailableInventory', 'allDistributionTransactions', 'getError', 'centerInfoWithBatchReport'
    ]),
  },
  mounted() {
    this.retrieveCenterInventory()
    this.retrieveDistributionTransactions();
    this.retrieveCenterInfoWithBatchReport()
  }
}
</script>

<style>

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1200px;
  }
}
</style>