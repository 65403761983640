<template>
  <div class="card">
    <div class="card-body">
      <form autocomplete="off" @submit.prevent="submitSystemUser">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="first_name">First Name</label>
            <input id="first_name" v-model="first_name" :class="['form-control ', checkIfErrorExists('first_name')]"
                   autocomplete="off" type="text">
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError['first_name']" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="last_name">Last Name</label>
            <input id="last_name" v-model="last_name" autocomplete="off" class="form-control" type="text">
          </div>
          <div class="form-group col-md-4">
            <label for="first_name">
              UserName
              <span class="text-danger">*</span>
            </label>
            <input id="username" v-model="username" :class="['form-control ', checkIfErrorExists('username')]"
                   autocomplete="" class="form-control" required="required" type="text">

            <InputErrorComponent input_name="username"/>
          </div>

        </div>
        <div class="form-row">

          <div class="form-group col-md-4">
            <label for="phone_number">
              Phone Number
              <span class="text-danger">*</span>
            </label>
            <input id="phone_number" v-model="phone_number"
                   :class="['form-control ', checkIfErrorExists('phone_number')]"
                   autocomplete="off" placeholder="+2547" required="required" type="tel">

            <InputErrorComponent input_name="phone_number"/>
            <InputSuccessComponent input_name="phone_number"/>

            <div>
              <div v-if="is_loading" class="offset-5">
                <p class="loader"></p>
                <p>Loading...</p>
              </div>
              <div class="invalid-feedback d-block">
              <span v-for="(error, index) in getError" v-bind:key="index">
                {{ error.message }}
              </span>
              </div>
            </div>
          </div>
          <div class="form-group col-md-4 ml-1">
            <label>
              Password
              <span class="text-danger">*</span>
            </label>
            <div class="row ml-1">
              <input id="password"
                     v-model="password" :class="['form-control col-md-8', checkIfErrorExists('password')]" :type="[show_password ? 'text' : 'password']"
                     autocomplete="new-password">
              <button class="btn btn-default" type="button" @click="togglePasswordShow">
                <i :class="['feather icon-eye', show_password ? 'd-none' : '']"></i>
                <i :class="['feather icon-eye-off', !show_password ? 'd-none' : '']"></i>
              </button>
            </div>
            <InputErrorComponent input_name="password"/>

          </div>
        </div>
        <div v-if="$func.userHasRole('Trainer of Trainers(TOT)')">
          <LocationFilter :defaultFacility="getUserBeingUpdated ? getUserBeingUpdated.center : null" @setLocationFilter="setLocationFilter" size="col-md-4" parent-component="userForm"/>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="search_center_value">Search Center</label>
              <input id="search_center_value" v-model="search_center_value" class="form-control" type="text">
            </div>
          </div>
          <div class="row">
            <div v-for="(result,index) in centerSearchResults" v-bind:key="index" class="col-md-3">
              <a v-if="result.id === center" class="btn btn-success d-block mb-2" @click="setCenter(result)">
                Assigned to {{ result.name }}
                <i class="feather icon-check"></i>
              </a>
              <a v-else class="btn btn-primary d-block mb-2" @click="setCenter(result)">
                Assign to {{ result.name }}
                <i class="feather icon-arrow-right"></i>
              </a>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="form-group col-md-12 ml-2">
              <label class="mb-3" for="userRoleSelect">
                Select Role
                <span class="text-danger">*</span>
                <span class="text-info">NB: Managers can only view reports</span>
              </label>
              <br>
              <div v-for="(role, index) in allowedUserGroups" id="userRoleSelect" :key="index"
                   class="form-check form-check-inline">
                <input :id="role.name"
                       v-model="groups_id[index]" :class="['form-check-input', checkIfErrorExists('groups_id')]" :value="role.id"
                       type="checkbox">
                <label :for="role.name" class="form-check-label"><span class="text-capitalize">{{ role.name }}</span>
                </label>
              </div>
              <InputErrorComponent input_name="groups_id"/>
            </div>
          </div>
          <div v-if="user_is_vaccinator" class="form-row">
            <div class="form-group col-md-4">
              <label for="practising_no">Practising Number</label>
              <input id="practising_no" v-model="practising_no" :class="['form-control ', checkIfErrorExists('staff_number')]"
                     autocomplete="off" type="text">
              <div class="invalid-feedback">
              <span v-for="(error, index) in getError['staff_number']" v-bind:key="index">
                {{ error }}
              </span>
              </div>
            </div>
          </div>
        </div>
        <SubmittingLoader v-if="submitting"></SubmittingLoader>
        <div v-else>
          <button v-if="selected_user_id" class="btn btn-primary" type="submit">
          <span v-if="this.$route.name === 'my_profile'">
            Update My Profile
            <i class="feather icon-edit"></i>
          </span>
            <span v-else>
            Update User
            <i class="feather icon-edit"></i>
          </span>
          </button>
          <button v-else class="btn btn-primary" type="submit">
            Create User
            <i class="feather icon-user-plus"></i>
          </button>
        </div>
      </form>


    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InputErrorComponent from "@/components/users/InputErrorComponent";
import InputSuccessComponent from "@/components/InputSuccessComponent";
import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";
import LocationFilter from "../filters/LocationFilter";

export default {
  name: "user_form",
  components: {LocationFilter, InputSuccessComponent, InputErrorComponent, SubmittingLoader},
  props: ["selected_user_id"],
  data() {
    return {
      user_id: "",
      first_name: "",
      last_name: "",
      username: "",
      center: "",
      password: "",
      phone_number: "+254",
      practising_no: "",
      groups_id: [],
      show_password: false,
      is_loading: false,
      phone_verified: true,
      filtered_subcounties: [],
      filtered_centers: [],
      center_county: null,
      center_subcounty: null,
      centerId: '',
      preselected_county: null,
      filteredCounties: [],
      search_center_value: '',
      county: '',
      subcounty: '',
      my_subcounties: '',
      searchable_centers: [],
      allowedUserGroups: [],
      user_is_vaccinator: false,
      vaccinator_group_id : '',
      submitting: false
    }
  },
  methods: {
    ...mapActions(['fetchCenters', 'fetchCounties', 'fetchSubCounties']),
    setCenter(center) {
      this.filtered_centers = [
        center
      ]
      this.center = center.id
    },
    checkIfVaccinator() {
      var groups_id_list = []
      this.groups_id.map((element, index) => {
        if (element) {
          groups_id_list.push(this.allowedUserGroups[index].id)
        }
      })

      let vaccinator_group_id = this.allowedUserGroups.filter(group => {
        return group.name === 'Vaccinator'
      })[0].id

      return this.user_is_vaccinator = groups_id_list.includes(vaccinator_group_id);
    },
    setLocationFilter(payload){
      this.center = payload
    },
    prefillUserBeingUpdated() {
      if (this.selected_user_id) {
        this.username = this.getUserBeingUpdated.username;
        this.first_name = this.getUserBeingUpdated.first_name;
        this.last_name = this.getUserBeingUpdated.last_name;
        this.center = this.getUserBeingUpdated.center;
        this.practising_no = this.getUserBeingUpdated.staff_number
        this.fetchCenters().then(resp => {
          this.filtered_centers = resp.filter(center => {
            return center.id === this.getUserBeingUpdated.center
          })
        })

        this.password = this.getUserBeingUpdated.password;
        this.phone_number = this.getUserBeingUpdated.phone_number;

        try {
          // get all groups
          if (this.getUserBeingUpdated.groups.length > 0) {
            let user_group_names = this.getUserBeingUpdated.groups.map(group => {
              return group.name
            })
            this.groups_id = []
            this.$store.dispatch('fetchSystemUserGroups').then(resp => {
              let allowedGroups = this.filterAllowedGroups(resp)
              allowedGroups.map((group, index) => {
                if (user_group_names.indexOf(group.name) !== -1) {
                  this.$set(this.groups_id, index, true)
                } else {
                  this.$set(this.groups_id, index, false)
                }
              })

              this.checkIfVaccinator()
              this.allowedUserGroups = allowedGroups;
              // eslint-disable-next-line no-unused-vars
            }).catch(err => {

            })
          }
          // eslint-disable-next-line no-empty
        } catch (err) {
        }
      }
    },
    retrieveAllCenters() {
      return this.fetchCenters().then(allCenters => {

        // Fetch counties
        this.$store.dispatch('fetchCounties').then(counties => {
          this.$store.dispatch('fetchSubCounties').then(subcounties => {
            // Get subcounty the center belongs to
            this.subcounty = subcounties.filter(subcounty => {
              return subcounty.id === this.centerInfo.sub_county
            })[0]

            // Get the county the center belongs to
            this.county = counties.filter(county => {
              return county.id === this.subcounty.county
            })[0]

            // Get the subcounties in the county
            this.my_subcounties = subcounties.filter(subcounty => {
              return subcounty.county === this.county.id
            }).map(subcounty => {
              return subcounty.id
            })

            if(this.$func.userHasRole('Support')){
              this.searchable_centers = allCenters
            }else{
              this.searchable_centers = allCenters.filter(center => {
                return this.my_subcounties.includes(center.sub_county)
              })
            }
          })
        })
      })
    },
    togglePasswordShow() {
      this.show_password = !this.show_password;
    },
    retrieveSystemUserGroups() {
      this.$store.dispatch('fetchSystemUserGroups').then( groups => {
        this.allowedUserGroups = groups.filter(group => {
          return group.name === 'Vaccinator' || group.name === 'Clerk' || group.name === 'Effects Reporter'
              || group.name === 'Stock Manager' || group.name === 'Manager' || group.name === 'HRIO' || group.name === 'Verifier'
        })
      });
    },
    submitSystemUser() {
      this.submitting = true
      if (this.selected_user_id) {
        this.updateSystemUser()
      } else {
        this.createSystemUser()
      }
    },
    updateSystemUser() {
      if (this.phone_verified === true) {
        var groups_id_list = []
        this.groups_id.map((element, index) => {
          if (element === true) {
            groups_id_list.push(this.allowedUserGroups[index].id)
          }
        })

        let tot_id = this.allSystemUserGroups.filter(group => {
          return group.name === 'Trainer of Trainers(TOT)'
        })[0].id

        let support_id = this.allSystemUserGroups.filter(group => {
          return group.name === 'Support'
        })[0].id

        //Get the ids of the user's groups
        let user_groups_ids = this.getUserBeingUpdated.groups.map( group => {
          return group.id
        })

        if(user_groups_ids.includes(tot_id)){
          groups_id_list.push(tot_id)
        }

        if(user_groups_ids.includes(support_id)){
          groups_id_list.push(support_id)
        }

        var payload = {
          "first_name": this.first_name,
          "last_name": this.last_name,
          "username": this.username,
          "center": this.center,
          "phone_number": this.phone_number,
          "groups_id": groups_id_list,
          "password" : this.password
        }

        if(this.password === ''){
          delete payload.password;
        }

        this.$store.dispatch('updateSystemUser', {
          id: this.selected_user_id, data: payload


          // eslint-disable-next-line no-unused-vars
        }).then((resp) => {
          // Refresh token
          if(this.centerInfo.id !== this.center){
            payload = {
              'refresh': localStorage.getItem("refresh_token")
            }

            // console.log('payload', payload)
            this.$store.dispatch('refreshToken', payload)
          }
          this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Updated User"}]);
          this.$router.push({name:'users'})
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.submitting = false
          this.$store.dispatch('setError', err)
        })
      } else {
        this.$store.dispatch('setError', {"phone_number": "Please Verify this Phone Number"})
      }

    },
    createSystemUser() {
      if (this.phone_verified === true) {
        let groups_id_list = []
        this.groups_id.map((element, index) => {
          groups_id_list.push(this.allowedUserGroups[index].id)
        })
        let payload = {};

        payload = {
          "first_name": this.first_name,
          "last_name": this.last_name,
          "username": this.username,
          "center": this.center,
          "password": this.password,
          "phone_number": this.phone_number,
          "staff_number": this.practising_no,
          "groups_id": groups_id_list
        }

        if(this.practising_no === ''){
          delete payload.staff_number;
        }

        // eslint-disable-next-line no-unused-vars
        this.$store.dispatch('createSystemUser', payload).then((resp) => {
          this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Registered User"}]);
          this.$router.push({name:'users'})
          this.first_name = "";
          this.last_name = "";
          this.username = "";
          this.center = "";
          this.password = "";
          this.phone_number = "";
          this.groups_id = [];
          this.show_password = false
          this.practising_no = ""
          // eslint-disable-next-line no-unused-vars
        }).then(resp => {
          this.$store.dispatch('setError', {})
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.submitting = false
        })
      } else {
        this.$store.dispatch('setError', {"phone_number": "Please Verify this Phone Number"})
      }

    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    populateCounty() {
      this.is_loading = true

      let center = this.centerInfo

      this.$store.dispatch('getCountyFromSubCounty', center.sub_county).then(resp => {
        this.preselected_county = resp.county
        this.allCounties.map((county) => {
          if (county.id === resp.county) {
            this.filteredCounties = []
            this.filteredCounties.push(county);
          }
        })
      }).catch(err => {
        this.$store.dispatch('setError', err)
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        this.is_loading = false

      })

    },
    titleCase(str) {
      if (str !== null) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
      }
    },
    filterAllowedGroups(allGroups) {
      return allGroups.filter(group => {
        return group.name === 'Vaccinator' || group.name === 'Clerk' || group.name === 'Effects Reporter'
            || group.name === 'Stock Manager' || group.name === 'Manager' || group.name === 'HRIO' || group.name === 'Verifier'
      })
    },
  },
  computed: {
    ...mapGetters([
      'centerInfo',
      'allSystemUserGroups', 'getError',
      'allCenters', 'allCounties', 'allSubCounties', 'getUserBeingUpdated'
    ]),
    centerSearchResults() {
      if (this.search_center_value === '') {
        return []
      } else {
        return this.searchable_centers.filter(center => {
          return center.name.startsWith(this.titleCase(this.search_center_value))
        })
      }
    }
  },
  watch: {
    center_county: function () {
      let filteredSubCounties = []
      // use getter to return all centers
      let allSubcountiesList = this.allSubCounties;

      // loop through all centers
      allSubcountiesList.map((subcounty) => {
        // get the centers that match that level
        if (subcounty.county === this.center_county) {
          filteredSubCounties.push(subcounty)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_subcounties = filteredSubCounties
    },

    center_subcounty: function () {
      let filteredCenters = []
      // use getter to return all centers
      let allCentersList = this.allCenters;

      // loop through all centers
      allCentersList.map((center) => {
        // get the centers that match that level
        if (center.sub_county === this.center_subcounty) {
          filteredCenters.push(center)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_centers = filteredCenters
    }
  },
  mounted() {
    this.retrieveSystemUserGroups();
    this.retrieveAllCenters()
    this.populateCounty()
    this.prefillUserBeingUpdated()
  },
}

</script>
