<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Person Registration Form"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <register_person_form class="mb-3"></register_person_form>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import register_person_form from "@/components/persons/register_person_form";
import success_messages from '@/components/layouts/includes/success_messages.vue';

export default {
  name: "register_person",
  components: {register_person_form, Breadcrumbs,  success_messages}
}
</script>