<template>
  <div>
    <PersonTable
        :data="getAllRegistrations"
        action="fetchAllRegistrations"
        :isLoading="isLoading"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import router from "@/router";
import PersonTable from "../tables/PersonTable";

export default {
  name: "vaccinations_today_report",
  components: {PersonTable},
  data() {
    return {
      isLoading: true,
    }
  },
  methods: {
    retrieveAllRegistrations() {
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchAllRegistrations', '/?limit=10&offset=0').then(resp => {
        this.isLoading = false;
      })
    },
    getSelectedPerson(person_id) {
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        router.push("update-vaccinee", resp.id);
      })
    },
    getRegistrationsForDay(){
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchAllRegistrations', '/?limit=10&offset=0&date='+ String(this.day) )
    },
  },
  computed: {
    ...mapGetters(['getAllRegistrations']),
  },
  mounted() {
    this.retrieveAllRegistrations()
  }
}
</script>

<style scoped>

</style>