<template>
    <div>

        <div class="form-head">
            <a href="/" class="logo"><img src="@/assets/images/logo.png" class="img-fluid"
                                          alt="logo"></a>
        </div>
        <h4 class="text-info my-4">Sign Up </h4>

        <form method="post" @submit.prevent="register">
            <!-- start email-->
            <div class="form-group">
                <input type="email" v-model="email" name="email" maxlength="254" class="form-control required" placeholder="Enter Email"
                       required="" id="id_email">
            </div>


            <!-- end email-->

            <!-- start username-->
            <div class="form-group">
                <input type="text" v-model="username" name="username" maxlength="150" autocapitalize="none" autocomplete="username"
                       autofocus="" class="form-control required" placeholder="Enter UserName" required=""
                       id="id_username">
            </div>


            <small style="color: grey" class="form-text text-muted">Required. 150 characters or fewer. Letters, digits
                and @/./+/-/_ only.</small>

            <!-- end username-->

            <div class="form-group">
                <input type="password" v-model="password1" name="password1" autocomplete="new-password" class="form-control"
                       placeholder="Enter Password" required="" id="id_password1">
            </div>

            <div class="form-group">
                <input type="password" v-model="password2" name="password2" autocomplete="new-password" class="form-control"
                       placeholder="Confirm Password" required="" id="id_password2">
            </div>

            <small style="color: grey">Enter the same password as before, for verification.</small>

            <input type="submit" class="btn btn-success btn-lg btn-block font-18" value="Register">
            <input type="hidden" name="next" value="">
        </form>
        <div class="login-or">
            <h6 style="color: transparent">OR</h6>
        </div>
        <div class="form-row mb-3">
            <div class="col-sm-6">
                <div class="custom-control custom-checkbox text-left">
                    <input type="checkbox" class="custom-control-input" id="rememberme">
                    <label class="custom-control-label font-14" for="rememberme">Remember Me</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="forgot-psw">
                    <router-link to="forgot_password" class="font-14">Lost password?</router-link>
                </div>
            </div>
        </div>
        <p class="mb-0 mt-3">Already have an account?
            <router-link to="login">Login</router-link>
        </p>

    </div>
</template>

<script>
    export default {
        name: "register",
        data() {
            return {
                username: "",
                email: "",
                password1: "",
                password2: "",

            }
        },
        methods: {
            setLayout() {
                this.$store.commit('SET_LAYOUT', 'outside-dashboard')
            },
          register() {
                let data ={
                    username : this.username,
                    email : this.email,
                    password1 : this.password1,
                    password2 : this.password2,
                }
                this.$store.dispatch('register', data)
                    .then(() => this.$router.push('/'))
                    .catch(err => console.log(err))
            }
        },
        created() {
            this.setLayout()
        }
    }
</script>

<style scoped>

</style>