<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="User Management"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <search_system_user class="mb-3"></search_system_user>
      <search_user_results v-if="Object.keys(allSystemUsers).length !== 0 && allSystemUsers.length !==0"></search_user_results>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import search_system_user from "@/components/support/search_system_user";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import search_user_results from "@/components/support/search_user_results";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "manage_vaccinees",
  components: {search_user_results, Breadcrumbs, search_system_user, success_messages},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    }
  },
  computed: {
    ...mapGetters(['personDetails', 'allSystemUsers'])
  },
  mounted() {
    this.clearErrors()
    console.log("I AM MOUNTED")
  }
}
</script>