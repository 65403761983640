import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import './utils/filters';
//required by vuex
import 'es6-promise/auto'
import api from './api'
// import styles
// import "./assets/plugins/switchery/switchery.min.css";
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.css"
// import "./assets/js/core_javascript.js"
import "./assets/css/icons.css";
// import "./assets/css/flag-icon.min.css";
import "./assets/css/style.css";
import VueFormulate from '@braid/vue-formulate'
// import "./assets/js/jquery.min.js"
import vuescroll from 'vuescroll';

import "jquery"

import "detect.js"
import VueNotification from "@kugatsu/vuenotification";

// Global functions
import {func} from './functions.js'
Vue.prototype.$func = func


Vue.use(VueNotification, {
  timer: 4,
  showCloseIcn:true,
  position: 'topCenter',
  error: {
    background: "rgb(247,71,28)",
    color: "white"
  }
});
// require('jquery-slimscroll');
Vue.config.productionTip = false
Vue.prototype.$http = api;
Vue.use(VueFormulate, {
  classes: {
    outer: 'mb-4',
    // input: 'capitalize border border-gray-400 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1',
    input: 'form-control',
    label: 'text-capitalize',
    help: 'font-12 mb-1 text-muted mt-2',
    errors: 'list-unstyled',
    error: 'text-danger font-14 mt-2 text-lowercase'
  }
})
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
});

window.$ = window.jQuery = require('jquery');


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
