import api from "@/api";

const state = {
	center: {
		'id': localStorage.getItem('id') || '',
		'name': localStorage.getItem('name') || '',
		'level': localStorage.getItem('level') || '',
		'sub_county': localStorage.getItem('sub_county') || '',
	},
	reporting_info:{

	},
	center_with_batch_report: {},
	county:{}
}

const getters = {
	centerInfo(state) {
		return state.center
	},
	centerInfoWithBatchReport(state){
		return 	state.center_with_batch_report
	},
	getReportInfo(state){
		return state.reporting_info
	}
}

const actions = {
	fetchCenterInfo: ({commit}) => {
		// eslint-disable-next-line no-unused-vars
		return new Promise((resolve , reject,) =>{
			api.get('/my-center/').then(resp=>{
				localStorage.setItem('id', resp.data.id)
				localStorage.setItem('name', resp.data.name)
				localStorage.setItem('level', resp.data.level)
				localStorage.setItem('sub_county', resp.data.sub_county)
				commit("setCenter", resp.data)
				resolve(resp.data)
			}).catch(() =>{
				reject({"detail":"please login"})
			})
		});
	},
	fetchCenterInfoWithBatchReport: ({commit}) => {
		// eslint-disable-next-line no-unused-vars
		return new Promise((resolve , reject,) =>{
			api.get('/my-center-with-stock-report/').then(resp=>{
				// commit("setCenterWithBatchReport", resp.data)
				localStorage.setItem('id', resp.data.id)
				localStorage.setItem('name', resp.data.name)
				localStorage.setItem('level', resp.data.level)
				localStorage.setItem('sub_county', resp.data.sub_county)
				let allDates = Object.values(resp.data.last_stock_update)
				let current_largest =allDates[0]
				allDates.forEach(element=>{
					if(new Date(element).getTime() > new Date(current_largest).getTime()){
						current_largest = element
					}
				})
				let today = new Date()
				commit('setReportInfo', {
					'date': current_largest,
					'is_upto_date' : new Date(current_largest).getTime() > (today.setDate(today.getDate()-3))
				})

				commit("setCenter", resp.data)
				resolve(resp.data)
			}).catch(() =>{
				reject({})
			})
		});
	},
	getCountyFromSubCounty: ({commit}, sub_county_id) => {
		return new Promise(((resolve, reject) => {
			api.get('/sub-counties/' + sub_county_id).then(resp=>{
				commit("setCounty", resp.data)
				resolve(resp.data)
			}).catch(err=>{
				reject(err)
			});
		}));
	}
}

const mutations = {
	setReportInfo:(state, reporting_info)=> (state.reporting_info = reporting_info),
    setCenter: (state, center) => (state.center = center),
    setCenterWithBatchReport: (state, center_with_batch_report) => (state.center_with_batch_report = center_with_batch_report),
    setCounty: (state, center) => (state.county = center)
}

export default {
    state,
    getters,
    actions,
    mutations,
}