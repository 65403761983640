<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Vaccination Defaulters Report"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="$func.userHasRoles( ['Vaccinator', 'Trainer of Trainers(TOT)' ,'Manager', 'HRIO', 'Support'])">
        <defaulters_count v-if="centerInfo.level === 'national'"></defaulters_count>
        <vaccination_defaulters_report v-else></vaccination_defaulters_report>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions, mapGetters} from "vuex";
import vaccination_defaulters_report from "@/components/reports/vaccination_defaulters_report";
import defaulters_count from "@/components/reports/defaulters_count";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "VaccineInventoryReport",
  components: {AccessDeniedCard, defaulters_count, vaccination_defaulters_report, Breadcrumbs},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  computed:{
    ...mapGetters([
        'centerInfo'
    ])
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>