<template>
  <div>
    <!-- Modal -->
    <div id="exampleModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" role="dialog"
         tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Gadget Ledger <span v-if="allGadgetLedgers.length !== 0">for Imei : {{
                allGadgetLedgers[0].gadget.imei_number
              }}</span>
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="activities-history">
              <div v-if="allGadgetLedgers.length !==0">
                <div v-for="(gadget, index) in allGadgetLedgers" :key="index" class="activities-history-list">
                  <div v-if="index<4" class="activities-history-item">
                    <h6>
                      <span v-if="index === 0" class="text-muted">Registered to</span>
                      <span v-else class="text-muted">Re Assigned to</span>
                      {{ gadget.assigned_to.name }} {{ gadget.assigned_to.level }}
                    </h6>
                    <h6 class="mb-0">
                      <span v-if="index === 0" class="badge badge-success-inverse pt-2">Registered</span>
                      <span v-else class="badge badge-warning-inverse pt-2">Re Assigned</span>
                      3rd May 2021
                    </h6>
                  </div>
                </div>
              </div>
              <div v-else>
                <h6>This Gadget has not been assigned to any facility yet</h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button class="btn btn-primary" type="button">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div id="editGadgetModal" aria-hidden="true"  aria-labelledby="editGadgetModal"
         class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editGadgetModalModalLabel" class="modal-title">IMEI number: {{imeiNumber}} at {{centerName}} </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editGadget">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="receiverID">ID of Person Assigned </label>
                  <input v-model="receiver_id_number" type="text" class="form-control" id="receiverID">
                </div>
                <div class="form-group col-md-4">
                  <label for="receiverName">Name of Person Assigned</label>
                  <input v-model="receiver_name" type="text" class="form-control" id="receiverName">
                </div>
                <div class="form-group col-md-4">
                  <label for="receiverPhone">Phone Number of Person Assigned </label>
                  <input v-model="receiver_phone" type="text" class="form-control" id="receiverPhone">
                </div>
                <input class="d-none" type="text" v-model="centerId" :placeholder="centerId">
              </div>

              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary mr-2" data-dismiss="modal" type="button">Close</button>
                <button class="btn btn-primary" type="submit">
                  Edit Gadget
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="assignGadgetModal" aria-hidden="true" aria-labelledby="assignGadgetModalLabel"
         class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="dispatchModalLabel" class="modal-title">IMEI number: {{ imeiNumber }} Currently at {{centerName}}</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="reAssignGadget(index, gadgetId)">
              <div class="form-group mx-sm-3 mb-2">
                <label for="recording_county">Select County</label>
                <select id="recording_county" v-model="center_county" class="form-control" required>
                  <option v-for="(county, index) in allCounties" :key="index" :value="county.id">
                    {{ county.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mx-sm-3 mb-2">
                <label for="recording_subcounty">Select Subcounty</label>
                <select id="recording_subcounty" v-model="center_subcounty" class="form-control" required>
                  <option v-for="(subcounty, index) in filtered_subcounties" :key="index"
                          :value="subcounty.id">
                    {{ subcounty.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mx-sm-3 mb-2">
                <label for="centerId">Select Destination Center</label>
                <select id="centerId" v-model="centerId" class='form-control' required>
                  <option v-for="(center, index) in filtered_centers" v-bind:key="index" :value="center.id">
                    {{ center.name }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary mr-2" data-dismiss="modal" type="button">Close</button>
                <button class="btn btn-primary" type="submit">
                              <span v-if="centerInfo.level === 'national'">
                                Assign Gadget
                              </span>
                  <span v-else>
                                Reassign Gadget
                              </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <h5 class="card-title">All Gadgets </h5>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                     class="form-control" placeholder="Search by IMEI or Phone Number" type="search">
              <div class="input-group-append">
                <button id="button-addon2" class="btn" type="submit">
                  <i class="feather icon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">IMEI Number</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Facility
              <th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody v-if="filteredGadgets.length === 0">
            <tr>
              <td class="text-center" colspan="6">
                No Gadgets found
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="(gadget, index) in filteredGadgets" v-bind:key="index">
              <td>{{ gadget.imei_number }}</td>
              <td>{{ gadget.phone_number }}</td>
              <td>{{ gadget.center.name }}</td>
              <td>
                <button class="btn btn-primary" type="button" @click="openModal(index, 'assignGadgetModal',
          centerId = gadget.center.id,
           '',
              '',
                '',
                gadget.id,
                               gadget.imei_number,
                               gadget.center.name

                )">
                  <span v-if="centerInfo.level === 'national'">
                    Assign Gadget
                  </span>
                  <span v-else>
                    Reassign Gadget
                  </span>
                  <i class="feather icon-arrow-right"></i>
                </button>


              </td>
              <td>
                <button class="btn btn-primary" type="button" @click="openModal(index, 'editGadgetModal',
                 centerId = gadget.center.id,
                (gadget.assigned_to !== null && gadget.assigned_to.id_number !== null ) ? gadget.assigned_to.id_number : '',
                (gadget.assigned_to !== null && gadget.assigned_to.name !== null ) ? gadget.assigned_to.name : '' ,
                (gadget.assigned_to !== null && gadget.assigned_to.phone_number !== null ) ? gadget.assigned_to.phone_number : '',
                gadget.id,
                gadget.imei_number,
                gadget.center.name
                 )">
                  <span>
                    Edit Gadget
                  </span>
                  <i class="feather icon-arrow-right"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
// import $ from 'jquery'
export default {
  name: "gadgets_list",
  data() {
    return {
      gadgetId: '',
      centerId: '',
      gadgetPositionInList: '',
      imeiNumber: '',
      filtered_subcounties: [],
      filtered_centers: [],
      center_county: null,
      center_subcounty: null,
      search_value: '',
      receiver_id_number: '',
      receiver_name: '',
      receiver_phone: '',
      centerName: ''
    }
  },
  methods: {
    ...mapActions(['fetchCenterInfo', 'fetchGadgets', 'fetchGadgetLedger', 'fetchCenters', 'fetchCounties', 'fetchSubCounties',
    ]),
    fetchAllGadgets() {
      this.fetchGadgets()
    },
    retrieveAllCenters() {
      return this.fetchCenters()
    },
    showGadgetLedger(gadget_id) {
      this.fetchGadgetLedger({gadget_id: gadget_id})
    },
    openModal(index, modalId, centerId,
    receiver_id_number,
    receiver_name,
    receiver_phone, gadgetId, imeiNumber, centerName) {
      this.filtered_subcounties = []
      this.filtered_centers = []
      this.center_county = null
      this.center_subcounty = null

      this.centerId = centerId;
      this.index = index;
      this.receiver_id_number = receiver_id_number;
      this.receiver_name = receiver_name;
      this.receiver_phone = receiver_phone;
      this.gadgetId = gadgetId;
      this.imeiNumber = imeiNumber;
      this.centerName = centerName;


      $('#' + modalId ).modal('show')
    },
    editGadget(){
      this.$store.dispatch('EditGadget', {
        gadget_id :this.gadgetId,
        imeiNumber: this.imeiNumber,
        assigned_to: {
          id_number: this.receiver_id_number.toString(),
          name: this.receiver_name.toString(),
          phone_number: this.receiver_phone.toString(),
        }
        // eslint-disable-next-line no-unused-vars
      },).then(resp =>{
        this.$store.dispatch('fetchGadgets')
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Updated Gadget"}])
        $('#' + 'editGadgetModal' ).modal('toggle')
      })
    },
    reAssignGadget(){
      let gadgetId = this.gadgetId
      let centerId = this.centerId
      let gadgetPositionInList = this.gadgetPositionInList

      this.$store.dispatch('reAssignGadget', {
        centerId: centerId,
        gadget_id: gadgetId,
        gadget_position: gadgetPositionInList

        // eslint-disable-next-line no-unused-vars
      }).then(resp=>{
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Reassigned Gadget"}])
        $('#' + 'assignGadgetModal' ).modal('toggle')
      })

    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    retrieveCurrentCenter() {
      return this.fetchCenterInfo();
    }
  },
  computed: {
    ...mapGetters([
      'allGadgets', 'gadgetReassignFormStatus', 'getSuccessMessages', 'allGadgetLedgers', 'allCenters', 'allCounties', 'allSubCounties', 'centerInfo'
    ]),
    filteredGadgets() {
      if (this.search_value === '') {
        return this.allGadgets
      } else {
        return this.allGadgets.filter(gadget => {
          return gadget.imei_number.startsWith(this.search_value) || gadget.phone_number.startsWith(this.search_value)
        })
      }
    }
  },
  mounted() {
    this.showGadgetLedger(1)
    this.fetchAllGadgets()
    this.retrieveAllCenters()
    this.retrieveSubCounties()
    this.retrieveCounties()

  },
  watch: {
    center_county: function () {
      let filteredSubCounties = []
      // use getter to return all centers
      let allSubcountiesList = this.allSubCounties;

      // loop through all centers
      allSubcountiesList.map((subcounty) => {
        // get the centers that match that level
        if (subcounty.county === this.center_county) {
          filteredSubCounties.push(subcounty)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_subcounties = filteredSubCounties
    },

    center_subcounty: function () {
      let filteredCenters = []
      // use getter to return all centers
      let allCentersList = this.allCenters;

      // loop through all centers
      allCentersList.map((center) => {
        // get the centers that match that level
        if (center.sub_county === this.center_subcounty) {
          filteredCenters.push(center)
        }
      })
      // assign to filteredDestination Centers
      this.filtered_centers = filteredCenters
    }
  },
}
</script>
