<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{getInvalidJabs.count}} Invalid Vaccination Dates
      </h5>
    </div>
    <div class="card-body">
      <default_table :previousLink="getInvalidJabs.previous" :nextLink="getInvalidJabs.next" @previousPage="previousPage()" @nextPage="nextPage()">
        <template #thead>
          <th v-for="(header, index) in headers" v-bind:key="index">
            {{header}}
          </th>
        </template>
        <template #tbody>
          <tr v-for="(vaccination, index) in getInvalidJabs.results" v-bind:key="index">
            <td>{{ vaccination.batch_number }}</td>
            <td>{{ vaccination.dose_number }}</td>
            <td>{{ new Date(vaccination.date_administered).toDateString() }}</td>
          </tr>
        </template>
      </default_table>
    </div>
  </div>
</template>

<script>
import default_table from "@/components/templates/DefaultTable";
import {mapGetters} from "vuex";

export default {
  name: "invalid_vaccinations_report",
  components: {default_table},
  data() {
    return {
      headers: [
        'Batch Number',
        'Dose Number',
        'Date Administered'
      ],
      values: []
    }
  },
  computed : {
    ...mapGetters(['getInvalidJabs']),
  },
  methods: {
    retrieveInvalidJabs(){
      return this.$store.dispatch('fetchInvalidJabs', '?limit=10&offset=0').then(resp => {
        resp.results.map(value => {
          let item = []
          item.push(value.batch_number)
          item.push(value.dose_number)
          item.push(value.date_administered)

          return this.values.push(item)
        })
      })
    },
    // function you can use:
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      return this.$store.dispatch('fetchInvalidJabs', '?'+ this.getParams(this.getInvalidJabs.next))
    },
    previousPage(){
      return this.$store.dispatch('fetchInvalidJabs', '?'+ this.getParams(this.getInvalidJabs.previous))
    }
  },
  mounted(){
    this.retrieveInvalidJabs()
  }
}
</script>

<style scoped>

</style>