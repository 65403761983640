<template>
  <div>
    <div v-if="currentRouteName === 'vaccination_stats'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Vaccination Stats"></breadcrumbs>

      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <div v-if="$func.userHasRoles(['Vaccinator','Trainer of Trainers(TOT)','Manager'])">
          <BriefStatsReport/>
        </div>
        <div class="row" v-else>
          <AccessDeniedCard/>
        </div>
      </div>
    </div>
    <router-view>
    </router-view>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";
import BriefStatsReport from "../../components/reports/BriefStatsReport";

export default {
  name: "DailyStats",
  components: {BriefStatsReport, AccessDeniedCard, Breadcrumbs},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
  },
  computed :{
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.clearErrors()
  }
}
</script>

<style scoped>

</style>