<template>
  <div>
    <table :title="title" :action="action" class="table">
      <thead class="thead-dark">
      <tr>
        <slot name="thead">
        </slot>
      </tr>
      </thead>
      <tbody>
      <tr v-if="isLoading">
        <td colspan="6" class="text-center">
          <SubmittingLoader/>
        </td>
      </tr>
      <slot v-else name="tbody">
      </slot>
      </tbody>
    </table>
    <ul class="pagination">
      <li v-if="previousLink !==null" @click="previousPage()" class="page-item"><a class="page-link" href="#">Previous</a>
      </li>
      <li v-if="nextLink !==null" @click="nextPage()" class="page-item"><a class="page-link" href="#">Next</a>
      </li>
    </ul>
  </div>
</template>

<script>

import SubmittingLoader from "@/components/inventory/SubittingLoader";

export default {
  name: "DefaultTable",
  components: {SubmittingLoader},
  data(){
    return {
      table_data:[]
    }
  },
  props: [
    'previousLink',
    'nextLink',
    'title',
    'has_custom_function',
    'action',
    'isLoading'
  ],
  methods: {
    nextPage(){
      return this.$emit('nextPage')
    },
    previousPage(){
      return this.$emit('previousPage')
    },
    dispatchAction(){
      if (this.has_custom_function){
        this.table_data = this.action
      }
      this.$store.dispatch(this.action).then(resp=>{
        this.table_data = resp
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>