<template>
  <div class="card m-b-30">
    <div v-if="batch !== ''" :id="'rejectModal'" aria-hidden="true" aria-labelledby="rejectModalLabel" class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="rejectModalLabel" class="modal-title">Reject Batch Number: {{batch.batch_number}}, Vaccine: {{batch.vaccine.name}}
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="remark">
                Remark
                <span class="text-danger">*</span>
              </label>
              <textarea id="remark" v-model="remark" class='form-control' rows="3" required></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button class="btn btn-danger pr-2" @click="rejectBatch(batch)">
              Reject
              <i class="feather icon-x"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <h5 class="card-title">Batches received from merchants</h5>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                   class="form-control" placeholder="Search by batch number" type="search">
            <div class="input-group-append">
              <button id="button-addon2" class="btn" type="submit">
                <i class="feather icon-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Batch Number</th>
            <th scope="col">Vaccine</th>
            <th scope="col">Number of Doses</th>
            <th scope="col">Date Of Manufacture</th>
            <th scope="col">Date Of Expiry</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody v-if="allBatchesToApprove.length !== 0">
          <tr v-for="(batch, index) in filteredInventory" v-bind:key="index">
            <td>{{ batch.batch_number }}</td>
            <td>{{ batch.vaccine.name }} - {{ batch.vaccine.disease.name }}</td>
            <td>{{ batch.starting_quantity}}</td>
            <td>{{ new Date(batch.date_of_manufacture).toDateString() }}</td>
            <td>
              <span :class="getExpiredColorCode(batch.date_of_expiry)">
                {{ new Date(batch.date_of_expiry).toDateString() }}
              </span>
            </td>
            <td>
              <h5 v-if="batch.approved === true">
                <span class="badge badge-success">
                  Approved
                </span>
              </h5>
              <button v-else-if="batch.approval_remarks === null" @click="approveBatch(batch)" class="btn btn-primary mr-2">
                Approve
                <i class="feather icon-check"></i>
              </button>
              <button v-if="batch.approved === false && batch.approval_remarks === null" @click="rejectBatchModal(batch)" class="btn btn-danger">
                Reject
                <i class="feather icon-x"></i>
              </button>
              <h5 v-if="batch.approval_remarks !== null">
                <span class="badge badge-danger">
                  Rejected
                </span>
              </h5>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="text-center" colspan="7">
              No Batches by Merchants
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";

export default {
  name: "approve_batches",
  data() {
    return {
      search_value: '',
      batch: '',
      remark: null
    }
  },
  methods: {
    ...mapActions(['fetchBatchesToApprove']),
    approveBatch(batch){
      let batch_number = batch.batch_number
      let approved = true
      let id = batch.id
      return this.$store.dispatch('updateBatch', {
        id,
        batch_number,
        approved
      }).then(resp => {
        if (resp) {
          this.$store.dispatch('setSuccessMessages', [{"message": "Batch approved successfully"}])
          this.fetchBatchesToApprove();
        }
        // eslint-disable-next-line no-unused-vars
      })
    },
    rejectBatch(batch){
      let approved = false
      let id = batch.id
      let remark = this.remark
      let batch_number = batch.batch_number + String(id)
      return this.$store.dispatch('updateBatch', {
        id,
        approved,
        remark,
        batch_number,
      }).then(resp => {
        if (resp) {
          $('.modal').modal('hide')
          this.$store.dispatch('setSuccessMessages', [{"message": "Batch rejected successfully"}])
          this.fetchBatchesToApprove();
        }
        // eslint-disable-next-line no-unused-vars
      })
    },
    retrieveBatchesToApprove() {
      return this.fetchBatchesToApprove();
    },
    getExpiredColorCode(date){
      const date1 = new Date;
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      let difference =  Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if(difference < 30){
        return "text-warning"
      }else if(difference < 0){
        return "text-danger"
      }
    },
    rejectBatchModal(batch){
      this.batch = batch
      $('#rejectModal').modal('show')
    },
  },
  computed: {
    ...mapGetters([
      'allBatchesToApprove', 'centerInfo',
    ]),
    filteredInventory() {
      if (this.search_value === '') {
        return this.allBatchesToApprove
      } else {
        return this.allBatchesToApprove.filter(batch => {
          return batch.batch_number.startsWith(this.search_value)
        })
      }
    }
  },
  mounted() {
    this.retrieveBatchesToApprove()
  }
}
</script>