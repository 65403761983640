<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs :pageName=appendPageNameToUser()></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <success_messages></success_messages>
    <div class="contentbar">
      <register_person_form :selected_person_id="getPersonBeingUpdated.id"></register_person_form>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import register_person_form from "@/components/persons/register_person_form";
import success_messages from "@/components/layouts/includes/success_messages";
import {mapGetters} from "vuex";

export default {
  name: "UpdateUser",
  components: {success_messages, register_person_form, Breadcrumbs},
  computed: {
    ...mapGetters(['getPersonBeingUpdated'])
  },
  methods: {
    appendPageNameToUser() {
      return "Update " + (this.getPersonBeingUpdated.first_name) + ' ' + this.getPersonBeingUpdated.middle_name + ' ' + this.getPersonBeingUpdated.last_name
    },
  }
}
</script>
<style lang="">

</style>