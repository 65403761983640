<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Vaccinees Registered Today"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="userHasRole('Trainer of Trainers(TOT)')">
        <all_registrations_report></all_registrations_report>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import all_registrations_report from "@/components/reports/all_registrations_report";
import {mapActions} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "VaccineInventoryReport",
  components: {AccessDeniedCard, Breadcrumbs, all_registrations_report},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>