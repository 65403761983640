<template>
    <div>
        <!-- Start Footerbar -->
        <div class="footerbar">
            <footer class="footer">
                <p class="mb-0">© {{new Date().getFullYear()}}  Chanjo - All Rights Reserved.</p>
            </footer>
        </div>
        <!-- End Footer -->
    </div>
</template>

<script>
  export default {
      name: "Footer",
      data: function () {
        return {

        };
      },
      props: [
        'centerInfo',
      ],
  }
</script>


