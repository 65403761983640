<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Dashboard"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div>
        <h2 class="text-center my-4">
          Welcome to Chanjo KE
        </h2>
        <div v-if="centerInfo.level !== 'vendor' && centerInfo.level !== 'distributor'" class="row">
          <div class="col-12 col-md-6">
            <div class="card m-b-30">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-5">
                      <span class="action-icon badge badge-primary-inverse mr-0"><i
                          class="fa fa-stethoscope"></i></span>
                  </div>
                  <div class="col-7 text-right">
                    <h2 class="card-title font-14">Total Vaccinations</h2>
                    <h2 v-if="!is_loading_total" class="mb-0">{{ total_jabs | withCommas}}</h2>
                    <div v-else class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card m-b-30">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-5">
                      <span class="action-icon badge badge-primary-inverse mr-0"><i
                          class="fa fa-stethoscope"></i></span>
                  </div>
                  <div class="col-7 text-right">
                    <h2 class="card-title font-14">Vaccinations Today</h2>
                    <h2 v-if="!is_loading_daily" class="mb-0">{{ jabs_today | withCommas}}</h2>
                    <div v-else class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="!is_loading_total" class="col-12 col-md-6">
            <GenderPieChart :jabs="jabs_object" class="mt-2"/>
          </div>
          <div v-else class="col-12 col-md-4">
            <div class="card">
              <div class="card-header">
                <h4>
                  Vaccinations By Gender
                </h4>
              </div>
              <div class="card-body">
                <div class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <AgePieChart :triggerUpdate="true" payload=""/>
          </div>
        </div>
        <KenyaMap/>
        <div class="row">
          <div class="col-12">
            <PeriodicJabs v-if="centerInfo.level === 'national'" class="mt-2"/>
          </div>
        </div>
        <div v-if="centerInfo.level === 'vendor' && centerInfo.level === 'distributor'">
          <div class="col-md-5 offset-md-3">
            <div class="card mt-5" style="background-color: #fffffff2">
              <div class="card-header">
              </div>
              <div class="card-body text-center ">
                <div class="">
                  <h4 class="text-center">Welcome to Chanjo KE<br></h4>
                  <router-link to="master_inventory" class="btn btn-primary mt-4"> View Inventory</router-link>
                  <div class="col-md-6 offset-md-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions, mapGetters} from 'vuex'
import PeriodicJabs from "../../components/charts/PeriodicJabs";
import GenderPieChart from "@/components/charts/GenderPieChart";
import AgePieChart from "@/components/charts/AgePieChart";
import KenyaMap from "@/components/reports/map/KenyaMap";

export default {
  name: "Home",
  components: {KenyaMap, AgePieChart, GenderPieChart, PeriodicJabs, Breadcrumbs},
  data() {
    return {
      view_stats: false,
      is_loading_total: true,
      is_loading_daily: true,
      is_downloading_adverse: false,
      jabs_object: {}
    }
  },
  methods: {
    ...mapActions(['fetchBriefStats']),
    getCenterInfo() {
      return this.centerInfo
    },
    fetchBriefStats() {
      this.view_stats = true
      this.$store.dispatch('fetchGenderGroupedJabs', '?date=' + this.$func.formatDate(new Date)).then(resp => {
        this.jabs_today = resp.map(gender => {
          return gender.people
        }).reduce((a, b) => a + b, 0)

        this.is_loading_daily = false

        this.$store.dispatch('fetchGenderGroupedJabs', '').then(resp => {
          this.jabs_object = resp
          this.total_jabs = resp.map(gender => {
            return gender.people
          }).reduce((a, b) => a + b, 0)
          this.is_loading_total = false
        })
      })
    },
    downloadAdverseEventsCSV() {
      this.is_downloading_adverse = true
      this.$store.dispatch('fetchAdverseCSV').then(resp => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(resp);
        anchor.target = '_blank';
        anchor.download = 'all_adverse_events.csv';
        anchor.click()
        this.is_downloading_adverse = false
      })
    },
  },
  computed: {
    ...mapGetters(['getBriefStats', 'centerInfo', 'getToken']),
  },
  mounted() {
    this.fetchBriefStats()
  }
}
</script>
<style scoped>

</style>
