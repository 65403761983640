<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs
        :pageName="'Verify vaccination certificate'"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <verify_vaccination_certificate></verify_vaccination_certificate>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import verify_vaccination_certificate from "@/components/vaccination_certificate/verify_vaccination_certificate";

export default {
  name: "show",
  components: {verify_vaccination_certificate, Breadcrumbs, success_messages},
}
</script>

<style scoped>

</style>