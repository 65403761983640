<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Invalid Vaccination Dates"></breadcrumbs>
    <div class="contentbar">
      <invalid_vaccinations_report></invalid_vaccinations_report>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import invalid_vaccinations_report from "@/components/invalid_data/invalid_vaccinations_report";

export default {
  name: "InvalidVaccinations",
  components: {Breadcrumbs, invalid_vaccinations_report}
}
</script>

<style scoped>

</style>