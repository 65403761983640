<template>
  <div id="containerbar" class="containerbar authenticate-bg">
    <div class="container">
      <div class="auth-box login-box">
        <!-- Start row -->
        <div class="row no-gutters align-items-center justify-content-center">
          <!-- Start col -->
          <div class="col-md-6 col-lg-5">

            <!-- Start Auth Box -->
            <div class="auth-box-right">
              <div class="card">
                <div class="card-body">
<!--                  <error_messages v-bind:error-message="getError.message"></error_messages>-->

                  <router-view/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import error_messages from "@/components/layouts/includes/error_messages";
import {mapGetters} from 'vuex'

export default {
  name: "outside_dashboard",
  data() {
    return {
      err_message: this.getError
    }
  },
  // components: {error_messages},
  computed: {
    ...mapGetters(['getError', 'getSuccessMessages'])
  },
  watch: {
    getError(){
      if(Object.keys(this.getError).length >0){
        let err = this.getError
        let full_error_message = ''
        Object.keys(err).map(msg_instance => {
          full_error_message += `${err[msg_instance]} \n`;
        })
        this.$notification.error(full_error_message, {title:'An Error Occurred',});
      }
    },
    getSuccessMessages(){
      if(Object.keys(this.getSuccessMessages).length > 0){
        console.log(this.getSuccessMessages)
        let string_msg =''
        this.getSuccessMessages.map(msg=>{
          return Object.keys(msg).map((val)=>{
            string_msg += msg[val]
          })
        })
        this.$notification.success(string_msg)
      }
    }
  }
}

</script>

<style scoped>

</style>