<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-30">
        <div class="card-body">
          <ul id="defaultTabLine" class="nav nav-tabs custom-tab-line mb-3" role="tablist">
            <li v-if="!$func.userHasRoles(['Verifier'])">
              <a id="profile-tab-line" aria-controls="profile-line" aria-selected="true" class="nav-link active"
                 data-toggle="tab"
                 href="#profile-line" role="tab"><i class="feather icon-user mr-2"></i>Profile
                Details</a>
            </li>
            <li v-if="$func.userHasRoles(['Vaccinator', 'Verifier', 'Trainer of Trainers(TOT)', 'HRIO'])" class="nav-item">
              <a id="vaccination-tab-line" aria-controls="vaccination-line" aria-selected="false" class="nav-link"
                 data-toggle="tab"
                 href="#vaccination-line" role="tab"><i class="feather icon-thermometer mr-2"></i>Vaccination</a>
            </li>
            <li v-if="$func.userHasRoles(['Vaccinator', 'Effects Reporter']) && personAdverseEffects" class="nav-item">
              <a id="adverse-tab-line" aria-controls="adverse-line" aria-selected="false" class="nav-link"
                 data-toggle="tab"
                 href="#adverse-line" role="tab"><i class="feather icon-alert-triangle mr-2"></i>Adverse
                Events</a>
            </li>
            <!--            <li class="nav-item" v-if="personDetails.vaccinations.length > 0">-->
            <!--              <a id="contact-tab-line" aria-controls="contact-line" aria-selected="false" class="nav-link"-->
            <!--                 data-toggle="tab"-->
            <!--                 href="#contact-line" role="tab"><i class="feather icon-message-circle mr-2"></i>Communication</a>-->
            <!--            </li>-->
          </ul>
          <div id="defaultTabContentLine" class="tab-content">
            <div id="profile-line" :class="$func.userHasRole('Verifier') ? 'tab-pane fade' : 'tab-pane fade active show'"
                 aria-labelledby="profile-tab-line" role="tabpanel">
              <AllPersonDetails v-if="showMoreDetails"/>
              <PersonDetailsMinimal v-else @toggleMoreDetails="toggleMoreDetails"/>
            </div>
            <div id="vaccination-line" :class="$func.userHasRole('Verifier') ? 'tab-pane fade active show' : 'tab-pane fade'"
                 aria-labelledby="vaccination-tab-line" role="tabpanel">
              <dosage_timeline :expired_batch="false"></dosage_timeline>
            </div>
            <div v-if="personAdverseEffects" id="adverse-line" aria-labelledby="adverse-tab-line" class="tab-pane fade" role="tabpanel">
              <adverse_effects_list></adverse_effects_list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import dosage_timeline from "@/components/persons/dosage_timeline";
import adverse_effects_list from "@/components/persons/adverse_effects_list";
import PersonDetailsMinimal from "./PersonDetailsMinimal";
import AllPersonDetails from "./AllPersonDetails";

export default {
  data: function () {
    return {
      submitting: false,
      personAdverseEffects: null,
      showMoreDetails: false
    }
  },
  name: "view_person",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    AllPersonDetails,
    PersonDetailsMinimal, adverse_effects_list, dosage_timeline},
  computed: {
    ...mapGetters(['personDetails','personVaccinations', 'allNationalities'])
  },
  methods: {
    getNationalityCountry(countryCode) {
      return this.allNationalities.filter(nationality => {
        return nationality.value === countryCode
      })[0].name
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres = JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    toggleMoreDetails(val){
      console.log("triggered!", val)
      this.showMoreDetails = val
    }
  },
  mounted() {
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>