<template>
  <div class="form-group">
    <label for="from-date">Select Date</label>
    <div class="input-group">
      <input v-model='date' id="date" class="datepicker-here form-control"
             aria-describedby="basic-addon2"
             placeholder="dd/mm/yyyy" type="date">
    </div>
  </div>
</template>

<script>
export default {
  name: "DateFilter",
  data(){
    return{
      date: this.$func.formatDate(new Date()),
    }
  },
  watch: {
    date: function (){
      this.$emit('setDateFilter', '&date=' + this.date)
    }
  },
  mounted(){
    this.$emit('setDateFilter', '&date=' + this.date)
  }
}
</script>

<style scoped>

</style>