<template>
  <div>
    <div class="row mb-3">
      <div v-if="centerInfo.level === 'national'" class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('county_stats', 'fetchCountyStatsCSV','County_stats')"
          class="btn btn-primary btn-block">
          <span v-if="csv_loading">
            Downloading CSV...
          </span>
          <span v-else>
            Download County Vaccination Stats CSV
            <i class="feather icon-file-text"></i>
          </span>
        </button>
      </div>
      <div v-if="centerInfo.level === 'national'" class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('ward_stats', 'fetchWardStatsCSV','Ward_stats')"
          class="btn btn-primary btn-block">
          <span>
            Download Ward Vaccination Stats CSV
            <i class="feather icon-file-text"></i>
          </span>
        </button>
      </div>
      <div v-else>
        <button @click="downloadStatsCSV('facility_stats', 'fetchFacilityStatsCSV', 'Vaccinations_per_facility')"
          class="btn btn-primary btn-block">
          <span v-if="csv_loading">
            Downloading CSV...
          </span>
          <span v-else>
            Download Facility Vaccination Stats CSV
            <i class="feather icon-file-text"></i>
          </span>
        </button>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('weekly_stats', 'fetchWeeklyStatsCSV', 'Weekly_Stats_')"
          class="btn btn-primary btn-block">
          Download Weekly Vaccination Stats CSV
          <i class="feather icon-file-text"></i>
        </button>
      </div>
      <div v-if="centerInfo.level ==='national'" class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('monthly_stats', 'fetchMonthlyStats','Monthly_stats')"
          class="btn btn-primary btn-block">
          Download Monthly Vaccination Stats CSV
          <i class="feather icon-file-text"></i>
        </button>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('age_group', 'fetchAgeGroupStats')" class="btn btn-primary btn-block">
          Download Age Group Stats CSV
          <i class="feather icon-file-text"></i>
        </button>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <button @click="downloadStatsCSV('booster_stats', 'fetchBoosterStatsCSV')" class="btn btn-primary btn-block">
          Download Booster Stats CSV
          <i class="feather icon-file-text"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-2">
        <button v-if="centerInfo.level === 'national'"
          @click="downloadStatsCSV('county_stats_pfizer_under18', 'fetchCountyStatsCSV', 'County_stats_under_18')"
          class="btn btn-primary btn-block">
          <span>
            Download County Vaccination Stats CSV (Under 18)
            <i class="feather icon-file-text"></i>
          </span>
        </button>
      </div>
      <div class="col-md-3 mb-2">
        <button v-if="centerInfo.level === 'national'"
          @click="downloadStatsCSV('county_stats_all_above18', 'fetchCountyStatsCSV', 'County_stats_above_18')"
          class="btn btn-primary btn-block">
          <span>
            Download County Vaccination Stats CSV (All above 18)
            <i class="feather icon-file-text"></i>
          </span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Vaccinations_stats_csv",
  data() {
    return {
      csv_loading: '',
      initial_payload: ''
    }
  },
  props: ['centerInfo', 'payload'],
  methods: {
    downloadStatsCSV(type, api_call = '', file_name = '') {
      this.csv_loading = true

      let params = this.payload

      if (type === 'county_stats_pfizer_under18') {
        params = `${this.payload}&maxAge=18`
      } else if (type === 'county_stats_all_above18') {
        params = `${this.payload}&minAge=18`
      }

      this.$store.dispatch(api_call, params).then(statsCSV => {
        this.$func.downloadCSV(statsCSV, file_name)
        this.payload = this.initial_payload
        this.csv_loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>