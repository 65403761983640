<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Tech Support"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <support_search class="mb-3"></support_search>
      <view_person v-if="Object.keys(personDetails).length !==0"></view_person>
      <search_results v-if="Object.keys(getSearchResults).length !==0 && getSearchResults.length !==0"></search_results>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import support_search from "@/components/support/support_search";
import view_person from "@/components/persons/view_person";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import {mapActions, mapGetters} from "vuex";
import search_results from "@/components/support/search_results";

export default {
  name: "search",
  components: {search_results, view_person, Breadcrumbs, support_search, success_messages},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    }
  },
  computed: {
    ...mapGetters(['getSearchResults', 'personDetails'])
  },
  mounted() {
    this.clearErrors()
  }
}
</script>