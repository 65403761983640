<template>
  <div class="form-group">
    <label for="from-date">From</label>
    <div class="input-group">
      <input v-model='from_date' id="from-date" class="datepicker-here form-control"
             aria-describedby="basic-addon2"
             placeholder="dd/mm/yyyy" type="date">
    </div>
  </div>
</template>

<script>
export default {
  name: "DateFromFilter",
  data(){
    return{
      from_date: this.$func.formatDate(new Date("3/1/2021")),
    }
  },
  watch: {
    from_date: function (){
      this.$emit('setDateFromFilter', '&from=' + this.from_date)
    }
  },
  mounted(){
    this.$emit('setDateFromFilter', '&from=' + this.from_date)
  }
}
</script>

<style scoped>

</style>