<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Approve Merchant Inventory"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <approve_batches></approve_batches>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import approve_batches from "@/components/inventory/approve_batches";
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from "@/components/layouts/includes/success_messages";

export default {
  name: "dispatch_master",
  components: {
    Breadcrumbs,
    approve_batches,
    success_messages
  },
  computed: {
    ...mapGetters(['centerInfo']),
  },
  methods: {
    ...mapActions(['fetchCenterInfo']),
    retrieveCurrentCenter() {
      return this.fetchCenterInfo();
    }
  },
  mounted() {
    this.retrieveCurrentCenter();
  }
}
</script>