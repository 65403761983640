<template>
  <div class="card">
    <div class="card-body">
      <div v-if="is_loading" class="offset-5">
        <p class="loader"></p>
        <p>Loading</p>
      </div>
      <div v-if="Object.keys(getError).length !==0 " id="err_alert"
           class="alert alert-danger alert-dismissible fade show"
           role="alert">
        <p v-for="(item, index) in getError" :key="index">
          {{ (index[0]).toUpperCase() }}{{ index.substr(1, index.length) }} : <span v-for="(sub_item, index) in item"
                                                                                    :key="index">{{ sub_item }}</span>
        </p>

        <button class="close" type="button" @click="hide_err">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form @submit.prevent="reportAdverseEffect">
        <span class="font-weight-bold">Select Reporting Facility</span>
        <LocationFilter @setLocationFilter="setLocationFilter" parent-component="adverseEffects" size="col-md-3"/>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="report_type">Select Report Type (Initial/Follow up)
              <span class="text-danger">*</span></label>
            <select id="report_type" v-model="report_type" class="form-control" required>
              <option value="initial">Initial</option>
              <option value="follow_up">Follow Up</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="vaccination">Select Vaccination associated with effect
              <span class="text-danger">*</span></label>
            <select id="vaccination" v-model="person_vaccination" class="form-control" required>
              <option v-for="(vaccination, index) in personDetails.vaccinations" v-bind:key="index" :value="vaccination.id">
                {{ getVaccineName(vaccination.vaccine) }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="reporter-designation">Designation of Person Reporting
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <input id="reporter-designation" v-model="reported_by_designation" class="form-control" required
                     type="text">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="reporter_name">Name of Person Reporting
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <input id="reporter_name" v-model="reported_by_name" class="form-control" required type="text">
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="reporter_phone_no">Reporter Phone Number
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <input id="reporter_phone_no" v-model="reported_by_phone" class="form-control" required type="text">
            </div>
          </div>
        </div>
        <div v-for="(effect,index) in adverse_effects" v-bind:key="index" class="form-row">
          <div class="form-group col-md-4">
            <label for="aefi_type">Select Type of AEFI
              <span class="text-danger">*</span></label>
            <select id="aefi_type" v-model="effect.adverse_effect_category" class="form-control" required>
              <option selected>Choose...</option>
              <option v-for="(type, index) in allEffectTypes" v-bind:key="index" :value="type.name">
                {{ type.name }}
              </option>
            </select>
          </div>
          <div v-if="effect.adverse_effect_category === 'Other'" class="form-group col-md-4">
            <label for="other_aefi">Specify AEFI if Other
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <input v-model="effect.specified_adverse_type" id="other_aefi" class="form-control" type="text">
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="aefi_description">Description
            </label>
            <div class="input-group">
              <input id="aefi_description" v-model="effect.description" class="form-control" type="text">
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="onset_of_event">Onset of Event
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <input id="onset_of_event" v-model="date_of_occurrence" aria-describedby="basic-addon2"
                     class="datepicker-here form-control" placeholder="dd/mm/yyyy" required
                     type="datetime-local">
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="care_received">Action Taken
              <span class="text-danger">*</span></label>
            <div class="input-group">
              <textarea id="care_received" v-model="effect.care_received" class="form-control" rows="3"></textarea>
            </div>
          </div>
          <div class="form-group col-md-4 pl-4">
            <label for="severity">Severe?
              <span class="text-danger">*</span></label>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="effect.severity" id="severe" value="true" checked>
              <label class="form-check-label" for="severe">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="effect.severity" id="notSevere" value="false" checked>
              <label class="form-check-label" for="notSevere">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-md-2">
            <button v-if="adverse_effects.length === index+1 " class="btn btn-primary mt-4" type="button" v-on:click="addNewAdverseEffect">
              Add Event
              <i class="feather icon-plus"></i>
            </button>
          </div>
          <div v-if="index !== 0" class="form-group col-md-2">
            <button class="btn btn-danger mt-4" type="button" v-on:click="removeAdverseEffect(index)">
              Remove Effect
              <i class="feather icon-trash-2"></i>
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="outcome">
              Select Outcome Category
              <span class="text-danger">*</span>
            </label>
            <select id="outcome" v-model="outcome" class="form-control" required>
              <option selected value="recovered">Recovered</option>
              <option value="recovering">Recovering</option>
              <option value="not_recovered">Not recovered</option>
              <option value="unknown">Unknown</option>
              <option value="died">Died</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="outcome_description">Outcome Description</label>
            <textarea id="outcome_description" v-model="outcome_description" class="form-control" rows="3"></textarea>
          </div>
        </div>
        <button class="btn btn-primary" type="submit">
          Record Adverse Event
          <i class="feather icon-arrow-right"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {mapActions} from "vuex/dist/vuex.mjs";
import Vue from 'vue';
import LocationFilter from "@/components/filters/LocationFilter";

export default {
  name: "adverse_effects_form",
  components: {LocationFilter},
  data: function () {
    return {
      report_type: '',
      person_vaccination: '',
      date_of_occurrence: '',
      reported_by_name: '',
      reported_by_phone: '',
      reported_by_designation: '',
      adverse_effect: {
        'adverse_effect_category': '',
        'description': '',
        'specified_adverse_type': '',
        'care_received': '',
        'severity': ''
      },
      adverse_effects : [{
        'adverse_effect_category': '',
        'description': '',
        'specified_adverse_type': '',
        'care_received': '',
        'severity': ''
      }],
      reporting_center_id: '',
      is_loading: false,
      successMessages: [],
      filtered_subcounties: [],
      filtered_centers: [],
      center_county: null,
      center_subcounty: null,
      outcome: '',
      outcome_description: ''
    }
  },
  methods: {
    ...mapActions(['setError', 'fetchPersonDetails', 'fetchEffectTypes']),
    addNewAdverseEffect() {
      this.adverse_effects.push(Vue.util.extend({}, this.adverse_effect))
    },
    getVaccineName(id){
      return this.allVaccines.find(vacc => vacc.id === id).name
    },
    removeAdverseEffect: function (index) {
      Vue.delete(this.adverse_effects, index);
    },
    reportAdverseEffect() {
      this.is_loading = true;
      // remove errors
      this.setError({})

      let adverse_effects = this.adverse_effects.map(effect => {
        if(effect.specified_adverse_type !== ''){
          effect.adverse_effect_category = 'Other - ' + effect.specified_adverse_type
        }
        delete effect.specified_adverse_type

        return effect
      })

      const payload = {
        person: this.personDetails.id,
        date_of_occurrence: this.date_of_occurrence,
        reported_by_name: this.reported_by_name,
        reported_by_phone: this.reported_by_phone,
        reported_by_designation: this.reported_by_designation,
        adverse_effects: adverse_effects,
        reporting_center_id: this.reporting_center_id,
        person_vaccination_id: this.person_vaccination,
        report_type: this.report_type,
        outcome: this.outcome
      }

      this.$store.dispatch('reportAdverseEffects', payload).then(resp => {
        if (resp) {
          this.is_loading = false;
          this.$store.dispatch('setSuccessMessages', [{"message": "Adverse Event Reported successfully"}])
          this.$store.dispatch('setPersonDetails', {})
          this.$router.push({name: "persons"})
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
    retrievePersonDetails() {
      return this.fetchPersonDetails()
    },
    setLocationFilter(payload){
      this.reporting_center_id = payload
    },
    retrieveEffectTypes() {
      return this.fetchEffectTypes();
    },
    hide_err() {
      this.setError({})
    },
    retrieveVaccines() {
      this.$store.dispatch('fetchVaccines')
    },
  },
  computed: {
    ...mapGetters(['centerInfo', "allCenters", 'personDetails', 'getError', 'allEffectTypes', 'allVaccines', 'allCounties', 'allSubCounties']),
    vaccinations: function () {
      return this.allVaccines.filter(vaccine => {
        return vaccine.id === this.personDetails.vaccinations[0].vaccine
      })
    },
    // schedules: function () {
    //   let schedule = this.personDetails.vaccinations[0].scheduled_doses
    //
    //   return schedule.sort((a, b) => (a.dose_number > b.dose_number) ? 1 : -1);
    // },
  },
  mounted() {
    this.retrieveEffectTypes()
  }
}
</script>

<style scoped>

</style>