<template>
  <div>

    <div v-if="Object.keys(getError).length !==0" id="err_alert"
         class="alert alert-danger alert-dismissible fade show" role="alert">
      <p v-for="(item, index) in getError" :key="index">
        <span v-for="(sub_item, index) in item" :key="index">{{ sub_item }}</span>
      </p>

      <button class="close" type="button" @click="hide_err()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>

</template>

<script>
import $ from "jquery";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "error_messages",
  props: ['errorMessage'],
  computed: {
    ...mapGetters(['getError'])
  },
  methods: {
    ...mapActions(['setError']),
    hide_err() {
      this.setError({})
      $("#err_alert").addClass("d-none");
    }, show_err() {
      $("#err_alert").removeClass("d-none");
    }
  },
  mounted() {
    if (this.getError.toString().includes('<!doctype html>')){
      this.setError({'detail':'An Unknown Error Occurred'})
    }

  }

}
</script>

<style scoped>

</style>