<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form class="row" @submit.prevent="retrieveFacilityByCode">
          <div class="form-group col-sm-12 col-md-4 mr-2">
            <label for="facility_code">
              Facility Code
              <span class="text-danger">*</span>
            </label>
            <input id="facility_code" v-model="facility_code" class="form-control" required type="text">
          </div>
          <div class="col-sm-12 col-md-3 form-group mt-4 ">
            <button class="btn btn-primary" type="submit">
              Search <i class="feather icon-search"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12" v-if="isLoading">
            <submitting-loader></submitting-loader>
          </div>
          <div class="card col-12">
            <div v-if="Object.keys(facility_result).length ===0" class="card-body">
              <h5 class="card-title">Please search for a facility </h5>
            </div>
            <div v-else class="card-body row">
              <h5 class="card-title col-12">{{facility_result.name }}({{facility_result.ownership}}) - Code {{facility_result.code}}</h5>
              <h6 class="mb-2 text-muted col-12"><i class="feather icon-map-pin text-dark"></i> {{facility_result.ward}} > {{facility_result.sub_county}} > {{facility_result.county}} </h6>

              <button class="btn btn-primary ml-md-3 mt-md-4" @click="insertFacility(facility_result.id)">Add Facility</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmittingLoader from "../layouts/includes/SubmittingLoader";
export default {
  name: "AddFacility",
  components: {SubmittingLoader},
  data() {
    return {
      headers: [],
      facility_code: '',
      facility_result: {},
      isLoading: false
    }
  },
  methods: {
    retrieveFacilityByCode() {
      this.facility_result = {}
      this.isLoading = true
      this.$store.dispatch('searchFacilityByCode', this.facility_code).then(resp => {
        this.facility_result = resp
        this.isLoading = false
      }).catch((err)=>{
        this.facility_result = {}
        if(err.status === 404){
          this.$store.dispatch('setError', {'detail': 'Facility Was Not Found In The KMHFl List'})
        }
        this.isLoading = false
      })
    },
    insertFacility(facility_id){
      this.isLoading = true
      this.$store.dispatch('insertFacilityByCode', {'id': facility_id}).then(()=>{
        this.$store.dispatch('setSuccessMessages', [{"message": "Facility Added Successfully"}])
        this.facility_result = {}
        this.isLoading = false
      }).catch(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>