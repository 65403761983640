<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Vaccine Utilization Report"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="(userHasRole('Stock Manager') || userHasRole('Manager')) ">
        <vaccine_utilization_report></vaccine_utilization_report>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions, mapGetters} from "vuex";
import vaccine_utilization_report from "@/components/reports/vaccine_utilization_report";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";
export default {
  name: "VaccineUtilizationReportPage",
  components: {AccessDeniedCard, vaccine_utilization_report, Breadcrumbs,},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    },
  },
  computed: {
    ...mapGetters(['centerInfo'])
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>