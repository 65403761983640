import api from "@/api";

const state = {
  counties: [],
  sub_counties: [],
  wards: [],
  phone_codes: [
    {"country": "BD", "code": "880"},
    {"country": "BE", "code": "32"},
    {"country": "BF", "code": "226"},
    {"country": "BG", "code": "359"},
    {"country": "BA", "code": "387"},
    {"country": "BB", "code": "+1-246"},
    {"country": "WF", "code": "681"},
    {"country": "BL", "code": "590"},
    {"country": "BM", "code": "+1-441"},
    {"country": "BN", "code": "673"},
    {"country": "BO", "code": "591"},
    {"country": "BH", "code": "973"},
    {"country": "BI", "code": "257"},
    {"country": "BJ", "code": "229"},
    {"country": "BT", "code": "975"},
    {"country": "JM", "code": "+1-876"},
    {"country": "BV", "code": ""},
    {"country": "BW", "code": "267"},
    {"country": "WS", "code": "685"},
    {"country": "BQ", "code": "599"},
    {"country": "BR", "code": "55"},
    {"country": "BS", "code": "+1-242"},
    {"country": "JE", "code": "+44-1534"},
    {"country": "BY", "code": "375"},
    {"country": "BZ", "code": "501"},
    {"country": "RU", "code": "7"},
    {"country": "RW", "code": "250"},
    {"country": "RS", "code": "381"},
    {"country": "TL", "code": "670"},
    {"country": "RE", "code": "262"},
    {"country": "TM", "code": "993"},
    {"country": "TJ", "code": "992"},
    {"country": "RO", "code": "40"},
    {"country": "TK", "code": "690"},
    {"country": "GW", "code": "245"},
    {"country": "GU", "code": "+1-671"},
    {"country": "GT", "code": "502"},
    {"country": "GS", "code": ""},
    {"country": "GR", "code": "30"},
    {"country": "GQ", "code": "240"},
    {"country": "GP", "code": "590"},
    {"country": "JP", "code": "81"},
    {"country": "GY", "code": "592"},
    {"country": "GG", "code": "+44-1481"},
    {"country": "GF", "code": "594"},
    {"country": "GE", "code": "995"},
    {"country": "GD", "code": "+1-473"},
    {"country": "GB", "code": "44"},
    {"country": "GA", "code": "241"},
    {"country": "SV", "code": "503"},
    {"country": "GN", "code": "224"},
    {"country": "GM", "code": "220"},
    {"country": "GL", "code": "299"},
    {"country": "GI", "code": "350"},
    {"country": "GH", "code": "233"},
    {"country": "OM", "code": "968"},
    {"country": "TN", "code": "216"},
    {"country": "JO", "code": "962"},
    {"country": "HR", "code": "385"},
    {"country": "HT", "code": "509"},
    {"country": "HU", "code": "36"},
    {"country": "HK", "code": "852"},
    {"country": "HN", "code": "504"},
    {"country": "HM", "code": " "},
    {"country": "VE", "code": "58"},
    {"country": "PR", "code": "+1-787 and 1-939"},
    {"country": "PS", "code": "970"},
    {"country": "PW", "code": "680"},
    {"country": "PT", "code": "351"},
    {"country": "SJ", "code": "47"},
    {"country": "PY", "code": "595"},
    {"country": "IQ", "code": "964"},
    {"country": "PA", "code": "507"},
    {"country": "PF", "code": "689"},
    {"country": "PG", "code": "675"},
    {"country": "PE", "code": "51"},
    {"country": "PK", "code": "92"},
    {"country": "PH", "code": "63"},
    {"country": "PN", "code": "870"},
    {"country": "PL", "code": "48"},
    {"country": "PM", "code": "508"},
    {"country": "ZM", "code": "260"},
    {"country": "EH", "code": "212"},
    {"country": "EE", "code": "372"},
    {"country": "EG", "code": "20"},
    {"country": "ZA", "code": "27"},
    {"country": "EC", "code": "593"},
    {"country": "IT", "code": "39"},
    {"country": "VN", "code": "84"},
    {"country": "SB", "code": "677"},
    {"country": "ET", "code": "251"},
    {"country": "SO", "code": "252"},
    {"country": "ZW", "code": "263"},
    {"country": "SA", "code": "966"},
    {"country": "ES", "code": "34"},
    {"country": "ER", "code": "291"},
    {"country": "ME", "code": "382"},
    {"country": "MD", "code": "373"},
    {"country": "MG", "code": "261"},
    {"country": "MF", "code": "590"},
    {"country": "MA", "code": "212"},
    {"country": "MC", "code": "377"},
    {"country": "UZ", "code": "998"},
    {"country": "MM", "code": "95"},
    {"country": "ML", "code": "223"},
    {"country": "MO", "code": "853"},
    {"country": "MN", "code": "976"},
    {"country": "MH", "code": "692"},
    {"country": "MK", "code": "389"},
    {"country": "MU", "code": "230"},
    {"country": "MT", "code": "356"},
    {"country": "MW", "code": "265"},
    {"country": "MV", "code": "960"},
    {"country": "MQ", "code": "596"},
    {"country": "MP", "code": "+1-670"},
    {"country": "MS", "code": "+1-664"},
    {"country": "MR", "code": "222"},
    {"country": "IM", "code": "+44-1624"},
    {"country": "UG", "code": "256"},
    {"country": "TZ", "code": "255"},
    {"country": "MY", "code": "60"},
    {"country": "MX", "code": "52"},
    {"country": "IL", "code": "972"},
    {"country": "FR", "code": "33"},
    {"country": "IO", "code": "246"},
    {"country": "SH", "code": "290"},
    {"country": "FI", "code": "358"},
    {"country": "FJ", "code": "679"},
    {"country": "FK", "code": "500"},
    {"country": "FM", "code": "691"},
    {"country": "FO", "code": "298"},
    {"country": "NI", "code": "505"},
    {"country": "NL", "code": "31"},
    {"country": "NO", "code": "47"},
    {"country": "NA", "code": "264"},
    {"country": "VU", "code": "678"},
    {"country": "NC", "code": "687"},
    {"country": "NE", "code": "227"},
    {"country": "NF", "code": "672"},
    {"country": "NG", "code": "234"},
    {"country": "NZ", "code": "64"},
    {"country": "NP", "code": "977"},
    {"country": "NR", "code": "674"},
    {"country": "NU", "code": "683"},
    {"country": "CK", "code": "682"},
    {"country": "XK", "code": ""},
    {"country": "CI", "code": "225"},
    {"country": "CH", "code": "41"},
    {"country": "CO", "code": "57"},
    {"country": "CN", "code": "86"},
    {"country": "CM", "code": "237"},
    {"country": "CL", "code": "56"},
    {"country": "CC", "code": "61"},
    {"country": "CA", "code": "1"},
    {"country": "CG", "code": "242"},
    {"country": "CF", "code": "236"},
    {"country": "CD", "code": "243"},
    {"country": "CZ", "code": "420"},
    {"country": "CY", "code": "357"},
    {"country": "CX", "code": "61"},
    {"country": "CR", "code": "506"},
    {"country": "CW", "code": "599"},
    {"country": "CV", "code": "238"},
    {"country": "CU", "code": "53"},
    {"country": "SZ", "code": "268"},
    {"country": "SY", "code": "963"},
    {"country": "SX", "code": "599"},
    {"country": "KG", "code": "996"},
    {"country": "KE", "code": "254"},
    {"country": "SS", "code": "211"},
    {"country": "SR", "code": "597"},
    {"country": "KI", "code": "686"},
    {"country": "KH", "code": "855"},
    {"country": "KN", "code": "+1-869"},
    {"country": "KM", "code": "269"},
    {"country": "ST", "code": "239"},
    {"country": "SK", "code": "421"},
    {"country": "KR", "code": "82"},
    {"country": "SI", "code": "386"},
    {"country": "KP", "code": "850"},
    {"country": "KW", "code": "965"},
    {"country": "SN", "code": "221"},
    {"country": "SM", "code": "378"},
    {"country": "SL", "code": "232"},
    {"country": "SC", "code": "248"},
    {"country": "KZ", "code": "7"},
    {"country": "KY", "code": "+1-345"},
    {"country": "SG", "code": "65"},
    {"country": "SE", "code": "46"},
    {"country": "SD", "code": "249"},
    {"country": "DO", "code": "+1-809 and 1-829"},
    {"country": "DM", "code": "+1-767"},
    {"country": "DJ", "code": "253"},
    {"country": "DK", "code": "45"},
    {"country": "VG", "code": "+1-284"},
    {"country": "DE", "code": "49"},
    {"country": "YE", "code": "967"},
    {"country": "DZ", "code": "213"},
    {"country": "US", "code": "1"},
    {"country": "UY", "code": "598"},
    {"country": "YT", "code": "262"},
    {"country": "UM", "code": "1"},
    {"country": "LB", "code": "961"},
    {"country": "LC", "code": "+1-758"},
    {"country": "LA", "code": "856"},
    {"country": "TV", "code": "688"},
    {"country": "TW", "code": "886"},
    {"country": "TT", "code": "+1-868"},
    {"country": "TR", "code": "90"},
    {"country": "LK", "code": "94"},
    {"country": "LI", "code": "423"},
    {"country": "LV", "code": "371"},
    {"country": "TO", "code": "676"},
    {"country": "LT", "code": "370"},
    {"country": "LU", "code": "352"},
    {"country": "LR", "code": "231"},
    {"country": "LS", "code": "266"},
    {"country": "TH", "code": "66"},
    {"country": "TF", "code": ""},
    {"country": "TG", "code": "228"},
    {"country": "TD", "code": "235"},
    {"country": "TC", "code": "+1-649"},
    {"country": "LY", "code": "218"},
    {"country": "VA", "code": "379"},
    {"country": "VC", "code": "+1-784"},
    {"country": "AE", "code": "971"},
    {"country": "AD", "code": "376"},
    {"country": "AG", "code": "+1-268"},
    {"country": "AF", "code": "93"},
    {"country": "AI", "code": "+1-264"},
    {"country": "VI", "code": "+1-340"},
    {"country": "IS", "code": "354"},
    {"country": "IR", "code": "98"},
    {"country": "AM", "code": "374"},
    {"country": "AL", "code": "355"},
    {"country": "AO", "code": "244"},
    {"country": "AQ", "code": ""},
    {"country": "AS", "code": "+1-684"},
    {"country": "AR", "code": "54"},
    {"country": "AU", "code": "61"},
    {"country": "AT", "code": "43"},
    {"country": "AW", "code": "297"},
    {"country": "IN", "code": "91"},
    {"country": "AX", "code": "+358-18"},
    {"country": "AZ", "code": "994"},
    {"country": "IE", "code": "353"},
    {"country": "ID", "code": "62"},
    {"country": "UA", "code": "380"},
    {"country": "QA", "code": "974"},
    {"country": "MZ", "code": "258"}
  ],
  identification_types: [
    {'name': 'National ID', 'value': 'national_id'},
    {'name': 'Passport', 'value': 'passport'},
    {'name': 'Birth Certificate', 'value': 'birth_cert'},
    {'name': 'Alien ID', 'value': 'alien_id'},
    {'name': 'Military ID', 'value': 'military_id'},
    {'name': 'NEMIS N.O', 'value': 'nemis'},
    {'name': 'Prisoner ID', 'value': 'prisoner_id'},
    {'name': 'Unknown Identity', 'value': 'unknown'}
  ],
  relationships_with_next_of_kin: [
    {name: "Mother"},
    {name: "Father"},
    {name: "Sibling"},
    {name: "Son"},
    {name: "Daughter"},
    {name: "Cousin"},
    {name: "Spouse"},
    {name: "Niece"},
    {name: "Nephew"},
    {name: "Grand Parent"},
    {name: "Grand Child"},
    {name: "Other"},
  ],
  regions: [],
  religions: [
    {'value': 'christianity', 'name': 'Christian'},
    {'value': 'islam', 'name': 'Muslim'},
    {'value': 'hinduism', 'name': 'Hindu'},
    {'value': 'indigenous', 'name': 'Traditionalist'},
    {'value': 'atheism', 'name': 'Atheist'},
    {'value': 'buddhism', 'name': 'Buddhist'},
    {'value': 'judaism', 'name': 'Jewish'},
    {'value': 'bahai', 'name': 'Bahai'},
  ],
  occupations: [],
  nationalities: [
    {"value": "AF", "name": "Afghanistan"},
    {"value": "AX", "name": "Åland Islands"},
    {"value": "AL", "name": "Albania"},
    {"value": "DZ", "name": "Algeria"},
    {"value": "AS", "name": "American Samoa"},
    {"value": "AD", "name": "Andorra"},
    {"value": "AO", "name": "Angola"},
    {"value": "AI", "name": "Anguilla"},
    {"value": "AQ", "name": "Antarctica"},
    {"value": "AG", "name": "Antigua and Barbuda"},
    {"value": "AR", "name": "Argentina"},
    {"value": "AM", "name": "Armenia"},
    {"value": "AW", "name": "Aruba"},
    {"value": "AU", "name": "Australia"},
    {"value": "AT", "name": "Austria"},
    {"value": "AZ", "name": "Azerbaijan"},
    {"value": "BS", "name": "Bahamas"},
    {"value": "BH", "name": "Bahrain"},
    {"value": "BD", "name": "Bangladesh"},
    {"value": "BB", "name": "Barbados"},
    {"value": "BY", "name": "Belarus"},
    {"value": "BE", "name": "Belgium"},
    {"value": "BZ", "name": "Belize"},
    {"value": "BJ", "name": "Benin"},
    {"value": "BM", "name": "Bermuda"},
    {"value": "BT", "name": "Bhutan"},
    {"value": "BO", "name": "Bolivia (Plurinational State of)"},
    {"value": "BQ", "name": "Bonaire, Sint Eustatius and Saba"},
    {"value": "BA", "name": "Bosnia and Herzegovina"},
    {"value": "BW", "name": "Botswana"},
    {"value": "BV", "name": "Bouvet Island"},
    {"value": "BR", "name": "Brazil"},
    {"value": "IO", "name": "British Indian Ocean Territory"},
    {"value": "BN", "name": "Brunei Darussalam"},
    {"value": "BG", "name": "Bulgaria"},
    {"value": "BF", "name": "Burkina Faso"},
    {"value": "BI", "name": "Burundi"},
    {"value": "CV", "name": "Cabo Verde"},
    {"value": "KH", "name": "Cambodia"},
    {"value": "CM", "name": "Cameroon"},
    {"value": "CA", "name": "Canada"},
    {"value": "KY", "name": "Cayman Islands"},
    {"value": "CF", "name": "Central African Republic"},
    {"value": "TD", "name": "Chad"},
    {"value": "CL", "name": "Chile"},
    {"value": "CN", "name": "China"},
    {"value": "CX", "name": "Christmas Island"},
    {"value": "CC", "name": "Cocos (Keeling) Islands"},
    {"value": "CO", "name": "Colombia"},
    {"value": "KM", "name": "Comoros"},
    {"value": "CG", "name": "Congo"},
    {"value": "CD", "name": "Congo (the Democratic Republic of the)"},
    {"value": "CK", "name": "Cook Islands"},
    {"value": "CR", "name": "Costa Rica"},
    {"value": "CI", "name": "Côte d'Ivoire"},
    {"value": "HR", "name": "Croatia"},
    {"value": "CU", "name": "Cuba"},
    {"value": "CW", "name": "Curaçao"},
    {"value": "CY", "name": "Cyprus"},
    {"value": "CZ", "name": "Czech Republic"},
    {"value": "DK", "name": "Denmark"},
    {"value": "DJ", "name": "Djibouti"},
    {"value": "DM", "name": "Dominica"},
    {"value": "DO", "name": "Dominican Republic"},
    {"value": "EC", "name": "Ecuador"},
    {"value": "EG", "name": "Egypt"},
    {"value": "SV", "name": "El Salvador"},
    {"value": "GQ", "name": "Equatorial Guinea"},
    {"value": "ER", "name": "Eritrea"},
    {"value": "EE", "name": "Estonia"},
    {"value": "SZ", "name": "Eswatini"},
    {"value": "ET", "name": "Ethiopia"},
    {"value": "FK", "name": "Falkland Islands (Malvinas)"},
    {"value": "FO", "name": "Faroe Islands"},
    {"value": "FJ", "name": "Fiji"},
    {"value": "FI", "name": "Finland"},
    {"value": "FR", "name": "France"},
    {"value": "GF", "name": "French Guiana"},
    {"value": "PF", "name": "French Polynesia"},
    {"value": "TF", "name": "French Southern Territories"},
    {"value": "GA", "name": "Gabon"},
    {"value": "GM", "name": "Gambia"},
    {"value": "GE", "name": "Georgia"},
    {"value": "DE", "name": "Germany"},
    {"value": "GH", "name": "Ghana"},
    {"value": "GI", "name": "Gibraltar"},
    {"value": "GR", "name": "Greece"},
    {"value": "GL", "name": "Greenland"},
    {"value": "GD", "name": "Grenada"},
    {"value": "GP", "name": "Guadeloupe"},
    {"value": "GU", "name": "Guam"},
    {"value": "GT", "name": "Guatemala"},
    {"value": "GG", "name": "Guernsey"},
    {"value": "GN", "name": "Guinea"},
    {"value": "GW", "name": "Guinea-Bissau"},
    {"value": "GY", "name": "Guyana"},
    {"value": "HT", "name": "Haiti"},
    {"value": "HM", "name": "Heard Island and McDonald Islands"},
    {"value": "VA", "name": "Holy See"},
    {"value": "HN", "name": "Honduras"},
    {"value": "HK", "name": "Hong Kong"},
    {"value": "HU", "name": "Hungary"},
    {"value": "IS", "name": "Iceland"},
    {"value": "IN", "name": "India"},
    {"value": "ID", "name": "Indonesia"},
    {"value": "IR", "name": "Iran (Islamic Republic of)"},
    {"value": "IQ", "name": "Iraq"},
    {"value": "IE", "name": "Ireland"},
    {"value": "IM", "name": "Isle of Man"},
    {"value": "IL", "name": "Israel"},
    {"value": "IT", "name": "Italy"},
    {"value": "JM", "name": "Jamaica"},
    {"value": "JP", "name": "Japan"},
    {"value": "JE", "name": "Jersey"},
    {"value": "JO", "name": "Jordan"},
    {"value": "KZ", "name": "Kazakhstan"},
    {"value": "KE", "name": "Kenya"},
    {"value": "KI", "name": "Kiribati"},
    {"value": "KP", "name": "North Korea (the Democratic People's Republic of Korea)"},
    {"value": "KR", "name": "South Korea (Republic of Korea )"},
    {"value": "KW", "name": "Kuwait"},
    {"value": "KG", "name": "Kyrgyzstan"},
    {"value": "LA", "name": "Lao People's Democratic Republic"},
    {"value": "LV", "name": "Latvia"},
    {"value": "LB", "name": "Lebanon"},
    {"value": "LS", "name": "Lesotho"},
    {"value": "LR", "name": "Liberia"},
    {"value": "LY", "name": "Libya"},
    {"value": "LI", "name": "Liechtenstein"},
    {"value": "LT", "name": "Lithuania"},
    {"value": "LU", "name": "Luxembourg"},
    {"value": "MO", "name": "Macao"},
    {"value": "MG", "name": "Madagascar"},
    {"value": "MW", "name": "Malawi"},
    {"value": "MY", "name": "Malaysia"},
    {"value": "MV", "name": "Maldives"},
    {"value": "ML", "name": "Mali"},
    {"value": "MT", "name": "Malta"},
    {"value": "MH", "name": "Marshall Islands"},
    {"value": "MQ", "name": "Martinique"},
    {"value": "MR", "name": "Mauritania"},
    {"value": "MU", "name": "Mauritius"},
    {"value": "YT", "name": "Mayotte"},
    {"value": "MX", "name": "Mexico"},
    {"value": "FM", "name": "Micronesia (Federated States of)"},
    {"value": "MD", "name": "Moldova (the Republic of)"},
    {"value": "MC", "name": "Monaco"},
    {"value": "MN", "name": "Mongolia"},
    {"value": "ME", "name": "Montenegro"},
    {"value": "MS", "name": "Montserrat"},
    {"value": "MA", "name": "Morocco"},
    {"value": "MZ", "name": "Mozambique"},
    {"value": "MM", "name": "Myanmar"},
    {"value": "NA", "name": "Namibia"},
    {"value": "NR", "name": "Nauru"},
    {'value': "NP", 'name': "Nepal"},
    {'value': "NL", 'name': "Netherlands"},
    {'value': "NC", 'name': "New Caledonia"},
    {'value': "NZ", 'name': "New Zealand"},
    {'value': "NI", 'name': "Nicaragua"},
    {'value': "NE", 'name': "Niger"},
    {'value': "NG", 'name': "Nigeria"},
    {'value': "NU", 'name': "Niue"},
    {'value': "NF", 'name': "Norfolk Island"},
    {'value': "MK", 'name': "North Macedonia"},
    {'value': "MP", 'name': "Northern Mariana Islands"},
    {'value': "NO", 'name': "Norway"},
    {'value': "OM", 'name': "Oman"},
    {'value': "PK", 'name': "Pakistan"},
    {'value': "PW", 'name': "Palau"},
    {'value': "PS", 'name': "Palestine, State of"},
    {'value': "PA", 'name': "Panama"},
    {'value': "PG", 'name': "Papua New Guinea"},
    {'value': "PY", 'name': "Paraguay"},
    {'value': "PE", 'name': "Peru"},
    {'value': "PH", 'name': "Philippines"},
    {'value': "PN", 'name': "Pitcairn"},
    {'value': "PL", 'name': "Poland"},
    {'value': "PT", 'name': "Portugal"},
    {'value': "PR", 'name': "Puerto Rico"},
    {'value': "QA", 'name': "Qatar"},
    {'value': "RE", 'name': "Réunion"},
    {'value': "RO", 'name': "Romania"},
    {'value': "RU", 'name': "Russian Federation"},
    {'value': "RW", 'name': "Rwanda"},
    {'value': "BL", 'name': "Saint Barthélemy"},
    {'value': "SH", 'name': "Saint Helena, Ascension and Tristan da Cunha"},
    {'value': "KN", 'name': "Saint Kitts and Nevis"},
    {'value': "LC", 'name': "Saint Lucia"},
    {'value': "MF", 'name': "Saint Martin (French part)"},
    {'value': "PM", 'name': "Saint Pierre and Miquelon"},
    {'value': "VC", 'name': "Saint Vincent and the Grenadines"},
    {'value': "WS", 'name': "Samoa"},
    {'value': "SM", 'name': "San Marino"},
    {'value': "ST", 'name': "Sao Tome and Principe"},
    {'value': "SA", 'name': "Saudi Arabia"},
    {'value': "SN", 'name': "Senegal"},
    {'value': "RS", 'name': "Serbia"},
    {'value': "SC", 'name': "Seychelles"},
    {'value': "SL", 'name': "Sierra Leone"},
    {'value': "SG", 'name': "Singapore"},
    {'value': "SX", 'name': "Sint Maarten (Dutch part)"},
    {'value': "SK", 'name': "Slovakia"},
    {'value': "SI", 'name': "Slovenia"},
    {'value': "SB", 'name': "Solomon Islands"},
    {'value': "SO", 'name': "Somalia"},
    {'value': "ZA", 'name': "South Africa"},
    {'value': "GS", 'name': "South Georgia and the South Sandwich Islands"},
    {'value': "SS", 'name': "South Sudan"},
    {'value': "ES", 'name': "Spain"},
    {'value': "LK", 'name': "Sri Lanka"},
    {'value': "SD", 'name': "Sudan"},
    {'value': "SR", 'name': "Suriname"},
    {'value': "SJ", 'name': "Svalbard and Jan Mayen"},
    {'value': "SE", 'name': "Sweden"},
    {'value': "CH", 'name': "Switzerland"},
    {'value': "SY", 'name': "Syrian Arab Republic"},
    {'value': "TW", 'name': "Taiwan (Province of China)"},
    {'value': "TJ", 'name': "Tajikistan"},
    {'value': "TZ", 'name': "Tanzania, the United Republic of"},
    {'value': "TH", 'name': "Thailand"},
    {'value': "TL", 'name': "Timor-Leste"},
    {'value': "TG", 'name': "Togo"},
    {'value': "TK", 'name': "Tokelau"},
    {'value': "TO", 'name': "Tonga"},
    {'value': "TT", 'name': "Trinidad and Tobago"},
    {'value': "TN", 'name': "Tunisia"},
    {'value': "TR", 'name': "Turkey"},
    {'value': "TM", 'name': "Turkmenistan"},
    {'value': "TC", 'name': "Turks and Caicos Islands"},
    {'value': "TV", 'name': "Tuvalu"},
    {'value': "UG", 'name': "Uganda"},
    {'value': "UA", 'name': "Ukraine"},
    {'value': "AE", 'name': "United Arab Emirates"},
    {'value': "GB", 'name': "United Kingdom of Great Britain and Northern Ireland"},
    {'value': "UM", 'name': "United States Minor Outlying Islands"},
    {'value': "US", 'name': "United States of America"},
    {'value': "UY", 'name': "Uruguay"},
    {'value': "UZ", 'name': "Uzbekistan"},
    {'value': "VU", 'name': "Vanuatu"},
    {'value': "VE", 'name': "Venezuela (Bolivarian Republic of)"},
    {'value': "VN", 'name': "Viet Nam"},
    {'value': "VG", 'name': "Virgin Islands (British)"},
    {'value': "VI", 'name': "Virgin Islands (U.S.)"},
    {'value': "WF", 'name': "Wallis and Futuna"},
    {'value': "EH", 'name': "Western Sahara"},
    {'value': "YE", 'name': "Yemen"},
    {'value': "ZM", 'name': "Zambia"},
    {'value': "ZW", 'name': "Zimbabwe"}
  ],
  allergies: [],
  preexisting_conditions: [],
  hw_categories: [
    {name: 'Dental staff', value: 'dental_staff'},
    {name: 'Laboratory staff', value: 'laboratory_staff'},
    {name: 'Medical practitioners', value: 'medical_practitioners'},
    {name: 'Midwives', value: 'midwives'},
    {name: 'Non surgical specialists', value: 'non_surgical_specialists'},
    {name: 'Surgical specialists', value: 'surgical_specialists'},
    {name: 'Nurses', value: 'nurses'},
    {name: 'Pharmacy staff', value: 'pharmacy_staff'},
    {name: 'Radiology staff', value: 'radiology_staff'},
    {name: 'Environmental health staff', value: 'environmental_health_staff'},
    {name: 'Community staff', value: 'community_staff'},
    {name: 'Rehabilitation Specialists', value: 'rehabilitation_specialists'},
    {name: 'Management staff', value: 'management_staff'},
    {name: 'Administrative staff', value: 'administrative_staff'},
    {name: 'General support staff', value: 'general_support_staff'},
    {name: 'Public Health Practitioners', value: 'public_health_practitioners'},
  ],
  disability_categories: [
    {'value': 1, 'name': 'Physical Disabilities'},
    {'value': 2, 'name': 'Hearing disabilities'},
    {'value': 3, 'name': 'Visual Disabilities'},
    {'value': 4, 'name': 'Deaf/Sign language'},
    {'value': 5, 'name': 'Deaf/Able to talk'},
    {'value': 6, 'name': 'Mental Disabilities'},
    {'value': 7, 'name': 'Epilepsy'},
    {'value': 8, 'name': 'Albinism'},
    {'value': 9, 'name': 'Short Stature'},
    {'value': 10, 'name': 'Other'},
  ]
}
const getters = {
  allIdentificationTypes(state) {
    return state.identification_types
  },
  allRelationshipsWithNextOfKin(state) {
    return state.relationships_with_next_of_kin
  },
  allRegions(state) {
    return state.regions
  },
  allCounties(state) {
    return state.counties
  },
  allSubCounties(state) {
    return state.sub_counties
  },
  allWards(state) {
    return state.wards
  },
  allReligions(state) {
    return state.religions
  },
  allOccupations(state) {
    return state.occupations
  },
  allNationalities(state) {
    return state.nationalities
  },
  allPhoneCodes(state) {
    return state.phone_codes
  },
  allAllergies(state) {
    return state.allergies
  },
  allPreexistingConditions(state) {
    return state.preexisting_conditions
  },
  allHWCategories(state) {
    return state.hw_categories
  },
  allDisabilityCategories(state){
    return state.disability_categories
  }
}

const actions = {
  fetchAllLevels({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/counties/").then(resp => {
        commit("setCounties", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  fetchCounties({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/counties/").then(resp => {
        commit("setCounties", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchSubCounties({commit}, payload = '') {
    return new Promise(((resolve, reject) => {
      api.get(`/sub-counties${payload}`).then(resp => {
        commit("setSubCounties", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  // eslint-disable-next-line no-unused-vars
  fetchWards({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get(`/wards/${payload}`).then(resp => {
        commit("setWards", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fetchOccupations({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/occupation/").then(resp => {
        commit("setOccupations", resp.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    }))
  },
  fetchAllergies({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/allergy/").then(resp => {
        commit("setAllergies", resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  fetchPreexistingConditions({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/preexisting-condition/").then(resp => {
        commit("setPreexistingConditions", resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  fetchRegions({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/regions/").then(resp => {
        commit("setRegions", resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  // eslint-disable-next-line no-unused-vars
  fetchCounty({commit}, payload) {
    return new Promise(((resolve, reject) => {
      api.get("/counties/" + payload + '/').then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
  // eslint-disable-next-line no-unused-vars
  fetchSubCounty({commit}, payload) {
    return new Promise(((resolve, reject) => {
      api.get("/sub-counties/" + payload + '/').then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
}

const mutations = {
  setRegions: (state, regions) => (state.regions = regions),
  setCounties: (state, counties) => (state.counties = counties),
  setSubCounties: (state, sub_counties) => (state.sub_counties = sub_counties),
  setWards: (state, wards) => (state.wards = wards),
  setOccupations: (state, occupations) => (state.occupations = occupations),
  setNationalities: (state, nationalities) => (state.nationalities = nationalities),
  setAllergies: (state, allergies) => (state.allergies = allergies),
  setPreexistingConditions: (state, preexisting_conditions) => (state.preexisting_conditions = preexisting_conditions),
}

export default {
  state,
  getters,
  actions,
  mutations,
}