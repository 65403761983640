<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Centers"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div class="container-fluid">
        <centers_list></centers_list>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import centers_list from "@/components/distribution_centers/centers_list.vue";
export default {
  name:"DistributionCenters",
  components: {centers_list, Breadcrumbs}
}
</script>


