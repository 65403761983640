import api from "@/api";
import axios from "axios";
import {otp_request_url, reset_password_url, unauthenticated_otp_request_url} from "@/utils/constants";


const state = {
  system_users: [],
  user_groups: [],
  system_user_details: {},
  user_being_updated: {},
  vaccinators: [],
  users_csv: [],
  user_actions: []
}
const getters = {
  allSystemUsers(state) {
    return state.system_users
  },
  getVaccinators(state) {
    return state.vaccinators
  },
  allSystemUserGroups(state) {
    return state.user_groups;
  },
  systemUserDetails(state) {
    return state.system_user_details
  },
  getUserBeingUpdated(state) {
    return state.user_being_updated;
  },
  allUsersCSV(state){
    return state.users_csv
  },
  getUserActions(state){
    return state.user_actions
  }
}
const actions = {
  fetchSystemUsers({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/users/").then(resp => {
        commit("setSystemUsers", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchVaccinators({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/my-vaccinators/").then(resp => {
        commit("setVaccinators", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchSystemUsersByParams({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/users/" + payload).then(resp => {
        commit("setSystemUsers", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchSingleSystemUser({commit}, user_id) {
    return new Promise((resolve, reject) => {
      api.get("/users/" + user_id + '/').then(resp => {
        commit("setUserBeingUpdated", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchSystemUserGroups({commit}) {
    return new Promise((resolve, reject) => {
      api.get("/user-groups/").then(resp => {
        commit("setSystemUserGroups", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  createSystemUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/users/', method: 'POST', data: payload
      }).then((resp) => {
        commit('createSystemUser', resp.data)
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        console.log(err)
        reject(err)
      })
    })
    // console.log(payload)
  },
  updateSystemUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      api({
        url: '/users/' + payload.id + '/', method: 'PATCH', data: payload.data
      }).then((resp) => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
    // console.log(payload)
  },
  DeactivateSystemUser({commit}, user_id) {
    return new Promise((resolve, reject) => {
      api({
        url: '/users/' + user_id + '/', method: 'DELETE'
      }).then((resp) => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
    // console.log(payload)
  },
  ActivateSystemUser({commit}, user_id) {
    return new Promise((resolve, reject) => {
      api({
        url: `/users/${user_id}/activate/`, method: 'POST', data: {}
      }).then((resp) => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
    // console.log(payload)
  },
  requestOTP({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit('set_error', {})
      axios({url: otp_request_url, data: payload, method: 'POST'})
        .then(resp => {
          resolve(resp.data)
        }).catch(err => {
        if (err.response.status === 500) {
          commit('set_error', {"message": "Phone Number provided at registration was not valid"})
          console.log("500")
          reject(err)
        } else {
          commit('set_error', err.response.data)
          console.log(err)
          reject(err)
        }

      })
    })
    // console.log(payload)
  },
  resetUserPassword({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit('set_error', {})
      axios({url: reset_password_url, data: payload, method: 'POST'})
        .then(resp => {
          resolve(resp.data)
        }).catch(err => {
        if (err.response.status === 500) {
          commit('set_error', {"message": "An Error Occurred"})
          console.log("500")
          reject(err)
        } else {
          commit('set_error', err.response.data)
          console.log(err)
          reject(err)
        }

      })
    })
    // console.log(payload)
  },
  requestOTPUnauthenticated({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit('set_error', {})
      axios({url: unauthenticated_otp_request_url, data: payload, method: 'POST'})
        .then(resp => {
          resolve(resp.data)
        }).catch(err => {
        if (err.response.status === 500) {
          commit('set_error', {"message": "Phone Number provided at registration was not valid"})
          console.log("500")
          reject(err)
        } else {
          commit('set_error', err.response.data)
          console.log(err)
          reject(err)
        }

      })
    })
    // console.log(payload)
  },
  fetchUsersCSV({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/user-details-csv" + payload).then(resp => {
        commit("setUsersCSV", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchSystemUserDetails({commit}, url) {
    return new Promise((resolve, reject,) => {
      api.get(url).then(resp => {
        commit("setSystemUserDetails", resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  clearSystemUsers({commit}) {
    commit('setSystemUsers', {})
  },
  fetchUserActions({commit}, payload){
    return new Promise((resolve, reject) => {
      api.get("/users/" + payload.user_id + '/actions/' + payload.params).then(resp => {
        commit("setUserActions", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
  setNewSystemUser: (state, user) => (state.system_users.push(user)),
  setSystemUsers: (state, system_users) => (state.system_users = system_users),
  setVaccinators: (state, vaccinators) => (state.vaccinators = vaccinators),
  setUserBeingUpdated: (state, user) => (state.user_being_updated = user),
  setSystemUserGroups: (state, user_groups) => (state.user_groups = user_groups),
  setSystemUserDetails: (state, system_user_details) => (state.system_user_details = system_user_details),
  setUsersCSV: (state, users_csv) => (state.users_csv = users_csv),
  setUserActions: (state, user_actions) => (state.user_actions = user_actions)
}

export default {
  state,
  getters,
  actions,
  mutations,
}