<template>
  <div>
    <div v-if="currentRouteName === 'deactivated_users'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Deactivated Users"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <search_system_user class="mb-3"></search_system_user>
        <search_user_results
            v-if="Object.keys(allSystemUsers).length !== 0 && allSystemUsers.length !==0"></search_user_results>

      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import search_system_user from "@/components/support/search_system_user";
import search_user_results from "@/components/support/search_user_results";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DeactivatedUsers",
  components: {search_user_results, Breadcrumbs, search_system_user},
  data(){
    return {
     is_downloading: false,
     county: '',
     downloading_csv: false
    }
  },
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getLoggedInUserCounty(){
      var subcounty = ''

      this.$store.dispatch('fetchSubCounty', this.centerInfo.sub_county).then(resp => {
        subcounty = resp

        this.$store.dispatch('fetchCounty', subcounty.county).then(resp => {
          this.county = resp
        })
      })
    },
  },
  computed: {
    ...mapGetters(['personDetails', 'allSystemUsers', 'centerInfo']),
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.clearErrors()
    this.getLoggedInUserCounty()
  }
}
</script>
