<template>
    <div class="auth-box error-box">
        <!-- Start row -->
        <div class="row no-gutters align-items-center justify-content-center">
            <!-- Start col -->
            <div class="col-12">
                <div class="text-center">
                    <img src="@/assets/images/logo.png" class="img-fluid error-logo" alt="logo">
                    <img src="@/assets/images/error/404.svg" class="img-fluid error-image" alt="404">
                    <h4 class="error-subtitle mb-4">Oops! Page not Found</h4>
                    <p class="mb-4">We did not find the page you are looking for. Please return to previous page or
                        visit home page. </p>
                    <router-link to="/" class="btn btn-info font-16"><i class="feather icon-home mr-2"></i> Go back
                        to Dashboard</router-link>
                </div>
            </div>
            <!-- End col -->
        </div>
        <!-- End row -->
    </div>
</template>

<script>
    import $ from 'jquery'
    export default {
        name: "error404",
        methods: {
            setLayout() {
                this.$store.commit('SET_LAYOUT', 'outside-dashboard')
            },
            change_bg(){
                $("div.card").css("background-color", "#f2f5fa")
            }
        },
        created() {
            this.setLayout()
        },
        mounted() {
            this.change_bg()
        }
    }
</script>

<style>

</style>