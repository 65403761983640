import api from "@/api";


const state ={

  vaccines :[
    {
      "id": 2,
      "name": "Sputnik-V",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 3,
          "dose_number": 1,
          "days_to_next": 21
        },
        {
          "id": 4,
          "dose_number": 2,
          "days_to_next": null
        }
      ],
      "manufacturer": "Russia",
      "vendor_type": "private",
      "vaccine_brands": []
    },
    {
      "id": 3,
      "name": "Moderna",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 5,
          "dose_number": 1,
          "days_to_next": 28
        },
        {
          "id": 6,
          "dose_number": 2,
          "days_to_next": null
        }
      ],
      "manufacturer": "Moderna",
      "vendor_type": "public",
      "vaccine_brands": [
        3
      ]
    },
    {
      "id": 4,
      "name": "Janssen (Ad26.COV2-S)",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 7,
          "dose_number": 1,
          "days_to_next": null
        }
      ],
      "manufacturer": "Janssen – Cilag International",
      "vendor_type": "public",
      "vaccine_brands": [
        5
      ]
    },
    {
      "id": 5,
      "name": "Pfizer",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 8,
          "dose_number": 1,
          "days_to_next": 28
        },
        {
          "id": 9,
          "dose_number": 2,
          "days_to_next": null
        }
      ],
      "manufacturer": "BioNTech",
      "vendor_type": "public",
      "vaccine_brands": [
        6
      ]
    },
    {
      "id": 6,
      "name": "Sinopharm SARS-CoV-2",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 10,
          "dose_number": 1,
          "days_to_next": null
        }
      ],
      "manufacturer": "Beijing Institute",
      "vendor_type": "public",
      "vaccine_brands": [
        7
      ]
    },
    {
      "id": 1,
      "name": "Oxford/AstraZeneca",
      "disease": {
        "id": 1,
        "name": "COVID-19",
        "condition_risks": [
          {
            "condition": 1,
            "risk": 3
          },
          {
            "condition": 2,
            "risk": 3
          },
          {
            "condition": 3,
            "risk": 3
          },
          {
            "condition": 4,
            "risk": 3
          },
          {
            "condition": 5,
            "risk": 3
          },
          {
            "condition": 6,
            "risk": 3
          },
          {
            "condition": 7,
            "risk": 3
          },
          {
            "condition": 8,
            "risk": 3
          },
          {
            "condition": 9,
            "risk": 3
          },
          {
            "condition": 10,
            "risk": 3
          },
          {
            "condition": 11,
            "risk": 3
          }
        ],
        "occupation_risks": [
          {
            "occupation": 1,
            "risk": 3
          },
          {
            "occupation": 2,
            "risk": 3
          },
          {
            "occupation": 3,
            "risk": 3
          },
          {
            "occupation": 4,
            "risk": 1
          }
        ],
        "age_risks": [
          {
            "risk": 3,
            "min_age": 55
          }
        ]
      },
      "allergies": [],
      "doses": [
        {
          "id": 1,
          "dose_number": 1,
          "days_to_next": 56
        },
        {
          "id": 2,
          "dose_number": 2,
          "days_to_next": null
        }
      ],
      "manufacturer": "Oxford/AstraZeneca",
      "vendor_type": "public",
      "vaccine_brands": [
        1,
        2,
        4
      ]
    }
  ],
  vaccine_brands: [],
  diseases :[],
  vaccine_with_id: [],
  batch_with_id: {}
}

const getters ={
  allVaccines(state){
    return state.vaccines
  },
  allVaccineBrands(state){
    return state.vaccine_brands
  },
  allDiseases(state){
    return state.diseases
  },
  getVaccineWithID(state){
    return state.vaccine_with_id
  }
}

const actions ={
  fetchDiseases ({commit}){
    return new Promise((resolve, reject) => {
      api.get('/vaccinatable-disease/').then(resp =>{
        commit('setDiseases', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err)
        reject(err)
      })
    });
  },
  fetchVaccines ({commit}){
    return new Promise((resolve, reject) => {
      api.get('/vaccine/').then(resp =>{
        commit('setVaccines', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err)
        reject(err)
      })
    });
  },
  fetchVaccineBrands ({commit}){
    return new Promise((resolve, reject) => {
      api.get('/vaccine_brand/').then(resp =>{
        commit('setVaccineBrands', resp.data)
        resolve(resp.data)
      }).catch(err=>{
        commit('set_error', err)
        reject(err)
      })
    });
  },
  fetchVaccineByID({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get("/vaccine/" + payload + "/").then(resp => {
        commit("setVaccineWithID", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  // eslint-disable-next-line no-unused-vars
  fetchVaccineDose({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get(`/vaccine-dose/${payload}`).then(resp => {
        // commit("setVaccineWithID", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
  fetchBatchByID({commit},payload) {
    return new Promise((resolve, reject) => {
      api.get("/vaccine-batch/" + payload + "/").then(resp => {
        commit("setBatchWithID", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    });
  },
}
const mutations ={
  setVaccines:(state, vaccines) => (state.vaccines = vaccines),
  setVaccineBrands:(state, vaccine_brands) => (state.vaccine_brands = vaccine_brands),
  setDiseases:(state, diseases) => (state.diseases = diseases),
  setVaccineWithID:(state, vaccine_with_id) => (state.vaccine_with_id = vaccine_with_id),
  setBatchWithID:(state, batch_with_id) => (state.batch_with_id = batch_with_id)

}

export default {
  state,
  getters,
  actions,
  mutations,
}