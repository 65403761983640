<template>
  <div>
    <div class="card">
      <div class="card-body align-items-start">
        <div class="">
          <div v-if="loading">
            <submitting-loader >

            </submitting-loader>
          </div>
          <div v-else class="breadcrumb-list">
            <ol  class="breadcrumb">
              <li v-for="(level, index) in level_breadcrumb" @click="navigateBackToLevel(index)"
                  class="breadcrumb-item text-capitalize pointer" :key="index" style="cursor: pointer">
                {{ level.name }}
<!--                <span v-if="index !== (level_breadcrumb.length-1)"> <i class="feather icon-chevrons-right"></i> </span>-->
              </li>
            </ol>
          </div>
        </div>

        <div class="border-0 border-bottom row text-white " style="background: #263a5b">
          <div class="col-2 border ">
            <p>Facility</p>
          </div>
          <div class="col-sm">
            <div class="row">
              <div class="col-3 border">
                <p>Vaccine</p>
              </div>
              <p v-for="(dose_count, dose_index) in dose_list" :key="dose_index" class="col-sm">Dose
                {{ dose_count }}</p>
              <div class="col-sm ">
                Doses Issued
              </div>
            </div>

          </div>
          <div class="col-1">
            Actions
          </div>
        </div>
        <div v-if="loading">
          <submitting-loader >

          </submitting-loader>
        </div>
        <div v-else>
          <div v-for="(facility, facility_index) in Object.keys(vaccine_utilization_stats)" :key="facility_index"
               class="row pt-2 border-bottom" style="border-bottom: 3px solid #263a5b !important;">
            <div class="col-2 ">
              <p>{{ facility }}</p>
            </div>
            <div class="col-sm  border-bottom" style="border-color: #0b2e13; border-width: 12px">
              <!-- Loop through vaccines -->
              <div v-for="(vaccine, index) in Object.keys(vaccine_utilization_stats[facility]['stats'])" :key="index"
                   class="">
                <!--              adasda {{vaccine_utilization_stats[facility]['stats'][vaccine]}}-->
                <div v-for="(dose, index) in vaccine_utilization_stats[facility]['stats'][vaccine][0]"
                     :key="index" class="row border-0 border-bottom " style="border-color: #1b1e2173 !important;">
                  <!--                sdfsf-->
                  <span class="col-3">{{ vaccine }}</span>
                  <p v-for="(dose_count, dose_index) in dose_list" :key="dose_index" class="col-sm">
                    <span v-if="dose[dose_index] !== undefined">{{ dose[dose_index].number_administered }}</span>
                    <span v-else>0</span>
                  </p>
                  <div class="col-sm">
                    <p v-if="vaccine_utilization_stats[facility]['stats'][vaccine][1].stock.good_quantity__sum !== null">
                      {{ vaccine_utilization_stats[facility]['stats'][vaccine][1].stock.good_quantity__sum }}
                    </p>
                    <p v-else>0</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-1">
              <button  class="btn btn-primary" @click="viewLevelStats(vaccine_utilization_stats[facility].id, facility)">
                View <i class="feather icon-arrow-right"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import SubmittingLoader from "../layouts/includes/SubmittingLoader";

export default {
  name: "vaccine_utilization_report",
  components: {SubmittingLoader},
  data() {
    return {
      vaccine_utilization_stats: {},
      vaccines_list: [],
      dose_list: [],
      max_dose_count: 0,
      filter_level: '',
      filter_id: '',
      level_breadcrumb: [],
      loading: false
    }
  },
  methods: {
    // removeSubsequentLevels(levelIndex){
    //
    // },
    navigateBackToLevel(levelIndex){
      //todo: add loader
      console.log("index", levelIndex)
      //root level cannot be removed, do nothing
      if(this.level_breadcrumb.length >1){
        this.level_breadcrumb.splice(levelIndex+1, this.level_breadcrumb.length-1)
        let bc_length = this.level_breadcrumb.length-1
        this.filter_level = this.level_breadcrumb[bc_length].level
        this.filter_id = this.level_breadcrumb[bc_length].level_id
        this.getVaccineUtilizationStats()
      }
    },
    viewLevelStats(levelId, current_level_name) {
      let item_in_breadcrumb_list= false
      //check if item exists in list
      this.level_breadcrumb.map(breadcrumb_item=>{
        if(breadcrumb_item.level_id === levelId){
          item_in_breadcrumb_list = true
        }
      })
      //prevent duplicate items
      if(!item_in_breadcrumb_list) {
        this.filter_level = this.getNextLevel(this.filter_level)
        this.level_breadcrumb.push({'level': this.filter_level, 'name': current_level_name, 'level_id': levelId})
        this.filter_id = levelId
      }

      this.getVaccineUtilizationStats()
    },
    getNextLevel(level) {
      switch (level) {
        case 'national':
          return 'region'
        case 'regional':
          return 'county'
        case 'region':
          return 'county'
        case 'county':
          return 'subcounty'
        case 'subcounty':
          return 'facility'
      }
    },
    getVaccineUtilizationStats() {
      this.loading = true
      let filter = this.filter_id === '' ? '' : '?' + this.filter_level + '=' + this.filter_id
      // console.log("getting stats", filter)
      this.$store.dispatch('fetchVaccineUtilizationStats', filter).then(resp => {
        this.vaccine_utilization_stats = resp
        this.loading=false
      }).catch(err => {
        this.loading=false
        this.$store.dispatch('setError', err)
      })
    },
    async getVaccines() {
      this.vaccines_list = await this.allVaccines
      // select vaccines with the most number of doses
      let current_max_dose_count = 0
      this.vaccines_list.map(vaccine => {
        let current_length = vaccine.doses.length
        if (current_length > current_max_dose_count) {
          this.max_dose_count = current_length
          current_max_dose_count = current_length
        }
      })
      this.max_dose_count = current_max_dose_count
      //create an array with length 10 to allow us to loop
      this.dose_list = Array(this.max_dose_count).fill(1).map((x, y) => x + y)
    },

  },
  computed: {
    ...mapGetters(['allVaccines', 'centerInfo']),
  },
  mounted() {
    this.filter_level = this.centerInfo.level
    this.level_breadcrumb.push({'level':this.centerInfo.level, 'name': this.centerInfo.level, 'level_id':''})
    this.getVaccineUtilizationStats();
    this.getVaccines()
  }
}
</script>

<style scoped>

</style>