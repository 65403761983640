<template>
  <div>
    <update_stock_warning v-if="!getReportInfo.is_upto_date">
    </update_stock_warning>
    <default_modal aria_label="Set facility" modal_header="Select Vaccinating facility" modal_id="set_facility_modal" modal_size="modal-lg">
      <LocationFilter @updatedFacilityFilter="updatedFacilityFilter" size="col-md-3"/>
      <template v-slot:modal_footer>
        <button class="btn btn-primary" @click="closeFacilityModal">
          Select Facility
        </button>
      </template>
    </default_modal>
    <default_modal v-if="Object.keys(batch_object).length !== 0" aria_label="Confirm Vaccine" modal_header="Confirm Vaccination" modal_id="confirm_vaccine_modal">
      <h4 class="text-center">Administer dose {{ $route.params.dose }}  of <span class="text-primary">{{ batch_object.vaccine.name }} </span> to  {{ personDetails.first_name }} {{ personDetails.middle_name }}
        {{ personDetails.last_name }} on {{new Date(date_administered).toDateString()}}</h4>
      <template v-slot:modal_footer>
        <button v-if="!is_loading" class="btn btn-primary" @click="administerVaccine">
          Vaccinate
          <i class="feather icon-arrow-right"></i>
        </button>
        <div v-else>
          <SubmittingLoader/>
        </div>
      </template>
    </default_modal>
    <div class="card">
      <div class="card-body">
        <div v-if="is_loading" class="offset-5">
          <p class="loader"></p>
          <p>Loading</p>
        </div>
        <div v-else>
          <div v-if="vaccinating_batches.length === 0">
            <h4 class="text-danger">
              Receive doses in your facility to enable vaccination
            </h4>
          </div>

          <div>
            <h4>Administer Dose: {{ $route.params.dose }} </h4>
            <span v-if="$func.calculateAge(personDetails.date_of_birth) < 18" class="text-danger">
              NB: Clients below 18 can only receive pfizer vaccine
            </span>
            <!--          <div v-if="has_reported_all_dates">-->
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="vaccine-filter" class="control-label">
                    Select Vaccine
                  </label>
                  <select v-model="vaccine" class="form-control" id="vaccine-filter" required>
                    <option v-for="(vaccine, index) in vaccinesInFacility" v-bind:key="index" :value="vaccine.id">{{ vaccine.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label class="control-label" for="batch">
                    Batch
                    <span class="text-danger">*</span>
                  </label>
                  <select id="batch" v-model="batch" :class="'form-control ' + checkIfErrorExists('batch')" required>
                    <option
                        v-for="(batch, index) in batchesForVaccine"
                        :key="index" :value="batch.batch.id">
                      Batch {{ batch.batch.batch_number }} {{ batch.batch.vaccine.name }} In Stock
                      {{ batch.good_quantity - batch.used_quantity }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="boosterIsEligible">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="control-label" for="date_administered">
                      Date administered
                      <span class="text-danger">*</span>
                    </label>
                    <input id="date_administered" v-model="date_administered" :class="'form-control ' + valid_date"
                           required type="datetime-local">
                    <div class="invalid-feedback">
                <span v-if="valid_date === 'is-invalid'">
                  {{invalid_date_error}}
                </span>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label" for="vaccinator">
                      Vaccinator
                      <span class="text-danger">*</span>
                    </label>
                    <select id="vaccinator" v-model="vaccinator" class="form-control" required>
                      <option v-for="(vaccinator, index) in vaccinators" :key="index" :value="vaccinator.id">
                        {{ vaccinator.username }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="input-group col-md-12 mb-3">
                    <select id="facility" v-model="facility" class="form-control" required>
                      <option v-for="(facility, index) in facilities" :key="index" :value="facility.id">
                        {{ facility.name }}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text btn btn-primary" @click="selectFacilityModal" id="basic-addon2">Select Different Facility</span>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label" for="temperature">
                      Current Body Temperature
                      <span class="text-danger">*</span>
                    </label>
                    <input id="temperature" v-model="temperature" class="form-control" required type="text">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group form-check">
                    <input id="consent" v-model="consent" class="form-check-input" type="checkbox">
                    <label class="form-check-label" for="consent">Has given consent</label>
                  </div>
                </div>
                <div v-if="!consent" class="form-row">
                  <div class="form-group col-md-6">
                    <label for="noConsentReason">Give Reason</label>
                    <textarea id="noConsentReason" class="form-control" rows="3"></textarea>
                  </div>
                </div>
                <div v-if="personDetails.gender === 'F'" class="row mb-3">
                  <div class="col-md-12">
                    <h5>Select Preexisting Conditions if any</h5>
                    <div class="form-check form-check-inline mb-3" v-for="(condition, index) in contextualConditions" :key="index">
                      <input v-model="conditions[index]" class="form-check-input" type="checkbox" value="" id="condition">
                      <label class="form-check-label" for="condition">
                        {{ condition.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <button v-if="temperature < 38 && show_submit" class="btn btn-primary" type="button" @click="openConfirmVaccinationModal">
                  Administer Vaccine
                  <i class="feather icon-arrow-right"></i>
                </button>
                <h4 v-if="temperature > 38" class="text-danger">
                  Temperature is too high to administer vaccine
                </h4>
              </div>
              <div v-else>
                <span class="text-danger">
                    {{ boosterIneligibleMessage }}
                  </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
.
<script>

import {mapActions, mapGetters} from "vuex";
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import $ from "jquery";
import update_stock_warning from "@/components/modal/update_stock_warning";
import LocationFilter from "@/components/filters/LocationFilter";

export default {
  name: "administration_form",
  components: {LocationFilter, update_stock_warning, default_modal, SubmittingLoader},
  data: function () {
    return {
      boosterIsEligible: true,
      boosterIneligibleMessage: '',
      person_id: '',
      batch: '',
      batch_object: {},
      dose: '',
      facility: '',
      facilities: [],
      vaccinating_batches: [],
      date_administered: '',
      vaccinator: '',
      vaccinators: [],
      temperature: '',
      date_scheduled: '',
      consent: true,
      is_loading: false,
      valid_date: '',
      show_submit: true,
      last_report_date: '',
      has_reported_all_dates : false,
      expired_batch: false,
      batch_expiry_date: null,
      invalid_date_error: '',
      vaccine: '',
      vaccinesInFacility: [],
      conditions: [],
      daysToBooster: '',
    }
  },
  computed: {
    ...mapGetters(['personDetails', 'centerInfo', 'allVaccinationBatches', 'getUser',
      'getError', 'allSystemUsers', 'getVaccinators', 'getReportInfo', 'personVaccinations', 'allPreexistingConditions',]),
    formattedConditions: function (){
      var results = []

      this.conditions.forEach((condition, index) => {
        if(condition){
          results.push(this.contextualConditions[index].id)
        }
      })

      return results
    },
    contextualConditions: function () {
      // get gender
      return this.allPreexistingConditions.filter(item => {
        return item.is_contextual
      })
    },
    getScheduleForDose: function () {
      if(this.personVaccinations.length > 0 && this.$route.params.dose !== 'Booster'){
        return this.personVaccinations[0].scheduled_doses.filter(schedule => {
          return schedule.dose_number === this.$route.params.dose
        })[0]
      }else{
        return []
      }
    },
    batchesForVaccine: function (){
      return this.vaccinating_batches.filter(inventory => {
        return inventory.batch.vaccine.id === this.vaccine
      })
    }
  },
  methods: {
    ...mapActions(['setError', 'fetchCenterInfoWithBatchReport']),
    getDaysToBooster(){
      if(this.$route.params.dose === 'Booster'){
        // check eligibility
        let lastVaccinationDate = this.personVaccinations[0].scheduled_doses.at(-1).date_administered

        // Get any batch matching the 
        let timeSinceLastDose = this.$func.diffDays(lastVaccinationDate, new Date())

        // Get the batch the last dose given
        this.$store.dispatch('fetchVaccineDose', this.personVaccinations[0].scheduled_doses.at(-1).dose).then(resp => {
          // Get days to booster
          const daysToBooster = resp.days_to_booster

          console.log('days to booster', daysToBooster, timeSinceLastDose)
          if(timeSinceLastDose >= daysToBooster){
            this.boosterIsEligible = true
          }else{
            this.boosterIsEligible = false
            this.boosterIneligibleMessage = `You have ${daysToBooster - timeSinceLastDose} days left to be eligible for a booster dose`
          }
        });
      }
    },
    administerVaccine() {
      //If person is being administered for the first time, create schedule record
      if (this.$route.params.dose === 1 || this.$route.params.dose === 'Booster' || this.$route.params.skipSchedule) {
        this.is_loading = true

        // remove errors
        this.setError({})

        let payload = {
          person_id: this.personDetails.id,
          batch: this.batch,
          dose: this.dose,
          facility: this.facility,
          date_administered: this.date_administered,
          date_scheduled: this.date_scheduled,
          vaccinator: this.vaccinator,
        }

        if(this.conditions.length){
          payload.preexisting_conditions_id = this.formattedConditions
        }

        if(this.$route.params.dose === 'Booster'){
          payload.is_booster = true
        }

        if(this.$route.params.skipSchedule){
          payload.params = '?skipSchedule=1';
        }else{
          payload.params = '';
        }

        this.$store.dispatch('administerVaccine', payload).then(resp => {
          if (resp) {
            $('#confirm_vaccine_modal').modal('hide')
            this.is_loading = false;
            this.$store.dispatch('setSuccessMessages', [{"message": "Vaccine administered successfully"}])
            this.$store.dispatch('setPersonDetails', {})

            if(this.$route.params.expired_batch === false){
              this.$router.push({name: "persons"})
            }else{
              this.$router.push({name: "expired_batch_vaccination"})
            }
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          $('#confirm_vaccine_modal').modal('hide')
          this.is_loading = false;
        })
      } else {
        //  Edit schedule for the dose to mark as administered
        this.is_loading = true;
        // remove errors
        this.setError({})
        this.$store.dispatch('updateVaccinationSchedule', {
          person_id: this.personDetails.id,
          batch: this.batch,
          dose: this.dose,
          facility: this.facility,
          date_administered: this.date_administered,
          vaccinator: this.vaccinator,
          temperature: this.temperature,
          schedule_id: this.getScheduleForDose.id
        }).then(resp => {
          if (resp) {
            $('#confirm_vaccine_modal').modal('hide')
            this.is_loading = false;
            this.$store.dispatch('setSuccessMessages', [{"message": "Vaccine administered successfully"}])
            this.$store.dispatch('setPersonDetails', {})

            if(this.$route.params.expired_batch === false){
              this.$router.push({name: "persons"})
            }else{
              this.$router.push({name: "expired_batch_vaccination"})
            }
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.is_loading = false;
        })
      }
    },
    retrieveVaccinationBatches() {
      let action = '';
      if(this.$route.params.expired_batch === true)
        action = 'fetchExpiredInventory'
      else
        action = 'fetchVaccinationBatches';

      this.is_loading = true;
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch(action).then(resp => {
        this.is_loading = false

        //Set to expired batches
        if(this.$route.params.expired_batch === true){
          this.vaccinating_batches = resp.filter(curr_batch=>{
            return new Date(curr_batch.batch.date_of_expiry) < new Date()
          })
        }else{
          this.vaccinating_batches = resp.filter(curr_batch=>{
            // Create new Date instance
            let expiry_date = new Date(curr_batch.batch.date_of_expiry)

            // Add a day
            expiry_date.setDate(expiry_date.getDate() + 1)

            return new Date() < expiry_date && (curr_batch.good_quantity - curr_batch.used_quantity) > 0
          })
        }


        //Check if is not first dose
        // if(this.$route.params.dose !== 1){
        //   // Only show batches the client is expecting a dose 2 of
        //   this.vaccinating_batches = this.vaccinating_batches.filter(curr_batch => {
        //     return curr_batch.batch.vaccine.id === this.personVaccinations[0].vaccine
        //   })
        // }

        // Prompt to receive if vaccinating batches is empty
        if(this.vaccinating_batches.length === 0){
          this.error_message = 'Receive doses in your facility to enable vaccination'
        }


        let batches = this.vaccinating_batches

        // all vaccines associated with the batches
        let vaccines = batches.map(inventory => {
          return inventory.batch.vaccine
        })


        // Get the unique list of vaccines
        for (var i = 0; i < vaccines.length; i++) {
          if (!this.vaccinesInFacility.some(e => e.id === vaccines[i].id)) {
            this.vaccinesInFacility.push(vaccines[i])
          }
        }

        // Clients below 18 can only receive pfizer
        if(this.$func.calculateAge(this.personDetails.date_of_birth) < 18){
          this.vaccinesInFacility = this.vaccinesInFacility.filter(vaccine => {
            return vaccine.name === 'Pfizer'
          })
        }

        //If booster dose
        if(this.$route.params.dose === 'Booster'){
          this.vaccinesInFacility = this.vaccinesInFacility.filter(vacc => {
            return vacc.doses.find(dose => {
              return dose.is_booster
            })
          });
        }

        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.setError({'detail': 'error fetching inventory'})
        this.is_loading = false
      });
    },
    formatDate() {
      // If vaccinating expired batches, force to specify date, else set default to now
      if(this.$route.params.expired_batch === false){
        let date = new Date();
        return this.date_administered =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) +
            "-" + ("00" + date.getDate()).slice(-2) +
            "T" + ("00" + date.getHours()).slice(-2) +
            ":" + ("00" + date.getMinutes()).slice(-2)
      }

    },
    getDateScheduled() {
      let date = new Date();
      return this.date_scheduled =
          date.getFullYear() + "-" +
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "-" + ("00" + date.getDate()).slice(-2)
    },
    retrieveVaccinators() {
      return this.$store.dispatch('fetchVaccinators')
    },
    retrievePreExistingConditions(){
      this.$store.dispatch('fetchPreexistingConditions');
    },
    setCurrentFacility() {
      this.facilities.push(this.centerInfo)
      this.facility = this.centerInfo.id
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    getJWT() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres = JSON.parse(atob(tk.split('.')[1]));

        this.vaccinator = tkres.user_id
        this.vaccinators.push({
          id: tkres.user_id,
          username: tkres.username
        })
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    checkIfExpired(){
      this.expired_batch = this.$route.params.expired_batch
    },
    openConfirmVaccinationModal(){
      $('#confirm_vaccine_modal').modal('show')
    },
    refreshBatchReportInfo(){
      this.is_loading = true
      this.$store.dispatch('fetchCenterInfoWithBatchReport').then(()=>{
        this.is_loading = false
      }).catch(()=>{
        this.is_loading = false
      })
    },
    updatedFacilityFilter(facility_id, facility_object){
      this.facility = facility_id
      this.facilities = []
      this.facilities.push(facility_object)
    },
    selectFacilityModal(){
      $('#set_facility_modal').modal('show')
    },
    closeFacilityModal(){
      $('#set_facility_modal').modal('hide')
    }
  },
  watch: {
    batch: function () {
      this.batch_object = this.vaccinating_batches.filter(inventory => {
        return inventory.batch.id === this.batch
      })[0].batch

      if(this.$route.params.dose === 'Booster'){
        // is booster true
        this.boosterDose = this.batch_object.vaccine.doses.find(dose => dose.is_booster);
        this.dose = this.boosterDose.id

      }else{
        this.dose = this.batch_object.vaccine.doses.filter(dose => {
          return dose.dose_number === this.$route.params.dose
        })[0].id
      }
    },
    date_administered: function () {
      // Format date
      let date_administered = new Date(this.date_administered)

      // Administration date has to be between 1st feb and now, and before the scheduled date
      if (date_administered < new Date('2021-2-1') || date_administered > new Date()) {
        this.valid_date = 'is-invalid'
        this.invalid_date_error = 'The date has to be between February and Today'
        this.show_submit = false
      } else {
        this.valid_date = 'is-valid'
        this.show_submit = true
      }

      // validation after scheduled day if vaccinated before
      if(this.personVaccinations.length !==0 && this.getScheduleForDose){
        var updated_date = new Date(this.getScheduleForDose.date_scheduled)

        // Allow administration 4 weeks before the vaccination date
        updated_date.setDate(updated_date.getDate() - 28)

        // Administration date has to be on the scheduled date or after
        if(new Date(date_administered) < new Date(updated_date)){
          this.valid_date = 'is-invalid'
          this.invalid_date_error = 'The date has to be after or on the scheduled date'
          this.show_submit = false
        }
      }

      // Check if expired batch and the administration date is after the expiry date, if true, fail
      if (this.expired_batch === true && new Date(this.date_administered) > new Date(this.batch_object.date_of_expiry)){
        this.valid_date = 'is-invalid'
        this.invalid_date_error = 'The date has to be before the batch expiry date ' + new Date(this.batch_object.date_of_expiry).toDateString()
        this.show_submit = false
      }

      // // Get the difference between date_administered and now
      // let diffDays = this.$func.diffDays(this.date_administered, new Date())
      //
      //
      // //Only TOTs can backdate
      // if(!this.$func.userHasRoles(['Trainer of Trainers(TOT)', 'HRIO']) && diffDays > 14){
      //   this.valid_date = 'is-invalid'
      //   this.invalid_date_error = 'Cannot backdate before 2 weeks'
      //   this.show_submit = false
      // }

    },
  },
  mounted() {
    //this.retrieveCurrentCenter()
    this.checkIfExpired()
    this.retrieveVaccinationBatches();
    this.formatDate();
    this.getDateScheduled();
    // this.retrieveVaccinators();
    this.setCurrentFacility()
    this.getJWT()
    this.retrievePreExistingConditions()
    this.getDaysToBooster()
  }
}
</script>

<style scoped>

</style>