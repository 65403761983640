<template>
  <div>
    <div :id="'outcomeModal'" aria-hidden="true" aria-labelledby="outcomeModalLabel" class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="outcomeModalLabel" class="modal-title">Record outcome for adverse event</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="outcome">
                Select Outcome Category
                <span class="text-danger">*</span>
              </label>
              <select id="outcome" v-model="outcome_category" class="form-control" required>
                <option selected value="recovered">Recovered</option>
                <option value="recovering">Recovering</option>
                <option value="not_recovered">Not recovered</option>
                <option value="unknown">Unknown</option>
                <option value="died">Died</option>
              </select>
            </div>
            <div class="form-group">
              <label for="outcome_description">Outcome Description</label>
              <textarea id="outcome_description" v-model="outcome_description" class="form-control" rows="3"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button class="btn btn-primary pr-2" @click="recordOutcome(recorded_effect)">
              Record Outcome
              <i class="feather icon-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="personDetails.vaccinations.length === 0">
      <div class="card-body">
        <h4 class="text-center">
          Administer first Dose to enable reporting of adverse events
        </h4>
      </div>
    </div>
    <div v-else class="card m-b-30">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-9">
            <h5 class="card-title mb-0">Adverse Events for {{ personDetails.first_name }} {{ personDetails.middle_name }}
              {{ personDetails.last_name }}</h5>
          </div>
          <div class="col-3">
            <router-link :to="{name: 'record_adverse_effects', params:{'id': personDetails.id}}" class="btn btn-primary mb-3 float-right">
              Record Adverse Event
              <i class="feather icon-alert-triangle"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body pt-1">
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead class="thead-dark">
            <tr>
              <th>Adverse Events</th>
              <th>Action Taken</th>
              <th>Date of Occurence</th>
              <th>Reported By</th>
              <th>Reported By Phone Number</th>
              <th>Reporting Center</th>
              <th>Outcome</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody v-if="personDetails.adverse_effects.length === 0">
            <tr>
              <td class="text-center text-success" colspan="7">
                <h4 class="text-success">No Adverse Events Reported</h4>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="(effect, index) in personDetails.adverse_effects" v-bind:key="index">
              <td>
                <span v-for="(effect_type, index) in effect.adverse_effects" v-bind:key="index">
                  <strong v-if="typeof effect_type.adverse_effect_category === 'string'">
                    {{ effect_type.adverse_effect_category }}
                  </strong>
                  <strong v-else>{{ allEffectTypes[effect_type.adverse_effect_category - 1].name }}</strong>
                  : {{ effect_type.description }}

                </span>
              </td>
              <td>
                <span v-for="(effect_type, index) in effect.adverse_effects" v-bind:key="index">
                  {{ effect_type.care_received }}
                </span>
              </td>
              <td>{{ new Date(effect.date_of_occurrence).toDateString() }}</td>
              <td>{{ effect.reported_by_designation }} {{ effect.reported_by_name }}</td>
              <td>{{ effect.reported_by_phone }}</td>
              <td>{{ effect.reporting_center.name }}</td>
              <td>{{effect.outcome}}</td>
              <td>
                <button @click="showOutcomeModal(effect.id)" class="btn btn-primary">
                  Record Outcome
                  <i class="feather icon-arrow-right"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import $ from "jquery";

export default {
  name: "adverse_effects_list",
  data () {
    return {
      personId:null,
      recorded_effect: null,
      outcome_category: '',
      outcome_description : ''
    }
  },
  methods: {
    ...mapActions(['setError']),
    showOutcomeModal(effect){
      $('#outcomeModal').modal('show')
      this.recorded_effect = effect
    },
    recordOutcome(id){
      this.is_loading = true;
      // remove errors
      this.setError({})
      let outcome = this.outcome_category

      this.$store.dispatch('reportOutcome', {
        id,
        outcome
      }).then(resp => {
        if (resp) {
          $('.modal').modal('hide')
          this.is_loading = false;
          this.$store.dispatch('setSuccessMessages', [{"message": "Adverse Event Outcome Reported successfully"}])
          this.outcome_category = ''
          this.$store.dispatch('fetchPersonDetails', "/person/" + this.personDetails.id)
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
  },
  computed: {
    ...mapGetters(['allEffectTypes', 'personDetails', 'allVaccines']),
  },
}
</script>