<template>
  <div>
    <div v-if="personDetails.vaccinations">
      <button id="cmd" @click="generateReport" class="btn btn-primary mb-2">
        Download Certificate
        <i class="feather icon-file-text"></i>
      </button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          filename="vaccination certificate"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"

          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="card p-4">
            <div class="card-header">
              <div class="row justify-content-center">
                <div class="">
                  <h4 class="font-weight-bold pb-3">
                    Covid-19 Vaccination Certificate
                  </h4>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div>

                <img
                    src="@/assets/images/mohlogo.png" style="width: 130px; margin-top: -14px ; margin-bottom: 40px"
                    class="img-fluid" alt="logo">
              </div>
            </div>
            <div class="card-body">
              <p class="text-black font-24 pb-4" style="line-height: 1.5;letter-spacing: 0.6px;">
                This is to certify that <strong>{{ personDetails.first_name }} <span v-if="personDetails.middle_name">{{ personDetails.middle_name }}</span> {{ personDetails.last_name }}</strong>,

                born on <strong>{{ new Date(personDetails.date_of_birth).toDateString() }}</strong>, from
                <strong>{{ getNationalityCountry(personDetails.nationality) }}</strong> with <strong><span
                  v-for="(identification, index) in personDetails.identification" v-bind:key="index" class="mb-0">
                    {{ titleCase(identification.id_type) }}
                   {{ identification.id_number }}
                  </span></strong> has been vaccinated against <strong>Covid 19</strong> on the date
                indicated in accordance with the National Health Regulations.
              </p></div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col" class="font-24">Vaccine</th>
                  <th scope="col" class="font-24">Dose</th>
                  <th scope="col" class="font-24">Date Administered</th>
                  <th scope="col" class="font-24">Batch No</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(schedule, index) in scheduled_doses" v-bind:key="index">
                  <!--          <td class="font-24">{{ getVaccineAdministered() }}</td>-->
                  <td class="font-16" v-if="schedule.vaccine">{{ getVaccineName(schedule.vaccine) }}</td>
                  <td class="font-16 text-warning" v-else>Pending</td>
                  <td class="font-16">{{ schedule.is_booster ? 'Booster' : schedule.dose_number }}</td>
                  <td class="font-16">
                    <span v-if="schedule.date_administered">
                      {{ new Date(schedule.date_administered).toDateString() }}
                    </span>
                    <span v-else class="text-warning">
                      <span> Next Dose </span> <br>
                      on <strong>{{ new Date(schedule.date_scheduled).toDateString() }}</strong>
                    </span>
                  </td>
                  <td class="font-24">
                    <span v-if="schedule.date_administered">
                      {{ schedule.batch_number }}
                    </span>
                    <span v-else class="text-warning">
                      Pending
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="row pb-5">
              <div class="col-md-5">
              </div>
              <div class="col-md-4">
                <p class="mt-3 mb-3 ml-4">Scan To Verify</p>
                <vue-qrcode :value="'https://chanjo.health.go.ke/certificate/' + hash_key" width=150 type="image/png"/>
              </div>
              <div class="col-12">
                <p class="text-center pt-3">This document is system generated and therefore does not require a signature. </p>
                <p class="text-center"> You may confirm this certificate by scanning the QR code. </p>
              </div>

            </div>
            <div class="row mt-2">
              <div class="col-md-5">
              </div>
              <div class="col-md-4">
              </div>
            </div>

          </div>
        </section>
        <button class="btn btn-success" @click="generateReport">
          Download Cert
        </button>
      </vue-html2pdf>
      <div class="card" id="certificate">
        <div class="card-header">
          <div class="row justify-content-center">
            <div class="">
              <h3 class="font-weight-bold pb-3">
                Covid-19 Vaccination Certificate
              </h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div>
            <img
                src="@/assets/images/mohlogo.png" style="width: 130px; margin-top: -14px ; margin-bottom: 40px"
                class="img-fluid" alt="logo">
          </div>
        </div>
        <div class="card-body">
          <p class="text-black font-24 pb-4" style="line-height: 1.5;letter-spacing: 0.6px;">
            This is to certify that <strong>{{ personDetails.first_name }} <span v-if="personDetails.middle_name">{{ personDetails.middle_name }}</span> {{ personDetails.last_name }}</strong>,

            born on <strong>{{ new Date(personDetails.date_of_birth).toDateString() }}</strong>, from
            <strong>{{ getNationalityCountry(personDetails.nationality) }}</strong> with <strong><span
              v-for="(identification, index) in personDetails.identification" v-bind:key="index" class="mb-0">
                    {{ titleCase(identification.id_type) }}
                   {{ identification.id_number }}
                  </span></strong> has been vaccinated against <strong>Covid 19</strong> on the date
            indicated in accordance with the National Health Regulations.
          </p>

          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col" class="font-24">Vaccine</th>
                <th scope="col" class="font-24">Dose</th>
                <th scope="col" class="font-24">Date Administered</th>
                <th scope="col" class="font-24">Batch No</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(schedule, index) in scheduled_doses" v-bind:key="index">
                <td class="font-16" v-if="schedule.vaccine">{{ getVaccineName(schedule.vaccine) }}</td>
                <td class="font-16 text-warning" v-else>Pending</td>
                <td class="font-16">{{ schedule.is_booster ? 'Booster' : schedule.dose_number }}</td>
                <td class="font-16">
                    <span v-if="schedule.date_administered">
                      {{ new Date(schedule.date_administered).toDateString() }}
                    </span>
                  <span v-else class="text-warning">
                      <span> Next Dose </span> <br>
                      on <strong>{{ new Date(schedule.date_scheduled).toDateString() }}</strong>
                    </span>
                </td>
                <td class="font-24">
                  <span v-if="schedule.date_administered">
                    {{schedule.batch_number}}
                  </span>
                  <span v-else class="text-warning">
                    Pending
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-2">
            <p class="mt-3 mb-3 ml-4">Scan To Verify</p>
            <vue-qrcode :value="'https://chanjo.health.go.ke/certificate/' + hash_key" width=150 type="image/png"/>
          </div>
        </div>
        <p class="pt-4 text-center pb-3">This document is system generated and therefore does not require a signature.
          You may confirm this certificate by scanning the QR code. </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueQrcode from 'vue-qrcode'
import {jsPDF} from "jspdf";
import $ from 'jquery';
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: "view_vaccination_certificate",
  data() {
    return {
      selected_vaccine: 1,
      size: 300,
      vaccine: '',
      hash_key: '',
      batch_numbers: [],
      scheduled_doses: [],
    }
  },
  components: {
    VueQrcode,
    VueHtml2pdf
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    clidked() {
      var doc = new jsPDF();
      var specialElementHandlers = {
        // eslint-disable-next-line no-unused-vars
        '#certificate': function (element, renderer) {
          return true;
        }
      };
      // console.log("clie")
      doc.fromHTML($('#certificate').html(), 15, 15, {
        'width': 170,
        'elementHandlers': specialElementHandlers
      });
      doc.save('sample-file.pdf');
    },
    retrievePersonDetails() {
      return this.$store.dispatch('fetchPersonDetails', "/person/" + this.personDetails.id).then(resp => {
        this.scheduled_doses = resp.vaccinations[0].scheduled_doses

        this.hash_key = this.personDetails.vaccinations[0].hash_key

        this.scheduled_doses.map(schedule => {
          // console.log('schedule',schedule.batch)
          let batch_no = schedule.batch

          if(batch_no){
            this.$store.dispatch('fetchBatchByID', batch_no).then(batch => {
              return this.batch_numbers.push(batch.batch_number)
            })
          }
        })

        this.vaccine = this.allVaccines.filter(vaccine => {
          return vaccine.id === this.personDetails.vaccinations[0].vaccine
        })[0]
      })
    },
    getNationalityCountry(countryCode) {
      return this.allNationalities.filter(nationality => {
        return nationality.value === countryCode
      })[0].name
    },
    titleCase(snake_case) {
      return this.capitalize(snake_case.replace(/_/g, " "));
    },
    capitalize(str) {
      return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
    },
    getAllVaccines() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchVaccines').then(resp => {
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {"detail": "error fetching vaccines"})
      });
    },
    getVaccineName(vaccine_id){
      return this.allVaccines.filter(vacc => {
        return vacc.id === vaccine_id
      })[0].name
    }
  },
  computed: {
    ...mapGetters(['personDetails', "allVaccines", 'allNationalities']),
  },
  mounted() {
    this.retrievePersonDetails()
    this.getAllVaccines()
  }
}
</script>

<style scoped>

</style>