<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Vaccine Inventory Report"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="$func.userHasRoles(['Stock Manager', 'Manager', 'Trainer of Trainers(TOT)'])">
        <vaccine_inventory_report></vaccine_inventory_report>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import vaccine_inventory_report from "@/components/reports/vaccine_inventory_report";
import {mapActions, mapGetters} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

export default {
  name: "VaccineInventoryReport",
  components: {AccessDeniedCard, Breadcrumbs, vaccine_inventory_report},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
  },
  computed: {
    ...mapGetters(['centerInfo'])
  },
  mounted() {
    this.clearErrors()
  }
}
</script>

<style scoped>

</style>