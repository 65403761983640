<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Inventory Report"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <stock_report_table></stock_report_table>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from "vuex";
  import stock_report_table from "@/components/inventory/stock_report_table";
  import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
  import success_messages from '@/components/layouts/includes/success_messages.vue';

  export default {
    name: "dispatch_master",
    components: {
      Breadcrumbs,
      stock_report_table,
      success_messages
    },
    computed: {
      ...mapGetters(['centerInfo']),
    },
    methods: {
      ...mapActions(['fetchCenterInfo']),
      retrieveCurrentCenter(){
        return this.fetchCenterInfo();
      }
    },
    mounted() {
      this.retrieveCurrentCenter();
    }
  }
</script>