<template>
  <div class="card">
    <div class="card-body">
      <form @submit.prevent="submitPerson">
        <h4 class="mb-3">Personal Details</h4>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="first_name">
              First Name
              <span class="text-danger">*</span>
            </label>
            <input id="first_name" v-model="first_name"
                   :class="'form-control ' + checkIfErrorExists('first_name')" required type="text">
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError.first_name" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="middle_name">Middle Name</label>
            <input id="middle_name" v-model="middle_name" class="form-control" type="text">
          </div>
          <div class="form-group col-md-4">
            <label for="last_name">
              Last Name
              <span class="text-danger">*</span>
            </label>
            <input id="last_name" v-model="last_name" :class="'form-control ' + checkIfErrorExists('last_name')"
                   required type="text">
            <div class="invalid-feedback">
              <span v-for="(error, index) in getError.last_name" v-bind:key="index">
                {{ error }}
              </span>
            </div>
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="gender">
              Gender
              <span class="text-danger">*</span>
            </label>
            <select id="gender" v-model="gender" :class="'form-control ' + checkIfErrorExists('gender')" required>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Intersex</option>
            </select>
            <div class="invalid-feedback">
              This field may not be blank
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="dateOfBirth">
              Date of Birth
              <span class="text-danger">*</span>
            </label>
            <input id="dateOfBirth" v-model="date_of_birth"
                   :class="'form-control ' + valid_age + checkIfErrorExists('date_of_birth')" required type="date">
            <div class="invalid-feedback">
              <span v-if="valid_age === 'is-invalid'">
                The vaccinee has to be above 11 years old
              </span>
              <span v-for="(error, index) in getError.date_of_birth" v-bind:key="index">
                {{ error }}
                </span>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="nationality">
              Country of Origin
              <span class="text-danger">*</span>
            </label>
            <select id="nationality" v-model="nationality" :class="'form-control ' + checkIfErrorExists('nationality')"
                    required>
              <option v-for="(nationality,index) in allNationalities" v-bind:key="index" :value="nationality.value">
                {{ nationality.name }}
              </option>
            </select>
            <div class="invalid-feedback">
              This field may not be blank
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 pr-0">
            <div v-for="(selected_identification,index) in identifications" v-bind:key="index" class="form-row">
              <div class="form-group col-md-6">
                <label for="identification">
                  Identification Type
                  <span class="text-danger">*</span>
                </label>
                <select id="identification" v-model="selected_identification.id_type"
                        :class="'form-control ' + checkIfErrorExists('identification')" required>
                  <option v-for="(identification_type,index) in allIdentificationTypes" v-bind:key="index"
                          :value="identification_type.value">
                    {{ identification_type.name }}
                  </option>
                </select>
                <div class="invalid-feedback">
              <span v-for="(error, index) in getError.identification" v-bind:key="index">
                <span v-for="(error_message, message_index) in error.id_type" v-bind:key="message_index">
                {{ error_message }}
                </span>
              </span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="id_number">
                  Identification Number
                  <span class="text-danger">*</span>
                </label>
                <input id="id_number" v-model="selected_identification.id_number"
                       :class="'form-control ' + checkIfErrorExists('identification')" required type="text">
                <div class="invalid-feedback">
              <span v-for="(error, index) in getError.identification" v-bind:key="index">
                <span v-for="(error_message, message_index) in error.id_number" v-bind:key="message_index">
                {{ error_message }}
                </span>
              </span>
                </div>
              </div>
              <div v-if="index !== 0" class="form-group col-md-3">
                <button class="btn btn-danger mt-2" type="button" v-on:click="removeIdentification(index)">
                  Remove Identification
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
              <div class="form-group col-md-3">
                <button v-if="identifications.length === index+1 " class="btn btn-primary mt-2" type="button" v-on:click="addIdentification">
                  Add Identification
                  <i class="feather icon-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-row">
              <div class="form-group col-md-12 pl-0">
                <label for="occupation">
                  Occupation
                  <span class="text-danger">*</span>
                </label>
                <select id="occupation" v-model="occupation"
                        :class="'form-control ' + checkIfErrorExists('occupation_id')" required>
                  <option v-for="(occupation,index) in allOccupations" v-bind:key="index" :value="occupation.id">
                    {{ occupation.name }}
                  </option>
                </select>
                <div class="invalid-feedback">
                <span v-for="(error, index) in getError.occupation_id" v-bind:key="index">
                  {{ error }}
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="religion">
              Religion
              <span class="text-danger">*</span>
            </label>
            <select id="religion" v-model="religion" :class="'form-control ' + checkIfErrorExists('religion')"
                    required>
              <option v-for="(religion,index) in allReligions" v-bind:key="index" :value="religion.value">
                {{ religion.name }}
              </option>
            </select>
            <div class="invalid-feedback">
              This field may not be blank
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="registeredOn">
              Registered on
<!--              <span class="text-danger">*</span>-->
            </label>
            <input id="registeredOn" v-model="registered_on"
                   :class="'form-control ' + valid_age + checkIfErrorExists('registered_on')" required type="date">
          </div>
        </div>
        <div v-if="occupationIsOther()" class="form-row">
          <div class="form-group col-md-4">
            <label for="other_occupation">
              Specify Occupation
              <span class="text-danger">*</span>
            </label>
            <input id="other_occupation" v-model="occupation_detail" class="form-control" required type="text">
          </div>
        </div>
        <div v-if="isHealthWorker()" class="form-row">
          <div class="form-group col-md-4">
            <label for="personal_phone">
              Personal Number
            </label>
            <input id="personal_phone" v-model="personal_phone_number" class="form-control" type="tel">
          </div>
          <div class="form-group col-md-4">
            <label for="practising_number">
              Practising Number
            </label>
            <input id="practising_number" v-model="practising_number" class="form-control" type="text">
          </div>
          <div class="form-group col-md-4">
            <label class="control-label" for="hw_category">
              Select Health Worker Category <span class="text-danger">*</span>
            </label>
            <select id="hw_category" v-model="occupation_detail" class="form-control" required>
              <option v-for="(hw_category, index) in allHWCategories" v-bind:key="index" :value="hw_category.value">
                {{ hw_category.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="isTeacher()" class="form-row">
          <div class="form-group col-md-4">
            <label class="control-label" for="teacher_category">
              Select Teacher category
            </label>
            <select id="teacher_category" v-model="occupation_detail" class="form-control" required>
              <option value="primary_teacher">Primary School Teacher</option>
              <option value="high_school_teacher">High School teacher</option>
            </select>
          </div>
        </div>
        <h4 class="my-3">Contact Info</h4>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="email">Email</label>
            <input id="email" v-model="email" class="form-control" type="email">
          </div>
          <div class="form-group col-md-3">
            <label for="phone_code">
              Phone Code
            </label>
            <select id="phone_code   " v-model="phone_code" :class="'form-control ' + checkIfErrorExists('nationality')"
                    required>
              <option v-for="(phone,index) in allPhoneCodes" v-bind:key="index" :value="phone.code">
                {{ phone.country }} + {{ phone.code }}
              </option>
            </select>
            <div class="invalid-feedback">
              This field may not be blank
            </div>
          </div>
          <div class="form-group col-md-5">
            <label for="phone">
              Phone (Enter the rest of the number e.g 712345567)
              <span class="text-danger">*</span>
            </label>
            <input id="phone" v-model="phone" :class="'form-control ' + valid_phone" required type="text">
            <span v-if="valid_phone === 'is-invalid'">
                The number should have 9 characters
              </span>
          </div>
        </div>
        <h4 class="my-3">Next of Kin</h4>
        <div v-for="(selected_next_of_kin,index) in next_of_kins" v-bind:key="index" class="form-row">
          <div class="form-group col-md-3">
            <label for="nextOfKinName">
              Next of Kin Name
              <span class="text-danger">*</span>
            </label>
            <input id="nextOfKinName" v-model="selected_next_of_kin.name" class="form-control" required type="text">
          </div>
          <div class="form-group col-md-3">
            <label for="nextOfKinEmail">
              Next of Kin Email
            </label>
            <input id="nextOfKinEmail" v-model="selected_next_of_kin.email" class="form-control" type="email">
          </div>
          <div class="form-group col-md-3">
            <label for="nextOfKinPhone">
              Next of Kin Phone
              <span class="text-danger">*</span>
            </label>
            <input id="nextOfKinPhone" v-model="selected_next_of_kin.phone" class="form-control" required type="text">
          </div>
          <div class="form-group col-md-3">
            <label for="nextOfKinRelationship">
              Relationship with Next of Kin
              <span class="text-danger">*</span>
            </label>
            <select id="nextOfKinRelationship" v-model="selected_next_of_kin.relationship" class="form-control"
                    required>
              <option v-for="(relationship,index) in allRelationshipsWithNextOfKin" v-bind:key="index"
                      :value="relationship.name">
                {{ relationship.name }}
              </option>
            </select>
          </div>
        </div>
        <h4 class="my-3">Residence</h4>
        <LocationFilter :defaultWard="getPersonBeingUpdated ? getPersonBeingUpdated.location : null" @setLocationFilter="setLocationFilter" parent-component="registration" size="col-md-4" class="mb-4"/>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="village">Village/Estate</label>
            <input id="village" class="form-control" type="text">
          </div>
          <div class="form-group col-md-4">
            <label for="landmark">Landmark</label>
            <input id="landmark" class="form-control" type="text">
          </div>
        </div>
        <h4 class="my-3">Allergies and Conditions</h4>
        <div class="row">
          <div class="col-md-6">
            <div v-for="(selected_allergy,index) in allergies" v-bind:key="index" class="form-row">
              <div class="form-group col-md-8">
                <label :for="'allergy-'+index">Allergy</label>
                <select :id="'allergy-'+index" v-model="selected_allergy.id" class="form-control">
                  <option selected></option>
                  <option v-for="(allergy,index) in allAllergies" v-bind:key="index" :value="allergy.id">{{
                      allergy.name
                    }}
                  </option>
                </select>
              </div>
              <div v-if="allergies.length === index+1" class="form-group col-md-4">
                <label class="text-center">Add Allergy</label>
                <button class="btn btn-primary float-right w-100" type="button" v-on:click="addNewAllergy">
                  Add
                  <i class="feather icon-plus"></i>
                </button>
              </div>
              <div v-if="index !== 0" class="form-group col-md-4">
                <label class="">Remove</label>
                <button class="btn btn-danger w-100" type="button" v-on:click="removeAllergy(index)">
                  Remove
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div v-for="(selected_other_allergy,index) in other_allergies" v-bind:key="index" class="form-row">
              <div class="form-group col-md-8">
                <label :for="'other_allergy-'+index">Other Allergy</label>
                <input :id="'other_allergy-'+index" v-model="selected_other_allergy.name" class="form-control"
                       type="text">
              </div>
              <div  v-if="other_allergies.length === index+1" class="form-group col-md-4">
                <label class="text-center">Add Other</label>
                <button class="btn btn-primary float-right w-100" type="button" v-on:click="addNewOtherAllergy">
                  Add
                  <i class="feather icon-plus"></i>
                </button>
              </div>
              <div v-if="index !== 0" class="form-group col-md-4">
                <label class="">Remove</label>
                <button class="btn btn-danger w-100" type="button" v-on:click="removeOtherAllergy(index)">
                  Remove
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div v-for="(selected_preexisting_condition,index) in preexisting_conditions" v-bind:key="index"
                 class="form-row">
              <div class="form-group col-md-8">
                <label for="preexisting_conditions">Preexisting Conditions</label>
                <select id="preexisting_conditions" v-model="selected_preexisting_condition.id" class="form-control">
                  <option selected></option>
                  <option v-for="(preexisting_condition,index) in allPreexistingConditions" v-bind:key="index"
                          :value="preexisting_condition.id">{{ preexisting_condition.name }}
                  </option>
                </select>
              </div>
              <div  v-if="preexisting_conditions.length === index+1" class="form-group col-md-4">
                <label class="text-center">Add Condition</label>
                <button class="btn btn-primary float-right w-100" type="button" v-on:click="addNewPreexistingCondition">
                  Add
                  <i class="feather icon-plus"></i>
                </button>
              </div>
              <div v-if="index !== 0" class="form-group col-md-4">
                <label class="">Remove</label>
                <button class="btn btn-danger w-100" type="button" v-on:click="removePreexistingCondition(index)">
                  Remove
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div v-for="(selected_other_condition,index) in other_conditions" v-bind:key="index"
                 class="form-row">
              <div class="form-group col-md-8">
                <label for="other_conditions">Other Conditions</label>
                <input id="other_conditions" v-model="selected_other_condition.name" class="form-control"
                       type="text">
              </div>
              <div v-if="other_conditions.length === index+1" class="form-group col-md-4">
                <label class="text-center">Other Condition</label>
                <button class="btn btn-primary float-right w-100" type="button"
                        v-on:click="addNewOtherPreexistingCondition">
                  Add
                  <i class="feather icon-plus"></i>
                </button>
              </div>
              <div v-if="index !== 0" class="form-group col-md-4">
                <label class="">Remove</label>
                <button class="btn btn-danger w-100" type="button" v-on:click="removeOtherPreexistingCondition(index)">
                  Remove
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <h4 class="my-3">Disability</h4>
        <div class="form-check mb-3">
          <input v-model="disabled" class="form-check-input" type="checkbox" value="" id="has_disability">
          <label class="form-check-label" for="has_disability">
            Has Disability
          </label>
        </div>
        <div class="row mb-3" v-if="disabled">
          <div class="form-group col-md-6">
            <label for="practising_number">
              Disability Registration Number
            </label>
            <input id="disability_registration_no" v-model="disability_registration_number" class="form-control" type="text">
          </div>
          <div class="col-md-12">
            <h5>Select disability category</h5>
            <div class="form-check form-check-inline mb-3" v-for="(category, index) in allDisabilityCategories" :key="index">
              <input v-model="disability_categories[index]" class="form-check-input" type="checkbox" value="" id="disability_category">
              <label class="form-check-label" for="disability_category">
                {{ category.name }}
              </label>
            </div>
          </div>
        </div>
        <success_messages></success_messages>
        <!--        <div v-if="is_loading" class="offset-5">-->
        <!--          <p class="loader"></p>-->
        <!--          <p>Loading</p>-->
        <!--        </div>-->
        <div v-if="Object.keys(getError).length !==0 " id="err_alert"
             class="alert alert-danger alert-dismissible fade show" role="alert">

          <p v-for="(item, index) in getError" :key="index">
            <span v-for="(sub_item, index) in item" :key="index">{{ sub_item }}</span>
          </p>

          <button class="close" type="button" @click="hide_err()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <SubmittingLoader v-if="submitting"></SubmittingLoader>
        <div v-else>
          <button v-if="confirmed" class="btn btn-primary" type="submit">
            Submit
            <i class="feather icon-user-plus"></i>
          </button>
          <a v-else class="btn btn-primary" @click="confirmSubmission()">
            Confirm Details
            <i class="feather icon-alert-circle"></i>
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex/dist/vuex.mjs";
import {mapGetters} from "vuex";
import Vue from "vue";
import success_messages from "@/components/layouts/includes/success_messages";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import LocationFilter from "@/components/filters/LocationFilter";
// import $ from "jquery";

export default {
  name: "register_person",
  components: {LocationFilter, SubmittingLoader, success_messages},
  props: ["selected_person_id"],
  data() {
    return {
      confirmed: false,
      initial_identifier: '',
      person_id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      religion: '',
      occupation: '',
      date_of_birth: '',
      registered_on: this.$func.formatDate(new Date()),
      next_of_kins: [{
        name: '',
        email: '',
        phone: '+2547',
        relationship: ''
      }],
      email: '',
      phone: '',
      phone_code: '254',
      location: '',
      nationality: 'KE',
      personal_phone_number: '',
      practising_number: '',
      occupation_detail: '',
      allergies: [
        {
          "id": '',
          "name": ''
        }
      ],
      allergy: {
        "id": '',
      },
      preexisting_conditions: [
        {
          "id": '',
          "name": ''
        }
      ],
      preexisting_condition: {
        "id": '',
        "name": ''
      },
      other_conditions: [
        {
          "name": ""
        }
      ],
      other_condition: {
        "name": ""
      },
      identifications: [
        {
          "id_type": "",
          "id_number": ""
        }
      ],
      identification: {
        "id_type": "",
        "id_number": ""
      },
      other_allergies: [
        {
          "name": ""
        }
      ],
      other_allergy: {
        'name': ''
      },
      is_loading: false,
      valid_age: 'valid',
      valid_phone: 'valid',
      invalid_phone_message: '',
      submitting: false,
      disabled: false,
      disability_categories: [],
      disability_registration_number: ''
    }
  },
  methods: {
    ...mapActions([
      'setError',
      'fetchOccupations',
      'fetchAllergies',
      'fetchPreexistingConditions',
      'setPersonDetails',
    ]),
    addIdentification() {
      this.identifications.push(Vue.util.extend({}, this.identification))
    },
    removeIdentification: function (index) {
      Vue.delete(this.identifications, index);
    },
    isEditingUser() {
      return this.$route.name === 'update_person';
    },
    hide_err() {
      this.$store.dispatch('setSuccessMessages', [])
      this.setError({})
      // $("#success_alert").addClass("d-none");
    },
    retrievePreExistingConditions(){
      this.$store.dispatch('fetchPreexistingConditions');
    },
    submitPerson() {
      this.$store.dispatch("setError", {})
      this.is_loading = true;
      this.submitting = true;

      if (this.next_of_kins[0].name === '' && this.next_of_kins[0].email === '' && this.next_of_kins[0].phone === '') {
        this.next_of_kins = []
      }

      const person = {
        person_id: this.person_id,
        first_name: this.$func.titleCase(this.first_name),
        middle_name: this.$func.titleCase(this.middle_name),
        last_name: this.$func.titleCase(this.last_name),
        gender: this.gender,
        religion: this.religion,
        occupation_id: this.occupation,
        date_of_birth: this.date_of_birth,
        email: this.email,
        phone: '+' + this.phone_code + this.phone,
        location_id: this.location,
        nationality: this.nationality,
        next_of_kin: this.next_of_kins,
        extra_fields : {
          hw_phone: this.personal_phone_number,
          hw_practicing_no: this.practising_number,
          registered_on: this.registered_on
        },
        occupation_detail: this.occupation_detail,
        preexisting_conditions_id: this.preexisting_conditions.filter(item => {
            return item.id !== ''
          }).map(item => {
            return item.id
          }),
        other_conditions: this.other_conditions.filter(item => {
            return item.name !== ''
          }),
        identification: this.identifications,
        allergies_id: [],
        disabilities_id: this.formatted_disability_categories
      }
      // Check if disabled category is other
      if(this.disabled){
        person.extra_fields.disability_registration_number = this.disability_registration_number
      }

      let action = '';

      if (this.$route.name === 'update_person') {
        action = 'updatePerson'
      } else {
        action = 'createPerson'
      }

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch(action, person).then(resp => {
        // $('#loaderModal').modal('show')
        this.$store.dispatch("setError", {})
        this.is_loading = false;
        this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinee Registered successfully"}])
        this.setPersonDetails({})
        this.$router.push({name: "persons"})
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.submitting = false
        if (err.response.status === 500) {
          this.$store.dispatch('set_error', {"identification": [{"id_number": ["A vaccinee with the given identification already exists"]}]})
          // eslint-disable-next-line no-empty
        } else {
        }
      }).then(
        this.is_loading = false
      )
    },
    setPersonToUpdateDetails() {
      if (this.$route.name === 'update_person') {
        this.person_id = this.getPersonBeingUpdated.id
        this.first_name = this.getPersonBeingUpdated.first_name
        this.middle_name = this.getPersonBeingUpdated.middle_name
        this.last_name = this.getPersonBeingUpdated.last_name
        this.gender = this.getPersonBeingUpdated.gender
        this.religion = this.getPersonBeingUpdated.religion
        this.occupation = this.getPersonBeingUpdated.occupation
        this.date_of_birth = this.getPersonBeingUpdated.date_of_birth
        this.email = this.getPersonBeingUpdated.email
        this.phone = this.getPersonBeingUpdated.phone.substring(4)
        this.nationality = this.getPersonBeingUpdated.nationality
        this.identifications = this.getPersonBeingUpdated.identification
        this.initial_identifier = this.getPersonBeingUpdated.identification
        if (this.getPersonBeingUpdated.next_of_kin && this.getPersonBeingUpdated.next_of_kin.length !== 0) {
          this.next_of_kins = this.getPersonBeingUpdated.next_of_kin
        } else {
          this.next_of_kins = [{
            name: '',
            email: '',
            phone: '+2547',
            relationship: ''
          }]
        }

        this.occupation = this.getPersonBeingUpdated.occupation.id

        if(this.getPersonBeingUpdated.occupation_detail !== '')
          this.occupation_detail = this.getPersonBeingUpdated.occupation_detail

        this.location = this.getPersonBeingUpdated.location.id

        this.filtered_wards = [this.getPersonBeingUpdated.location]

        this.disabled = this.getPersonBeingUpdated.has_disability

        if(this.getPersonBeingUpdated.disabilities){
          if(this.getPersonBeingUpdated.extra_fields && this.getPersonBeingUpdated.extra_fields.disability_registration_number){
            this.disability_registration_number = this.getPersonBeingUpdated.extra_fields.disability_registration_number
          }

          let disability_ids = this.getPersonBeingUpdated.disabilities.map(i => i.id)

          this.disability_categories = this.allDisabilityCategories.map(i => {
            return disability_ids.includes(i.value)
          })
        }

        // if (this.getPersonBeingUpdated.allergies.length !== 0) {
        //   this.allergies = this.getPersonBeingUpdated.allergies
        // }
        if (this.getPersonBeingUpdated.preexisting_conditions.length !== 0) {

          this.preexisting_conditions = this.getPersonBeingUpdated.preexisting_conditions
        }
        if (Object.keys(this.getPersonBeingUpdated.other_conditions).length !== 0) {
          this.other_conditions = this.getPersonBeingUpdated.other_conditions
        }
        // if (Object.keys(this.getPersonBeingUpdated.other_allergies).length !== 0) {
        //   this.other_allergies = this.getPersonBeingUpdated.other_allergies
        // }

      }
    },
    setLocationFilter(payload){
      this.location = payload
    },
    isHealthWorker() {
      if (this.occupation !== '') {
        return this.allOccupations.filter(occupation => {
          return occupation.id === this.occupation
        })[0].name === "Health Worker"
      }
    },
    isTeacher() {
      if (this.occupation !== '') {
        return this.allOccupations.filter(occupation => {
          return occupation.id === this.occupation
        })[0].name === "Teacher"
      }
    },
    occupationIsOther() {
      // occupation is not empty
      if (this.occupation) {
        // get a clients occupation
        const occupationObject = this.allOccupations.find(occupation => {
          return occupation.id === this.occupation
        });

        if(occupationObject)
        // check if occupation is other
          return occupationObject.name === 'Other'
      }
    },
    addNewAllergy() {
      this.allergies.push(Vue.util.extend({}, this.allergy))
    },
    removeAllergy: function (index) {
      Vue.delete(this.allergies, index);
    },
    addNewOtherAllergy() {
      this.other_allergies.push(Vue.util.extend({}, this.other_allergy))
    },
    removeOtherAllergy: function (index) {
      Vue.delete(this.other_allergies, index);
    },
    addNewPreexistingCondition() {
      this.preexisting_conditions.push(Vue.util.extend({}, this.preexisting_condition))
    },
    removePreexistingCondition: function (index) {
      Vue.delete(this.preexisting_conditions, index);
    },
    addNewOtherPreexistingCondition() {
      this.other_conditions.push(Vue.util.extend({}, this.other_condition))
    },
    removeOtherPreexistingCondition: function (index) {
      Vue.delete(this.other_conditions, index);
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    confirmSubmission() {
      window.scrollTo(0, 0);
      this.confirmed = true
    },
    retrieveOccupations(){
      this.$store.dispatch('fetchOccupations')
    }
  },
  computed: {
    ...mapGetters([
      'getPersonBeingUpdated',
      'allIdentificationTypes',
      'allRelationshipsWithNextOfKin',
      'allReligions',
      'allOccupations',
      'allNationalities',
      'allPhoneCodes',
      'allAllergies',
      'allPreexistingConditions',
      'getError',
      'allHWCategories',
      'allDisabilityCategories'
    ]),
    formatted_disability_categories: function (){
      var results = []

      this.disability_categories.forEach((category, index) => {
        if(category){
          results.push(this.allDisabilityCategories[index].value)
        }
      })

      return results
    },
    identifications_list: function(){
      return this.identifications.map(id=>{
        return id.id_type
      })
    }
  },
  mounted() {
    this.setPersonToUpdateDetails()
    this.retrieveOccupations()
    this.retrievePreExistingConditions()
  },
  watch: {
    date_of_birth: function () {
      if (this.$func.calculateAge(this.date_of_birth) < 12) {
        this.valid_age = 'is-invalid'
      } else {
        this.valid_age = 'is-valid'
      }
    },
    phone: function () {
      if(this.phone.substring(0, 1) === "0"){
        // this.invalid_phone_message = "0's are not allowed"
        this.phone = this.phone.substring(1, this.phone.length)

      }else{
        // this.invalid_phone_message = '';
      }
      if (this.phone.length !== 9 && this.phone_code === '254') {
        this.valid_phone = 'is-invalid'
      } else {
        this.valid_phone = 'is-valid'
      }
    },
    identifications_list:function(){
      if(this.identifications_list.includes('unknown')){
        this.identifications[0].id_number =`${this.first_name.slice(0,1)}${this.middle_name.slice(0,1)}${this.last_name.slice(0,1)}`.toUpperCase()
      }

      if(this.identifications_list.includes('prisoner_id')){

        this.identifications.filter(id => {
          return id.id_type === 'unknown'
        })

        this.occupation = this.allOccupations.filter(occ => {
          return occ.name === 'Inmate'
        })[0].id
      }
    }
  },
}
</script>

<style scoped>

</style>
