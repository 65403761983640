<template>
  <div>
    <div class="alert alert-success alert-dismissible fade show col-md-6" v-if="getSuccessMessages.length !== 0"  id="success_alert" role="alert">
      <p v-for="(item, index) in getSuccessMessages" :key="index">
      <span v-for="(value, key) in item" :key="key">
        {{key}} : {{value}}
      </span>
      </p>
      <button type="button" class="close" @click="hide_err">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>

</template>

<script>
    import $ from "jquery";
    import {mapGetters} from "vuex";

    export default {
        name: "success_messages",
        computed: {
          ...mapGetters(['getSuccessMessages'])
        },
        methods:{
            hide_err(){
              this.$store.dispatch('setSuccessMessages', [])
                // $("#success_alert").addClass("d-none");
            },show_err(){
                $("#success_alert").removeClass("d-none");
            }
        },

    }
</script>

<style scoped>

</style>