<template>
  <div>
    <default_modal aria_label="Cancel Batch Report" modal_header="Reverse Daily Batch Report" modal_id="cancel_batch_report_modal">
      <div v-if="Object.keys(getError).length !==0 " id="err_alert" class="alert alert-danger alert-dismissible fade show"
           role="alert">
        <p v-for="(item, index) in getError" :key="index">
          <span v-for="(sub_item, index) in item" :key="index">{{ toTitleCase(sub_item) }}</span>
        </p>

        <button class="close" type="button" @click="hide_err">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <h4 class="text-center">
        Confirm reversal of daily report on
      </h4>
      <h4 class="text-center text-primary">
        {{report_to_cancel.date}}
      </h4>
      <label class="text-capitalize mt-2"> Remarks <span class="text-danger">*</span></label>
      <textarea name="remark" id="remark" v-model="remarks"
                class="form-control" required>
      </textarea>
      <template v-slot:modal_footer>
        <button v-if="submitting === false" class="btn btn-danger" @click="cancelBatchReport">
          Reverse Report
        </button>
        <div v-else>
          <SubmittingLoader/>
        </div>
      </template>
    </default_modal>
    <div class="card">
      <div class="card-header">
        <h4>Batch {{batch_number}} daily stock report</h4>
      </div>
      <div class="card-body">
        <p class="loader offset-md-6" v-if="loading"></p>
        <div v-else>
          <table v-if="Object.keys(batch_inventory_report).length !== 0" class="table table-bordered">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Doses Used</th>
              <th scope="col">Number Vaccinated</th>
              <th scope="col">System Number Vaccinated</th>
              <th scope="col">Current Doses Balance</th>
              <th scope="col">Prev Doses Balance</th>
              <th scope="col">Wasted Doses</th>
              <th scope="col">Adjustment Reason</th>
              <th scope="col">Record Type</th>
              <th scope="col">Performed By</th>
              <th scope="col">Remark</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody >
            <tr v-for="(report, index) in batch_inventory_report" v-bind:key="index">
              <td>
                {{report.date}}
              </td>
              <td class="text-capitalize">
                {{report.report_doses_used === null ? 'N/A' : numberWithCommas(report.report_doses_used)}}
              </td>
              <td class="text-capitalize">
                {{report.number_vaccinated === null ? 'N/A' : numberWithCommas(report.number_vaccinated)}}
              </td>
              <td class="text-capitalize">
                {{report.system_number_vaccinated === null ? 'N/A' : numberWithCommas(report.system_number_vaccinated)}}
              </td>
              <td class="text-capitalize">
                {{numberWithCommas(report.doses_balance)}}
              </td>
              <td class="text-capitalize">
                {{report.doses_balance_before_change === null ? 'N/A': numberWithCommas(report.doses_balance_before_change) }}
              </td>
              <td class="text-capitalize">
                {{report.doses_wasted === null ? 'N/A': numberWithCommas(report.doses_wasted)}}
              </td>
              <td class="text-capitalize">
                {{report.batch_transaction_type === 'transaction' ? 'Stock Update' : ''}}
                {{report.batch_transaction_type === 'report' ? 'Daily Stock Report' : ''}}
              </td>
              <td class="text-capitalize">
                {{report.adjustment_reason}}
              </td>
              <td>
                {{report.performed_by.username}}
              </td>
              <td>
                {{report.remarks}}
              </td>
              <td>
                <p class="text-danger" v-if="report.is_reversed">
                  Reversed
                </p>
                <div v-else>
                  <button v-if="index === index_to_return" class="btn btn-danger" @click="openCancelBatchReport(report)" type="button">
                    Reverse
                  </button>
                </div>

              </td>
            </tr>
            </tbody>
          </table>
          <p class="offset-md-5" v-else>No Reports Recorded Yet</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {numberWithCommas} from "@/utils/methods";
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";
import $ from "jquery";
import {mapGetters} from "vuex";

export default {
  name: "stock_report_table",
  components: {SubmittingLoader, default_modal},
  data(){
    return{
      batch_inventory_report: {},
      batch_number: '',
      loading: false,
      report_to_cancel: '',
      submitting: false,
      remarks: '',
      index_to_return: ''
    }
  },
  methods:{
    getBatchInventoryReport(){
      this.batch_number = this.$route.params.batch_number
      let batch_inventory_id = this.$route.params.batch_inventory_id
      this.loading = true
      this.$store.dispatch('fetchBatchInventoryReport', '?batch_inventory=' + batch_inventory_id).then(resp => {
        this.batch_inventory_report = resp
        this.loading = false
        this.canReverse()
        // eslint-disable-next-line no-unused-vars
      }).catch(err=>{
        this.$store.dispatch('setError', {'detail':'error fetching batch inventory report'})
        this.loading = false
      })
    },
    numberWithCommas(int){
      return numberWithCommas(int)
    },
    openCancelBatchReport(report) {
      this.report_to_cancel = report
      $('#cancel_batch_report_modal').modal('show')
    },
    canReverse(){
      for (var i = 0; i < this.batch_inventory_report.length; i++) {
        if (!this.batch_inventory_report[i].is_reversed) {
          this.index_to_return = i
          break;
        }
      }
    },
    cancelBatchReport(){
      let id = this.report_to_cancel.id
      let remarks = this.remarks
      let type = this.report_to_cancel.batch_transaction_type

      console.log('report_to_cancel', this.report_to_cancel)
      this.$store.dispatch('cancelBatchDailyReport', {
        id,
        remarks,
        type
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        $('#cancel_batch_report_modal').modal('hide')
        this.getBatchInventoryReport()
      }).catch(err => {
        if (err.response.status === 500) {
          this.$store.dispatch('setError', {'data':'An Unknown Error Occurred'})
        }else{
          this.$store.dispatch('setError', err.response.data)
        }
      })
    }
  },
  computed:{
    ...mapGetters(['getError'])
  },
  mounted() {
    this.getBatchInventoryReport()
  }
}
</script>

<style scoped>

</style>