<template>
  <div>
    <div class="px-2">
      <div class="form-row mb-3 bg-white px-3 py-3 rounded">
        <div class="form-group col-md-2">
          <label class="control-label" for="vaccine_filter">
            Filter By Disease
          </label>
          <select id="disease_filter" v-model="selected_disease_filter" class="form-control" required>
            <option value="">All</option>
            <option v-for="(disease, index) in all_diseases" v-bind:key="index" :value="disease.id">{{ disease.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label class="control-label" for="vaccine_filter">
            Filter By Vaccine
          </label>
          <select id="vaccine_filter" v-model="selected_vaccine_filter" class="form-control" required>
            <option value="">All</option>
            <option v-for="(vaccine, index) in all_vaccines" v-bind:key="index" :value="vaccine.id">{{ vaccine.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label class="control-label" for="stock_condition">
            Filter By Condition
          </label>
          <select id="stock_condition" v-model="selected_condition_filter" class="form-control" required>
            <option value="">All</option>
            <option v-for="(condition, index) in all_conditions" v-bind:key="index" :value="condition"
                    class="text-capitalize">
              {{ condition }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="selectLevel">
            Select Destination Level
          </label>
          <select id="selectLevel" v-model="selected_level_filter" class="form-control" required>
            <option value="">All</option>
            <option v-for="(level, index) in allLevels" v-bind:key="index" :value="level.name">
              {{ level.verbose_name }} Level
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="destinationCenterSelect">
            Select Destination Center
          </label>
          <select id="destinationCenterSelect" v-model="selected_center_filter"
                  :class="'form-control ' + checkIfErrorExists('to_center_id')" required="required">
            <option disabled value="">Select Center</option>
            <option v-for="(center, index) in filtered_centers[0]" v-bind:key="index"
                    :value="center.id" selected>
              {{ center.name }}
            </option>
          </select>
          <span
              class="text-muted text-capitalize font-12 text-success">Click on the dropdown and start typing to search</span>
        </div>
        <div class="form-group col-md-2">
          <label class="control-label" for="stock_condition">
            Filter By Action
          </label>
          <select id="action" v-model="selected_action_filter" class="form-control" required>
            <option value="">All</option>
            <option v-for="(action, index) in all_actions_types" v-bind:key="index" :value="action.value"
                    class="text-capitalize">
              {{ action.verbose_name }}
            </option>
          </select>
        </div>
        <!--        <div class="col-md-2">-->
        <!--          <div class="form-group">-->
        <!--            <label for="start_date"> Date</label>-->
        <!--            <div class="input-group">-->
        <!--              <input v-model='start_date_filter' id="start_date" :class="'datepicker-here form-control ' + checkIfErrorExists('start_date_filter')"-->
        <!--                     aria-describedby="basic-addon2"-->
        <!--                     placeholder="dd/mm/yyyy" type="date">-->
        <!--              <InputErrorComponent input_name="start_date_filter"/>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-md-3 d-flex pb-3">
          <button class="btn btn-primary align-self-end" type="button" @click="retrieveGroupedInventory">
            Filter
            <i class="feather icon-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div v-if="is_loading" class="col-md-4">
            <p class="loader"></p>
            Loading...
          </div>
          <div class="col-md-4">
            <h5 class="card-title">Distribution Ledger For
              <span v-if="!(is_loading)" class="text-defaultColor">{{ loaded_center_info.name }}</span>
            </h5>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input v-model="search_value" aria-describedby="button-addon2" aria-label="Search"
                     class="form-control" placeholder="Search by Center" type="search">
              <div class="input-group-append">
                <button id="button-addon2" class="btn" type="submit">
                  <i class="feather icon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!(is_loading)" class="card-body" style="overflow: scroll">
        <div v-if="allGroupedInventory.results.size === 0">
          <h4 class="text-center text-danger">
            No Ledger Records Found
          </h4>
        </div>
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Type</th>
            <th scope="col">Quantity</th>
            <th scope="col">Src Location</th>
            <th scope="col">Dst Location</th>
            <th scope="col">Batch</th>
            <th scope="col">VVM</th>
            <th scope="col">Expiry</th>
            <th scope="col">Action</th>
            <th scope="col">Batch Balance</th>
            <th scope="col">Product Balance</th>

          </tr>
          </thead>
          <tbody v-for="(value, index) in allGroupedInventory.results" :key="index">
          <tr v-for="(batch_value, index) in (value[1])  "
              :key="index" :class="[batch_value.is_reversed ? 'bg-danger':batch_value.action==='received' ? 'bg-light-success' : batch_value.action==='dispatched'? 'bg-light-danger' : '' ]">
            <td>{{ new Date(batch_value.created_at).toLocaleDateString() }}</td>
            <td class="text-capitalize">
              <span v-if="batch_value.action==='received'">Receive</span>
              <span v-if="batch_value.action==='dispatched'">Issue</span>
            </td>
            <td>{{ batch_value.quantity | withCommas }}</td>
            <td class="text-capitalize">
              <span
                  v-if="batch_value.center.id && batch_value.action!=='received'">from {{
                  batch_value.center.name
                }}</span>
            </td>
            <td>
              <span v-if="batch_value.to_center" class="text-capitalize">to {{ batch_value.to_center.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ value[1][0].batch.batch_number }} -
              {{ value[1][0].batch.vaccine.name }}
            </td>
            <td>{{ batch_value.vvm_status }}</td>
            <td>{{ new Date(batch_value.batch.date_of_expiry).toLocaleDateString() }}</td>
            <td><span v-if="batch_value.is_reversed" class="">Reversed</span></td>
            <td v-if="batch_value.batch_inventory_after !==null">
              {{ parseInt(batch_value.batch_inventory_after) | withCommas }}
            </td>
            <td v-else>N/A</td>
            <td v-if="batch_value.cumulative_inventory_after !== null">
              {{ parseInt(batch_value.cumulative_inventory_after) | withCommas }}
            </td>
            <td v-else>N/A</td>
          </tr>
          </tbody>
        </table>
        <ul class="pagination">
          <a href="#" v-if="allGroupedInventory.previous !== null" class="page-item" @click="gotoPage(allGroupedInventory.previous)"><a
              class="page-link">Previous</a></a>
          <a href="#" v-if="allGroupedInventory.next !== null" class="page-item" @click="gotoPage(allGroupedInventory.next)"><a class="page-link">Next</a>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InputErrorComponent from "@/components/users/InputErrorComponent";

export default {
  name: "distribution_ledger_report_tabular",
  // eslint-disable-next-line vue/no-unused-components
  components: {InputErrorComponent,},
  data: function () {
    return {
      action: "Received",
      receivedBy: 'none',
      batch_number: '324',
      quantity: '',
      date_of_manufacture: '',
      date_of_expiry: '',
      sourceCenter: null,
      vaccine_id: null,
      is_loading: true,
      search_value: '',
      inventory: null,
      batch_list_inventory: [],
      no_transactions: false,
      all_vaccines: [],
      all_diseases: [],
      selected_vaccine_filter: '',
      all_conditions: ["good", "expired", "damaged"],
      selected_condition_filter: '',
      selected_action_filter: '',
      selected_disease_filter: '',
      selected_level_filter: '',
      selected_center_filter: '',
      all_actions_types: [{value: 'received', verbose_name: 'receive'}, {value: 'dispatched', verbose_name: 'issue'}],
      start_date_filter: '',
      end_date_filter: '',
      filtered_centers: [],
      some_centers: [],
      selected_center: null,
      loaded_center_info: {}
    }
  },
  methods: {
    ...mapActions(['fetchCenters']),
    getAllVaccines() {
      this.$store.dispatch('fetchVaccines').then(resp => {
        this.all_vaccines = resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {"detail": "error fetching vaccine types"})
      });
    },
    retrieveAllCenters() {
      return this.fetchCenters();
    },
    getAllDiseases() {
      this.$store.dispatch('fetchDiseases').then(resp => {
        this.all_diseases = resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setError', {"detail": "error fetching diseases"})
      });
    },
    retrieveGroupedInventory() {
      this.selected_center = localStorage.getItem('id')
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchLedgerDistributionTransactions', '?center=' +
          this.selected_center
          + (this.selected_vaccine_filter !== '' ? '&vaccine=' + this.selected_vaccine_filter : '')
          + (this.start_date_filter !== '' ? '&date=' + this.start_date_filter : '')
          + (this.selected_condition_filter !== '' ? '&condition=' + this.selected_condition_filter : '')
          + (this.selected_action_filter !== '' ? '&action=' + this.selected_action_filter : '')
          + (this.selected_disease_filter !== '' ? '&disease=' + this.selected_disease_filter : '')
          + (this.selected_center_filter !== '' ? '&center=' + this.selected_center_filter : '')
          // eslint-disable-next-line no-unused-vars
      ).then(grouped_inventory => {
        // get center info
        let selected_center = this.selected_center_filter !== '' ? this.selected_center_filter : localStorage.getItem('id');
        this.getCenterById(selected_center)
      });
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    arrayHasValues(arr) {
      return arr.length > 0;
    },
    applyLedgerFilters() {

    },
    getCenterById(centerId) {
      this.is_loading = true
      this.$store.dispatch('fetchSingleCenter', centerId).then(resp => {
        this.loaded_center_info = resp
        this.is_loading = false
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false
        this.$store.dispatch('setError', {"detail": "could not fetch center"})
      })
    },
    getParams(str) {
      return str.split('?')[1];
    },
    gotoPage(url) {
      this.is_loading = true
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchLedgerDistributionTransactions', '?' + this.getParams(url)).then(grouped_inventory => {
        this.is_loading = false
        // eslint-disable-next-line no-unused-vars
      }).catch(err=>{
        this.is_loading = false
        this.$store.dispatch("setError", {"detail":"error while fetching page"})
      });
    },
  },
  computed: {
    ...mapGetters([
      'getError', 'allDispatchedInventory', 'centerInfo', 'allCenterInventory', 'allGroupedInventory', 'allCenters', 'allLevels'
    ]),

  },
  watch: {
    start_date_filter: function () {
      let new_start_date = new Date(this.start_date)
      let new_end_date = new Date(this.end_date)
      if (new_end_date < new_start_date) {
        this.$store.dispatch('setError', {"start_date": "Start date cannot be after end date"})
      }
    },
    end_date_filter: function () {
      let new_start_date = new Date(this.start_date)
      let new_end_date = new Date(this.end_date)
      if (new_end_date < new_start_date) {
        this.$store.dispatch('setError', {"end_date": "end date cannot be before start date"})
      }
    },
    selected_level_filter: function () {
      let all_centers = this.allCenters;
      this.filtered_centers = []
      this.filtered_centers.splice(0, 0, all_centers.filter(center => {
        return center.level === this.selected_level_filter;
      }))
      console.log("res", this.filtered_centers)

    }
  },
  mounted() {
    this.retrieveGroupedInventory();
    this.getAllVaccines();
    this.getAllDiseases();
    this.retrieveAllCenters();
  }
}
</script>
<style>
.bg-light-success {
  background: #AEFEC4;
}

.white-text {
  color: white !important;
}

.bg-light-danger {
  background: #FED4AE;
}

</style>