<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <div class="breadcrumbbar">

      <div class="row align-items-center">
        <div class="col-md-8 col-lg-8">
          <h4 class="page-title">{{ pageName }}</h4>
          <div class="breadcrumb-list">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ">
                <router-link :to="{'name':'home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ pageName }}</li>
            </ol>
          </div>
        </div>
      </div>

    </div>

    <!-- End Breadcrumbbar -->
  </div>

</template>`

<script>
import {mapGetters} from "vuex";
export default {
  name: "Breadcrumbs",
  computed:{
    ...mapGetters(['getError'])
  },
  props: [
    'pageName',
  ]
}
</script>

<style scoped>
.error-bar{
  position: fixed;
  align-self: center;
  z-index: 100;
  margin-top: 42px;
}
.breadcrumbbar {
  margin: 90px 30px 0 30px;
  padding: 0;
  background-color: transparent;
}
</style>
