<template>
  <div>
    <div class="card" v-if="isLoading">
      <div class="card-body text-center">
        <SubmittingLoader status="Loading"/>
      </div>
    </div>
    <div v-else class="card m-b-30">
      <div class="card-header">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <h5 class="card-title">{{new Date(day).toDateString()}}</h5>
            <h4 class="mt-3">{{data.count}} Vaccinees</h4>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="default-date">Select Day</label>
              <div class="input-group">
                <input @change="getDataForDay()" v-model='day' id="default-date" class="datepicker-here form-control"
                       aria-describedby="basic-addon2"
                       placeholder="dd/mm/yyyy" type="date">
              </div>
            </div>
          </div>
          <div class="col-md-3 d-none">
            <Vaccine_filter
                @setVaccineFilter="setVaccineFilter"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <h6 class="card-subtitle">
          NB: Only vaccinees who have not received a dose can be edited
        </h6>
        <div class="table-responsive m-b-30">
          <table class="table">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Identification</th>
              <th scope="col">Date of Birth</th>
              <th scope="col">Gender</th>
              <th scope="col">Contact</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(person, index) in data.results" v-bind:key="index">
              <td class="text-capitalize">{{ person.first_name }} {{ person.middle_name }}
                {{ person.last_name }}
              </td>
              <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ $func.titleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
              </td>
              <td>
                {{ new Date(person.date_of_birth).toDateString() }} ({{ $func.calculateAge(person.date_of_birth) }} Years)
              </td>
              <td>
                <span v-if="person.gender === 'M'" class="mb-0">
                  Male
                </span>
                <span v-if="person.gender === 'F'">
                  Female
                </span>
                <span v-if="person.gender === 'O'">
                  Intersex
                </span>
                <span v-if="person.gender === 'T'">
                  Trans
                </span>
              </td>
              <td>
                {{ person.phone }}
              </td>
            </tr>
            </tbody>
          </table>
          <ul class="pagination">
            <li v-if="data.previous !== null" @click="previousPage()" class="page-item"><a class="page-link" href="#">Previous</a></li>
            <li v-if="data.next !== null" @click="nextPage()" class="page-item"><a class="page-link" href="#">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import $ from "jquery";
import Vaccine_filter from "../filters/Vaccine_filter";
import SubmittingLoader from "../layouts/includes/SubmittingLoader";

export default {
  name: "PersonTable",
  components: {SubmittingLoader, Vaccine_filter},
  props: {
    data: Object,
    action: String,
    isLoading: Boolean
  },
  data() {
    return {
      person: '',
      search_value: '',
      day: this.$func.formatDate(new Date())
    }
  },
  methods: {
    setVaccineFilter(vaccine_filter, doses){
      this.vaccine_filter = vaccine_filter
      this.vaccine_doses = doses
      this.updateFullPayload()
    },
    getSelectedPerson(person_id) {
      this.$store.dispatch('fetchPersonToUpdate', person_id).then(resp => {
        router.push("update-vaccinee", resp.id);
      })
    },
    openDetailsModal(person) {
      $('#personModal').modal('show')
      this.person = person
    },
    getDataForDay(){
      return this.$store.dispatch(this.action, '?limit=10&offset=0&date='+ String(this.day) )
    },
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      return this.$store.dispatch(this.action, '?'+ this.getParams(this.data.next) + '&date=' + this.day)
    },
    previousPage(){
      return this.$store.dispatch(this.action, '?'+ this.getParams(this.data.previous) + '&date=' + this.day)
    }
  },
}
</script>

<style scoped>

</style>