
<template>
  <div v-if="Object.keys(personDetails).length !== 0" class="card">
    <div class="card-body">
      <div v-if="personVaccinations.length === 0">
        <div v-if="is_loading" class="offset-5">
          <p class="loader"></p>
          <p>Loading</p>
        </div>
        <div v-else>
          <div v-if="all_batches.length === 0">
            <h4 class="text-danger">
              Receive doses in your facility to enable vaccination
            </h4>
          </div>
          <div v-else>
            <h4>Administer Dose to {{ personDetails.first_name }} {{ personDetails.middle_name }}
              {{ personDetails.last_name }}</h4>
            <!--          <div v-if="has_reported_all_dates">-->
            <form @submit.prevent="administerVaccine">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="batch">
                    Dose
                    <span class="text-danger">*</span>
                  </label>
                  <select id="dose" v-model="dose_filter" :class="'form-control ' + checkIfErrorExists('dose')" required>
                    <option value="dose_2">
                      Dose 2
                    </option>
                    <option value="booster">
                      Booster Dose
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label class="control-label" for="batch">
                    Batch
                    <span class="text-danger">*</span>
                  </label>
                  <select id="batch" v-model="batch" :class="'form-control ' + checkIfErrorExists('batch')" required>
                    <option
                        v-for="(batch, index) in (vaccinatingBatches.filter(curr_batch=>{ return (curr_batch.good_quantity - curr_batch.used_quantity)> 0}))"
                        :key="index" :value="batch.batch.id">
                      Batch {{ batch.batch.batch_number }} {{ batch.batch.vaccine.name }} In Stock
                      {{ batch.good_quantity - batch.used_quantity }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="date_administered">
                    Date administered
                    <span class="text-danger">*</span>
                  </label>
                  <input id="date_administered" v-model="date_administered" :class="'form-control ' + valid_date"
                         required type="datetime-local">
                  <div class="invalid-feedback">
                <span v-if="valid_date === 'is-invalid'">
                  The date has to be between February and Today
                </span>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="control-label" for="facility">
                    Vaccinated at
                    <span class="text-danger">*</span>
                  </label>
                  <select id="facility" v-model="facility" class="form-control" required>
                    <option v-for="(facility, index) in facilities" :key="index" :value="facility.id">
                      {{ facility.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="temperature">
                    Current Body Temperature
                  </label>
                  <input id="temperature" v-model="temperature" class="form-control" type="text">
                </div>
                <div class="form-group col-md-6">
                  <label class="control-label" for="vaccinator">
                    Vaccinator
                    <span class="text-danger">*</span>
                  </label>
                  <select id="vaccinator" v-model="vaccinator" class="form-control" required>
                    <option v-for="(vaccinator, index) in vaccinators" :key="index" :value="vaccinator.id">
                      {{ vaccinator.username }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group form-check">
                  <input id="consent" v-model="consent" class="form-check-input" type="checkbox">
                  <label class="form-check-label" for="consent">Has given consent</label>
                </div>
              </div>
              <div v-if="consent === false" class="form-row">
                <div class="form-group col-md-6">
                  <label for="noConsentReason">Give Reason</label>
                  <textarea id="noConsentReason" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <button v-if="temperature < 38 && show_submit" class="btn btn-primary">
                Administer Vaccine
                <i class="feather icon-arrow-right"></i>
              </button>
              <h4 v-if="temperature > 38" class="text-danger">
                Temperature is too high to administer vaccine
              </h4>
            </form>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 class="text-center text-danger">
          The client has already been vaccinated
        </h4>
      </div>
    </div>
  </div>
</template>
.
<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "administration_form",
  data: function () {
    return {
      person_id: '',
      batch: '',
      dose_filter: '',
      dose: '',
      facility: '',
      facilities: [],
      date_administered: '',
      vaccinator: '',
      vaccinators: [],
      temperature: '',
      date_scheduled: '',
      consent: true,
      is_loading: false,
      valid_date: '',
      show_submit: true,
      last_report_date: '',
      has_reported_all_dates : false,
      all_batches: '',
      vaccinatingBatches: ''
    }
  },

  computed: {
    ...mapGetters(['personDetails', 'personVaccinations','centerInfo', 'getUser', 'getError', 'allSystemUsers', 'getVaccinators']),
  },
  methods: {
    ...mapActions(['setError']),
    administerVaccine() {
      this.is_loading = true;
      // remove errors
      this.setError({})
      let person_id = this.personDetails.id
      let batch = this.batch
      let dose = this.dose
      let facility = this.facility
      let date_administered = this.date_administered
      let vaccinator = this.vaccinator
      let temperature = this.temperature
      let date_scheduled = this.date_scheduled

      let payload = {
        person_id,
        batch,
        dose,
        facility,
        date_administered,
        date_scheduled,
        vaccinator,
        temperature,
      }

      if(this.dose_filter === 'booster'){
        payload.is_booster = true
      }

      if(this.$route.params.skipSchedule){
        payload.params = '?skipSchedule=1';
      }else{
        payload.params = '';
      }

      this.$store.dispatch('administerVaccine', payload).then(resp => {
        if (resp) {
          this.is_loading = false;
          this.$store.dispatch('setSuccessMessages', [{"message": "Vaccine administered successfully"}])
          this.$store.dispatch('setPersonDetails', {})
          this.$router.push({name: "persons"})
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
    retrieveVaccinationBatches() {
      this.is_loading = true;
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchVaccinationBatches').then(resp => {
        this.all_batches = resp

        this.vaccinatingBatches = []

        this.is_loading = false
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.setError({'detail': 'error fetching inventory'})
        this.is_loading = false
      });
    },
    formatDate() {
      let date = new Date();
      return this.date_administered =
          date.getFullYear() + "-" +
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "-" + ("00" + date.getDate()).slice(-2) +
          "T" + ("00" + date.getHours()).slice(-2) +
          ":" + ("00" + date.getMinutes()).slice(-2)
    },
    getDateScheduled() {
      let date = new Date();
      return this.date_scheduled =
          date.getFullYear() + "-" +
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "-" + ("00" + date.getDate()).slice(-2)
    },
    retrieveVaccinators() {
      return this.$store.dispatch('fetchVaccinators')
    },
    setCurrentFacility() {
      this.facilities.push(this.centerInfo)
      this.facility = this.centerInfo.id
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    getJWT() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres = JSON.parse(atob(tk.split('.')[1]));
        // console.log("token", tkres)
        this.vaccinator = tkres.user_id
        this.vaccinators.push({
          id: tkres.user_id,
          username: tkres.username
        })
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
  },
  watch: {
    dose_filter: function () {
      if(this.dose_filter === 'booster'){
        this.vaccinatingBatches = this.all_batches.filter(inv => {
          return inv.batch.vaccine.doses.find(dose => {
            return dose.is_booster
          })
        })
      }else{
        this.vaccinatingBatches = this.all_batches
      }
    },
    batch: function () {
      let batch = this.vaccinatingBatches.filter(inventory => {
        return inventory.batch.id === this.batch
      })[0].batch

      if(this.dose_filter === 'booster'){
        this.dose = batch.vaccine.doses.at(-1).id
      }else{
        this.dose = batch.vaccine.doses.filter(dose => {
          return dose.dose_number === 2
        })[0].id
      }

    },
    date_administered: function () {
      let date_administered = new Date(this.date_administered)
      if (date_administered < new Date('2021-2-1') || date_administered > new Date()) {
        this.valid_date = 'is-invalid'
        this.show_submit = false
      } else {
        this.valid_date = 'is-valid'
        this.show_submit = true
      }
    },
  },
  mounted() {
    // this.retrieveCurrentCenter()
    this.retrieveVaccinationBatches();
    this.formatDate();
    this.getDateScheduled();
    // this.retrieveVaccinators();
    this.setCurrentFacility()
    this.getJWT()
  }
}
</script>

<style scoped>

</style>