<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Defaulters by age"></breadcrumbs>

    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="$func.userHasRoles( ['Vaccinator', 'Trainer of Trainers(TOT)' ,'Manager', 'HRIO', 'Support'])">
        <DefaultersByAge/>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import {mapActions, mapGetters} from "vuex";
import AccessDeniedCard from "../../components/templates/AccessDeniedCard";
import DefaultersByAge from "../../components/reports/DefaultersByAge";

export default {
  name: "index",
  components: {DefaultersByAge, AccessDeniedCard, Breadcrumbs},
  methods: {
    ...mapActions(['setError']),
    clearErrors(){
      this.setError({})
    },
    getUserRoles() {
      var tk = this.$store.getters.getToken;
      // var user = this.$store.getters.getUserInStorage;

      try {
        var tkres =  JSON.parse(atob(tk.split('.')[1]));
        return tkres.roles
      } catch (e) {
        console.log("err", e)
      }
    },
    userHasRole(role){
      return this.getUserRoles().includes(role)
    }
  },
  computed:{
    ...mapGetters([
      'centerInfo'
    ])
  },
  mounted() {
    this.clearErrors()
    this.getUserRoles()
  }
}
</script>

<style scoped>

</style>