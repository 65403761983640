<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs
        :pageName="'Administer Vaccine to ' + personDetails.first_name +' '+ personDetails.middle_name  +' '+ personDetails.last_name"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
      <vaccine_administration_form></vaccine_administration_form>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import vaccine_administration_form from "@/components/vaccine_administration/vaccine_administration_form";
import success_messages from '@/components/layouts/includes/success_messages.vue';
import {mapGetters} from "vuex";
import {mapActions} from "vuex/dist/vuex.mjs";

export default {
  name: "administer_vaccine",
  components: {vaccine_administration_form, Breadcrumbs, success_messages},
  methods: {
    ...mapActions(['fetchPersonDetails']),
  },
  computed: {
    ...mapGetters([
      'personDetails'
    ])
  },
}
</script>

<style scoped>

</style>