<template>
  <div class="card mw-100">
    <div class="card-header">
      <h4>
        Vaccinations By Month
      </h4>
    </div>
    <div class="card-body">
      <div v-if="!loaded_periodic_chart" class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
        <span class="sr-only">Loading...</span>
      </div>
      <canvas id="periodic-jabs"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'PeriodicJabs',
  data(){
    return {
      labels: [],
      totals: [],
      from: '2021-03-01',
      upto: this.$func.formatDate(new Date()),
      period: 'month',
      loaded_periodic_chart: false
    }
  },
  methods: {
    fetchPeriodicJabs(){
          // ?from=2021-03-01&upto=2021-11-09&period=month
      this.$store.dispatch('fetchPeriodicJabs', '?from=' + this.from + '&upto=' + this.upto
        + '&period=' + this.period
      ).then(resp => {
        this.loaded_periodic_chart = true;
        this.plotChart(resp)
      })
    },
    plotChart(data){
      this.totals = data.map(obj => {
        return obj.total
      })

      this.dose_1 = data.map(obj => {
        return obj.dose_1
      })

      this.dose_2 = data.map(obj => {
        return obj.dose_2
      })

      this.labels = data.map(obj => {
        return new Date(obj.time).toLocaleString('default', { month: 'long' })
      })

      // this.labels = this.$func.datesInRange('2021-3-1', this.$func.formatDate(new Date()))

      const ctx = document.getElementById('periodic-jabs');
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Total Vaccinations",
              type: "line",
              data: this.totals,
              borderWidth: 3,
              lineTension: 0.4,
              backgroundColor: [
                'rgba(29,82,136,1)',
              ],
              borderColor: [
                'rgba(29,82,136,1)',
              ],
            },
            {
              label: "Dose 1",
              data: this.dose_1,
              borderWidth: 3,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
              ],
              borderColor: [
                'rgb(255, 99, 132)',
              ],
            },
            {
              label: "Dose 2",
              data: this.dose_2,
              borderWidth: 3,
              backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgb(75, 192, 192)',
              ],
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ],
          }
        }
      });

      this.loaded_periodic_chart = true
    }
  },
  mounted() {
    this.fetchPeriodicJabs()
  }
}
</script>
