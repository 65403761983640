import api from "@/api";

const state ={
  facilities:[]
}
const getters ={
  allFacilities(state){
      return state.facilities
  }

}
const actions ={
  fetchFacilities({commit}) {
    return new Promise(((resolve, reject) => {
      api.get("/health-facilities/").then(resp => {
        commit("setFacilities", resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    }))
  },
}
const mutations ={
  setFacilities:(state, facilities) => (state.facilities = facilities)

}

export default {
  state,
  getters,
  actions,
  mutations,
}