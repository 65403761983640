<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <facility_filter v-if="facilities_loaded"
              :default="facility_filter" :facilities="facilities" @updatedFacilityFilter="updatedFacilityFilter"
          />
        </div>
        <div class="col-md-4">
          <Vaccine_filter
              @setVaccineFilter="setVaccineFilter"
          />
        </div>
        <div class="col-md-4">
          <div class="row">
            <DateFromFilter
                @setDateFromFilter="setDateFromFilter"
                class="col-md-6"
            />
            <DateToFilter
                @setDateToFilter="setDateToFilter"
                class="col-md-6"
            />
          </div>
        </div>
        <div class="col-md-2 d-flex align-self-center mt-3">
          <button type="button" @click="retrieveDefaulters()" class="btn btn-primary">
            Filter Defaulters
            <i class="feather icon-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3" v-if="$func.userHasRoles(['Trainer of Trainers(TOT)', 'HRIO'])">
          <SubmittingLoader status="Downloading CSV" v-if="downloading_csv" class="mb-3"></SubmittingLoader>
          <button v-else @click="fetchDefaultersCSV" class="btn btn-primary mr-2 mt-4">
            Download Defaulters CSV
            <i class="feather icon-file-text"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h4 class="mt-3">
            {{allDefaulters.count | withCommas}} Defaulters
          </h4>
        </div>
      </div>
      <default_table :isLoading="is_loading" @previousPage="previousPage()" @nextPage="nextPage()">
        <template #thead>
          <th>#</th>
          <th v-for="(header, index) in headers" v-bind:key="index">
            {{header}}
          </th>
        </template>
        <template #tbody>
          <tr v-for="(person, index) in allDefaulters.results" v-bind:key="index">
            <td>{{index+offset+1}}</td>
            <td>
              <span class="text-capitalize">
                {{ person.first_name }} {{ person.middle_name }} {{ person.last_name }}
              </span>
            </td>
            <td>
              <span v-for="(identification, index) in person.identification" v-bind:key="index"
                    class="mb-0">
                <strong>
                  {{ $func.titleCase(identification.id_type) }}:
                </strong> {{ identification.id_number }}
              </span>
            </td>
            <td>
              {{ $func.maskString(person.phone) }}
            </td>
            <td>
              <button @click="viewPerson(person.id)" class="btn btn-primary">
                View Person
                <i class="feather icon-arrow-right"></i>
              </button>
            </td>
          </tr>
        </template>
      </default_table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import default_table from "@/components/templates/DefaultTable";
import facility_filter from "@/components/filters/facility_filter";
import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";
import Vaccine_filter from "../filters/Vaccine_filter";
import DateFromFilter from "../filters/DateFromFilter";
import DateToFilter from "../filters/DateToFilter";

export default {
  name: "vaccination_defaulters_report",
  components: {DateToFilter, DateFromFilter, Vaccine_filter, facility_filter, default_table, SubmittingLoader},
  data() {
    return {
      headers: [
        'Name ',
        'Identification',
        'Contact',
        'Actions'
      ],
      offset: 0,
      is_loading: true,
      facilities: [],
      facilities_loaded: false,
      facility_filter: '',
      downloading_csv: false,
      vaccine_filter: '',
      from_date: '&from=' + this.$func.formatDate(new Date("3/1/2021")),
      to_date: '&upto=' + this.$func.formatDate(new Date())
    }
  },
  methods: {
    ...mapActions([
        'fetchCenters'
    ]),
    // On initial load
    retrieveDefaulters() {
      this.is_loading = true
      this.offset = 0
      console.log('test', this.payload)

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchDefaulters', '?limit=20&offset=0'+this.payload).then(resp => {
        this.is_loading = false
      })
    },
    viewPerson(person_id) {
      // eslint-disable-next-line no-unused-vars
      return this.$store.dispatch('fetchPersonDetails', "/person/" + person_id + "/").then(resp => {
        this.$router.push({name: "persons"})
      })
    },
    getParams(str) {
      return str.split('?')[1];
    },
    nextPage(){
      this.is_loading = true
      this.offset = this.offset + 20
      return this.$store.dispatch('fetchDefaulters', `?${this.getParams(this.allDefaulters.next)}`).then(
          this.is_loading = false
      )
    },
    previousPage(){
      this.is_loading = true
      this.offset = this.offset - 20
      return this.$store.dispatch('fetchDefaulters', `?${this.getParams(this.allDefaulters.previous)}`).then(
          this.is_loading = false
      )
    },
    retrieveFacilities() {
      this.$store.dispatch('fetchSubCounty', this.centerInfo.sub_county).then(resp => {
        let subcounty = resp

        this.$store.dispatch('fetchCounty', subcounty.county).then(resp => {
          this.$store.dispatch('fetchCenters', `?county=${resp.id}`).then(resp => {
            this.facilities = resp;
            this.facility_filter = this.centerInfo.id;
            this.retrieveDefaulters();
            this.facilities_loaded = true;
          })
        })
      })
    },
    updatedFacilityFilter(facility_filter){
      this.facility_filter = facility_filter
    },
    fetchDefaultersCSV(){
      this.downloading_csv = true
      this.$store.dispatch('fetchDropoutsCSV', '?facility=' + this.facility_filter).then(resp => {
        this.$func.downloadCSV(resp, 'defaulters_csv')
        this.downloading_csv = false
      })
    },
    setVaccineFilter(vaccine_filter){
      this.vaccine_filter = vaccine_filter
    },
    setDateFromFilter(payload){
      this.from_date = payload
    },
    setDateToFilter(payload){
      this.to_date = payload
    },
  },
  computed: {
    ...mapGetters([
      'allDefaulters',
      'allCenters',
      'centerInfo',
      'allSubCounties',
      'allCounties'
    ]),
    payload: function () {
      return `&facility=${this.facility_filter}${this.vaccine_filter}${this.from_date}${this.to_date}`
    }
  },
  mounted() {
    this.retrieveFacilities()
  },
}
</script>

<style scoped>

</style>