<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs
        :pageName="'View vaccination certificate'"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <success_messages></success_messages>
<!--      <router-link :to="{name: 'persons'}" class="btn btn-primary my-3">-->
<!--        <i class="feather icon-arrow-left"></i>-->
<!--        Back-->
<!--      </router-link>-->
      <view_vaccination_certificate></view_vaccination_certificate>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex/dist/vuex.mjs";
import {mapGetters} from "vuex";
import view_vaccination_certificate from "@/components/vaccination_certificate/view_vaccination_certificate";
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';

export default {
  name: "vaccine_certificate",
  components: {view_vaccination_certificate, Breadcrumbs, success_messages},
  methods: {
    ...mapActions(['fetchPersonDetails']),
  },
  computed: {
    ...mapGetters([
      'personDetails'
    ])
  }
}
</script>

<style scoped>

</style>