import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index'
import error404 from '../views/utility/error404'
import store from '../store/index'
import support_routes from "./support/support_routes";
import person_routes from "./persons/person_routes";
import gadget_routes from "./gadgets/gadget_routes";
import inventory_routes from "./inventory/inventory_routes";
import reports_routes from "./reports/reports_routes";
import notification_routes from "./notifications/notification_routes";
import user_management_routes from "./users/user_management_routes";
import account_management from "./account_management/account_management";

Vue.use(VueRouter)

const sharedRoutes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        isSingleRoute: true
    },
    {
        path: '*',
        name: 'error404',
        component: error404,
        showInLeftBar: false
    }
]

let allRoutes = []
allRoutes = allRoutes.concat(sharedRoutes, support_routes, person_routes, gadget_routes,
    notification_routes, inventory_routes, reports_routes, user_management_routes, account_management)

const routes = allRoutes
const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
