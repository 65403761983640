import api from "@/api";
import $ from "jquery";

const state ={
  inventory_to_receive :[],
  inventory :[],
  grouped_inventory: [],
  center_inventory: [],
  available_inventory: [],
  expired_inventory: [],
  distribution_transactions: [],
  dispatched_inventory: [],
  inventory_for_vaccine: [],
  inventory_per_store: [],
  vaccination_batches: []
}
const getters ={
  allInventoryToReceive(state){
    return state.inventory_to_receive
  },
  allInventory(state){
      return state.inventory
  },
  allGroupedInventory(state){
    return state.grouped_inventory  
  },
  allCenterInventory(state){
    return state.center_inventory
  },
  allAvailableInventory(state){
    return state.available_inventory
  },
  allExpiredInventory(state){
    return state.available_inventory
  },
  allVaccinationBatches(state){
    return state.vaccination_batches
  },
  allDistributionTransactions(state){
    return state.distribution_transactions
  },
  allDispatchedInventory(state){
    return state.dispatched_inventory
  }
}
const actions ={
  fetchInventoryToReceive({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get("/distribution-transactions/"+payload).then(resp=>{
        commit("setInventoryToReceive", resp.data
      )
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchInventoryToDispatch({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get("/distribution-transactions/"+payload).then(resp=>{
        commit("setInventoryToReceive", resp.data
        )
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchBatchInventoryReport({commit},payload){
    return new Promise((resolve, reject) => {
      api.get('/batch-inventory-daily-balance/'+ payload).then(resp =>{
        resolve(resp.data.reverse())
      }).catch(err=>{
        commit('set_error', {})
        reject(err)
      })
    });
  },
  fetchDispatchedInventory({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get("/distribution-transactions/"+payload).then(resp=>{
        commit("setDispatchedInventory", resp.data
        )
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchLedgerDistributionTransactions({commit}, payload){
    return new Promise((resolve , reject) =>{
        api.get("/distribution-transactions"+payload).then(resp=>{
          resp.data.results.map(element =>{
            element.batch_number = element.batch.batch_number
          })
          function groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
              const key = keyGetter(item);
              const collection = map.get(key);
              if (!collection) {
                map.set(key, [item]);
              } else {
                collection.push(item);
              }
            });
            return map;
          }
          const grouped = groupBy(resp.data.results, el => el.batch.batch_number);
          let results_with_pagination ={
            count: resp.data.count,
            next: resp.data.next,
            previous: resp.data.previous,
            results: grouped,
          }
          commit("setGroupedInventory", results_with_pagination)
          resolve(results_with_pagination)
        }).catch(err =>{
          reject(err)
        })
    });
  },
  createInventory({commit}, payload){
    return new Promise((resolve , reject) =>{
      api({url:'/distribution-transactions/', method:"POST", data: payload}).then(resp =>{
        commit('setInventory',resp.data)
        resolve(resp.data)
      }).catch(err =>{
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  fetchCenterInventory({commit}, payload){
    return new Promise((resolve , reject) =>{
        api.get(`/batch-inventory-minimal/${payload}`).then(resp=>{
          commit("setCenterInventory", resp.data);
        // .filter(inventory => {
        //     return inventory.good_quantity > 0
        //   })
          resolve(resp.data)
        }).catch(err =>{
          reject(err)
        })
    });
  },
  fetchAvailableInventory({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get(`/batch-inventory-minimal/available/${payload}`).then(resp=>{
        commit("setAvailableInventory", resp.data);
        resolve(resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchExpiredInventory({commit}){
    return new Promise((resolve , reject) =>{
      api.get(`/batch-inventory-minimal/expired/`).then(resp=>{
        commit("setExpiredInventory", resp.data);
        resolve(resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchVaccinationBatches({commit}, payload={in_stock_batches_only:true}){
    return new Promise((resolve , reject) =>{
      api.get("/batch-inventory-minimal/available").then(resp=>{
        //if all batches is set to false, then show only in stock batches
        commit("setVaccinationBatches", payload.in_stock_batches_only ? resp.data.filter(inventory => {
          return (inventory.good_quantity - inventory.used_quantity) > 0
        }) : resp.data)
        resolve(resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchAllVaccineBatches({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get(`/vaccine-batch${payload}`).then(resp=>{
        commit("setVaccinationBatches", resp.data)
        resolve(resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchInventoryForVaccine({commit}, payload){
    return new Promise((resolve , reject) =>{
      api.get("/batch-inventory/" + payload).then(resp=>{
        commit("setInventoryForVaccine", resp.data.filter(inventory => {
          return inventory.good_quantity > 0
        }))
        resolve(resp.data)
      }).catch(err =>{
        reject(err)
      })
    });
  },
  // eslint-disable-next-line no-unused-vars
  editDistributionTransactions({commit}, payload){
    return new Promise((resolve, reject)=>{
      let newPayload = {is_reversed: payload.is_reversed};
      api.patch("/distribution-transactions/" + payload.id + '/', newPayload ).then(resp=>{
        $('#reverse_distribution_modal').modal('hide')
          resolve(resp.data)
      }).catch(err=>{
        reject(err);
      })
    })
  },
  fetchDistributionTransactions({commit}){
    return new Promise((resolve , reject) =>{
      api.get("/distribution-transactions/").then(resp=>{
        commit("setDistributionTransactions", resp.data
        )
      }).catch(err =>{
        reject(err)
      })
    });
  },
  fetchPerStoreInventory({commit}, payload){
    let payloadUrl= ''
    if(payload !==undefined){
      payloadUrl = payload
    }
    return new Promise((resolve, reject)=>{
      api.get("/store-inventories?"+ payloadUrl).then(resp=>{
        commit("setInventoryPerStore", resp.data)
        resolve(resp.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
}

const mutations ={
  setVaccinationBatches:(state, vaccination_batches) => (state.vaccination_batches = vaccination_batches),
  setInventoryToReceive:(state, inventory_to_receive) => (state.inventory_to_receive = inventory_to_receive),
  setCenterInventory:(state, center_inventory) => (state.center_inventory = center_inventory),
  setAvailableInventory:(state, available) => (state.available_inventory = available),
  setExpiredInventory:(state, expired) => (state.expired_inventory = expired),
  setInventoryForVaccine:(state, inventory_for_vaccine) => (state.inventory_for_vaccine = inventory_for_vaccine),
  setInventory:(state, inventory) => (state.inventory = inventory),
  setGroupedInventory:(state, grouped_inventory) => (state.grouped_inventory = grouped_inventory),
  setDistributionTransactions:(state, distribution_transactions) => (state.distribution_transactions = distribution_transactions),
  setDispatchedInventory:(state, dispatched_inventory) => (state.dispatched_inventory = dispatched_inventory),
  setInventoryPerStore:(state, inventory_per_store) => (state.inventory_per_store = inventory_per_store),
}

export default {
  state,
  getters,
  actions,
  mutations,
}