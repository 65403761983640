<template>
  <div v-if="inventory" id="dispatchModal" aria-hidden="true" aria-labelledby="dispatchModalLabel"
       class="modal fade"
       role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="dispatchModalLabel" class="modal-title">Dispatch {{ inventory.batch ? inventory.batch.vaccine.name : '' }}, Batch
            No {{ inventory.batch ? inventory.batch.batch_number : '' }} Confirmation</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <error_messages/>
          <div class="form-group">
            <label for="s11" class="control-label">
              S11
            </label>
            <input id="s11" class="form-control" type="text" required>
          </div>
          <div class="form-group">
            <label for="dispatcherID">
              ID of Person collecting
              <span class="text-danger">*</span>
            </label>
            <input id="dispatcherID" v-model="receiver_id" class="form-control" required type="text">
          </div>
          <div class="form-group">
            <label for="dispatcherName">
              Name of Person collecting
              <span class="text-danger">*</span>
            </label>
            <input id="dispatcherName" v-model="receiver_name" class="form-control" required type="text">
          </div>
          <div class="form-group">
            <label for="dispatcherPhone">
              Phone Number of Person collecting
              <span class="text-danger">*</span>
            </label>
            <input id="dispatcherPhone" v-model="receiver_phone" class="form-control" required type="text">
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="search_center_value">Search Center</label>
              <input id="search_center_value" v-model="search_center_value" class="form-control" type="text">
            </div>
          </div>
          <div class="row" v-if="centerSearchResults.length !== 0">
            <div v-for="(result,index) in centerSearchResults" v-bind:key="index" class="col-md-4">
              <a v-if="result.id === dispatchTo" class="btn btn-success d-block mb-2" @click="setCenter(result)">
                Assigned to {{ result.name }}
                <i class="feather icon-check"></i>
              </a>
              <a v-else class="btn btn-primary d-block mb-2" @click="setCenter(result)">
                Assign to {{ result.name }}
                <i class="feather icon-arrow-right"></i>
              </a>
            </div>
          </div>
          <div class="row justify-content-center my-2" v-if="search_center_value !== '' && centerSearchResults.length === 0">
                        <span class="text-danger text-center">
                          No Center Found!
                        </span>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="destinationLevelSelect">
                Select Destination Level
              </label>
              <select id="destinationLevelSelect" v-model="destinationLevel" class="form-control" required>
                <option disabled value="">Select Destination Level</option>
                <option v-for="(level, index) in allLevels" v-bind:key="index" :value="level.name">
                  {{ level.verbose_name }} Level
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="destinationCenterSelect">
                Select Destination Center
                <span class="text-danger">*</span>
              </label>
              <select id="destinationCenterSelect" v-model="dispatchTo"
                      :class="'form-control ' + checkIfErrorExists('to_center_id')" required="required">
                <option disabled value="">Select Destination Center</option>
                <option v-for="(center, index) in filteredDestinations" v-bind:key="index"
                        :value="center.id" selected>
                  {{ center.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                            <span v-for="(error, index) in getError.to_center_id" v-bind:key="index">
                              {{ error }}
                            </span>
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <div class="col">
              <label for="condition">
                Select Condition
                <span class="text-danger">*</span>
              </label>
              <select id="condition" v-model="condition" class="form-control" required>
                <option value="good" selected>Good</option>
                <option value="expired">Expired</option>
                <option value="damaged">Damaged</option>
              </select>
            </div>
            <div class="col">
              <label for="amountToDispatch">
                Number of Doses
                <span class="text-danger">*</span>
              </label>
              <input id="amountToDispatch" v-model="amountToDispatch"
                     :class="'form-control ' + checkIfErrorExists('quantity')" name="amountToDispatch"
                     required type="number">
              <div class="invalid-feedback">
                            <span v-for="(error, index) in getError.quantity" v-bind:key="index">
                              {{ error }}
                            </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="vvm_status" class="control-label">
              VVM Status
              <span class="text-danger">*</span>
            </label>
            <select v-model="vvm_status" class="form-control" id="vvm_status" required>
              <option value="N/A" selected>N/A</option>
              <option value="1">Stage 1</option>
              <option value="2">Stage 2</option>
              <option value="3">Stage 3</option>
              <option value="4">Stage 4</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>

          <a v-if="submitting === true" class="btn btn-white pr-2">
            Loading
            <div class="spinner-grow  text-success" role="status" style="height: 20px; width: 20px">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status"  style="height: 20px; width: 20px">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning" role="status"  style="height: 20px; width: 20px">
              <span class="sr-only">Loading...</span>
            </div>
          </a>
          <a v-else class="btn btn-primary pr-2"
             @click="dispatchInventory()">
            Proceed to dispatch
            <i class="feather icon-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
import error_messages from "@/components/layouts/includes/error_messages";

export default {
name: "DispatchModal",
  components: {error_messages},
  props: ['inventory'],
  data() {
    return {
      amountToDispatch: '',
      condition: 'good',
      destinationLevel: '',
      dispatchTo: null,
      remark: 'Dispatched in good condition',
      filteredDestinations: [],
      is_loading: false,
      grouped_inventory_local: [],
      successMessages: [],
      amountLeftInStock: '',
      search_value: '',
      receiver_id: '',
      receiver_name: '',
      receiver_phone: '',
      vvm_status: '',
      submitting: false,
      search_center_value: '',
      county: '',
      subcounty: '',
      my_subcounties: '',
      searchable_centers: [],
      canBeDispatchedTo: true,
      url: 'fetchCenterInventory',
      action: '',
      offset: 0,
      limit: 20
    }
  },
  watch:{
    inventory: function () {
      this.receiver_name = ''
      this.receiver_id = ''
      this.receiver_phone = ''
      this.dispatchTo = null
      this.condition= 'good'
      this.amountToDispatch= null
      this.vvm_status= 'N/A'
    },
    destinationLevel: function () {
      let filteredCenters = []
      // use getter to return all centers
      let allCentersList = this.allCenters;
      // loop through all centers
      allCentersList.map((center) => {
        // get the centers that match that level
        if (center.level === this.destinationLevel && center.id !== this.centerInfo.id) {
          filteredCenters.push(center)
        }
      })

      // assign to filteredDestination Centers
      this.filteredDestinations = filteredCenters

    }
  },
  methods: {
    ...mapActions(['fetchCenters', 'fetchCenterInventory', 'setError', 'fetchCurrentUser', 'fetchCenterInfo', 'fetchCounties', 'fetchSubCounties']),
    retrieveCurrentCenter() {
      return this.fetchCenterInfo();
    },
    retrieveSubCounties() {
      return this.fetchSubCounties()
    },
    retrieveCounties() {
      return this.fetchCounties()
    },
    setCenter(center) {
      this.filteredDestinations = [
        center
      ]
      this.dispatchTo = center.id
    },
    retrieveAllCenters() {
      return this.fetchCenters().then(allCenters => {
        // Get subcounty the center belongs to
        this.subcounty = this.allSubCounties.filter(subcounty => {
          return subcounty.id === this.centerInfo.sub_county
        })[0]

        // Get the county the center belongs to
        this.county = this.allCounties.filter(county => {
          return county.id === this.subcounty.county
        })[0]

        // Get the subcounties in the county
        this.my_subcounties = this.allSubCounties.filter(subcounty => {
          return subcounty.county === this.county.id
        }).map(subcounty => {
          return subcounty.id
        })

        this.searchable_centers = allCenters
      })
    },
    retrieveCenterInventory() {
      this.loading = true
      this.$store.dispatch(this.url, `?good_quantity_gt=0&limit=${this.limit}&offset=0`).then(resp => {
        this.loading = false
        return resp
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.loading = false
        this.$store.dispatch('setError', {'detail': 'Could Not Fetch Inventory'})
      });
    },
    dispatchInventory() {
      this.setError({})
      this.is_loading = true;
      this.submitting = true;

      // remove errors
      let batch_id = this.inventory.batch.id
      let quantity = this.amountToDispatch
      let reason = 'incoming'
      let condition = this.condition
      let center_id = this.centerInfo.id
      let remark = this.remark
      let to_center_id = this.dispatchTo
      let action = 'dispatched'
      let receivedOn = new Date()
      let is_returned = this.inventory.is_returned
      let vvm_status = this.vvm_status

      // Create distribution transaction
      this.$store.dispatch('createInventory', {
        batch_id: batch_id,
        reason: reason,
        condition: condition,
        to_center_id: to_center_id,
        center_id: center_id,
        remark: remark,
        receivedOn: receivedOn,
        is_returned: is_returned,
        action: action,
        quantity: quantity,
        vvm_status: vvm_status,
        collector: {
          name: this.receiver_name,
          id: this.receiver_id,
          phone: this.receiver_phone
        }
        // eslint-disable-next-line no-unused-vars
      }).then(resp => {
        this.submitting = false;
        $('#dispatchModal').modal('hide')
        this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Dispatched Batch"}])
        this.setError({})
        this.is_loading = false;

        this.retrieveCenterInventory()

        this.batch_id = ''
        this.quantity = ''
        this.reason = ''
        this.condition = 'good'
        this.center_id = ''
        this.remark = 'Dispatched in good condition'
        this.to_center_id = ''
        this.action = ''
        this.receivedOn = ''
        this.is_returned = ''
        this.vvm_status = '1'
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.submitting = false;
        this.is_loading = false;
      })
    },
    hide_err() {
      this.setError({})
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
  },
  computed: {
    ...mapGetters([
      'allLevels', 'allCenters', 'allCenterInventory', 'getError', 'getUser', 'centerInfo', 'allSubCounties', 'allCounties'
    ]),
    centerSearchResults() {
      if (this.search_center_value === '') {
        return []
      } else {
        return this.searchable_centers.filter(center => {
          let name = this.$func.titleCase(center.name)
          return name.startsWith(this.$func.titleCase(this.search_center_value))
        })
      }
    },
  },
  mounted(){
    this.retrieveAllCenters();
  }
}
</script>

<style scoped>

</style>