<template>
  <div class="col-md-3 col-12">
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label for="minAge">Min Age</label>
        <input id="minAge" v-model="minAge" class="form-control" type="text">
      </div>
      <div class="form-group col-12 col-md-6">
        <label for="minAge">Max Age</label>
        <input id="maxAge" v-model="maxAge" class="form-control" type="text">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgeRangeFilter",
  data(){
    return {
      minAge: 10,
      maxAge: 100
    }
  },
  watch: {
    minAge: function (){
      this.emitEvent()
    },
    maxAge: function(){
      this.emitEvent()
    }
  },
  mounted(){
    // this.emitEvent()
  },
  methods: {
    emitEvent(){
      this.$emit('setAgeRangeFilter', `&minAge=${this.minAge}&maxAge=${this.maxAge}`)
    }
  }
}
</script>

<style scoped>

</style>