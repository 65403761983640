<template>
  <div class="form-group">
    <label for="vaccine_filter" class="control-label">
      Filter By Vaccine
    </label>
    <select v-model="vaccine_filter" class="form-control" id="vaccine_filter" required>
      <option value="all_vaccines">All Vaccines</option>
      <option v-for="(vaccine, index) in vaccines" v-bind:key="index" :value="vaccine.id">{{
          vaccine.name
        }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "vaccine_filter",
  data () {
    return {
      vaccine_filter : 'all_vaccines',
      vaccines: '',
    }
  },
  props: [
    'default'
  ],
  watch: {
    vaccine_filter: function (){
      if( this.vaccine_filter !== 'all_vaccines'){
        var doses = this.vaccines.filter(vacc => {
          return vacc.id === this.vaccine_filter
        })[0].doses
      }

      this.$emit('updatedVaccineFilter', this.vaccine_filter, this.vaccine_doses)

      this.$emit('setVaccineFilter',
          this.vaccine_filter === 'all_vaccines' ? '' :
          '&vaccine=' + this.vaccine_filter, doses)

    }
  },
  methods: {
    retrieveAllVaccines(){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchVaccines').then(resp => {
        // Check if on defaulters report page
        if(this.$route.name === 'vaccination_defaulters_report'){
          // Only return multi dose vaccines
          this.vaccines = resp.filter(vaccine => {
            return vaccine.doses.length > 1
          })
        }else {
          this.vaccines = resp
        }
      })
    },
  },
  mounted(){
    this.retrieveAllVaccines()
  }
}
</script>

<style scoped>

</style>