<template>
  <div class="form-group">
    <label for="facility_filter" class="control-label">
      Filter By Facility
    </label>
    <select v-model="facility_filter" class="form-control" id="facility_filter" required>
      <option v-for="(facility, index) in facilities" v-bind:key="index" :value="facility.id">{{
          facility.name
        }} Facility Stats
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "facility_filter",
  data () {
    return {
      facility_filter : ''
    }
  },
  props: [
    'facilities',
    'default'
  ],
  watch: {
    facility_filter: function (){
      this.$emit('updatedFacilityFilter', this.facility_filter)
    }
  },
  mounted(){
    this.facility_filter = this.default
  }
}
</script>

<style scoped>

</style>