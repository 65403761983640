<template>
  <div>
    <LocationFilter @setLocationFilter="setLocationFilter" size="col-md-3"/>
    <div class="form-row">
      <div class="col-md-4">
        <div class="row">
          <DateFromFilter
              @setDateFromFilter="setDateFromFilter"
              class="col-md-6"
          />
          <DateToFilter
              @setDateToFilter="setDateToFilter"
              class="col-md-6"
          />
        </div>
      </div>
      <div class="col-md-2 d-flex pb-3">
        <button type="button" @click="filterStats" class="btn btn-primary align-self-end">
          Filter Stats
          <i class="feather icon-arrow-right"></i>
        </button>
      </div>
    </div>
    <div v-if="filter_selected">
      <div class="card">
        <div class="card-body">
          <default_table :isLoading="is_loading">
            <template #thead>
              <th>Age Band</th>
              <th v-for="(header, index) in headers" v-bind:key="index">
                {{header}}
              </th>
            </template>
            <template #tbody>
              <tr v-for="(stat, key, index) in stats" v-bind:key="index">
                <td v-for="(val, i) in stat" v-bind:key="i">
                  {{ val | withCommas }}
                </td>
              </tr>
            </template>
          </default_table>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-primary">
            Select filters then click filter stats to generate defaulters report
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFromFilter from "@/components/filters/DateFromFilter";
import DateToFilter from "@/components/filters/DateToFilter";
import {mapGetters} from "vuex";
import default_table from "@/components/templates/DefaultTable";
import LocationFilter from "@/components/filters/LocationFilter";
export default {
  name: "DefaultersByAge",
  components: {LocationFilter, default_table, DateToFilter, DateFromFilter},
  data(){
    return {
      is_loading: false,
      defaulters_count: '',
      filter_selected: false,
      location_filter: '',
      from_date: '',
      to_date: '',
      payload : ''
    }
  },
  computed:{
    ...mapGetters([
      'getDropoutsByAge'
    ]),
    headers: function () {
      return Object.keys(this.getDropoutsByAge)
    },
    stats: function () {
      let age_bands = [
        "70+",
        "60-69",
        "50-59",
        "40-49",
        "30-39",
        "18-29",
        "-18"
      ];

      let vaccines = Object.keys(this.getDropoutsByAge)

      let list = [];
      for (let i = 0; i < age_bands.length; i++) {
        let object = {};
        object.age_band = age_bands[i]
        for ( let j = 0; j < vaccines.length; j++) {
          let vacc_name = vaccines[j];
          object[vacc_name] = this.getDropoutsByAge[vacc_name][object.age_band]
        }

        list.push(object)
      }

      return list;
    }
  },
  methods: {
    formatObject(){
      let age_bands = [
        "70+",
        "60-69",
        "50-59",
        "40-49",
        "30-39",
        "18-29",
        "-18"
      ];

      let vaccines = Object.keys(this.getDropoutsByAge)

      let list = [];
      for (let i = 0; i < age_bands.length; i++) {
        let object = {};
        object.age_band = age_bands[i]
        for ( let j = 0; j < vaccines.length; j++) {
          let vacc_name = vaccines[j];
          object[vacc_name] = this.getDropoutsByAge[vacc_name][object.age_band]
        }

        list.push(object)
      }
    },
    setLocationFilter(payload){
      this.location_filter = payload
      this.updateFullPayload()
    },
    filterStats(){
      this.filter_selected = true
      this.updateFullPayload()
      this.fetchStats(this.payload)
    },
    fetchStats(payload) {
      this.$store.dispatch('clearDropoutStats')

      this.is_loading = true
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchDropoutsByAge', payload).then(stats => {
        this.is_loading = false

        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false
      })
    },
    setDateFromFilter(payload){
      this.from_date = payload
      this.updateFullPayload()
    },
    setDateToFilter(payload){
      this.to_date = payload
      this.updateFullPayload()
    },
    updateFullPayload(){
      let payload = this.location_filter

      payload = payload +
          this.from_date +
          this.to_date

      // Replace first string with ?
      this.payload = payload.replace('&','?');
    },
  },
  mounted(){
    this.formatObject()
  }
}
</script>

<style scoped>

</style>