import ManageVaccinees from "../../views/support/manage_vaccinees";
import InvalidAges from "../../views/invalid_details/age";
import InvalidVaccinations from "../../views/invalid_details/vaccinations";
import DeactivatedVaccinees from "../../views/support/deactivated_vaccinees";
import ManageFacilities from "../../views/support/manage_facilities";

const support_routes =[
    {
        path: '/manage_vaccinees',
        name: 'manage_vaccinees',
        component: ManageVaccinees,
        parentName: 'Support',
        verboseName: 'Search Vaccinee',
        icon:'<i class="dripicons-information"></i>',
        children: [
            {
                path: 'invalid-ages',
                name: 'invalid_ages',
                component: InvalidAges
            },
            {
                path: 'invalid-vaccinations',
                name: 'invalid_vaccinations',
                component: InvalidVaccinations
            },
            {
                path: 'deactivated_vaccinees',
                name: 'deactivated_vaccinees',
                component: DeactivatedVaccinees
            },
            {
                path: 'manage-facilities',
                name: 'manage_facilities',
                component: ManageFacilities
            }
        ]
    },

]

export default support_routes