<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs :pageName=appendPageNameToUser()></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <success_messages></success_messages>
    <div class="contentbar">
      <user_form :selected_user_id="getUserBeingUpdated.id"></user_form>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import user_form from "@/components/users/user_form";
import success_messages from "@/components/layouts/includes/success_messages";
import {mapGetters} from "vuex";

export default {
  name: "UpdateUser",
  components: {success_messages, user_form, Breadcrumbs},
  computed: {
    ...mapGetters(['getUserBeingUpdated'])
  },
  methods: {
    appendPageNameToUser() {
      return "Update " + (this.getUserBeingUpdated.username[0]).toUpperCase() + this.getUserBeingUpdated.username.substr(1)
    },
  }
}
</script>
<style lang="">

</style>