import api from "@/api";

const state ={
  notifications:[]
}
const getters ={
  allNotifications(state){
    return state.notifications
  },
}

const actions ={
  fetchNotifications({commit}, payload=''){
    return new Promise((resolve, reject)=>{
      api.get('/notifications/'+payload).then(resp => {
        commit('setNotifications', resp.data)
        resolve(resp.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchSingleNotification({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/notifications/'+payload).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },
  createNotification({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.post('/notifications/', payload).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        commit('set_error', err.response.data)
        reject(err)
      })
    })
  },

}
const mutations ={
  setNotifications:(state, notifications) => (state.notifications = notifications),
}

export default {
  state,
  getters,
  actions,
  mutations,
}