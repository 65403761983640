<template>
  <div>

    <router-view/>


    <form @submit.prevent="login">
      <div class="form-head">
        <div class="">
          <img
              src="@/assets/images/mohlogo.png" style="width: 100px; margin-top: -14px" class="img-fluid" alt="logo">
<!--          <img-->
<!--              src="../../assets/images/logo.png" style="width: 52px; margin-top: -14px" class="img-fluid" alt="logo">-->
          <span class="font-19 pt-3 text-defaultColor h4 d-block" style="font-weight: 600">Chanjo KE</span>
        </div>
        <div>

        </div>
      </div>
      <h6 class="text-defaultColor my-4">Log In </h6>

      <div class="form-group">
        <input v-model="username" type="text" name="username" autofocus="" autocapitalize="none" autocomplete="username"
               maxlength="150" class="form-control required" id="username" placeholder="Enter Username"
               required="required">
      </div>
      <div class="form-group">
        <input v-model="password" type="password" name="password" autocomplete="current-password"
               class="form-control"
               placeholder="Enter Password" id="id_password" required="required">
      </div>
      <div class="row d-none" v-if="password">
        <div class="form-group col-md-8">
          <input v-model="otp" type="text" name="otp" autofocus="" autocapitalize="none" autocomplete="otp"
                 maxlength="150" class="form-control required" id="otp" placeholder="Enter OTP"
          >
        </div>
        <div class="form-group col-md-4">
          <button v-if="otpRequested && countDown !== 0" type="button" @click="getOTP" class="btn btn-primary" disabled>
            Request again in {{countDown}}
          </button>
          <button v-else type="button" @click="getOTP" class="btn btn-primary">
            Get OTP
          </button>
        </div>
        <div v-if="is_loading" class="offset-5">
          <p class="loader"></p>
          <p>Loading...</p>
        </div>
        <div class="invalid-feedback d-block">
              <span v-for="(error, index) in getError" v-bind:key="index">
                {{ error.message }}
              </span>
        </div>
        <div class="valid-feedback d-block mb-4 mt-0" v-if="countDown !==0">
              <span v-for="(successMessage, index) in getSuccessMessages" v-bind:key="index">
                {{ successMessage.message }}. Expires in {{countDown}}
              </span>
        </div>
        <div class="invalid-feedback d-block mb-4 mt-0" v-if="countDown === 0">
            <span>
              OTP Expired, Request Again
            </span>
        </div>


      </div>
      <SubmittingLoader status="Logging In" v-if="submitting"></SubmittingLoader>
      <input v-else type="submit" class="btn btn-defaultColor btn-lg btn-block font-18 " value="login"/>
    </form>
    <div class="login-or">
      <h6 style="color: transparent">OR</h6>
    </div>
    <div class="form-row mb-3">
      <div class="col-sm-6">
        <div class="custom-control custom-checkbox text-left">
          <input type="checkbox" class="custom-control-input" id="rememberme">
          <label class="custom-control-label font-14" for="rememberme">Remember Me</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="forgot-psw">
          <router-link to="forgot_password" class="font-14">Lost password?</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {mapActions, mapGetters} from 'vuex'
import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";
import router from "@/router";

export default {
  name: "login",
  components: {SubmittingLoader},
  data() {
    return {
      error:{},
      username: "",
      password: "",
      otp: "",
      is_loading:false,
      countDown : 500,
      otpRequested: false,
      submitting: false
    }
  },


  methods: {
    ...mapActions(['setError']),
    getOTP(){
      this.is_loading = true;

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('requestOTP', {username:this.username, password:this.password}).then((resp)=>{
        this.otpRequested = true
        this.is_loading = false;
        this.countDown = 500;
        this.$store.dispatch('setNonFadingSuccessMessages', [{"message": "Successfully Sent OTP"}]);
        this.countDownTimer()
        // eslint-disable-next-line no-unused-vars
      }).catch((err)=>{
        this.is_loading = false;
      })

    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }else{
        this.otpRequested = false
      }
    },
    setLayout() {
      this.$store.commit('SET_LAYOUT', 'outside-dashboard')
      $("div.card").css("background-color", "#fff")
    },
    resetSuccessMessages(){
      this.$store.dispatch('setNonFadingSuccessMessages', []);
    },
    setLayoutForDashboard() {
      this.$store.commit('SET_LAYOUT', 'inside-dashboard')
    },
    login() {
      this.submitting = true
      let username = this.username
      let password = this.password
      let otp = this.otp
      this.$store.dispatch('login', {username, password, otp})
          .then(() => {
            this.$store.dispatch('setSuccessMessages',[{"detail": "Login Success"}])
            router.push('/home')
          })
          .then(this.setLayoutForDashboard)
          .catch(
              // eslint-disable-next-line no-unused-vars
              err =>{
                this.submitting = false
              }
          )

    },
    hide_err(){
      this.$store.dispatch('setError',{})
      this.$store.dispatch('setSuccessMessages',{})
    },
    isLoggedIn(){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchCenterInfo').then(resp=>{
        this.$store.commit('SET_LAYOUT', 'inside-dashboard')
        router.push('/home')
        console.log("user is logged in")
      }).catch(err=>{
        this.$store.commit('SET_LAYOUT', 'outside-dashboard')
        console.log("err ", err)
      })
    }
  },
  mounted() {
    this.isLoggedIn()
    this.resetSuccessMessages()
    this.setLayout()
    this.hide_err()
  },
  computed:{
    ...mapGetters(['getError', 'getSuccessMessages'])
  },
  created() {
    this.hide_err()
    this.countDownTimer()
  }
}
</script>

<style scoped>

</style>
