const state = {
    allowed_routes : [],
    allowed_components : []
}
const getters = {
    getAllowedRoutes(state){
        return state.allowed_routes
    },
    getAllowedComponents(state){
        return state.allowed_components
    }
}
const actions = {
    createAllowedRoutes({commit}, payload){
        commit('setAllowedRoutes', payload)
    },
    createAllowedComponents({commit}, payload){
        commit('setAllowedComponents', payload)
    }
}
const mutations = {
    setAllowedRoutes:(state, allowed_routes) => (state.allowed_routes = allowed_routes),
    setAllowedComponents:(state, allowed_components) => (state.allowed_components = allowed_components),
}

export default {
    state,
    getters,
    actions,
    mutations,
}