import MasterInventory from "../../views/inventory/master_inventory";
import BatchInventoryReport from "../../views/inventory/stock_inventory_report";
import DistributionCenters from "../../views/distribution_centers";
import ApproveBatches from "../../views/inventory/approval";
import Dispatching from "../../views/inventory/dispatching";
import Receiving from "../../views/inventory/receive";
import DistributionLedger from "../../views/distribution_ledger";
import DistributionLedgerTabular from "../../views/distribution_ledger/tabular";

const inventory_routes=[
    {
        path: '/master_inventory',
        name: 'master_inventory',
        component: MasterInventory,
        parentName: 'Vaccine Inventory',
        verboseName: 'My Inventory',
        icon:'<i class="feather icon-package"></i>',
        children: [
            {
                path: 'batch_inventory_report/:batch_number/:batch_inventory_id',
                name: 'batch_inventory_report',
                component: BatchInventoryReport,
                showInLeftBar: false
            },
            {
                path: 'distribution_centers',
                name: 'distribution_centers',
                component: DistributionCenters
            },
            {
                path: 'approve-batches',
                name: 'approve_batches',
                component: ApproveBatches
            },
            {
                path: 'dispatching',
                name: 'dispatching',
                verboseName: 'Dispatching',
                component: Dispatching,
            }, {
                path: 'receiving',
                name: 'receiving',
                verboseName: 'Receiving',
                component: Receiving,
            },
            {
                path: 'distribution-ledger',
                name: 'distribution_ledger_report',
                verboseName: 'Distribution Ledger',
                component: DistributionLedger,
            },
            {
                path: 'distribution-ledger-tabular',
                name: 'distribution_ledger_report_tabular_inventory',
                verboseName: 'Ledger Table',
                component: DistributionLedgerTabular,
            },
        ]
    },
]
export default inventory_routes