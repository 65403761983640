<template>
  <div>
    <div class="form-row">
      <div :class="'form-group ' + size">
        <label for="county-filter" class="control-label">
          Filter By County
        </label>
        <select v-model="county_filter" class="form-control" id="county-filter">
          <option v-if="centerInfo.level ==='national' && parentComponent !== 'registration'" value="all_counties">All Counties</option>
          <option v-for="(county, index) in countiesToFIlter" v-bind:key="index" :value="county.id">{{ county.name }}
          </option>
        </select>
      </div>
      <div :class="'form-group ' + size">
        <label for="subcounty_filter" class="control-label">
          Filter By Subcounty
        </label>
        <select v-model="subcounty_filter" class="form-control" id="subcounty_filter">
          <option v-if="parentComponent !== 'registration'" value="all_subcounties">All Subcounties</option>
          <option v-for="(subcounty, index) in my_subcounties" v-bind:key="index" :value="subcounty.id">{{
              subcounty.name
            }}
          </option>
        </select>
      </div>
      <div v-if="parentComponent !== 'userForm'" :class="'form-group ' + size">
        <label for="subcounty_filter" class="control-label">
          Filter By Ward
        </label>
        <select v-model="ward_filter" class="form-control" id="ward_filter" required>
          <option  v-if="parentComponent !== 'registration'" value="all_wards">All Wards</option>
          <option v-for="(ward, index) in wards" v-bind:key="index" :value="ward.id">{{
              ward.name
            }}
          </option>
        </select>
      </div>
      <div v-if="parentComponent !== 'registration'" :class="'form-group ' + size">
        <label for="facility_filter" class="control-label">
          Filter By Facility
        </label>
        <select v-model="facility_filter" class="form-control" id="facility_filter" required>
          <option value="all_facilities">All facilities</option>
          <option v-for="(facility, index) in subcounty_facilities" v-bind:key="index" :value="facility.id">{{
              facility.name
            }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LocationFilter",
  data(){
    return {
      is_loading: true,
      defaulters_count: '',
      countiesToFIlter: [],
      county_filter: '',
      subcounty_filter: 'all_subcounties',
      ward_filter: 'all_wards',
      facility_filter: 'all_facilities',
      filtered_subcounties: [],
      filtered_wards: [],
      subcounty_facilities: [],
      county: '',
      subcounty: '',
      my_subcounties: '',
      wards: [],
    }
  },
  props: {
    size: String,
    defaultWard: [Object],
    defaultFacility: [String, Number],
    parentComponent: String
  },
  computed: {
    ...mapGetters([
      'centerInfo',
      'allCounties',
      'allSubCounties',
      'allCenters'
    ])
  },
  methods: {
    ...mapActions([
      'fetchCenters'
    ]),
    setLocation() {
      var subcounty = ''
      this.$store.dispatch('fetchSubCounty', this.centerInfo.sub_county).then(resp => {
        subcounty = resp

        this.$store.dispatch('fetchCounty', subcounty.county).then(resp => {
          this.county = resp

          if(this.centerInfo.level === 'national' || this.parentComponent === 'registration'){
            if (this.centerInfo.level === 'national')
              this.county_filter = 'all_counties'

            this.$store.dispatch('fetchCounties').then(resp => {
              this.countiesToFIlter = resp
            })
          }else{
            this.county_filter = this.county.id
            this.countiesToFIlter.push(this.county)
          }


          // Get the subcounties in the county
          this.my_subcounties = this.allSubCounties.filter(subcounty => {
            return subcounty.county === this.county.id
          })
        })
      })
    },
    emitLocation(){
      // Specific facility selected
      if(this.facility_filter !== 'all_facilities') {
        let arg = '';

        if(this.parentComponent === 'adverseEffects' || this.parentComponent === 'userForm')
          arg = this.facility_filter;
        else
          arg = '&facility=' + this.facility_filter;

        return this.$emit('setLocationFilter', arg);
      } else {
        // If all facilities but a specific ward
        if(this.ward_filter !== 'all_wards') {
          let arg = '';

          if(this.parentComponent === 'registration')
            arg = this.ward_filter;
          else
            arg = '&ward=' + this.ward_filter;

          return this.$emit('setLocationFilter', arg);
        } else {
          // all facilities, all wards, specific subcounty
          if(this.subcounty_filter !== 'all_subcounties') {
            return this.$emit('setLocationFilter', '&subcounty=' + this.subcounty_filter)
          } else{
            // all facilities, all wards, all subcounties, specific county
            if(this.county_filter !== 'all_counties') {
              return this.$emit('setLocationFilter', '&county=' + this.county_filter)
            } else {
              // all facilities, all wards, all subcounties, all counties
              return this.$emit('setLocationFilter', '')
            }
          }
        }
      }
    },
    setDefaultWard(){
      if(this.defaultWard){
        this.wards.push(this.defaultWard);
        this.ward_filter = this.defaultWard.id;
      }
    },
    setDefaultFacility(){
      if(this.defaultFacility){
        this.facility_filter = this.defaultFacility

        this.$store.dispatch('fetchCenters', `${this.defaultFacility}/`).then(resp => {
          this.subcounty_facilities.push(resp);
        })
      }
    }
  },
  watch: {
    county_filter: function () {
      let filteredSubcounties = []

      if(this.filtered_subcounties)
      this.$store.dispatch('fetchSubCounties', (this.county_filter === 'all_counties') ? '' :
          `?county=${this.county_filter}`).then(resp => {
        if(this.county_filter === 'all_counties'){
          filteredSubcounties = resp
        }else{
          // loop through all subcounties
          resp.map((subcounty) => {
            // get the subcounties that match that county
            if (subcounty.county === this.county_filter) {
              filteredSubcounties.push(subcounty)
            }
          })
        }
      })

      // assign to filteredDestination Centers
      this.my_subcounties = filteredSubcounties
      this.subcounty_filter = 'all_subcounties'

      this.emitLocation()
    },
    subcounty_filter: function () {
      if(this.subcounty_filter !== 'all_subcounties'){
        // Fetch wards
        this.$store.dispatch('fetchWards', '?sub_county=' + this.subcounty_filter).then(resp => {
          this.wards = resp
        })

        // Fetch facilities
        this.$store.dispatch('fetchCenters', '?subcounty=' + this.subcounty_filter).then(resp => {
          this.subcounty_facilities = resp
        })
      }

      this.emitLocation()
    },
    ward_filter: function () {
      if(this.ward_filter !== 'all_wards'){
        this.$store.dispatch('fetchCenters', '?ward=' + this.ward_filter).then(resp => {
          this.subcounty_facilities = resp
        })
      }

      this.emitLocation()
    },
    facility_filter: function (){
      // Get facillity object selected
      let facility_object = this.subcounty_facilities.filter(fac => {
        return fac.id === this.facility_filter
      })[0]

      this.emitLocation()
      this.$emit('updatedFacilityFilter', this.facility_filter, facility_object)
    },
  },
  mounted() {
    this.setLocation();

    // Pass a clients ward in registration
    this.setDefaultWard()

    // Pass a users facility in user form
    this.setDefaultFacility()
  }
}
</script>

<style scoped>

</style>