<template>
  <div>
    <div v-if="currentRouteName === 'master_inventory'">
      <!-- Start Breadcrumbbar -->
      <breadcrumbs pageName="Inventory"></breadcrumbs>
      <!-- End Breadcrumbbar -->
      <!-- Start main page content -->
      <div class="contentbar">
        <!--      <router-view></router-view>-->
        <success_messages></success_messages>
        <batches_master_inventory></batches_master_inventory>
      </div>
    </div>
    <router-view>
    </router-view>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import batches_master_inventory from "@/components/inventory/batches_master_inventory";
import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
import success_messages from '@/components/layouts/includes/success_messages.vue';

export default {
  name: "dispatch_master",
  components: {
    Breadcrumbs,
    batches_master_inventory,
    success_messages
  },
  data(){
    return {
      isCurrentRoute: false
    }
  },
  computed: {
    ...mapGetters(['centerInfo']),
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions(['fetchCenterInfo']),
    retrieveCurrentCenter() {
      return this.fetchCenterInfo();
    }
  },
  mounted() {
    this.retrieveCurrentCenter();
  },
  watch: {
    // $route: {
    //   async handler() {
    //     if (
    //         this.$route.matched.length === 1 || (this.$route.matched.length > 1 && this.pageResult).length === 0 )
    //     {
    //       this.isCurrentRoute = true;
    //     }
    //   },
    // },
  },

}
</script>
<style>
.hide{
  display: none !important;
}
</style>