<template>
  <div class="form-group">
    <label for="batchFilter" class="control-label">
      Filter By Batch
    </label>
    <select v-model="batchFilter" class="form-control" id="batchFilter" required>
      <option value="all_batches">All Batches</option>
      <option v-for="(batch, index) in batches" v-bind:key="index" :value="batch.id">{{
          batch.batch_number
        }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "BatchFilter",
  data(){
    return {
      batchFilter: 'all_batches',
      batches: []
    }
  },
  props: {
    vaccineFilter: [String]
  },
  watch: {
    vaccineFilter: function (){
      // Fetch batches associated with a vaccine
      this.$store.dispatch('fetchAllVaccineBatches', `/?vaccine=${this.vaccineFilter.slice(-1)}`)
          .then(resp => {
            this.batches = resp
          })
    },
    batchFilter: function (){
        this.$emit('setBatchFilter', this.batchFilter ==='all_batches' ?
            '' :
            `&batch=${this.batchFilter}`
        )
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>