<template>
  <div class="modal-open">
    <submitting-loader></submitting-loader>
    <div :aria-labelledby="object_title" aria-hidden="true"
         class="modal fade show" role="dialog"
         style="display: block;" tabindex="-1" >
      <div :class="['modal-dialog ' + modal_size]" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-capitalize">Create {{ object_title }}</h5>
            <button aria-label="Close" class="close" @click="back" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <submitting-loader v-if="loading"></submitting-loader>
            <FormulateForm :errors="this.getError" :form-errors="formErrors" :schema="jsonSchema">

            </FormulateForm>
            <slot>

            </slot>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button" @click="back">Close</button>

            <button v-if="!submitting" class="btn btn-primary" @click="executeAction">
              Save
            </button>
            <submitting-loader v-if="submitting"></submitting-loader>
            <!--            <slot name="modal_footer">-->

            <!--            </slot>-->
          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SubmittingLoader from "@/components/inventory/SubittingLoader";

export default {
  name: "schema_create_modal",
  components: {SubmittingLoader},
  props: {
    object_title: {
      type: String,
      default: '',
    },
    vuex_action: {
      type: String,
    },
    vuex_payload: {},
    jsonSchema: {
      type: Array, default: () => {
        return []
      }
    },
    modal_size: {
      type: String,
      default: 'modal-lg'
    },
  },
  data() {
    return {
      form: {},
      inputErrors: {},
      formErrors: [],
      loading: false,
      submitting: false
    }
  },
  computed: {
    ...mapGetters(['getError'])
  },
  methods: {
    executeAction() {
      this.submitting = true
      let payload = {}
      if (this.vuex_payload) {
        payload = this.vuex_payload
      } else {
        payload = this.form
      }
      console.log("vuex", this.vuex_action)
      this.$store.dispatch(this.vuex_action, payload).then(() => {
        this.$toast.success(
            "" + this.object_title + " Created Successfully"
        )
        this.hidden = true;
        this.back()
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.submitting = false
      }).then(() => {
        // this.loading = false
      });
    },
    back() {
      this.$router.back()
    },
  }
}
</script>

<style scoped>

</style>