<template>
  <div>
    <div class="card mb-4">
      <div class="card-body">
        <div v-if="is_loading" class="offset-5">
          <p class="loader"></p>
          <p>Loading</p>
        </div>

        <form @submit.prevent="submitBatch">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label>
                Vaccine Generic Name
                <span class="text-danger">*</span>
              </label>
              <select v-model="vaccine_id" :class="'form-control ' + checkIfErrorExists('vaccine_id')"
                      id="vaccine_idSelect" required>
                <option v-for="(vaccine_id, index) in filteredVaccines" :value="vaccine_id.id" v-bind:key="index">
                  {{ vaccine_id.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.vaccine_id" v-bind:key="index">
                        {{ error }}
                      </span>
              </div>
            </div>
            <div v-if="vaccine_brands" class="form-group col-md-4">
              <label>
                Vaccine Brand Name
                <span class="text-danger">*</span>
              </label>
              <select v-model="vaccine_brand_id" :class="'form-control ' + checkIfErrorExists('vaccine_brand_id')"
                      id="vaccine_brand" required>
                <option v-for="(vaccine_brand, index) in vaccine_brands" :value="vaccine_brand.id" v-bind:key="index">
                  {{ vaccine_brand.name }} by {{vaccine_brand.manufacturer}}
                </option>
              </select>
              <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.vaccine_id" v-bind:key="index">
                        {{ error }}
                      </span>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="batch_number">
                Batch No
                <span class="text-danger">*</span>
              </label>
              <input v-model="batch_number" type="text" :class="'form-control ' + checkIfErrorExists('batch_number')"
                     id="batch_number" required>
              <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.batch_number" v-bind:key="index">
                        {{ error }}
                      </span>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="batchAmount">
                Number of Doses
                <span class="text-danger">*</span>
              </label>
              <input v-model="quantity" type="number" :class="'form-control ' + checkIfErrorExists('starting_quantity')"
                     id="batchAmount" required>
              <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.starting_quantity" v-bind:key="index">
                        {{ error }}
                      </span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="s13_delivery_note" class="control-label">
                S13
              </label>
              <input v-model="s13_delivery_note" id="s13_delivery_note" class="form-control" type="text" required>
            </div>
            <div class="form-group col-md-4">
              <label for="default-date">
                Date of Manufacture
                <span class="text-danger">*</span>
              </label>
              <div class="input-group">
                <input v-model="date_of_manufacture" type="date" id="default-date"
                       :class="'datepicker-here form-control ' + checkIfErrorExists('date_of_manufacture')"
                       placeholder="dd/mm/yyyy" aria-describedby="basic-addon2">
                <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.date_of_manufacture" v-bind:key="index">
                        {{ error }}
                      </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="date_of_expiry">
                Date of Expiry
                <span class="text-danger">*</span>
              </label>
              <div class="input-group">
                <input type="date" id="date_of_expiry" v-model="date_of_expiry"
                       :class="'datepicker-here form-control ' + checkIfErrorExists('date_of_expiry')"
                       placeholder="dd/mm/yyyy" aria-describedby="basic-addon2" required>
                <div class="invalid-feedback">
                      <span v-for="(error, index) in getError.date_of_expiry" v-bind:key="index">
                        {{ error }}
                      </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <div class="form-group">
                <label for="batchRemarkTextArea">
                  Remark
                  <span class="text-danger">*</span>
                </label>
                <textarea v-model="remark" class="form-control" id="batchRemarkTextArea" rows="3" required></textarea>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label>
                Condition On Arrival
                <span class="text-danger">*</span>
              </label>
              <select v-model="condition" class="form-control" id="condition" required>
                <option value="good">Accepted</option>
                <option value="damaged">Rejected(Damaged)</option>
                <option value="expired">Rejected(Expired)</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="vvm_status" class="control-label">
                VVM Status
                <span class="text-danger">*</span>
              </label>
              <select v-model="vvm_status" class="form-control" id="vvm_status" required>
                <option value="N/A">N/A</option>
                <option value="1">Stage 1</option>
                <option value="2">Stage 2</option>
                <option value="3">Stage 3</option>
                <option value="4">Stage 4</option>
              </select>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">
            Submit
            <i class="feather icon-arrow-right-circle"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {mapActions} from "vuex/dist/vuex.mjs";

export default {
  name: "receiving_form",
  data() {
    return {
      batch_number: '',
      quantity: '',
      date_of_manufacture: '',
      date_of_expiry: '',
      sourceCenter: null,
      vaccine_id: null,
      vaccine_brand_id: null,
      remark: '',
      condition: '',
      received_from: null,
      to_center_id: 0,
      action: 'received',
      is_returned: false,
      is_loading: false,
      successMessages: [],
      s13_delivery_note: '',
      s11_delivery_reports: '',
      vvm_status: '',
      vaccine_brands: false
    }
  },
  computed: {
    ...mapGetters([
      'allCenters', 'allVaccines', 'allBatches', 'loggedInCenter', 'getError', 'allVaccineBrands'
    ]),
    filteredVaccines() {
      if (this.centerInfo().level === 'vendor') {
        return this.allVaccines.filter(vaccine => {
          return vaccine.vendor_type === 'private'
        })
      } else {
        return this.allVaccines
      }
    }
  },
  methods: {
    ...mapGetters(['centerInfo',]),
    ...mapActions(['setError', 'fetchVaccines']),
    submitBatch() {
      this.is_loading = true;
      // remove errors
      this.setError({})
      let batch_number = this.batch_number
      let quantity = this.quantity
      let date_of_manufacture = this.date_of_manufacture
      let date_of_expiry = this.date_of_expiry
      let status = 'dispatched'
      let reason = 'incoming'
      let condition = this.condition
      let dispatchedBy = this.sourceCenter
      let receivedBy = this.loggedInCenter
      let vaccine_id = this.vaccine_id
      let remark = this.remark
      let to_center_id = this.to_center_id
      let action = this.action
      let receivedOn = '2020-11-21'
      let is_returned = this.is_returned
      let s13_delivery_note = this.s13_delivery_note
      let s11_delivery_reports = this.s11_delivery_reports
      let vvm_status = this.vvm_status
      let approved = true
      let vaccine_brand_id = this.vaccine_brand_id

      if (this.centerInfo().level === 'vendor') {
        approved = false
      }


      this.$store.dispatch('createBatch', {
        batch_number,
        quantity,
        date_of_manufacture,
        date_of_expiry,
        reason,
        condition,
        receivedBy,
        dispatchedBy,
        status,
        vaccine_id,
        remark,
        receivedOn,
        to_center_id,
        is_returned,
        action,
        s13_delivery_note,
        s11_delivery_reports,
        vvm_status,
        approved,
        vaccine_brand_id
      }).then(resp => {
        if (resp) {
          this.is_loading = false;
          this.$store.dispatch('setSuccessMessages', [{"message": "Batch received successfully"}])
          this.batch_number = ''
          this.quantity = ''
          this.date_of_manufacture = ''
          this.date_of_expiry = ''
          this.vaccine_id = null
          this.vaccine_brand_id = null
          this.remark = ''
          this.condition = ''
          this.s13_delivery_note = ''
          this.s11_delivery_reports = ''
          this.vvm_status = ''
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
    getCenterInfo() {
      this.to_center_id = this.centerInfo().centerId
      // Get center level .centerLevel
    },
    hide_err() {
      this.setError({})
    },
    retrieveVaccines() {
      this.$store.dispatch('fetchVaccines')
    },
    checkIfErrorExists(input_id) {
      if (Object.keys(this.getError).length === 0) {
        return '';
      } else if (typeof this.getError[input_id] !== "undefined") {
        return 'is-invalid'
      }
    },
    retrieveVaccineBrands() {
      this.$store.dispatch('fetchVaccineBrands')
    }
  },
  watch: {
    vaccine_id: function (){
      // Get brands associated with a vaccine
      let brands = this.allVaccineBrands.filter(brand => {
        return brand.vaccine.id === this.vaccine_id
      })

      // Set result
      if(brands.length > 0){
        this.vaccine_brands = brands
      }else{
        this.vaccine_brands = false
      }
    }
  },
  mounted() {
    this.getCenterInfo()
    this.retrieveVaccines()
    this.retrieveVaccineBrands()
    this.$store.dispatch('fetchCenters')
  },


}
</script>