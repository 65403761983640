import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import systemUsers from './modules/SystemUsers'
import persons from './modules/Persons'
import adverse_effects from './modules/AdverseEffects'
import centers from './modules/Centers'
import vaccines from './modules/Vaccines'
import batches from './modules/Batches'
import facilities from './modules/Facilities'
import gadgets from './modules/Gadgets'
import inventory from './modules/Inventory'
import current_center from './modules/CurrentCenter'
import options from './modules/Options'
import Auth from './modules/Auth'
import Reports from './modules/Reports'
import Support from './modules/Support'
import Routes from './modules/RoutesPermissions'
import Notifications from './modules/Notifications'

export default new Vuex.Store({
    state: {
        layout: 'inside-dashboard'
    },
    mutations: {
        SET_LAYOUT (state, payload){
            state.layout = payload
        }
    },
    actions: {
    },
    getters:{
        layout (state){
            return state.layout
        }
    },
    modules: {
        Auth,
        current_center,
        centers,
        vaccines,
        batches,
        facilities,
        gadgets,
        persons,
        inventory,
        systemUsers,
        adverse_effects,
        options,
        Reports,
        Support,
        Routes,
        Notifications
    }
})
