<template>
  <div class="card  m-b-30">
    <!--  Edit vaccination date modal  -->
    <div id="editVaccinationDate" aria-hidden="true" aria-labelledby="editVaccinationDateLabel" class="modal fade"
         role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editVaccinationDateLabel" class="modal-title">Edit {{ personDetails.first_name }}
              {{ personDetails.last_name }} Vaccination Date: Dose {{schedule_to_update.dose_number}}</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="schedule_to_update.dose_number === 1" class="form-group">
              <label class="control-label" for="batch">
                Batch
                <span class="text-danger">*</span>
              </label>
              <select id="batch" v-model="batch" class="form-control" required>
                <option
                    v-for="(batch, index) in vaccinating_batches"
                    :key="index" :value="batch.batch.id">
                  Batch {{ batch.batch.batch_number }} {{ batch.batch.vaccine.name }} In Stock
                  {{ batch.good_quantity - batch.used_quantity }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="date_administered" class="control-label">
                Date administered
                <span class="text-danger">*</span>
              </label>
              <input v-model="date_administered" id="date_administered" :class="'form-control ' + valid_date" type="datetime-local" required>
              <div class="invalid-feedback">
                <span v-if="valid_date === 'is-invalid'">
                  The date has to be between February and Today
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <a v-if="submitting === true" class="btn btn-white pr-2">
              Loading
              <div class="spinner-grow  text-success" role="status" style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status"  style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status"  style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
            </a>
            <button v-if="submitting === false && show_submit" @click="updateVaccinationDetails()" class="btn btn-primary" type="button">
              Update Vaccination Details
              <i class="feather icon-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <default_modal aria_label="Delete Vaccination" modal_header="Delete Vaccination" modal_id="delete_vaccination_modal">
      <h4 class="text-center text-danger">Confirm deletion of {{ personDetails.first_name }} {{ personDetails.middle_name }}
        {{ personDetails.last_name }} dose {{schedule_to_delete.dose_number}}</h4>
      <template v-slot:modal_footer>
        <button v-if="!submitting" class="btn btn-danger" @click="deleteVaccination">
          Delete
          <i class="feather icon-trash-2"></i>
        </button>
        <div v-else>
          <SubmittingLoader/>
        </div>
      </template>
    </default_modal>
    <div class="">
      <h4 class="card-title text-center mb-0"><strong><u>Vaccination Schedule</u></strong></h4>
    </div>
    <div class="">
      <div v-if="personVaccinations.length === 0"
           class="card-body text-center border-left-2">
        <h4 class="text-warning">
          {{ personDetails.first_name }} {{ personDetails.last_name }} is due for first dose
        </h4>
        <router-link v-if="$func.userHasRole('Vaccinator')" :to="{name: 'administer_vaccine', params: {dose: 1, expired_batch: expired_batch}}"
                     class="btn btn-primary my-3">
          Administer First Dose
          <i class="feather icon-thermometer"></i>
        </router-link>
      </div>
      <div v-else class="card-body">
        <h4 v-if="$func.userHasRole('Verifier')">
          {{ personDetails.first_name }} {{ personDetails.middle_name }} {{ personDetails.last_name }}
        </h4>
        <div v-if="scheduled_doses.length !== 0" class="activities-history">
          <router-link
              v-if="administerFirstDoseOnly && $func.userHasRole('Vaccinator')"
              :to="{name: 'administer_vaccine', params: {dose: 1, skipSchedule:true, expired_batch: expired_batch}}" class="btn btn-primary my-3 mr-1">
            Administer Dose 1
          </router-link>
          <router-link
              v-if="administerSecondDoseOnly && $func.userHasRole('Vaccinator')"
              :to="{name: 'administer_vaccine', params: {dose: 2, skipSchedule:true, expired_batch: expired_batch}}" class="btn btn-primary my-3 mr-1">
            Administer Dose 2
          </router-link>
          <div v-for="(schedule, index) in scheduled_doses" v-bind:key="index"
               class="activities-history-list">
            <div class="activities-history-item">
              <h4 v-if="schedule.date_administered">Vaccine: {{schedule.vaccine_name}}</h4>
              <h5 v-if="schedule.is_booster">Booster {{schedule.booster_number}}</h5>
              <h5 v-else>Dose {{ schedule.dose_number }}</h5>
              <h6 class="mb-0 mb-3">
                <span class="badge badge-primary-inverse pt-2">Scheduled</span>
                {{ new Date(schedule.date_scheduled).toDateString() }}
              </h6>
              <h6 v-if="schedule.date_administered === null" class="mb-0">
                <span class="badge badge-success-inverse pt-2">Administered</span>
                <span class="text-warning">
                    Pending
                  </span>
              </h6>
              <div v-else>
                <h6  class="mb-3">
                  <span class="badge badge-success-inverse pt-2">Administered</span>
                  <span class="text-success">
                    {{ new Date(schedule.date_administered).toDateString() }}
                  </span>
                </h6>
                <div v-if="$func.userHasRole('Trainer of Trainers(TOT)')">
                  <h6 class="mb-0 mb-3">
                    <span class="badge badge-success-inverse pt-2">Vaccinated at: </span>
                    <span v-if="center_vaccinated.dose_number === schedule.dose_number">
                    {{ center_vaccinated.facility_name }}
                  </span>
                    <span v-else class="text-primary show-cursor" @click="retrieveCenterVaccinated(schedule)">
                  Show Facility Vaccinated
                </span>
                  </h6>
                  <h6  class="mb-0 mb-3">
                    <span class="badge badge-success-inverse pt-2">Vaccinated by: </span>
                    <span v-if="vaccinated_by.dose_number === schedule.dose_number">
                    {{ vaccinated_by.username }}
                  </span>
                    <span v-else class="text-primary show-cursor" @click="retrieveSingleSystemUser(schedule)">
                  Show Vaccinator
                </span>
                  </h6>
                </div>
              </div>
              <router-link
                  v-if="schedule.date_administered === null && $func.userHasRole('Vaccinator') && canAdminister(schedule.date_scheduled)"
                  :to="{name: 'administer_vaccine', params: {dose: schedule.dose_number, expired_batch: expired_batch}}" class="btn btn-primary my-3 mr-1">
                Administer Dose {{ schedule.dose_number }}
              </router-link>
              <button v-if="$func.userHasRoles(['Trainer of Trainers(TOT)','Support', 'HRIO']) && schedule.date_administered" class="btn btn-primary my-3 mr-1" @click="openEditVaccinationModal(schedule)"
                      type="button">
                Edit Dose {{ schedule.dose_number }} Vaccination
              </button>
              <button v-if="canDeleteVaccination(schedule)" class="btn btn-danger my-3" @click="openDeleteVaccinationModal(schedule)"
                      type="button">
                Delete Vaccination
              </button>
            </div>
          </div>
          <router-link :to="{name: 'administer_vaccine', params: {dose: 'Booster', expired_batch: expired_batch}}"
                       class="btn btn-primary my-3">
            Administer Booster Dose
            <i class="feather icon-thermometer"></i>
          </router-link>
        </div>
        <router-link
            :to="{name: 'vaccination_certificate', params: {person: personDetails.id}}"
            class="btn btn-primary my-3">
          Vaccination Certificate
          <i class="feather icon-file-text"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
import default_modal from "@/components/modal/default_modal";
import SubmittingLoader from "@/components/inventory/SubittingLoader";

export default {
  name: "vaccination_schedule",
  components: {default_modal, SubmittingLoader},
  data() {
    return {
      vaccination: {},
      date_administered: '',
      submitting: false,
      valid_date: '',
      show_submit: false,
      scheduled_doses: [],
      schedule_to_update: {},
      vaccine: {},
      username: '',
      schedule_to_delete: {},
      batch: '',
      vaccinating_batches: [],
      dose: '',
      center_vaccinated: {
        "facility_name" : "",
        "dose_number" : ""
      },
      vaccinated_by: {
        "username" : "",
        "dose_number" : ""
      },
      vaccinations: [],
      boosterIsEligible: false,
      boosterIneligibleMessage: '',
      secondDoseGiven: false,
      administerFirstDoseOnly: false,
      administerSecondDoseOnly: false
    }
  },
  props: ['expired_batch'],
  computed: {
    ...mapGetters(['personDetails', 'personVaccinations', "allVaccines", 'allNationalities','getToken']),
  },
  methods: {
    ...mapActions(['fetchVaccines', 'retrieveCachedVaccinesById']),
    formatDate(date){
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    canAdministerSingleDoseOnly(schedule_doses){
      // First dose is either dose 2 or booster
      if(schedule_doses[0].dose_number === 2  || schedule_doses[0].is_booster){
        this.administerFirstDoseOnly = true;  
      }

      // Second dose is booster
      if(schedule_doses[1].is_booster){
        this.administerSecondDoseOnly = true;  
      }
    },
    openEditVaccinationModal(schedule){
      this.retrieveVaccinationBatches();
      this.schedule_to_update = schedule

      this.batch = schedule.batch
      // this.date_administered = new Date(schedule.date_administered).toISOString()
      $('#editVaccinationDate').modal('show')
    },
    openDeleteVaccinationModal(schedule){
      this.schedule_to_delete = schedule
      $('#delete_vaccination_modal').modal('show')
    },
    sendNotification(person, notification_message){
      return this.$store.dispatch('sendNotification', {
        notification_type: 'sms',
        priority: 3,
        recipient: person.phone,
        notification_message: notification_message,
        start_sending_at: new Date(),
      })
    },
    updateVaccinationDetails(){
      this.submitting = true;

      //Set updated dose details
      let dose = {
        id: this.schedule_to_update.id,
        date_scheduled: this.formatDate(this.date_administered),
        date_administered: this.date_administered,
      }

      // Edit both first and subsequent dose
      if(this.schedule_to_update.dose_number === 1){
        // If batch has not been changed
        if(this.schedule_to_update.batch === this.batch){
          // eslint-disable-next-line no-unused-vars
          this.$store.dispatch('updateVaccinationDetails', dose).then(dose => {
            this.submitting=false;
            $('#editVaccinationDate').modal('hide')
            this.personDetails = {}
            this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Updated Vaccination Schedule"}]).then(
                this.$store.dispatch('setPersonDetails', {})
            )
            // eslint-disable-next-line no-unused-vars
          }).catch(err => {

          })
        }else{
          let payload = {
            schedule_id: this.schedule_to_update.id,
            date_administered: this.schedule_to_update.date_administered,
            batch: this.batch
          }

          // eslint-disable-next-line no-unused-vars
          this.$store.dispatch('changeVaccinatingBatch', payload).then(dose => {
            $('#editVaccinationDate').modal('hide')
            this.personDetails = {}
            this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Updated Vaccination Schedule"}]).then(
                this.$store.dispatch('setPersonDetails', {})
            )
            // eslint-disable-next-line no-unused-vars
          }).catch(err => {

          })
        }
      }else{
        // Edit second dose only
        this.$store.dispatch('updateVaccinationDetails', dose).then(dose => {
          if(dose){
            $('#editVaccinationDate').modal('hide')
            this.submitting = false;
            this.$store.dispatch('setSuccessMessages', [{"message": "Successfully Updated Vaccination Schedule"}])
            this.$store.dispatch('setPersonDetails', {})
            this.batch = ''
            this.date_administered = ''
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {

        })
      }
    },
    deleteVaccination(){
      this.is_loading = true;
      // If dose 1 or a single dose vaccine
      if(this.schedule_to_delete.dose_number === 1 || this.scheduled_doses.length === 1){
        this.deleteVaccinatioObject()
      }else{
        // eslint-disable-next-line no-unused-vars
        this.$store.dispatch('deleteVaccinationSchedule', this.schedule_to_delete.id).then(resp => {
          this.is_loading = false;
          $('#delete_vaccination_modal').modal('hide')
          this.$store.dispatch('setSuccessMessages', [{"message": "Vaccination deleted successfully"}])
          this.$store.dispatch('setPersonDetails', {})
          this.$router.push({name: "persons"})
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.is_loading = false;
        })
      }
    },
    canAdminister(scheduled_date) {
      if(this.username === 'markmuigai' || this.username === 'jared'){
        return true
      }else{
        if(this.vaccine.id === 1){
          var updated_date = new Date(scheduled_date)

          updated_date.setDate(updated_date.getDate() - 28)

          //subtract 4 weeks from scheduled date
          return new Date() >= updated_date
        }else{
          return new Date() >= new Date(scheduled_date)
        }
      }
    },
    getVaccination() {
      return this.personDetails.vaccinations[0]
    },
    checkSecondDoseGiven(){
      return this.secondDoseGiven = this.scheduled_doses.find(dose => {
        return dose.dose_number === 2 && dose.date_administered 
      })
    },
    retrieveScheduledDoses() {
      this.$store.dispatch('fetchPersonVaccinations', this.personDetails.id).then(resp => {
        this.vaccination = resp[0]
      
        // if client has been vaccinated
        this.scheduled_doses  = resp[0].scheduled_doses
        let vaccine_id = resp[0].vaccine;
        let allVaccines = this.allVaccines;

        
        let selected_vaccine = allVaccines.filter(vaccine =>{
          return vaccine.id === vaccine_id
        });
        
        // console.log('dose given')

        let boosterIndex = 1;

        // Update booster dose numbers
        for(const dose of this.scheduled_doses){
          if(dose.is_booster){
            dose.booster_number = boosterIndex
            boosterIndex = boosterIndex + 1
          }
        }

        this.vaccine = selected_vaccine[0];

        this.canAdministerSingleDoseOnly(resp[0].scheduled_doses)
        this.checkBoosterEligibility(resp[0])
        this.checkSecondDoseGiven();
        console.log('dose given', this.secondDoseGiven)
      })
    },
    retrieveVaccinationBatches() {
      this.is_loading = true;
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('fetchVaccinationBatches').then(resp => {
        this.is_loading = false

        this.vaccinating_batches = resp

        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.setError({'detail': 'error fetching inventory'})
        this.is_loading = false
      });
    },
    retrieveCenterVaccinated(schedule){
      this.$store.dispatch('fetchSingleCenter', schedule.facility).then(resp => {
        console.log("center vaccinated b4", this.center_vaccinated)
        this.center_vaccinated = {
          "facility_name" : resp.name,
          "dose_number" : schedule.dose_number
        }
        console.log("center vaccinated", this.center_vaccinated)
      })
    },
    retrieveSingleSystemUser(schedule){
      this.$store.dispatch('fetchSingleSystemUser', schedule.vaccinator).then(resp => {
        this.vaccinated_by = {
          "username" : resp.username,
          "dose_number" : schedule.dose_number
        }
        console.log("center vaccinated", this.center_vaccinated)
      })
    },
    canDeleteVaccination(schedule){
      // Check if there is a dose 2 and if administered
      if(schedule.dose_number === 1 && this.secondDoseGiven){
        return false
      }
      return this.$func.userHasRoles(['Trainer of Trainers(TOT)','Support', 'HRIO'])
          && (schedule.date_administered || schedule.is_booster)
    },
    deleteVaccinatioObject(){
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('deleteVaccinationObject', this.personVaccinations[0].id).then(resp => {
        this.is_loading = false;
        $('#delete_vaccination_modal').modal('hide')
        this.$store.dispatch('setSuccessMessages', [{"message": "Vaccination deleted successfully"}])
        this.$store.dispatch('setPersonDetails', {})
        this.$router.push({name: "persons"})
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.is_loading = false;
      })
    },
    checkBoosterEligibility(vaccination){
      if(vaccination.scheduled_doses.at(-1).date_administered){
        this.boosterIsEligible = true
      }
    },
  },
  watch: {
    date_administered: function () {
      let date_administered = new Date(this.date_administered)
      if (date_administered < new Date('2021-2-1') || date_administered > new Date()) {
        this.valid_date = 'is-invalid'
        this.show_submit = false
      } else {
        this.valid_date = 'is-valid'
        this.show_submit = true
      }
    },
    batch: function () {
    },
  },
  mounted() {
    this.retrieveScheduledDoses()
  }
}
</script>

<style scoped>
 .show-cursor{
   cursor: pointer;
 }
</style>