
<template>
  <div class="card">
    <div class="card-header">
      <h4>
        Total Vaccinations by Occupation
      </h4>
    </div>
    <div class="card-body">
      <div class="table-responsive m-b-30">
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Occupation</th>
            <th scope="col">Vaccinations</th>
          </tr>
          </thead>
          <tbody v-if="occupationStatsLoading">
          <tr>
            <td colspan="2" class="text-center">
              <div class="spinner-grow  text-primary" role="status" style="height: 20px; width: 20px">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr v-for="(occupation_stat, index) in occupationStats" v-bind:key="index">
            <td>{{ occupation_stat.occupation_name }}</td>
            <td>{{ occupation_stat.people | withCommas }}</td>
          </tr>
<!--          <tr v-if="otherOccupations > 0">-->
<!--            <td>Other</td>-->
<!--            <td>{{otherOccupations | withCommas }}</td>-->
<!--          </tr>-->
<!--          <tr v-if="above58 > 0">-->
<!--            <td>Above 58</td>-->
<!--            <td>{{above58 | withCommas }}</td>-->
<!--          </tr>-->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OccupationVaccinations",
  props: {
    payload: String,
    triggerUpdate: Boolean
  },
  data(){
    return {
      occupationStats: [],
      above_58: '',
      occupationStatsLoading: true,
      other_occupations: '',
    }
  },
  watch: {
    triggerUpdate: function () {
      return this.fetchOccupationJabs()
    }
  },
  methods: {
    fetchOccupationJabs() {
      this.$store.dispatch('fetchOccupationGroupedJabs', this.payload).then(occupationStats => {
        this.occupationStats = occupationStats;

        this.occupationStatsLoading = false;

        // eslint-disable-next-line no-unused-vars
        // this.$store.dispatch('fetchJabsOver58', this.payload).then(above_58 => {
          // let occupation_stats = ''
          // if(this.isCummulativeStats()){
          //   this.above_58 = above_58.jabs
          //   this.occupation_stats = occupation_stats.jabs_occupation_grouped
          // }else{
          //   this.above_58 = above_58.jabs_today
          //   this.occupation_stats = occupation_stats.jabs_today_occupation_grouped
          // }
          //
          //
          // this.all_occupations = occupation_stats.filter(occupation => {
          //   return occupation.occupation_name !== 'Other'
          // })
          //
          // let other_occupations = occupation_stats.filter(occupation => {
          //   return occupation.occupation_name === 'Other'
          // })
          //
          // if(other_occupations.length > 0){
          //   this.other_occupations = other_occupations[0].people - this.above_58
          // }
          //
          // this.occupation_stats_loading = false
        // })
      })
    }
  },
  mounted() {
    this.fetchOccupationJabs()
  }
}
</script>

<style scoped>

</style>