<template>
  <div class="card" id="certificate">
  </div>
</template>

<script>
// import router from "@/router";

export default {
  name: "verify_vaccination_certificate",
  mounted(){
    window.location.href = "https://portal.health.go.ke/certificate/" + this.$route.params.hash
    // router.push("https://portal.health.go.ke/certificate" + this.$route.params.hash);
  }
}
</script>

<style scoped>

</style>