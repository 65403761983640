<template>
  <div class="card">
    <div class="card-body">
      <error_messages/>
      <div class="row">
        <div class="col-md-8">
          <h4 class="mb-3">
            Search for a vaccinee
          </h4>
          <h5 class="text-danger">NB: Search using different ID types if vaccinee is not found using the ID type and ID number provided</h5>
        </div>
        <div v-if="$func.userHasRole('Vaccinator') || $func.userHasRole('Clerk')" class="col-md-4 float-right">
          <router-link :to="{name: 'register_person'}" class="btn btn-primary ml-2 float-right">
            Register Vaccinee
            <i class="feather icon-user-plus"></i>
          </router-link>
        </div>
      </div>
      <form @submit.prevent="searchPerson()">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="id_type">
              {{search_label.label}}
              <span class="text-danger">*</span>
            </label>
            <select id="id_type" v-model="id_type" class="form-control mr-2" required>
              <option value="national_id">National ID</option>
              <option value="passport">Passport Number</option>
              <option value="birth_cert">Birth Certificate</option>
              <option value="military_id">Military ID</option>
              <option value="alien_id">Alien ID</option>
              <option value="prisoner_id">Prisoner ID</option>
              <option value="nemis">NEMIS n.o</option>
              <option value="unknown">Unknown</option>
              <option value="phone">Phone Number</option>
            </select>
          </div>
          <div class="form-group col-md-3 mr-2">
            <label for="id_number">
              {{search_label.value}}
              <span class="text-danger">*</span>
            </label>
            <input id="id_number" v-model="id_number" class="form-control" type="text" required>
          </div>
          <div class="col-md-2">
            <label class=""></label> <br>
            <SubmittingLoader status="Searching" class="mt-2" v-if="is_loading"/>
            <button v-else class="btn btn-primary mt-2">
              Search Vaccinee
              <i class="feather icon-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {toTitleCase} from "@/utils/methods";
import SubmittingLoader from "@/components/layouts/includes/SubmittingLoader";
import error_messages from "../layouts/includes/error_messages.vue";

export default {
  name: "search_person",
  components: { SubmittingLoader, error_messages },
  data: function () {
    return {
      id_type: '',
      id_number: '',
      is_loading: false,
      search_label: {
        label: 'ID Type',
        value: 'ID Number'
      }
    }
  },
  methods: {
    ...mapActions(['setError']),
    toTitleCase(str){
      return toTitleCase(str)
    },
    searchPerson() {
      //clear person details
      this.$store.dispatch('setPersonDetails', {})
      this.$store.dispatch('clearPersonVaccination')
      this.$store.dispatch('clearSearchResults', {})
      this.is_loading = true;
      if(this.id_type === 'phone'){
        this.searchByPhone()
      }else{
        this.searchByID()
      }
    },
    searchByID(){
      let minimal = '';

      if(this.$route.name === 'adverse_events_page'){
        minimal = 0
      }else{
        minimal = 1
      }

      this.$store.dispatch('searchPerson', `?id_type=${this.id_type}&id_number=${this.id_number}&minimal=${minimal}`
      ).then(resp => {
        if(Array.isArray(resp) && resp.length === 0){
          this.$store.dispatch('setError', {'data':'No person found with the given id type and id number'})
          this.$store.dispatch('setPersonDetails', {})
          this.is_loading = false;
        }else{
          this.$store.dispatch('setSuccessMessages', [{"message": "Person found successfully"}])
          this.setError({})
          this.is_loading = false;
          this.id_number = this.personDetails.identification[0].id_number;
          this.id_type = this.personDetails.identification[0].id_type;
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setPersonDetails', {})
        this.is_loading = false;
      })
    },
    searchByPhone(){
      let payload = '?phone=%2B' + (this.id_number).toString().substring(1)

      this.$store.dispatch('advancedSearch', payload
      ).then(resp => {
        if (resp.length > 0) {
          this.$store.dispatch('setSuccessMessages', [{"message": "Vaccinees found successfully"}])
          this.setError({})
          this.is_loading = false;
        }else{
          this.is_loading = false;
          this.setError({
            message: "No vaccinee found"
          })
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        this.$store.dispatch('setPersonDetails', {})
        this.is_loading = false;
      })
    },
    hide_err(){
      this.setError({})
    }
  },
  watch: {
    id_type: function (){
      if(this.id_type === 'phone'){
        this.id_number = '+254'
        this.search_label = {
          label: 'Phone Number',
          value: 'Number e.g +254712345678'
        }
      }else{
        if(this.id_number === '+254'){
          this.id_number =  ''
        }

        this.search_label = {
          label: 'ID Type',
          value: 'ID Number'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['personDetails','personVaccinations', 'getError', 'getVaccineById'])
  },
}
</script>

<style scoped>

</style>