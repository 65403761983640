<template>
  <div>
    <!-- Start Breadcrumbbar -->
    <breadcrumbs pageName="Dispatch batches"></breadcrumbs>
    <!-- End Breadcrumbbar -->
    <!-- Start main page content -->
    <div class="contentbar">
      <div v-if="$func.userHasRole('Stock Manager')">
        <success_messages> </success_messages>
        <dispatch_batches_list></dispatch_batches_list>
      </div>
      <div class="row" v-else>
        <AccessDeniedCard/>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import Breadcrumbs from "@/components/layouts/includes/Breadcrumbs";
  import dispatch_batches_list from "@/components/inventory/dispatch_batches_list";
  import success_messages from '@/components/layouts/includes/success_messages.vue';
  import {mapActions} from "vuex";
  import AccessDeniedCard from "../../components/templates/AccessDeniedCard";

  export default {
    name: "dispatching",
    components: {AccessDeniedCard, dispatch_batches_list, Breadcrumbs, success_messages},
    methods: {
      ...mapActions(['setError']),
      clearErrors(){
        this.setError({})
      },
    },
    mounted() {
      this.clearErrors()
    }
  }
</script>