<template>
  <div id="containerbar">
    <InfoBar></InfoBar>
    <LeftBar :centerInfo="centerInfo"></LeftBar>
    <!-- Start Rightbar -->
    <div class="rightbar" style="overflow: scroll">
      <!-- Start Topbar -->
      <TopBar :centerInfo="centerInfo"></TopBar>
      <!-- End Topbar -->
      <vuescroll>
        <router-view>

        </router-view>

        <slot>
        </slot>
      </vuescroll>

      <!-- End main page content-->
      <!-- Start Footer -->
      <Footer :centerInfo="centerInfo"></Footer>
      <!-- End Footer -->
    </div>
    <!-- End Rightbar -->
  </div>
</template>

<script>
import InfoBar from "@/components/layouts/includes/InfoBar";
import LeftBar from "@/components/layouts/includes/Leftbar";
import TopBar from "@/components/layouts/includes/TopBar";
import Footer from "@/components/layouts/includes/Footer";
import vuescroll from 'vuescroll';
import {mapActions, mapGetters} from "vuex";


export default {
  name: "InsideDashboard",
  components: {Footer, TopBar, LeftBar, InfoBar, vuescroll},
  computed: {
    ...mapGetters(['centerInfo', 'getError', 'getSuccessMessages'])
  },
  methods: {
    ...mapActions(['fetchCenterInfo', 'fetchCenterInfoWithBatchReport']),
    retrieveCurrentCenter() {
      return this.fetchCenterInfoWithBatchReport();
    },
  },
  mounted() {
    this.retrieveCurrentCenter()
  },
  watch: {
    getError() {
      if (Object.keys(this.getError).length > 0) {
        let err = this.getError
        let full_error_message = ''
        Object.keys(err).map(msg_instance => {
          full_error_message += `${err[msg_instance]} \n`;
        })
        this.$notification.error(full_error_message, {title: 'An Error Occurred',});
      }
    },
    getSuccessMessages(){
      if(Object.keys(this.getSuccessMessages).length > 0){
        console.log(this.getSuccessMessages)
        let string_msg =''
        this.getSuccessMessages.map(msg=>{
          return Object.keys(msg).map((val)=>{
            string_msg += msg[val]
          })
        })
        this.$notification.success(string_msg)
      }
    }
  }
}
</script>

<style scoped>

</style>