<template>
  <div class="col-md-5 offset-md-3 onboarding-bg">
    <div class="card mt-5" style="background-color: #fffffff2">
      <div class="card-header">
      </div>
      <div class="card-body text-center ">
        <div class="">
          <h1 class="text-danger text-center">
            <i class="feather icon-alert-circle"></i>
          </h1>
          <h2 class="text-center text-danger">403</h2>
          <h3 class="text-danger">Access Forbiden</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessDeniedCard"
}
</script>

<style scoped>

</style>